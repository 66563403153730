import type { UntitledIconData } from '../../UntitledIconData'

const ulUnderline01: UntitledIconData = {
  prefix: 'ul',
  name: 'underline-01',
  paths: [
    {
      shape: 'M18 4V11C18 14.3137 15.3137 17 12 17C8.68629 17 6 14.3137 6 11V4M4 21H20',
    },
  ],
}

export default ulUnderline01
