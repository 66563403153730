import { UntitledIcon } from '@faceup/icons'
import { Card, UnstyledButton } from '@faceup/ui'
import { Avatar, Modal, type ModalProps, Typography } from '@faceup/ui-base'
import { FormattedMessage, defineMessages } from '../../TypedIntl'
import type { ContactVariant } from '../ContactSalesModal'

const messages = defineMessages({
  title: 'Administration.contactSalesModal.overview.title',
  heading: 'Administration.contactSalesModal.overview.heading',
  description: 'Administration.contactSalesModal.overview.description',
})

type ContactSalesModalOverviewProps = {
  contactVariants: ContactVariant[]
} & Required<Pick<ModalProps, 'open' | 'onClose'>>

const ContactSalesModalOverview = ({
  contactVariants,
  ...props
}: ContactSalesModalOverviewProps) => (
  <Modal {...props} title={<FormattedMessage {...messages.title} />} width={950}>
    <div className='flex flex-col gap-8 text-center'>
      <div>
        <Typography.Title level={3}>
          <FormattedMessage {...messages.heading} />
        </Typography.Title>
        <Typography.Text type='secondary'>
          <FormattedMessage {...messages.description} />
        </Typography.Text>
      </div>
      <div className='grid grid-cols-4 gap-4'>
        {contactVariants.map(({ label, icon, onClick, type }) => (
          <UnstyledButton key={type} onClick={onClick}>
            <Card className='w-full h-full p-[42px_32px_32px_32px] flex flex-col items-center justify-center gap-7'>
              <Avatar size='large'>
                <UntitledIcon icon={icon} size={24} />
              </Avatar>
              <Typography.Text strong>{label}</Typography.Text>
            </Card>
          </UnstyledButton>
        ))}
      </div>
    </div>
  </Modal>
)

export default ContactSalesModalOverview
