import { type RouteCallback, useLocation, useMatchPath } from '@faceup/router'
import { useEffect } from 'react'
import { routes } from '../App/AppRouter/useRoutes'
import { useAppUpdater } from '../Contexts/AppUpdaterProvider'
import { defineMessages, useIntl } from '../TypedIntl'
import { ThemeType, getTheme } from '../locales'
import useAnalytics from '../utils/analytics'
import Auth from '../utils/auth'

const theme = getTheme()

const messages = defineMessages({
  login: 'Administration.page.login',
  sso: 'Administration.page.sso',
  dashboard: 'Administration.page.dashboard',
  reports: 'Administration.page.reports',
  report: 'Administration.page.report',
  materials: 'Administration.page.materials',
  services: 'Administration.page.services',
  service: 'Administration.page.service',
  settingsAccount: 'Administration.page.settings.profile',
  settingsUsers: 'Administration.page.settings.users',
  settingsStructure: 'Administration.page.settings.structure',
  settingsAffiliate: 'Administration.settings.affiliate',
  settingsCustomization: 'Administration.page.settings.customization',
  settingsInvoicing: 'Administration.settings.tabInvoicing',
  settingsInstitution: 'Administration.page.settings.institution',
  settingsWhitelabel: 'Administration.page.settings.whitelabel',
  profileNotifications: 'Administration.page.profile.notifications',
  profileSecurity: 'Administration.page.profile.security',
  profileAffiliate: 'Administration.page.profile.affiliate',
  password: 'Administration.page.password',
  notFound: 'Administration.page.notFound',
  trialRequest: 'Administration.page.register.trialRequest',
  fillInfo: 'Administration.page.register.fillInfo',
  customization: 'Administration.customization.title',
  institutions: 'Administration.page.institutions',
  partnerSettings: 'Administration.page.partnerSettings',
  statistics: 'Administration.page.statistics',
  hotlineSetup: 'Administration.hotlineSetup.title',
  connectReportSource: 'Administration.connectReportSource.title',
  settingsInvoicingChangePlan: 'Administration.companyInvoicing.changePlan',
  dataManagement: 'Administration.page.dataManagement',
  integrations: 'Administration.page.integrations',
  surveys: 'Administration.surveys.title',
})

const setDocumentTitle = (title: string, domain?: string) =>
  (document.title = `${title} ${domain ? `| ${domain}` : ''}`)

const useGetTitle = () => {
  const { formatMessage } = useIntl()
  const matchPath = useMatchPath()

  const isRouteActive: (path: RouteCallback | string) => boolean = path => Boolean(matchPath(path))

  const getTitle = () => {
    if (isRouteActive(routes.dashboard) && Auth.isAuthenticated()) {
      return formatMessage(messages.dashboard)
    } else if (isRouteActive(routes.home)) {
      return formatMessage(messages.login)
    } else if (isRouteActive(routes.reports)) {
      return formatMessage(messages.reports)
    } else if (isRouteActive(routes.partnerReports)) {
      return formatMessage(messages.reports)
    } else if (matchPath(routes.report, { wildcard: true })) {
      return formatMessage(messages.report)
    } else if (matchPath(routes.reportExport, { wildcard: true })) {
      return formatMessage(messages.report)
    } else if (isRouteActive(routes.settingsUsers)) {
      return formatMessage(messages.settingsUsers)
    } else if (isRouteActive(routes.settingsInstitution)) {
      return formatMessage(messages.settingsInstitution)
    } else if (isRouteActive(routes.settingsStructure)) {
      return formatMessage(messages.settingsStructure)
    } else if (isRouteActive(routes.settingsCustomization)) {
      return formatMessage(messages.settingsCustomization)
    } else if (isRouteActive(routes.settingsInvoicing)) {
      return formatMessage(messages.settingsInvoicing)
    } else if (isRouteActive(routes.accountGeneral)) {
      return formatMessage(messages.settingsAccount)
    } else if (isRouteActive(routes.accountNotifications)) {
      return formatMessage(messages.profileNotifications)
    } else if (isRouteActive(routes.accountAffiliate)) {
      return formatMessage(messages.profileAffiliate)
    } else if (isRouteActive(routes.accountSecurity)) {
      return formatMessage(messages.profileSecurity)
    } else if (isRouteActive(routes.materials) || isRouteActive(routes.materialsTable)) {
      return formatMessage(messages.materials)
    } else if (isRouteActive(routes.institutionServices) || isRouteActive(routes.partnerServices)) {
      return formatMessage(messages.services)
    } else if (isRouteActive(routes.institutionService) || isRouteActive(routes.partnerService)) {
      return formatMessage(messages.service)
    } else if (
      isRouteActive(routes.registerTrialRequest) ||
      isRouteActive(routes.registerPurchase)
    ) {
      return formatMessage(messages.trialRequest)
    } else if (isRouteActive(routes.registerFillInfo)) {
      return formatMessage(messages.fillInfo)
    } else if (
      matchPath(routes.institutionCustomization, {
        wildcard: true,
      })
    ) {
      return formatMessage(messages.customization)
    } else if (isRouteActive(routes.loginSso)) {
      return formatMessage(messages.sso)
    } else if (isRouteActive(routes.token)) {
      return formatMessage(messages.password)
    } else if (
      matchPath(routes.settingsInstitution, { wildcard: true }) ||
      isRouteActive(routes.institutions)
    ) {
      return formatMessage(messages.institutions)
    } else if (matchPath(routes.partnerSettings, { wildcard: true })) {
      return formatMessage(messages.partnerSettings)
    } else if (matchPath(routes.partnerAccountGeneral, { wildcard: false })) {
      return formatMessage(messages.settingsAccount)
    } else if (matchPath(routes.partnerAccountSecurity, { wildcard: false })) {
      return formatMessage(messages.profileSecurity)
    } else if (isRouteActive(routes.statistics)) {
      return formatMessage(messages.statistics)
    } else if (isRouteActive(routes.partnerStatistics)) {
      return formatMessage(messages.statistics)
    } else if (isRouteActive(routes.institutionCustomizationCreate)) {
      return formatMessage(messages.connectReportSource)
    } else if (isRouteActive(routes.institutionCustomizationCreateHotline)) {
      return formatMessage(messages.hotlineSetup)
    } else if (matchPath(routes.settingsInvoicingChangePlan, { wildcard: true })) {
      return formatMessage(messages.settingsInvoicingChangePlan)
    } else if (matchPath(routes.dataManagement, { wildcard: true })) {
      return formatMessage(messages.dataManagement)
    } else if (isRouteActive(routes.partnerMaterialsManagement)) {
      return formatMessage(messages.materials)
    } else if (isRouteActive(routes.settingsWhiteLabel)) {
      return formatMessage(messages.settingsWhitelabel)
    } else if (matchPath(routes.integrations, { wildcard: true })) {
      return formatMessage(messages.integrations)
    } else if (matchPath(routes.surveys, { wildcard: true })) {
      return formatMessage(messages.surveys)
    } else if (isRouteActive(routes.partnerIntrowPage)) {
      return 'Introw'
    } else if (matchPath(routes.pdfViewer, { wildcard: true })) {
      // title is set dynamically in the <PDFViewer /> component
      return null
    }

    return formatMessage(messages.notFound)
  }

  return getTitle
}

const NavigationChange = () => {
  const { trackPageView } = useAnalytics()
  const { pathname } = useLocation()
  const getTitle = useGetTitle()
  const { reloadAppIfNeeded } = useAppUpdater()

  const domain = theme === ThemeType.NNTB ? 'NNTB' : 'FaceUp'

  // biome-ignore lint/correctness/useExhaustiveDependencies(getTitle):
  // biome-ignore lint/correctness/useExhaustiveDependencies(pathname):
  // biome-ignore lint/correctness/useExhaustiveDependencies(reloadAppIfNeeded): Reloads ASAP when in depedency array
  useEffect(() => {
    const title = getTitle()
    if (title) {
      setDocumentTitle(title, domain)
    }
    trackPageView()
    reloadAppIfNeeded()
    // getTitle creates a new function on every render, so we need to disable the eslint rule
  }, [pathname, trackPageView, domain])

  return null
}

export default NavigationChange
