import type { UntitledIconData } from '../../UntitledIconData'

const ulItalic01: UntitledIconData = {
  prefix: 'ul',
  name: 'italic-01',
  paths: [
    {
      shape: 'M19 4H10M14 20H5M15 4L9 20',
    },
  ],
}

export default ulItalic01
