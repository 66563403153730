import type { UntitledIconData } from '../../UntitledIconData'

const ulChevronRight: UntitledIconData = {
  prefix: 'ul',
  name: 'chevron-right',
  paths: [
    {
      shape: 'M9 18L15 12L9 6',
    },
  ],
}

export default ulChevronRight
