export * from './validationConfig'
export * from './storage'
export * from './localization'
export * from './files'
export * from './validation'
export * from './qrCode'
export * from './cookies'
export * from './enums'
export * from './translate'
export * from './url'
export * from './range'
export * from './constants'
export * from './array'
export * from './customValidation'
export * from './chargebee'
export * from './domain'
export * from './objects'
export * from './branded'
export * from './voiceRecording'
export * from './hotlineConfiguration'
export * from './html'
export * from './promise'
export * from './types'

export const delay = async (ms: number) => new Promise(resolve => setTimeout(resolve, ms))

export const removeDiacritics = (value: string) =>
  value.normalize('NFD').replace(/[\u0300-\u036f]/gu, '')

// replace all newlines with spaces, double spaces with single spaces and double quotes with single quotes
export const formatVonageMessage = (value: string) =>
  value
    .replace(/[\r\n|\n|\r]+/g, ' ')
    .replace(/\s{2,}/g, ' ')
    .replace(/"/g, `'`)
    .replace(/'{2,}/g, `'`)
    .trim()

export const omitNullInArray = <D extends unknown | null>(array: D[]) =>
  array.filter((item): item is NonNullable<typeof item> => Boolean(item))
