import { Button as AntButton, type ButtonProps as AntButtonProps } from 'antd'
import { Fragment, type ReactElement, forwardRef, isValidElement } from 'react'

export type ButtonProps = Omit<AntButtonProps, 'variant' | 'color'>

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(({ children, ...props }, ref) => {
  /*
   * antd hack for intl - <FormattedMessage /> isn't typed as fragment,
   * antd does not wrap children in <span />
   */
  const isProbablyAtypicalComponent = (child: ReactElement): boolean =>
    child && isValidElement(child) && child.type !== Fragment && typeof children === 'object'
  return (
    <AntButton ref={ref} {...props}>
      {isProbablyAtypicalComponent(children as ReactElement) ? <span>{children}</span> : children}
    </AntButton>
  )
})
