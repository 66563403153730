import { Typography as AntTypography } from 'antd'
import type { ComponentProps } from 'react'

type Size = 'base' | 'sm' | 'lg' | 'xl'

const sizeClass: Record<Size, string> = {
  base: '',
  sm: 'ant-typography-text-sm',
  lg: 'ant-typography-text-lg',
  xl: 'ant-typography-text-xl',
}

export type TextProps = {
  size?: Size
} & ComponentProps<typeof AntTypography.Text>

const Text = ({ size = 'base', ...props }: TextProps) => {
  return <AntTypography.Text {...props} className={`${sizeClass[size]} ${props.className ?? ''}`} />
}

export const Typography = {
  Text,
  Paragraph: AntTypography.Paragraph,
  Title: AntTypography.Title,
  Link: AntTypography.Link,
}
