import { forwardRef } from 'react'
import { BaseDatePicker, type BaseDatePickerProps } from './bases/BaseDatePicker'

export type DatePickerProps = Omit<BaseDatePickerProps, 'showTime'>

// biome-ignore lint/suspicious/noExplicitAny:
export const DatePicker = forwardRef<any, DatePickerProps>((props, ref) => (
  <BaseDatePicker ref={ref} {...props} />
))

DatePicker.displayName = 'DatePicker'
