import { UntitledIcon } from '@faceup/icons'
import { ulXClose } from '@faceup/icons/ulXClose'
import { Tag as AntTag, type TagProps as AntTagProps } from 'antd'
import { tint } from 'polished'

export type TagProps = AntTagProps

/**
 *
 * Due to high amount of tags without border, the default value is changed to false.
 *
 * Dashed border in Figma is not implemented yet, we probably do not use it
 */
export const Tag = ({ closeIcon, color, ...props }: TagProps) => {
  const colorObject = getColor(color)
  return (
    <AntTag
      {...props}
      color={colorObject.isCustomColor ? undefined : colorObject.color}
      bordered={props.bordered ?? false}
      closable={props.closable ?? false}
      closeIcon={closeIcon ?? <UntitledIcon icon={ulXClose} />}
      style={{
        backgroundColor: colorObject.isCustomColor ? colorObject.backgroundColor : undefined,
        color: colorObject.isCustomColor ? colorObject.color : undefined,
        borderColor:
          colorObject.isCustomColor && props.bordered ? colorObject.borderColor : undefined,
        ...(props?.style ?? {}),
      }}
    />
  )
}

const getColor: (color: TagProps['color']) => {
  isCustomColor: boolean
  color: TagProps['color']
  backgroundColor?: string
  borderColor?: string
} = color => {
  const isHexColor = color?.startsWith('#')
  if (!isHexColor || !color) {
    return { isCustomColor: false, color }
  }
  const backgroundColor = tint(0.93, color)
  const borderColor = tint(0.76, color)
  return {
    isCustomColor: true,
    color,
    backgroundColor,
    borderColor,
  }
}
