import { Typography } from '@faceup/ui-base'
import type { ReactNode } from 'react'

type StepHeadingProps = {
  title: ReactNode
  description: ReactNode
}

export const StepHeading = (props: StepHeadingProps) => {
  const { title, description } = props
  return (
    <div className='flex flex-col gap-4 mb-8'>
      <Typography.Title level={3} className='text-center'>
        {title}
      </Typography.Title>
      <Typography.Text className='text-center'>{description}</Typography.Text>
    </div>
  )
}
