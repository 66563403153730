import { useMutation } from '@apollo/client'
import { useMotherId } from '@faceup/institution'
import { notification } from '@faceup/ui-base'
import { FormItemType, getTranslation } from '@faceup/utils'
import type { ReactNode } from 'react'
import { sharedMessages } from '../../../../../../Shared/translations'
import { useIntl } from '../../../../../../TypedIntl'
import { type FragmentType, getFragmentData, graphql } from '../../../../../../__generated__'
import { AbstractSurveyFormItem } from './AbstractSurveyFormItem'

const fragments = {
  EditSurveyFormItem_reportSource: graphql(`
    fragment EditSurveyFormItem_reportSource on ReportSource {
      id
      defaultLanguage
    }
  `),
  EditSurveyFormItem_formItem: graphql(`
    fragment EditSurveyFormItem_formItem on FormItem {
      id
      order
      formItemId
      isRequired
      type
      maxLength
      minResponses
      maxResponses
      labelTranslations {
        language
        translation
      }
      hintTranslations {
        language
        translation
      }
      options(includeDeleted: false) {
        id
        labelTranslations {
          language
          translation
        }
        order
      }
      scaleEndLabelTranslations {
        language
        translation
      }
      scaleStartLabelTranslations {
        language
        translation
      }
    }
  `),
}

const mutations = {
  EditSurveyItem: graphql(`
    mutation EditSurveyItem($input: EditSurveyFormItemInput!) {
      editSurveyFormItem(input: $input) {
        config {
          id
        }
      }
    }
  `),
  EditSurveySelectItem: graphql(`
    mutation EditSurveySelectItem($input: EditSurveySelectFormItemInput!) {
      editSurveySelectFormItem(input: $input) {
        config {
          id
        }
      }
    }
  `),
  EditSurveyScaleItem: graphql(`
    mutation EditSurveyScaleItem($input: EditSurveyScaleFormItemInput!) {
      editSurveyScaleFormItem(input: $input) {
        config {
          id
        }
      }
    }
  `),
}

type EditSurveyFormItemProps = {
  reportSource: FragmentType<typeof fragments.EditSurveyFormItem_reportSource>
  formItem: FragmentType<typeof fragments.EditSurveyFormItem_formItem>
  opened: boolean
  onClose: () => void
  onSuccess: () => void
}

export const EditSurveyFormItem = ({
  reportSource: _reportSource,
  formItem: _formItem,
  opened,
  onClose,
  onSuccess,
}: EditSurveyFormItemProps) => {
  const reportSource = getFragmentData(fragments.EditSurveyFormItem_reportSource, _reportSource)
  const formItem = getFragmentData(fragments.EditSurveyFormItem_formItem, _formItem)
  const { getMotherId } = useMotherId()
  const { formatMessage } = useIntl()

  const [editSurveyItem] = useMutation(mutations.EditSurveyItem, {
    onError: error => {
      console.error(error)
      notification.error({
        message: formatMessage(sharedMessages.apiError),
        description: error.message,
      })
    },
  })

  const [editSurveySelectItem] = useMutation(mutations.EditSurveySelectItem, {
    onError: error => {
      console.error(error)
      notification.error({
        message: formatMessage(sharedMessages.apiError),
        description: error.message,
      })
    },
  })

  const [editSurveyScaleItem] = useMutation(mutations.EditSurveyScaleItem, {
    onError: error => {
      console.error(error)
      notification.error({
        message: formatMessage(sharedMessages.apiError),
        description: error.message,
      })
    },
  })

  const items: Record<FormItemType, () => ReactNode> = {
    [FormItemType.MultilineText]: () => (
      <AbstractSurveyFormItem
        type={FormItemType.MultilineText}
        variant='edit'
        defaultValues={{}}
        onClose={onClose}
        opened={opened}
        onSubmit={() => true}
      />
    ),
    [FormItemType.Scale]: () => (
      <AbstractSurveyFormItem
        type={FormItemType.Scale}
        variant='edit'
        defaultValues={{
          question: getTranslation(
            formItem.labelTranslations,
            reportSource.defaultLanguage,
            reportSource.defaultLanguage
          ),
          description: formItem.hintTranslations
            ? getTranslation(
                formItem.hintTranslations,
                reportSource.defaultLanguage,
                reportSource.defaultLanguage
              )
            : undefined,
          isRequired: formItem.isRequired,
          firstScaleOption: {
            label: formItem.scaleStartLabelTranslations
              ? getTranslation(
                  formItem.scaleStartLabelTranslations,
                  reportSource.defaultLanguage,
                  reportSource.defaultLanguage
                )
              : '',
            value: formItem.options[0]
              ? Number(
                  getTranslation(
                    formItem.options[0].labelTranslations,
                    reportSource.defaultLanguage,
                    reportSource.defaultLanguage
                  )
                )
              : 0,
          },
          secondScaleOption: {
            label: formItem.scaleEndLabelTranslations
              ? getTranslation(
                  formItem.scaleEndLabelTranslations,
                  reportSource.defaultLanguage,
                  reportSource.defaultLanguage
                )
              : '',
            value: formItem.options[0]
              ? Number(
                  getTranslation(
                    formItem.options[0].labelTranslations,
                    reportSource.defaultLanguage,
                    reportSource.defaultLanguage
                  )
                ) +
                formItem.options.length -
                1
              : 0,
          },
        }}
        onClose={onClose}
        opened={opened}
        onSubmit={async values => {
          const result = await editSurveyScaleItem({
            variables: {
              input: {
                formItemId: formItem.id,
                channelId: reportSource.id,
                motherId: getMotherId(),
                item: {
                  question: values.question,
                  description: values.description,
                  isRequired: values.isRequired ?? false,
                },
                start: {
                  label:
                    values.firstScaleOption.label && values.firstScaleOption.label.trim().length > 0
                      ? values.firstScaleOption.label
                      : undefined,
                  value: Number(values.firstScaleOption.value),
                },
                end: {
                  label:
                    values.secondScaleOption.label &&
                    values.secondScaleOption.label.trim().length > 0
                      ? values.secondScaleOption.label
                      : undefined,
                  value: Number(values.secondScaleOption.value),
                },
              },
            },
          })
          if (!result.errors) {
            onClose()
            onSuccess()
            return true
          }
          return false
        }}
      />
    ),
    [FormItemType.Select]: () => (
      <AbstractSurveyFormItem
        type={FormItemType.Select}
        variant='edit'
        defaultValues={{
          question: getTranslation(
            formItem.labelTranslations,
            reportSource.defaultLanguage,
            reportSource.defaultLanguage
          ),
          description: formItem.hintTranslations
            ? getTranslation(
                formItem.hintTranslations,
                reportSource.defaultLanguage,
                reportSource.defaultLanguage
              )
            : undefined,
          isRequired: formItem.isRequired,
          responses: formItem.options.map(option => ({
            id: option.id,
            label: getTranslation(
              option.labelTranslations,
              reportSource.defaultLanguage,
              reportSource.defaultLanguage
            ),
          })),
        }}
        onClose={onClose}
        opened={opened}
        onSubmit={async values => {
          const result = await editSurveySelectItem({
            variables: {
              input: {
                formItemId: formItem.id,
                channelId: reportSource.id,
                motherId: getMotherId(),
                item: {
                  question: values.question,
                  description: values.description,
                  isRequired: values.isRequired ?? false,
                },
                options: values.responses.map((response, order) => ({
                  id: response.id.startsWith('new-') ? null : response.id,
                  label: response.label,
                  order,
                })),
              },
            },
          })
          if (!result.errors) {
            onClose()
            onSuccess()
            return true
          }
          return false
        }}
      />
    ),
    [FormItemType.MultiSelect]: () => (
      <AbstractSurveyFormItem
        type={FormItemType.MultiSelect}
        variant='edit'
        defaultValues={{
          question: getTranslation(
            formItem.labelTranslations,
            reportSource.defaultLanguage,
            reportSource.defaultLanguage
          ),
          maxResponses: formItem.maxResponses,
          minResponses: formItem.minResponses,
          showLimits:
            typeof formItem.maxResponses === 'number' || typeof formItem.minResponses === 'number',
          description: formItem.hintTranslations
            ? getTranslation(
                formItem.hintTranslations,
                reportSource.defaultLanguage,
                reportSource.defaultLanguage
              )
            : undefined,
          isRequired: formItem.isRequired,
          responses: formItem.options.map(option => ({
            id: option.id,
            label: getTranslation(
              option.labelTranslations,
              reportSource.defaultLanguage,
              reportSource.defaultLanguage
            ),
          })),
        }}
        onClose={onClose}
        opened={opened}
        onSubmit={async values => {
          const result = await editSurveySelectItem({
            variables: {
              input: {
                formItemId: formItem.id,
                channelId: reportSource.id,
                motherId: getMotherId(),
                item: {
                  question: values.question,
                  description: values.description,
                  isRequired: values.isRequired ?? false,
                  maxResponses: values.showLimits ? values.maxResponses : null,
                  minResponses: values.showLimits ? values.minResponses : null,
                },
                options: values.responses.map((response, order) => ({
                  id: response.id.startsWith('new-') ? null : response.id,
                  label: response.label,
                  order,
                })),
              },
            },
          })
          if (!result.errors) {
            onClose()
            onSuccess()
            return true
          }
          return false
        }}
      />
    ),
    [FormItemType.MoreInformation]: () => (
      <AbstractSurveyFormItem
        type={FormItemType.MoreInformation}
        variant='edit'
        defaultValues={{}}
        onClose={onClose}
        opened={opened}
        onSubmit={() => true}
      />
    ),
    [FormItemType.Category]: () => (
      <AbstractSurveyFormItem
        type={FormItemType.Category}
        variant='edit'
        defaultValues={{}}
        onClose={onClose}
        opened={opened}
        onSubmit={() => true}
      />
    ),
    [FormItemType.OrganizationalUnit]: () => (
      <AbstractSurveyFormItem
        type={FormItemType.OrganizationalUnit}
        variant='edit'
        defaultValues={{}}
        onClose={onClose}
        opened={opened}
        onSubmit={() => true}
      />
    ),
    [FormItemType.SenderName]: () => (
      <AbstractSurveyFormItem
        type={FormItemType.SenderName}
        variant='edit'
        defaultValues={{}}
        onClose={onClose}
        opened={opened}
        onSubmit={() => true}
      />
    ),
    [FormItemType.Classroom]: () => (
      <AbstractSurveyFormItem
        type={FormItemType.Classroom}
        variant='edit'
        defaultValues={{}}
        onClose={onClose}
        opened={opened}
        onSubmit={() => true}
      />
    ),
    [FormItemType.SimpleText]: () => (
      <AbstractSurveyFormItem
        type={FormItemType.SimpleText}
        variant='edit'
        defaultValues={{
          question: getTranslation(
            formItem.labelTranslations,
            reportSource.defaultLanguage,
            reportSource.defaultLanguage
          ),
          description: formItem.hintTranslations
            ? getTranslation(
                formItem.hintTranslations,
                reportSource.defaultLanguage,
                reportSource.defaultLanguage
              )
            : undefined,
          isRequired: formItem.isRequired,
          maxLength: formItem.maxLength ?? 500,
        }}
        onClose={onClose}
        opened={opened}
        onSubmit={async values => {
          const result = await editSurveyItem({
            variables: {
              input: {
                formItemId: formItem.id,
                channelId: reportSource.id,
                motherId: getMotherId(),
                item: {
                  question: values.question,
                  description: values.description,
                  isRequired: values.isRequired ?? false,
                  maxLength: values.maxLength,
                },
              },
            },
          })
          if (!result.errors) {
            onClose()
            onSuccess()
            return true
          }
          return false
        }}
      />
    ),
    [FormItemType.Date]: () => (
      <AbstractSurveyFormItem
        type={FormItemType.Date}
        variant='edit'
        defaultValues={{}}
        onClose={onClose}
        opened={opened}
        onSubmit={() => true}
      />
    ),
    [FormItemType.Email]: () => (
      <AbstractSurveyFormItem
        type={FormItemType.Email}
        variant='edit'
        defaultValues={{}}
        onClose={onClose}
        opened={opened}
        onSubmit={() => true}
      />
    ),
    [FormItemType.PhoneNumber]: () => (
      <AbstractSurveyFormItem
        type={FormItemType.PhoneNumber}
        variant='edit'
        defaultValues={{}}
        onClose={onClose}
        opened={opened}
        onSubmit={() => true}
      />
    ),
  }

  return items[formItem.type]()
}
