import { type ButtonHTMLAttributes, type Ref, forwardRef } from 'react'
import { twMerge } from 'tailwind-merge'

type ButtonProps = { href?: never } & ButtonHTMLAttributes<HTMLButtonElement>
type AnchorProps = { href: string } & ButtonHTMLAttributes<HTMLAnchorElement>

type UnstyledButtonProps = ButtonProps | AnchorProps

export const UnstyledButton = forwardRef<
  HTMLButtonElement | HTMLAnchorElement,
  UnstyledButtonProps
>((props, ref) => {
  if ('href' in props && props.href !== undefined) {
    return (
      <a
        ref={ref as Ref<HTMLAnchorElement>}
        {...props}
        className={twMerge('text-inherit!', props.className)}
      />
    )
  }
  return (
    <button
      ref={ref as Ref<HTMLButtonElement>}
      type='button'
      {...props}
      className={twMerge('border-0 p-0 bg-inherit cursor-pointer text-start', props.className)}
    />
  )
})
