import type { ModalLocale } from 'antd/lib/modal'
import type { ReactNode } from 'react'
import { Button } from './Button'
import { Modal, type ModalProps } from './Modal'
import { useUiBaseConfig } from './UiBaseProvider'

export type ModalConfirmProps = {
  cancelText?: ReactNode | string
  onCancel: () => void
  cancelState?: {
    loading?: boolean
    disabled?: boolean
    danger?: boolean
  }
  confirmText?: ReactNode | string
  onConfirm: () => void
  confirmState?: {
    loading?: boolean
    disabled?: boolean
    danger?: boolean
  }
  afterClose?: () => void
} & Omit<ModalProps, 'footer'>

export const ModalConfirm = ({
  children,
  afterClose,
  cancelText,
  confirmText,
  onCancel,
  onConfirm,
  confirmState,
  cancelState,
  ...props
}: ModalConfirmProps) => {
  const { locale } = useUiBaseConfig()
  const modalContext: ModalLocale = locale?.Modal ?? {
    cancelText: 'Cancel',
    okText: 'OK',
    justOkText: 'OK',
  }

  const dataTest = props['data-test']
  const dataOnboarding = props['data-onboarding']

  return (
    <Modal
      {...props}
      footer={
        <div className='ant-modal-confirm-footer'>
          <Button
            type='default'
            onClick={() => {
              onCancel()
              afterClose?.()
            }}
            loading={cancelState?.loading}
            disabled={cancelState?.disabled}
            danger={cancelState?.danger}
            data-test={dataTest ? `${dataTest}-cancel-button` : undefined}
            data-onboarding={dataOnboarding ? `${dataOnboarding}-cancel-button` : undefined}
          >
            {cancelText ?? modalContext.cancelText}
          </Button>
          <Button
            type='primary'
            onClick={() => {
              onConfirm()
              afterClose?.()
            }}
            loading={confirmState?.loading}
            disabled={confirmState?.disabled}
            danger={confirmState?.danger}
            data-test={dataTest ? `${dataTest}-confirm-button` : undefined}
            data-onboarding={dataOnboarding ? `${dataOnboarding}-confirm-button` : undefined}
          >
            {confirmText ?? modalContext.okText}
          </Button>
        </div>
      }
    >
      {children}
    </Modal>
  )
}
