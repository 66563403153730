import { SearchInput, useForm, yup } from '@faceup/form'
import { UntitledIcon } from '@faceup/icons'
import { ulPlus } from '@faceup/icons/ulPlus'
import { UpsellPageContent, useUpsell } from '@faceup/institution'
import { ContentLayout } from '@faceup/ui'
import { Button, Flex } from '@faceup/ui-base'
import { useDebouncedValue } from '@mantine/hooks'
import { useState } from 'react'
import { FormattedMessage, defineMessages, useIntl } from '../../TypedIntl'
import useAnalytics from '../../utils/analytics'
import { CreateSurveyModal, SurveyTable } from './components'

const messages = defineMessages({
  title: 'Administration.surveys.title',
  createSurvey: 'Administration.surveys.createSurvey',
  creationSuccess: 'Administration.surveys.modal.success',
  search: 'Administration.institutions.search.placeholder',
})

const searchSchema = yup.object().shape({
  search: yup.string(),
})

const SurveysTableView = () => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
  const upsell = useUpsell()
  const { formatMessage } = useIntl()
  const { trackNewSurveyButtonClicked } = useAnalytics()

  const searchForm = useForm({
    schema: searchSchema,
    afterSubmit: 'nothing',
  })

  const [debouncedName] = useDebouncedValue(searchForm.watch('search'), 300)

  return (
    <UpsellPageContent upsell={upsell.surveys}>
      <ContentLayout
        header={
          <ContentLayout.Header
            title={<FormattedMessage {...messages.title} />}
            secondaryContent={
              <Flex justify='flex-end'>
                <Flex vertical={false} gap='middle' align='center'>
                  <SearchInput
                    control={searchForm.control}
                    name='search'
                    placeholder={formatMessage(messages.search)}
                    style={{ width: '180px' }}
                  />
                  <Button
                    type='primary'
                    icon={<UntitledIcon icon={ulPlus} />}
                    data-test='new-survey-button'
                    onClick={() => {
                      setIsModalOpen(true)
                      trackNewSurveyButtonClicked()
                    }}
                  >
                    <FormattedMessage {...messages.createSurvey} />
                  </Button>
                </Flex>
              </Flex>
            }
          />
        }
      >
        <SurveyTable name={debouncedName ?? ''} />
        <CreateSurveyModal
          isModalOpen={isModalOpen}
          onClose={() => {
            setIsModalOpen(false)
          }}
        />
      </ContentLayout>
    </UpsellPageContent>
  )
}

export default SurveysTableView
