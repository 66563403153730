import { useLocalization } from '@faceup/localization'
import type { UserQuery_memberViewer as GQLViewer } from '@faceup/member'
import { type Language, convertLanguageToBCP47Language } from '@faceup/utils'
import { useViewportSize } from '@mantine/hooks'
import { useCallback } from 'react'

// https://remarkablemark.medium.com/how-to-generate-a-sha-256-hash-with-javascript-d3b2696382fd
const hash = async (email: string | null) => {
  if (!email) {
    return null
  }

  const utf8 = new TextEncoder().encode(email)
  const hashBuffer = await crypto.subtle.digest('SHA-256', utf8)
  const hashArray = Array.from(new Uint8Array(hashBuffer))
  const hashHex = hashArray.map(bytes => bytes.toString(16).padStart(2, '0')).join('')
  return hashHex
}

type UserInput = {
  id: string | null
  name: string | null
  phone: string | null
  email: string | null
  institutionName: string | null
  institutionType: string | null
  country: string | null
}

type GTMConsent = ['consent', 'update', Record<string, string>]

export const useGTM = (): {
  // on page load - page 17
  pageLoaded: (user: GQLViewer) => void
  // load registration form - page 19
  signUpLoadAdmin: (user: UserInput, type: 'purchase' | 'trial') => void
  // click "try for free" - page 20
  signUpSubmitAdmin: (user: UserInput, type: 'purchase' | 'trial') => void
  // registration step 1 or 2 - page 21
  signUpStepAdmin: (user: UserInput, step: number) => void
  // registration completed - page 22
  signUpCompletedAdmin: (user: UserInput) => void
  // update consent
  updateConsent: (consent: GTMConsent) => void
} => {
  const { width } = useViewportSize()
  const { language } = useLocalization()

  // https://stackoverflow.com/questions/26085570/how-to-convert-ietf-bcp-47-language-identifier-to-iso-639-2
  const getISO639Language = (language: Language): string | null => {
    const BCP47 = convertLanguageToBCP47Language(language)

    return BCP47.split('-')[0] ?? null
  }

  window.dataLayer = window.dataLayer || []
  const dataLayer = window.dataLayer

  const getWindowSize = () => {
    if (width < 768) {
      return 'phone'
    }
    if (width >= 768 && width < 992) {
      return 'tablet'
    }
    return 'screen'
  }

  const getUserField = async ({
    id,
    name,
    phone,
    email,
    institutionName,
    institutionType,
    country,
  }: UserInput): Promise<
    {
      id: string | null
      firstName: string | null
      lastName: string | null
      phone: string | null
      email: string | null
      hashedEmail: string | null
      companyName: string | null
      companyType: string | null
      country: string | null
    }[]
  > => {
    return [
      {
        id,
        firstName: name?.split(' ')[0] ?? null,
        lastName: name?.split(' ')[1] ?? null,
        phone,
        email,
        hashedEmail: await hash(email),
        companyName: institutionName,
        companyType: institutionType,
        country: country?.toUpperCase() ?? null,
      },
    ]
  }

  const pageLoaded = async (viewer: GQLViewer) => {
    const user = {
      ...viewer,
      institutionName: viewer.motherImplicit?.config.institutionName ?? null,
      institutionType: viewer.motherImplicit?.type ?? null,
      country: viewer.motherImplicit?.country ?? null,
    }
    dataLayer.push({
      event: 'pageLoaded',
      environment: import.meta.env.VITE_ENVIRONMENT === 'production' ? 'live' : 'dev',
      user: await getUserField(user),
      page: {
        device: getWindowSize(),
        response: 200,
        language: getISO639Language(language),
      },
    })
  }

  const signUpLoadAdmin = async (user: UserInput, type: 'purchase' | 'trial') => {
    const signUp = {
      purchase: {
        type: 'purchase',
        data: 'Sign up',
      },
      trial: {
        type: 'free trial',
        data: 'Try FaceUp for free',
      },
    }

    dataLayer.push({
      event: 'signUpLoadAdmin',
      user: await getUserField(user),
      signUp: signUp[type],
      _clear: true,
    })
  }

  const signUpSubmitAdmin = async (user: UserInput, type: 'purchase' | 'trial') => {
    const signUpType = {
      purchase: {
        formName: 'Purchase',
        type: 'Sign up',
      },
      trial: {
        formName: 'Trial',
        type: 'Try FaceUp for free',
      },
    }

    dataLayer.push({
      event: 'signUpSubmitAdmin',
      user: await getUserField(user),
      formID: null, // not using this
      formName: signUpType[type].formName,
      type: signUpType[type].type,
      _clear: true,
    })
  }

  const signUpStepAdmin = async (user: UserInput, step: number) => {
    const stepNames = <Record<number, string>>{
      1: 'Account creation',
      2: 'Organisation details',
    }
    dataLayer.push({
      event: 'signUpStepAdmin',
      user: await getUserField(user),
      step,
      stepName: stepNames[step] ?? null,
      _clear: true,
    })
  }

  const signUpCompletedAdmin = async (user: UserInput) => {
    dataLayer.push({
      event: 'signUpCompletedAdmin',
      user: await getUserField(user),
      step: 3,
      stepName: 'sign up completed',
      _clear: true,
    })
  }

  const updateConsent = async (consent: GTMConsent) => {
    dataLayer.push(consent)
  }

  return {
    pageLoaded: useCallback(pageLoaded, []),
    signUpLoadAdmin: useCallback(signUpLoadAdmin, []),
    signUpSubmitAdmin,
    signUpStepAdmin,
    signUpCompletedAdmin,
    updateConsent,
  }
}
