import { useInstitution } from '../../../providers'
import type { UpsellData } from '../upsellData'

type UpgradeButtonsProps = {
  upsell: UpsellData
}

export const UpgradeButtons = (props: UpgradeButtonsProps) => {
  const { upsell: upsellData } = props
  const { upsell } = useInstitution()

  if (!upsell.isAllowed) {
    return null
  }

  return <div className='flex gap-4'>{'buttons' in upsell && upsell.buttons(upsellData)}</div>
}
