import { UntitledIcon } from '@faceup/icons'
import { ulLock01 } from '@faceup/icons/ulLock01'
import { Avatar, Col, Row, Typography } from '@faceup/ui-base'
import type { ReactNode } from 'react'

type ContentNoPermissionProps = {
  title: ReactNode
  description: ReactNode
}

export const ContentNoPermission = (props: ContentNoPermissionProps) => {
  const { title, description } = props
  return (
    <Row justify='center' className='mt-40'>
      <Col className='text-center' span={24} style={{ maxWidth: '442px' }}>
        <Avatar size={80} className='bg-primary'>
          <UntitledIcon icon={ulLock01} />
        </Avatar>
        <div className='mt-10'>
          <Typography.Title level={4}>{title}</Typography.Title>
          <Typography.Text className='text-lg text-gray-700'>{description}</Typography.Text>
        </div>
      </Col>
    </Row>
  )
}
