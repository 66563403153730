import styled from '@emotion/styled'

export const ChartKeyRow = styled.tr`
  margin: 0.3rem;
`

export const ChartKeyCellDivider = styled.td`
  padding-inline-start: 0.5rem;
  padding-inline-end: 0.5rem;
  color: #aaa;
`

export const ChartKeyCell = styled.td`
  color: #aaa;
`
