import { UntitledIcon } from '@faceup/icons'
import { ulChevronDown } from '@faceup/icons/ulChevronDown'
import { ulGlobe02 } from '@faceup/icons/ulGlobe02'
import { UnstyledButton } from '@faceup/ui'
import { Dropdown, Typography } from '@faceup/ui-base'
import type { Language } from '@faceup/utils'
import { type ComponentProps, type ForwardedRef, forwardRef } from 'react'
import { useLanguageName } from '../hooks'

type LanguageDropdownProps = {
  languages: Language[]
  activeLanguage: Language
  areLanguagesNative?: boolean
  onChangeLanguage: (language: Language) => void
  styles?: { targetText?: Omit<ComponentProps<typeof Typography.Text>, 'children'> }
}

export const LanguageDropdown = forwardRef(
  (
    {
      languages,
      activeLanguage,
      onChangeLanguage,
      areLanguagesNative = false,
      styles,
    }: LanguageDropdownProps,
    ref: ForwardedRef<HTMLButtonElement>
  ) => {
    const { getLanguageName, getLanguageList } = useLanguageName(areLanguagesNative)

    return (
      <Dropdown
        menu={{
          style: {
            maxHeight: '50vh',
          },
          items: getLanguageList(languages).map(language => ({
            key: language.language,
            label: language.name,
            onClick: () => onChangeLanguage(language.language),
          })),
        }}
      >
        <UnstyledButton ref={ref} className='flex items-center gap-2' data-test='language-dropdown'>
          <Typography.Text {...styles?.targetText}>
            <div className='flex items-center gap-2'>
              <UntitledIcon icon={ulGlobe02} />
              {getLanguageName(activeLanguage)}
              <UntitledIcon icon={ulChevronDown} size={18} />
            </div>
          </Typography.Text>
        </UnstyledButton>
      </Dropdown>
    )
  }
)
