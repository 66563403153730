import { downloadFile } from '@faceup/utils'
import { pdf } from '@react-pdf/renderer'
import type { ReactElement } from 'react'

const PDFDownloader = async (fileName: string, fileContent: ReactElement) => {
  const fileBlob = await pdf(fileContent).toBlob()

  downloadFile(fileBlob, `${fileName}.pdf`)
}

export default PDFDownloader
