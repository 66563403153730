import { UntitledIcon, type UntitledIconData } from '@faceup/icons'
import { ucDots2Grid } from '@faceup/ui'
import { transitionDuration, twMerge, useThemeColors } from '@faceup/ui'
import { Typography } from '@faceup/ui-base'
import { type ReactNode, forwardRef } from 'react'

type DragListItemProps = {
  isFocused: boolean
  icon?: UntitledIconData
  label: ReactNode
  isDraggable: boolean
  isClickable?: boolean
  onClick?: () => void
  children?: ReactNode
  secondary?: ReactNode
  identifier: string
  disabled?: boolean
}

export const DragListItem = forwardRef<HTMLDivElement, DragListItemProps>((props, ref) => {
  const {
    isFocused,
    icon,
    label,
    isDraggable,
    isClickable,
    onClick,
    children,
    secondary,
    identifier,
    disabled = false,
  } = props
  const { getColorFromTheme } = useThemeColors()
  const getCursor: () => string = () => {
    if (isFocused) {
      return 'default'
    }
    if (isClickable || onClick) {
      return 'pointer'
    }
    return 'default'
  }

  return (
    <div
      ref={ref}
      className={`${identifier ? `${identifier}-item` : ''} ${twMerge(
        'rounded-[12px] my-3 p-2 overflow-hidden border transition-all',
        isFocused ? 'bg-[#E6F3FF] border-[#71BBFF]' : 'border-[#CDDDEE] hover:border-[#48A7FF]',
        disabled && 'bg-[#9DB0DF14] text-[#92B4DB]'
      )}`}
    >
      <div className='flex items-center gap-1 w-full'>
        {isDraggable && (
          <div
            className={`dragger draggable ${identifier} ${disabled ? 'cursor-not-allowed' : 'cursor-grab'} text-[#6F91B7]`}
          >
            <UntitledIcon icon={ucDots2Grid} size={20} />
          </div>
        )}
        <div
          className='flex items-center justify-between w-full ps-3'
          style={{
            cursor: getCursor(),
          }}
          onClick={onClick}
        >
          <div className='w-full'>
            <div className='flex justify-between items-center w-full'>
              <div className='flex gap-2 items-center'>
                {icon && (
                  <UntitledIcon
                    icon={icon}
                    color={
                      isFocused ? getColorFromTheme('primary.100') : getColorFromTheme('dark.110')
                    }
                    size={20}
                  />
                )}
                <Typography.Text
                  strong
                  style={{
                    color: isFocused
                      ? getColorFromTheme('primary.100')
                      : getColorFromTheme('dark.110'),
                  }}
                >
                  {label}
                </Typography.Text>
              </div>
              <div
                className='flex items-center gap-4'
                style={{
                  cursor: 'initial',
                  color: getColorFromTheme('text'),
                  transition: `color ${transitionDuration}`,
                }}
                onClick={e => e.stopPropagation()}
              >
                {secondary}
              </div>
            </div>
            {isFocused && children}
          </div>
        </div>
      </div>
    </div>
  )
})
