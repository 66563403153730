import { Flex, Skeleton, Typography } from '@faceup/ui-base'
import { type HTMLAttributes, type ReactNode, forwardRef } from 'react'
import { twMerge } from 'tailwind-merge'
import { HintIcon } from '../AdminLayout'

export type CardProps = {
  loading?: boolean
} & Omit<HTMLAttributes<HTMLDivElement>, 'title'>

const _Card = forwardRef<HTMLDivElement, CardProps>((props, ref) => {
  const { children, loading = false, className, ...otherProps } = props

  return (
    <div
      ref={ref}
      {...otherProps}
      className={`${twMerge('rounded-[20px] bg-white shadow-[0px_2px_20px_0px_rgba(22,25,84,0.1)] overflow-hidden', className)}`}
    >
      {loading ? <Skeleton className='p-6 pb-3' /> : children}
    </div>
  )
})

export type SectionProps = {
  children?: ReactNode
  loading?: boolean
}

export const Section = ({ children, loading = false }: SectionProps) => {
  return (
    <div className='group'>
      {loading ? <Skeleton className='p-6 pb-3' /> : children}
      <hr className='group-last-of-type:hidden mx-[32px] my-0 border-0 border-b-1 border-b-[#e6ecf9]' />
    </div>
  )
}

type HeaderProps = {
  children: ReactNode
  extra?: ReactNode
  hint?: ReactNode
}

const Header = ({ children, extra, hint }: HeaderProps) => (
  <div className='flex justify-between p-[32px_32px_24px_32px]'>
    <Typography.Title level={5} className='flex-auto'>
      <Flex align='center' gap='small'>
        {children}
        {hint && <HintIcon title={hint} />}
      </Flex>
    </Typography.Title>
    <Flex>{extra}</Flex>
  </div>
)

type ContentProps = { children: ReactNode }

const Content = forwardRef<HTMLDivElement, ContentProps>(({ children }, ref) => (
  <div ref={ref} className='relative flex flex-col p-[0_32px_32px_32px]'>
    {children}
  </div>
))

type CardType = typeof _Card & {
  Section: typeof Section
  Header: typeof Header
  Content: typeof Content
}

_Card.displayName = 'Card'
Section.displayName = 'Card.Section'
Header.displayName = 'Card.Header'
Content.displayName = 'Card.Content'

export const Card: CardType = _Card as CardType

Card.Section = Section
Card.Header = Header
Card.Content = Content
