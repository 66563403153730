import { FormItemType, getTranslation } from '@faceup/utils'
import { sharedMessages } from '../../../../Shared/translations'
import { type FragmentType, getFragmentData, graphql } from '../../../../__generated__'
import useMockedReportMessages from '../../../../hooks/useMockedReportMessages'

const fragments = {
  FormItemsListItemLabel_formItem: graphql(`
    fragment FormItemsListItemLabel_formItem on FormItem {
      id
      formItemId
      type
      labelTranslations {
        language
        translation
      }
    }
  `),
  FormItemsListItemLabel_reportSource: graphql(`
    fragment FormItemsListItemLabel_reportSource on ReportSource {
      id
      defaultLanguage
    }
  `),
}

type ListItemLabelProps = {
  reportSource: FragmentType<typeof fragments.FormItemsListItemLabel_reportSource>
  formItem: FragmentType<typeof fragments.FormItemsListItemLabel_formItem>
}

export const ListItemLabel = ({
  formItem: _formItem,
  reportSource: _reportSource,
}: ListItemLabelProps) => {
  const formItem = getFragmentData(fragments.FormItemsListItemLabel_formItem, _formItem)
  const reportSource = getFragmentData(fragments.FormItemsListItemLabel_reportSource, _reportSource)
  const { formatMessageWithConfigLanguage } = useMockedReportMessages(reportSource.defaultLanguage)
  const labelTranslation = getTranslation(
    formItem.labelTranslations,
    reportSource.defaultLanguage,
    reportSource.defaultLanguage
  )
  const labels: Record<FormItemType, string> = {
    [FormItemType.Category]:
      labelTranslation || formatMessageWithConfigLanguage(sharedMessages.categoryLabel),
    [FormItemType.OrganizationalUnit]:
      labelTranslation || formatMessageWithConfigLanguage(sharedMessages.organizationLabel),
    [FormItemType.Classroom]:
      labelTranslation || formatMessageWithConfigLanguage(sharedMessages.classNameLabel),
    [FormItemType.MoreInformation]:
      labelTranslation || formatMessageWithConfigLanguage(sharedMessages.moreInfoLabel),
    [FormItemType.SimpleText]: labelTranslation,
    [FormItemType.Select]: labelTranslation,
    [FormItemType.Scale]: labelTranslation,
    [FormItemType.MultiSelect]: labelTranslation,
    [FormItemType.SenderName]:
      labelTranslation || formatMessageWithConfigLanguage(sharedMessages.globalNameLabel),
    [FormItemType.MultilineText]: labelTranslation,
    [FormItemType.Date]: labelTranslation,
    [FormItemType.Email]: labelTranslation,
    [FormItemType.PhoneNumber]: labelTranslation,
  }

  return <>{labels[formItem.type]}</>
}
