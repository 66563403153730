import styled from '@emotion/styled'
import EChartsReact from 'echarts-for-react'
import { memo } from 'react'
import { getValueInPercentages } from '../../../utils/numbers'
import { ChartKeyRow } from '../../ChartKeyComponents'
import DotText from '../../DotText'
import NoDataOverlay from '../../NoDataOverlay'

type Props = {
  // biome-ignore lint/suspicious/noExplicitAny:
  graphRef?: any
  height?: number
  data: {
    name: string
    value: number
    color: string
  }[]
  legendPosition: 'bottom' | 'right'
}

const PieChartWrapper = memo((props: Props) => {
  const { height = 200, data, legendPosition } = props

  const totalCount = data.reduce((pre, curr) => pre + curr.value, 0)
  const dataForGraph = data.filter(value => value.value > 0)

  return (
    <OuterChartWrapper legendPosition={legendPosition} ref={props.graphRef}>
      <InnerChartWrapper>
        <NoDataOverlay visible={!dataForGraph.length} />
        <EChartsReact
          className='w-full'
          // Force our height instead of echarts default height
          style={{ height }}
          notMerge
          lazyUpdate
          option={{
            textStyle: {
              fontFamily: `Inter, sans-serif`,
              fontSize: 14,
            },
            tooltip: {
              trigger: 'item',
            },
            series: [
              {
                cursor: 'default',
                type: 'pie',
                radius: `50%`,
                tooltip: {
                  formatter: ({ name, value }: { name: string; value: number }) =>
                    `${name}: <strong>${value} (${getValueInPercentages(
                      totalCount,
                      value
                    )}%)</strong>`,
                },
                emphasis: {
                  itemStyle: {
                    shadowBlur: 10,
                    shadowOffsetX: 0,
                    shadowColor: 'rgba(0, 0, 0, 0.5)',
                  },
                },
                data: dataForGraph.map(({ name, value, color }) => ({
                  name,
                  value,

                  itemStyle: { color },
                })),
              },
            ],
          }}
        />
      </InnerChartWrapper>

      <ChartKeyWrapper>
        <table>
          <tbody>
            {data.map(item => (
              <ChartKeyRow key={`legenda-${item.name}`}>
                <td>
                  <DotText color={item.color}>
                    {item.name}
                    <span>
                      {totalCount ? ` | ${getValueInPercentages(totalCount, item.value)}%` : ''}
                    </span>
                  </DotText>
                </td>
                <td style={{ paddingLeft: '1rem' }}>{item.value}</td>
              </ChartKeyRow>
            ))}
          </tbody>
        </table>
      </ChartKeyWrapper>
    </OuterChartWrapper>
  )
})

const OuterChartWrapper = styled.div<{ legendPosition: 'bottom' | 'right' }>`
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: ${props => (props.legendPosition === 'bottom' ? 'column' : 'row')};
  width: 100%;
  height: 250px;

  @media print {
    flex-direction: column;
  }
`

export const InnerChartWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  /* We don't want to show pointer cursor on charts */
  & * {
    cursor: default !important;
  }

  @media print {
    margin-inline-end: 0;
    margin-bottom: 50px;
  }
`

const ChartKeyWrapper = styled.div`
  display: none;
  justify-content: center;
  flex-direction: column;
  margin: auto;

  @media print {
    display: flex;
  }
`

export default PieChartWrapper
