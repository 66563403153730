import { useMotherId } from '@faceup/institution'
import { type Routes, generatePath } from '@faceup/router'
import { type Institution, type Language, type MaterialType, buildPath } from '@faceup/utils'
import type { Tab } from '../../Pages/CompanyReportDetail/ReportDetail'
import type { App } from '../../Pages/IntegrationsView/AppDetailView'
import { usePersistentUserStorage } from '../../utils/persistentUserStorage'
import { type ReportsFilterSchema, defaultFilter } from '../../utils/reportFilters'

export const routes: Record<keyof RoutesInterface, string> = {
  account: '/:institutionId/account',
  dashboard: '/:institutionId',
  dataManagement: '/:institutionId/data-management',
  dataManagementCategories: '/:institutionId/data-management/categories',
  dataManagementEmployees: '/:institutionId/data-management/employees',
  dataManagementEmployeesDetail: '/:institutionId/data-management/employees/:id',
  home: '/',
  institutionCustomization: '/:institutionId/institution-customization',
  institutionCustomizationCreate: '/:institutionId/institution-customization/create',
  institutionCustomizationCreateHotline: '/:institutionId/institution-customization/create/hotline',
  institutionCustomizationCreateLiveHotline:
    '/:institutionId/institution-customization/create/live-hotline',
  institutionCustomizationCreateReportForm:
    '/:institutionId/institution-customization/create/reportForm',
  institutionCustomizationFormInputs: '/:institutionId/institution-customization/:id/form-inputs',
  institutionCustomizationGeneral: '/:institutionId/institution-customization/:id/general',
  institutionCustomizationHotlineTexts:
    '/:institutionId/institution-customization/:id/hotline-texts',
  institutionCustomizationOverview: '/:institutionId/institution-customization/:id/overview',
  institutionCustomizationPages: '/:institutionId/institution-customization/:id/pages',
  institutionCustomizationSubmitPage: '/:institutionId/institution-customization/:id/submit-page',
  institutionCustomizationWorkingHours:
    '/:institutionId/institution-customization/:id/working-hours',
  institutions: '/institutions',
  integrations: '/:institutionId/integrations',
  integrationsAppDetail: '/:institutionId/integrations/apps/:app',
  integrationsApps: '/:institutionId/integrations/apps',
  integrationsApiKeys: '/:institutionId/integrations/api-keys',
  integrationsWebhooks: '/:institutionId/integrations/webhooks',
  loginSso: '/sso',
  materials: '/:institutionId/materials',
  materialsTable: '/:institutionId/materials-list/:language/:type/:institution?',
  partnerIntrowPage: '/introw',
  partnerMaterialsManagement: '/materials-management',
  partnerMaterialsManagementTable: '/materials-management/:language/:type/:institution?',
  partnerSettings: '/partner-settings',
  partnerSettingsGeneral: '/partner-settings/general',
  partnerSettingsUsers: '/partner-settings/users',
  partnerSettingsWhiteLabel: '/partner-settings/whitelabel',
  partnerStatistics: 'partner-statistics',
  partnerAccount: '/partner-account',
  partnerAccountGeneral: '/partner-account/general',
  partnerAccountSecurity: '/partner-account/security',
  partnerReports: '/partner-reports',
  register: '/register',
  registerFillInfo: '/register/fill-info/:token',
  registerPurchase: '/register/purchase/:id?',
  registerTrialRequest: '/register/trial-request/:plan?',
  registerTrialRequestExtended: '/register/trial-request-long/:plan?',
  report: '/:institutionId/report/:id',
  reportOverview: '/:institutionId/report/:id/overview',
  reportInvestigation: '/:institutionId/report/:id/investigation',
  reportExport: '/:institutionId/report/:id/print',
  reports: '/:institutionId/reports',
  institutionService: '/:institutionId/service/:id',
  institutionServices: '/:institutionId/services',
  partnerService: '/service/:id',
  partnerServices: '/services',
  settings: '/:institutionId/settings',
  settingsCustomization: '/:institutionId/settings/report-customisation',
  settingsInstitution: '/:institutionId/settings/organization-settings',
  settingsInvoicing: '/:institutionId/settings/invoicing',
  settingsInvoicingChangePlan: '/:institutionId/settings/invoicing/change-plan',
  settingsStructure: '/:institutionId/settings/organization-structure',
  settingsWhiteLabel: '/:institutionId/settings/whitelabel',
  settingsUsers: '/:institutionId/settings/users',
  surveys: '/:institutionId/surveys',
  surveyDetail: '/:institutionId/surveys/:id',
  surveySubmissions: '/:institutionId/surveys/:id/submissions',
  surveySubmissionsStatistics: '/:institutionId/surveys/:id/submissions/statistics',
  surveySubmissionsDetails: '/:institutionId/surveys/:id/submissions/details',
  accountGeneral: '/:institutionId/account/general',
  accountNotifications: '/:institutionId/account/notifications',
  accountSecurity: '/:institutionId/account/security',
  accountAffiliate: '/:institutionId/account/affiliate',
  statistics: '/:institutionId/statistics',
  token: '/token/:id',
  typography: '/:institutionId/typography',
  pdfViewer: '/:institutionId/pdf-viewer/:text/:institution/:sourceType/:source/:title/:shortId?',
}

export const useRoutes = (): Routes => {
  const { getMotherIdWithNull } = useMotherId()
  const institutionId = getMotherIdWithNull()

  // Function definition copied from react-router
  const generatePathWithInstitutionId = <Path extends string>(
    originalPath: Path,
    // biome-ignore lint/suspicious/noExplicitAny:
    params?: any
  ) => {
    if (!institutionId && !params?.institutionId) {
      throw new Error(`Tried generate path '${originalPath}' without institutionId`)
    }
    return generatePath(originalPath, { institutionId, ...(params ?? {}) })
  }

  const { reportsFilters, statisticsFilters } = usePersistentUserStorage()

  return {
    account: () => generatePathWithInstitutionId(routes.account),
    institutionCustomizationCreate: () =>
      generatePathWithInstitutionId(routes.institutionCustomizationCreate),
    dashboard: params => generatePathWithInstitutionId(routes.dashboard, params),
    dataManagement: () => generatePathWithInstitutionId(routes.dataManagement),
    dataManagementCategories: () => generatePathWithInstitutionId(routes.dataManagementCategories),
    dataManagementEmployees: () => generatePathWithInstitutionId(routes.dataManagementEmployees),
    dataManagementEmployeesDetail: ({ id }) =>
      generatePathWithInstitutionId(routes.dataManagementEmployeesDetail, { id }),
    home: () => generatePath(routes.home),
    institutionCustomizationCreateHotline: () =>
      generatePathWithInstitutionId(routes.institutionCustomizationCreateHotline),
    institutionCustomizationCreateLiveHotline: () =>
      generatePathWithInstitutionId(routes.institutionCustomizationCreateLiveHotline),
    institutionCustomization: () => generatePathWithInstitutionId(routes.institutionCustomization),
    institutionCustomizationCreateReportForm: () =>
      generatePathWithInstitutionId(routes.institutionCustomizationCreateReportForm),
    institutionCustomizationFormInputs: ({ id }) =>
      generatePathWithInstitutionId(routes.institutionCustomizationFormInputs, {
        id,
      }),
    institutionCustomizationGeneral: ({ id }) =>
      generatePathWithInstitutionId(routes.institutionCustomizationGeneral, {
        id,
      }),
    institutionCustomizationHotlineTexts: ({ id }) =>
      generatePathWithInstitutionId(routes.institutionCustomizationHotlineTexts, { id }),
    institutionCustomizationOverview: ({ id }) =>
      generatePathWithInstitutionId(routes.institutionCustomizationOverview, {
        id,
      }),
    institutionCustomizationPages: ({ id }) =>
      generatePathWithInstitutionId(routes.institutionCustomizationPages, {
        id,
      }),
    institutionCustomizationSubmitPage: ({ id }) =>
      generatePathWithInstitutionId(routes.institutionCustomizationSubmitPage, {
        id,
      }),
    institutionCustomizationWorkingHours: ({ id }) =>
      generatePathWithInstitutionId(routes.institutionCustomizationWorkingHours, { id }),
    institutions: () => generatePath(routes.institutions),
    integrations: () => generatePathWithInstitutionId(routes.integrations),
    integrationsAppDetail: ({ app }) =>
      generatePathWithInstitutionId(routes.integrationsAppDetail, { app }),
    integrationsApps: () => generatePathWithInstitutionId(routes.integrationsApps),
    integrationsApiKeys: () => generatePathWithInstitutionId(routes.integrationsApiKeys),
    integrationsWebhooks: () => generatePathWithInstitutionId(routes.integrationsWebhooks),
    loginSso: () => generatePath(routes.loginSso),
    materials: () => generatePathWithInstitutionId(routes.materials),
    materialsTable: ({ type, language, institution }) =>
      generatePathWithInstitutionId(routes.materialsTable, { type, language, institution }),
    partnerIntrowPage: () => generatePath(routes.partnerIntrowPage),
    partnerReports: () => generatePath(routes.partnerReports),
    partnerMaterialsManagement: () => generatePath(routes.partnerMaterialsManagement),
    partnerMaterialsManagementTable: props =>
      generatePath(routes.partnerMaterialsManagementTable, props),
    partnerSettings: () => generatePath(routes.partnerSettings),
    partnerSettingsGeneral: () => generatePath(routes.partnerSettingsGeneral),
    partnerSettingsUsers: () => generatePath(routes.partnerSettingsUsers),
    partnerSettingsWhiteLabel: () => generatePath(routes.partnerSettingsWhiteLabel),
    partnerStatistics: () => generatePath(routes.partnerStatistics),
    partnerAccount: () => generatePath(routes.partnerAccount),
    partnerAccountGeneral: () => generatePath(routes.partnerAccountGeneral),
    partnerAccountSecurity: () => generatePath(routes.partnerAccountSecurity),
    register: () => generatePath(routes.register),
    registerFillInfo: ({ token }) => generatePath(routes.registerFillInfo, { token }),
    registerPurchase: ({ id }) => generatePath(routes.registerPurchase, { id }),
    registerTrialRequest: params => generatePath(routes.registerTrialRequest, params),
    registerTrialRequestExtended: params =>
      generatePath(routes.registerTrialRequestExtended, params),
    report: ({ ...params }) => generatePathWithInstitutionId(routes.report, params),
    reportOverview: ({ tab, ...params }) =>
      generatePathWithInstitutionId(routes.reportOverview, params) + (tab ? `#${tab}` : ''),
    reportInvestigation: ({ ...params }) =>
      generatePathWithInstitutionId(routes.reportInvestigation, params),
    reportExport: ({ id }) => generatePathWithInstitutionId(routes.reportExport, { id }),
    reports: params =>
      buildPath(
        generatePathWithInstitutionId(routes.reports),
        params ?? reportsFilters ?? defaultFilter.reports
      ),
    institutionService: ({ id }) =>
      generatePathWithInstitutionId(routes.institutionService, { id }),
    institutionServices: () => generatePathWithInstitutionId(routes.institutionServices),
    partnerService: ({ id }) => generatePath(routes.partnerService, { id }),
    partnerServices: () => generatePath(routes.partnerServices),
    settings: () => generatePathWithInstitutionId(routes.settings),
    settingsCustomization: () => generatePathWithInstitutionId(routes.settingsCustomization),
    settingsInstitution: () => generatePathWithInstitutionId(routes.settingsInstitution),
    settingsWhiteLabel: () => generatePathWithInstitutionId(routes.settingsWhiteLabel),
    settingsInvoicing: () => generatePathWithInstitutionId(routes.settingsInvoicing),
    settingsInvoicingChangePlan: () =>
      generatePathWithInstitutionId(routes.settingsInvoicingChangePlan),
    settingsStructure: () => generatePathWithInstitutionId(routes.settingsStructure),
    settingsUsers: () => generatePathWithInstitutionId(routes.settingsUsers),
    accountGeneral: () => generatePathWithInstitutionId(routes.accountGeneral),
    accountNotifications: () => generatePathWithInstitutionId(routes.accountNotifications),
    accountSecurity: () => generatePathWithInstitutionId(routes.accountSecurity),
    accountAffiliate: () => generatePathWithInstitutionId(routes.accountAffiliate),
    statistics: params =>
      buildPath(
        generatePathWithInstitutionId(routes.statistics),
        params ?? statisticsFilters ?? defaultFilter.statistics
      ),
    surveys: () => generatePathWithInstitutionId(routes.surveys),
    surveyDetail: params => generatePathWithInstitutionId(routes.surveyDetail, params),
    surveySubmissions: params => generatePathWithInstitutionId(routes.surveySubmissions, params),
    surveySubmissionsStatistics: params =>
      generatePathWithInstitutionId(routes.surveySubmissionsStatistics, params),
    surveySubmissionsDetails: params =>
      generatePathWithInstitutionId(routes.surveySubmissionsDetails, params),
    token: params => generatePath(routes.token, params),
    typography: () => generatePath(routes.typography),
    pdfViewer: params => generatePathWithInstitutionId(routes.pdfViewer, params),
  }
}

interface RoutesInterface {
  account: RouteNoParams
  accountGeneral: RouteNoParams
  accountNotifications: RouteNoParams
  accountSecurity: RouteNoParams
  accountAffiliate: RouteNoParams
  dashboard: RouteWithOptionalParams<{ institutionId?: string }>
  dataManagement: RouteNoParams
  dataManagementCategories: RouteNoParams
  dataManagementEmployees: RouteNoParams
  dataManagementEmployeesDetail: RouteWithParams<{ id: string }>
  home: RouteNoParams
  institutionCustomization: RouteNoParams
  institutionCustomizationCreate: RouteNoParams
  institutionCustomizationCreateHotline: RouteNoParams
  institutionCustomizationCreateLiveHotline: RouteNoParams
  institutionCustomizationCreateReportForm: RouteNoParams
  institutionCustomizationFormInputs: RouteWithParams<{ id: string }>
  institutionCustomizationGeneral: RouteWithParams<{ id: string }>
  institutionCustomizationHotlineTexts: RouteWithParams<{ id: string }>
  institutionCustomizationOverview: RouteWithParams<{ id: string }>
  institutionCustomizationPages: RouteWithParams<{ id: string }>
  institutionCustomizationSubmitPage: RouteWithParams<{ id: string }>
  institutionCustomizationWorkingHours: RouteWithParams<{ id: string }>
  institutions: RouteNoParams
  integrations: RouteNoParams
  integrationsAppDetail: RouteWithParams<{ app: App }>
  integrationsApps: RouteNoParams
  integrationsApiKeys: RouteNoParams
  integrationsWebhooks: RouteNoParams
  loginSso: RouteNoParams
  materials: RouteNoParams
  materialsTable: RouteWithParams<{
    type: MaterialType
    language: Language
    institution?: Institution
  }>
  partnerAccount: RouteNoParams
  partnerAccountGeneral: RouteNoParams
  partnerAccountSecurity: RouteNoParams
  partnerIntrowPage: RouteNoParams
  partnerReports: RouteNoParams
  partnerMaterialsManagement: RouteNoParams
  partnerMaterialsManagementTable: RouteWithParams<{
    type: MaterialType
    language: Language
    institution?: Institution
  }>
  partnerSettings: RouteNoParams
  partnerSettingsGeneral: RouteNoParams
  partnerSettingsUsers: RouteNoParams
  partnerSettingsWhiteLabel: RouteNoParams
  partnerStatistics: RouteNoParams
  register: RouteNoParams
  registerFillInfo: RouteWithParams<{ token: string }>
  registerPurchase: RouteWithParams<{ id: string }>
  registerTrialRequest: RouteWithOptionalParams<{ plan: string }>
  registerTrialRequestExtended: RouteWithOptionalParams<{ plan: string }>
  report: RouteWithParams<{ id: string; institutionId?: string }>
  reportOverview: RouteWithParams<{ id: string; institutionId?: string; tab?: Tab }>
  reportInvestigation: RouteWithParams<{ id: string; institutionId?: string }>
  reportExport: RouteWithParams<{ id: string }>
  reports: RouteWithOptionalParams<ReportsFilterSchema>
  institutionService: RouteWithParams<{ id: string }>
  institutionServices: RouteNoParams
  partnerService: RouteWithParams<{ id: string }>
  partnerServices: RouteNoParams
  settings: RouteNoParams
  settingsCustomization: RouteNoParams
  settingsInstitution: RouteNoParams
  settingsInvoicing: RouteNoParams
  settingsInvoicingChangePlan: RouteNoParams
  settingsStructure: RouteNoParams
  settingsUsers: RouteNoParams
  settingsWhiteLabel: RouteNoParams
  statistics: RouteWithOptionalParams<ReportsFilterSchema>
  surveys: RouteNoParams
  surveyDetail: RouteWithParams<{ id: string }>
  surveySubmissions: RouteWithParams<{ id: string }>
  surveySubmissionsStatistics: RouteWithParams<{ id: string }>
  surveySubmissionsDetails: RouteWithParams<{ id: string }>
  token: RouteWithParams<{ id: string }>
  typography: RouteNoParams
  pdfViewer: RouteWithParams<{
    text: string
    institution: string | null
    sourceType: string
    source: string
    title: string
    shortId?: string
  }>
}

type RouteNoParams = () => string
type RouteWithParams<P> = (params: P) => string
type RouteWithOptionalParams<P> = (params?: P) => string

declare module '@faceup/router' {
  export interface RoutesOverride extends RoutesInterface {}
}
