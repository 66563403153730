import { UntitledIcon } from '@faceup/icons'
import { usAlertCircle } from '@faceup/icons/usAlertCircle'
import { useNavigate } from '@faceup/router'
import { Button, Modal, Space, Typography } from '@faceup/ui-base'
import { ContactIssue } from '@faceup/utils'
import { useState } from 'react'
import { FormattedMessage, type TypedMessageDescriptor, defineMessages } from '../TypedIntl'
import useAnalytics from '../utils/analytics'
import ContactSalesModal from './ContactSalesModal'

const { Title } = Typography

const messages = defineMessages({
  action: 'Administration.trialEnded.action',
  contactSales: 'Administration.trialEnded.contactSales',
})

type ModalMessages = 'title' | 'description'

const messagesTrialEnded = defineMessages<ModalMessages>({
  title: 'Administration.trialEnded.title',
  description: 'Administration.trialEnded.description',
})

const messagesSubscriptionEnded = defineMessages<ModalMessages>({
  title: 'Administration.subscriptionEnded.title',
  description: 'Administration.subscriptionEnded.description',
})

type Variant = 'trialEnded' | 'subscriptionEnded'
type TrialEndedModalProps = {
  variant: Variant
  visible: boolean
}

const SubscriptionEndedModal = ({ variant, visible }: TrialEndedModalProps) => {
  const [isContactSalesModalOpened, setIsContactSalesModalOpened] = useState<boolean>(false)
  const { trackBuyActionClicked } = useAnalytics()
  const navigate = useNavigate()

  const config: Record<
    Variant,
    {
      modalMessages: Record<ModalMessages, TypedMessageDescriptor>
      onBuyClick: () => void
    }
  > = {
    subscriptionEnded: {
      modalMessages: messagesSubscriptionEnded,
      onBuyClick: () => {
        navigate(routes => routes.settingsInvoicing())
      },
    },
    trialEnded: {
      modalMessages: messagesTrialEnded,
      onBuyClick: () => {
        trackBuyActionClicked()
        navigate(routes => routes.settingsInvoicing())
      },
    },
  }
  const { modalMessages, onBuyClick } = config[variant]

  return (
    <>
      <Modal open={visible} title={<FormattedMessage {...modalMessages.title} />} width={520}>
        <Space
          style={{ width: '100%', marginTop: '1rem', marginBottom: '1rem' }}
          align='center'
          direction='vertical'
          size='large'
        >
          <UntitledIcon icon={usAlertCircle} size='1.5rem' color='#ef4a45' />
          <Space align='center' direction='vertical'>
            <Title level={2}>
              <FormattedMessage {...modalMessages.title} />
            </Title>
            <FormattedMessage {...modalMessages.description} />
            <Button
              type='link'
              onClick={() => {
                setIsContactSalesModalOpened(true)
              }}
            >
              <FormattedMessage {...messages.contactSales} />
            </Button>
          </Space>
          <Button type='primary' onClick={onBuyClick}>
            <FormattedMessage {...messages.action} />
          </Button>
        </Space>
      </Modal>
      <ContactSalesModal
        contactIssue={ContactIssue.ContactAfterTrial}
        onClose={() => setIsContactSalesModalOpened(false)}
        open={isContactSalesModalOpened}
      />
    </>
  )
}

export default SubscriptionEndedModal
