import type { UntitledIconData } from '@faceup/icons'

export const ucIntrow: UntitledIconData = {
  prefix: 'custom',
  name: 'introw',
  viewBox: '0 0 64 64',
  paths: [
    {
      shape:
        'M31.9998 40.4108C36.7851 40.4108 40.6642 36.5317 40.6642 31.7464C40.6642 26.9612 36.7851 23.082 31.9998 23.082C27.2146 23.082 23.3354 26.9612 23.3354 31.7464C23.3354 36.5317 27.2146 40.4108 31.9998 40.4108Z" fill="#33CC99',
      fill: 'currentColor',
    },
    {
      shape:
        'M0.755615 23.7444H10.4702C11.7672 20.2775 13.88 17.1739 16.6298 14.6961C19.3797 12.2182 22.6857 10.4389 26.2684 9.50867V0.0117188C20.1948 1.12139 14.5653 3.94552 10.0446 8.15074C5.52396 12.356 2.30095 17.7668 0.755615 23.7444Z',
      fill: 'currentColor',
    },
    {
      shape:
        'M37.6799 -0.00195312V9.48858C41.2753 10.4125 44.5946 12.191 47.3551 14.6729C50.1157 17.1547 52.236 20.2664 53.536 23.7435H63.2506C61.7031 17.7558 58.4721 12.337 53.9403 8.12863C49.4085 3.92028 43.7657 1.09867 37.6799 -0.00195312Z',
      fill: 'currentColor',
    },
    {
      shape:
        'M63.2506 39.7849H53.536C52.239 43.2518 50.1262 46.3554 47.3764 48.8332C44.6266 51.3111 41.3206 53.0904 37.7378 54.0206V63.5176C43.8114 62.4079 49.4409 59.5838 53.9616 55.3786C58.4823 51.1733 61.7053 45.7625 63.2506 39.7849Z',
      fill: 'currentColor',
    },
    {
      shape:
        'M26.3262 63.5176V54.027C22.7308 53.1031 19.4116 51.3246 16.651 48.8428C13.8904 46.3609 11.7701 43.2492 10.4701 39.7721H0.755526C2.30297 45.7598 5.534 51.1787 10.0658 55.387C14.5976 59.5953 20.2405 62.417 26.3262 63.5176Z',
      fill: 'currentColor',
    },
    {
      shape:
        'M10.4702 39.7539H0.755615C2.54417 46.6854 6.58312 52.8269 12.2391 57.2149C17.895 61.6029 24.8477 63.989 32.0062 63.9988C39.1639 63.9887 46.1157 61.6024 51.7706 57.2143C57.4254 52.8263 61.4633 46.6849 63.2506 39.7539H53.536C51.8973 44.1434 48.9579 47.9282 45.1106 50.6024C41.2634 53.2767 36.6916 54.7131 32.0062 54.7197C27.321 54.709 22.7502 53.2711 18.9027 50.5974C15.0553 47.9238 12.1139 44.1413 10.4702 39.7539Z',
      fill: 'currentColor',
    },
  ],
}
