import { Select as AntSelect } from 'antd'
import type { BaseSelectRef } from 'rc-select'
import {
  type ComponentProps,
  type HTMLAttributes,
  type ReactNode,
  type Ref,
  forwardRef,
} from 'react'

export type Option<Value, Label> = {
  value: Value
  label: Label
}

type Foo<Value, Label> = {
  value?: Value
  defaultValue?: Value
  options?: Option<Value, Label>[]
  onSelect?: (value: Value) => void
  onSearch?: (value: string) => void
  filterOption?: boolean | ((search: string, option: Option<Value, Label>) => boolean)
  onClear?: () => void
  disabled?: boolean
  showSearch?: boolean
  labelRender?: (option: Option<Value, Label>) => ReactNode
  optionRender?: (option: Option<Value, Label>) => ReactNode
} & Pick<
  ComponentProps<typeof AntSelect>,
  | 'loading'
  | 'size'
  | 'variant'
  | 'suffixIcon'
  | 'showAction'
  | 'placeholder'
  | 'notFoundContent'
  | 'placement'
  | 'dropdownRender'
> &
  Omit<HTMLAttributes<HTMLElement>, 'value' | 'onChange' | 'defaultValue' | 'onSelect'>

export type SelectProps<Value, Label extends ReactNode = ReactNode> = Foo<Value, Label> &
  (
    | {
        allowClear?: false
        onChange?: (value: Value) => void
      }
    | {
        allowClear: true
        onChange?: (value: Value | undefined) => void
      }
  )

const SelectInner = <Value = string, Label extends ReactNode = ReactNode>(
  { onSelect, filterOption, labelRender, optionRender, ...props }: SelectProps<Value, Label>,
  ref: Ref<BaseSelectRef>
) => {
  return (
    <AntSelect
      ref={ref}
      {...props}
      onSelect={onSelect ? value => onSelect(value as Value) : undefined}
      filterOption={
        typeof filterOption === 'function'
          ? (search, option) =>
              option ? filterOption(search, option as Option<Value, Label>) : false
          : filterOption
      }
      labelRender={labelRender ? option => labelRender(option as Option<Value, Label>) : undefined}
      optionRender={
        optionRender ? option => optionRender(option as unknown as Option<Value, Label>) : undefined
      }
      optionFilterProp='label'
    />
  )
}

export const Select = forwardRef(SelectInner) as <
  Value = string,
  Label extends ReactNode = ReactNode,
>(
  props: SelectProps<Value, Label> & { ref?: Ref<BaseSelectRef> }
) => ReactNode
