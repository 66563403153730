import { UntitledIcon } from '@faceup/icons'
import { ulFilterFunnel02 } from '@faceup/icons/ulFilterFunnel02'
import { Table as AntTable, type TableProps as AntTableProps } from 'antd'
import type { AnyObject } from 'antd/es/_util/type'
import type { ColumnType } from 'antd/es/table'
import { type ReactNode, isValidElement } from 'react'
import { Typography } from './Typography'
import { itemRender, showSizeChanger } from './helpers/paginationHelper'

export type TableColumns<D extends AnyObject> = TableColumn<D>[]

export type TableColumn<D extends AnyObject> = Omit<
  ColumnType<D>,
  'dataIndex' | 'render' | 'hidden' | 'filterIcon'
> & {
  disableRowEvent?: boolean | ((rowData: D) => boolean)
  fitWidthToContent?: boolean
  visible?: boolean
  // Allow only function. ReactNode somehow freezes browser
  filterIcon?: (filtered: boolean) => ReactNode
} & (
    | {
        dataIndex?: keyof D
        render?: never
      }
    | {
        dataIndex?: never
        render?: (record: D) => ReactNode
      }
  )

export type TableProps<D extends AnyObject = AnyObject> = {
  shadow?: boolean
  title?: ReactNode
  columns: TableColumns<D>
  dataSource: D[]
} & Omit<AntTableProps<D>, 'bordered' | 'columns' | 'dataSource' | 'title'>

type StopPropagationWrapperProps = {
  children: ReactNode
}

const StopPropagationWrapper = ({ children }: StopPropagationWrapperProps) => {
  const handleClick = (event: React.MouseEvent) => {
    event.stopPropagation()
  }

  return <div onClick={handleClick}>{children}</div>
}

const isObjectWithChildren = (value: unknown): value is { children: React.ReactNode } => {
  return value !== null && typeof value === 'object' && 'children' in value
}

export const Table = <D extends AnyObject>({ shadow, title, ...props }: TableProps<D>) => {
  const columns = props.columns?.map(column => {
    if (column.disableRowEvent && column.render) {
      const originalRender = column.render
      column.render = record => {
        const shouldDisableRowEvent =
          typeof column.disableRowEvent === 'boolean'
            ? column.disableRowEvent
            : column.disableRowEvent?.(record)

        const rendered = originalRender(record)
        if (isObjectWithChildren(rendered) && shouldDisableRowEvent) {
          return <StopPropagationWrapper>{rendered.children}</StopPropagationWrapper>
        } else if (isValidElement(rendered) && shouldDisableRowEvent) {
          return <StopPropagationWrapper>{rendered}</StopPropagationWrapper>
        }
        return rendered
      }
    }
    if (column.fitWidthToContent) {
      column.className = `${column.className || ''} ant-table-fit-width-to-content`
    }
    if (!column.filterIcon) {
      column.filterIcon = () => <UntitledIcon icon={ulFilterFunnel02} />
    }
    return column
  })

  return (
    <div className='ant-table-outer-wrapper '>
      {title && (
        <Typography.Text
          size='lg'
          strong
          className={shadow ? undefined : 'ant-table-outer-wrapper-title'}
        >
          {title}
        </Typography.Text>
      )}
      <AntTable
        {...props}
        pagination={{
          itemRender,
          showSizeChanger,
          ...props.pagination,
        }}
        columns={columns.map(column => ({
          ...column,
          // biome-ignore lint/suspicious/noExplicitAny:
          render: column.render ? (_, record) => column.render?.(record as any) : undefined,
          dataIndex: column.dataIndex as string | undefined,
          hide: column.visible === false,
        }))}
        className={`${shadow ? 'ant-table-shadow' : ''}`}
        rowClassName={(record, index, indent) => {
          const getClassNameFromProps = (): string => {
            if (!props.rowClassName) {
              return ''
            }
            if (typeof props.rowClassName === 'string') {
              return props.rowClassName
            }
            return props.rowClassName(record, index, indent)
          }
          return `${getClassNameFromProps()} ${props.onRow?.(record).onClick ? 'ant-table-row-clickable' : ''}`
        }}
      />
    </div>
  )
}
