import { UntitledIcon } from '@faceup/icons'
import { ulCopy01 } from '@faceup/icons/ulCopy01'
import { Button, Flex, Input, type InputProps } from '@faceup/ui-base'
import { useContext } from 'react'
import { UiContext } from '../UiProvider/UiProvider'

export type InputCopyableProps = {
  onCopyModify?: (inputValue: string) => string
  onCopySuccess?: () => void
  onCopyError?: () => void
  copyButtonDataCy?: string
  buttonPosition?: 'insideInput' | 'afterInput'
} & Omit<InputProps, 'children'>

export const InputCopyable = ({
  onCopyModify = inputValue => inputValue,
  onCopySuccess,
  onCopyError,
  copyButtonDataCy,
  buttonPosition = 'insideInput',
  ...props
}: InputCopyableProps) => {
  const { inputCopyable } = useContext(UiContext)

  const onClick = async () => {
    try {
      await navigator.clipboard.writeText(onCopyModify(props?.value?.toString() ?? ''))
      inputCopyable?.onCopySuccess?.()
      onCopySuccess?.()
    } catch {
      onCopyError?.()
    }
  }
  return (
    <Flex gap='small' align='end'>
      <Input
        {...props}
        readOnly
        suffix={
          <div className='flex gap-8px w-full'>
            {props.suffix}
            {buttonPosition === 'insideInput' && (
              <Button
                shape='circle'
                type='primary'
                size='small'
                data-test={copyButtonDataCy}
                onClick={onClick}
              >
                <UntitledIcon icon={ulCopy01} />
              </Button>
            )}
          </div>
        }
      />
      {buttonPosition === 'afterInput' && (
        <Button onClick={onClick} type='primary' size='large' ghost data-test={copyButtonDataCy}>
          {inputCopyable?.copy ?? 'Copy'}
        </Button>
      )}
    </Flex>
  )
}
