import {
  type BooleanSchema,
  Checkbox,
  type InferType,
  ModalForm,
  type ModalFormProps,
  MultiSelect,
  Radio,
  Switch,
  TextInput,
  useForm,
  useWatch,
  yup,
} from '@faceup/form'
import { useAccessRights } from '@faceup/member'
import { FeatureFlagContext, type FeatureFlagContextProps, HintIcon } from '@faceup/ui'
import { Divider, Space, Tooltip, Typography } from '@faceup/ui-base'
import { FrontendPermissionType } from '@faceup/utils'
import { useElementSize } from '@mantine/hooks'
import { Fragment, type JSX, type ReactNode, useContext, useMemo } from 'react'
import { permissionTypeMessages, sharedMessages } from '../../../Shared/translations'
import { type DefineMessagesType, FormattedMessage, defineMessages } from '../../../TypedIntl'
import { type FragmentType, getFragmentData, graphql } from '../../../__generated__'
import { Upsell, UpsellIcon, useUpsell } from '../../Upsell'
import {
  type Permission,
  type Permissions,
  type ReportAccessVariant,
  findPermissionByPermissionType,
  getReportAccessCategories,
  getReportAccessVariant,
} from './abstractMemberModalHelpers'

const messages = defineMessages({
  allReportCategories: 'Administration.permissions.allReportCategories',
  specificReportCategories: 'Administration.permissions.specificReportCategories',
  specificReportCategoriesOrganizationUnits:
    'Administration.permissions.specificReportCategoriesOrganizationUnits',
  onlyAssignedReports: 'Administration.permissions.onlyAssignedReports',
  accessSettings: 'Administration.settings.abstractMemberModal.accessSettings',
  categories: 'Shared.report.category',
  organizationalUnits: 'Shared.report.organization',
  insufficientPermissions: 'Administration.permissions.tooltip',
})

const permissionGroupMessages: Record<
  AccessKey,
  DefineMessagesType<'title'> | DefineMessagesType<'title' | 'hint'>
> = {
  reports: defineMessages({ title: 'Administration.settings.permissions.reports.title' }),
  analytics: defineMessages({
    title: 'Administration.settings.permissions.analytics.title',
    hint: 'Administration.settings.permissions.analytics.hint',
  }),
  settings: defineMessages({ title: 'Administration.settings.permissions.settings.title' }),
  surveys: defineMessages({
    title: 'Administration.settings.permissions.surveys.title',
    hint: 'Administration.settings.permissions.surveys.hint',
  }),
}

const permissionMessages = permissionTypeMessages

const fragments = {
  AbstractMemberModal_member: graphql(`
    fragment AbstractMemberModal_member on Member {
      id
      isAccountOwner(motherId: $motherId)

      companyIds(motherId: $motherId)
      keys {
        permissions(motherId: $motherId) {
          type
          enabled
          additionalData {
            categoryIds
          }
        }
      }
    }
  `),
  AbstractMemberModal_institution: graphql(`
    fragment AbstractMemberModal_institution on Company {
      id
      config {
        id
        reportCategories {
          id
          name
        }
      }
      organizationalStructure {
        id
        organizationalUnitName
      }
    }
  `),
}

const accessKeys = ['reports', 'analytics', 'settings', 'surveys'] as const
export type AccessKey = (typeof accessKeys)[number]

type Access = {
  isVisible?: (config: { featureFlags: FeatureFlagContextProps }) => boolean
  type?: FrontendPermissionType
  subItems?: { type: FrontendPermissionType; disabled?: boolean }[]
}

type Accesses = Record<AccessKey, Access>

const schema = yup.object().shape({
  email: yup.string().email().min(3).trim().required(),
  institutionIds: yup.array().of(yup.string().required()),
  categoryIds: yup.array().of(yup.string().required()),
  reportAccessVariant: yup.string().oneOf(['all', 'specific', 'assigned']),
  permissions: yup.object(
    Object.values(FrontendPermissionType).reduce(
      (acc, key) => ({
        ...acc,
        [key]: yup.boolean(),
      }),
      {} as Record<FrontendPermissionType, BooleanSchema>
    )
  ),
  openedSection: yup.object(
    accessKeys.reduce(
      (acc, accessKey) => ({
        ...acc,
        [accessKey]: yup.boolean(),
      }),
      {} as Record<string, BooleanSchema>
    )
  ),
})

// I don't know why it mismatches type if used InferType<typeof memberSchema> on FormProps
// It puts ?: into optional fields and RHF doesn't like it
type ValidationSchema = InferType<typeof schema>

export type FormValues = {
  email: string
  institutionIds: string[]
  permissions: Permissions
}

export const accesses: Accesses = {
  reports: {
    type: FrontendPermissionType.ReportAccess,
    subItems: [
      {
        type: FrontendPermissionType.ReportAccess,
        disabled: true,
      },
      { type: FrontendPermissionType.EditCases },
      { type: FrontendPermissionType.ExportCases },
      { type: FrontendPermissionType.DeleteCases },
      { type: FrontendPermissionType.WriteInternalComments },
      { type: FrontendPermissionType.WriteMessages },
    ],
  },
  analytics: {
    type: FrontendPermissionType.Analytics,
  },
  settings: {
    subItems: [
      { type: FrontendPermissionType.SettingsAccess },
      { type: FrontendPermissionType.ManageUsers },
      { type: FrontendPermissionType.ManageCategories },
      { type: FrontendPermissionType.ManageOrganizationalUnits },
      { type: FrontendPermissionType.ManageReportingChannels },
      { type: FrontendPermissionType.BillingAccess },
    ],
  },
  surveys: {
    type: FrontendPermissionType.Surveys,
  },
}

const getPermissions: (
  permissions: Partial<Record<FrontendPermissionType, boolean>>,
  accessKeys: Partial<Record<AccessKey, boolean>>
) => Record<FrontendPermissionType, boolean> = (permissions, accessKeys) =>
  (Object.entries(accesses) as [AccessKey, Access][]).reduce(
    (acc, [accessKey, access]) => {
      let updatedAcc = { ...acc }
      if (access.type) {
        updatedAcc = {
          ...updatedAcc,
          [access.type]: permissions[access.type] ?? false,
        }
      }
      return {
        ...updatedAcc,
        ...access.subItems?.reduce((acc, subItem) => {
          const isParentAccessAllowed = access.type
            ? permissions[access.type]
            : accessKeys[accessKey]
          return {
            ...acc,
            [subItem.type]: permissions[subItem.type] && isParentAccessAllowed,
          }
        }, {}),
      }
    },
    {} as Record<FrontendPermissionType, boolean>
  )

type AbstractMemberModalProps = {
  institution: FragmentType<typeof fragments.AbstractMemberModal_institution>
  member: FragmentType<typeof fragments.AbstractMemberModal_member> | null
  defaultValues: FormValues
  onSubmit: (values: FormValues) => Promise<boolean>
  overrideDisabledMessageForType?: (
    permissionType: FrontendPermissionType,
    isPermissionInputDisabled: JSX.Element | null
  ) => JSX.Element | null
  overrideDisabledMessageForSection?: (
    section: AccessKey,
    isPermissionInputDisabled: JSX.Element | null
  ) => JSX.Element | null
  modalVariant: 'add' | 'edit'
} & Required<Pick<ModalFormProps<ValidationSchema>, 'open' | 'onClose' | 'title'>>

export const AbstractMemberModal = (props: AbstractMemberModalProps) => {
  const {
    member: _member,
    institution: _institution,
    defaultValues,
    open,
    onClose,
    onSubmit,
    title,
    modalVariant,
    overrideDisabledMessageForType = (_, disabledMessage) => disabledMessage,
    overrideDisabledMessageForSection = (_, disabledMessage) => disabledMessage,
  } = props
  const member = getFragmentData(fragments.AbstractMemberModal_member, _member)
  const institution = getFragmentData(fragments.AbstractMemberModal_institution, _institution)
  const upsell = useUpsell()
  const accessRights = useAccessRights()
  const featureFlags = useContext(FeatureFlagContext)

  const isKredenc = !member

  const findEditedUserPermission: (
    permissionType: FrontendPermissionType
  ) => Permission | undefined = FrontendPermissionType =>
    findPermissionByPermissionType(defaultValues.permissions, FrontendPermissionType)

  const getEditedUserReportAccessCategories: () => string[] = () =>
    getReportAccessCategories(defaultValues.permissions, institution.config.reportCategories ?? [])

  const getMineReportAccessCategories: () => string[] = () => {
    if (isKredenc) {
      return institution.config.reportCategories.map(reportCategory => reportCategory.id)
    }
    if (member.isAccountOwner) {
      return institution.config.reportCategories?.map(category => category.id) ?? []
    }
    return getReportAccessCategories(
      member.keys?.permissions ?? [],
      institution.config.reportCategories ?? []
    )
  }

  const organizationStructure = useMemo(
    () => institution.organizationalStructure ?? [],
    [institution.organizationalStructure]
  )

  const getEditedUserReportAccessVariant = () =>
    getReportAccessVariant(
      defaultValues.permissions,
      defaultValues.institutionIds,
      organizationStructure
    )

  const getMineReportAccessVariants = (): ReportAccessVariant => {
    if (isKredenc) {
      return 'all'
    }
    return getReportAccessVariant(
      member.keys?.permissions ?? [],
      member?.companyIds ?? [],
      organizationStructure
    )
  }

  const isSomePermissionEnabled = (accessKey: AccessKey) =>
    accesses[accessKey].subItems?.some(subItem => {
      const permission = findEditedUserPermission(subItem.type)
      return permission?.enabled ?? false
    }) ?? false

  const mineCategories = getMineReportAccessCategories()

  const editedUserCategories = getEditedUserReportAccessCategories()

  // If edited user is assigned reports only, we want to have preselected all available categories
  const formCategoryIds = editedUserCategories.length > 0 ? editedUserCategories : mineCategories
  const editorsInstitutions =
    (isKredenc
      ? institution.organizationalStructure.map(institution => institution.id)
      : member.companyIds) ?? []
  const institutionIds =
    editedUserCategories.length > 0 ? defaultValues.institutionIds : editorsInstitutions

  const form = useForm({
    schema,
    afterSubmit: modalVariant === 'add' ? 'resetValues' : 'persistValues',
    defaultValues: {
      email: defaultValues.email,
      categoryIds: formCategoryIds,
      reportAccessVariant:
        modalVariant === 'add' && member?.isAccountOwner
          ? 'all'
          : getEditedUserReportAccessVariant(),
      institutionIds,
      permissions: defaultValues.permissions.reduce(
        (acc, permission) => ({
          ...acc,
          [permission.type]: permission.enabled,
        }),
        {}
      ),
      openedSection: accessKeys.reduce(
        (acc, accessKey) => ({
          ...acc,
          [accessKey]: isSomePermissionEnabled(accessKey),
        }),
        {}
      ),
    },
  })
  const watchPermissions = useWatch({ control: form.control, name: 'permissions' })
  const watchOpenedSections = useWatch({ control: form.control, name: 'openedSection' })
  const watchReportAccessVariant = useWatch({ control: form.control, name: 'reportAccessVariant' })
  const watchCategoryIds = useWatch({ control: form.control, name: 'categoryIds' })
  const watchInstitutionIds = useWatch({ control: form.control, name: 'institutionIds' })

  const isSubmitButtonDisabled: boolean = useMemo(() => {
    const areAllPermissionsDisabled = Object.values(
      getPermissions(watchPermissions, watchOpenedSections)
    ).every(permission => !permission)
    if (areAllPermissionsDisabled) {
      return true
    }
    const isReportAccessSpecific = watchReportAccessVariant === 'specific'
    if (isReportAccessSpecific) {
      const allCategoriesCount = institution.config.reportCategories.length ?? 0
      const hasSelectedAllCategories = watchCategoryIds?.length === allCategoriesCount
      const hasSelectedAllOrganizationalUnits =
        watchInstitutionIds?.length === organizationStructure.length
      const areCategoriesEmpty = watchCategoryIds?.length === 0
      const areInstitutionsEmpty = watchInstitutionIds?.length === 0
      if (
        (hasSelectedAllCategories && hasSelectedAllOrganizationalUnits) ||
        areCategoriesEmpty ||
        areInstitutionsEmpty
      ) {
        return true
      }
    }
    return false
  }, [
    watchPermissions,
    watchOpenedSections,
    watchReportAccessVariant,
    watchCategoryIds,
    watchInstitutionIds,
    institution.config.reportCategories,
    organizationStructure,
  ])

  const disabledReportAccessAll =
    !member?.isAccountOwner &&
    getEditedUserReportAccessVariant() !== 'all' &&
    ['specific', 'assigned'].includes(getMineReportAccessVariants()) ? (
      <FormattedMessage {...messages.insufficientPermissions} />
    ) : null
  const disableReportAccessSpecific =
    !member?.isAccountOwner &&
    getEditedUserReportAccessVariant() === 'assigned' &&
    ['assigned'].includes(getMineReportAccessVariants()) ? (
      <FormattedMessage {...messages.insufficientPermissions} />
    ) : null

  const customContent: Record<FrontendPermissionType, ReactNode> = {
    [FrontendPermissionType.ReportAccess]: (
      <Space direction='vertical' size={24} className='ml-8'>
        <Radio.Group control={form.control} name='reportAccessVariant' withAsterisk={false}>
          <div className='flex flex-col gap-2'>
            <TooltipCannotSetAccess disabledReason={disabledReportAccessAll}>
              <Radio.Item value='all' disabled={Boolean(disabledReportAccessAll)}>
                <FormattedMessage {...messages.allReportCategories} />
              </Radio.Item>
            </TooltipCannotSetAccess>
            <div>
              <TooltipCannotSetAccess disabledReason={disableReportAccessSpecific}>
                <Radio.Item value='specific' disabled={Boolean(disableReportAccessSpecific)}>
                  <FormattedMessage
                    {...(organizationStructure.length > 1
                      ? messages.specificReportCategoriesOrganizationUnits
                      : messages.specificReportCategories)}
                  />
                </Radio.Item>
              </TooltipCannotSetAccess>
              <Collapse in={form.watch('reportAccessVariant') === 'specific'}>
                <Space
                  direction='vertical'
                  size={8}
                  style={{
                    marginBlock: '16px',
                  }}
                >
                  <MultiSelect
                    control={form.control}
                    name='categoryIds'
                    label={<FormattedMessage {...messages.categories} />}
                    options={institution.config.reportCategories.map(reportCategory => ({
                      value: reportCategory.id,
                      label: reportCategory.name,
                      disabled:
                        !member?.isAccountOwner &&
                        !mineCategories.includes(reportCategory.id) &&
                        !getEditedUserReportAccessCategories().includes(reportCategory.id),
                    }))}
                  />
                  {organizationStructure.length > 1 && (
                    <MultiSelect
                      control={form.control}
                      name='institutionIds'
                      label={<FormattedMessage {...messages.organizationalUnits} />}
                      options={organizationStructure.map(institution => ({
                        value: institution.id,
                        label: institution.organizationalUnitName,
                        disabled:
                          (!member?.isAccountOwner &&
                            !editorsInstitutions.includes(institution.id) &&
                            !defaultValues.institutionIds?.includes(institution.id)) ||
                          (!member?.isAccountOwner &&
                            // if editor is assigned only, they can't edit OU unless it's already added
                            ['assigned'].includes(getMineReportAccessVariants()) &&
                            !defaultValues.institutionIds?.includes(institution.id)) ||
                          (!member?.isAccountOwner &&
                            // if edited user is assigned only, editor can only add OUs they already have
                            ['assigned'].includes(getEditedUserReportAccessVariant()) &&
                            !editorsInstitutions.includes(institution.id)),
                      }))}
                    />
                  )}
                </Space>
              </Collapse>
            </div>
            <Radio.Item value='assigned'>
              <FormattedMessage {...messages.onlyAssignedReports} />
            </Radio.Item>
          </div>
        </Radio.Group>
      </Space>
    ),
    [FrontendPermissionType.EditCases]: null,
    [FrontendPermissionType.ExportCases]: null,
    [FrontendPermissionType.DeleteCases]: null,
    [FrontendPermissionType.WriteInternalComments]: null,
    [FrontendPermissionType.WriteMessages]: null,
    [FrontendPermissionType.Analytics]: null,
    [FrontendPermissionType.SettingsAccess]: null,
    [FrontendPermissionType.BillingAccess]: null,
    [FrontendPermissionType.ManageCategories]: null,
    [FrontendPermissionType.ManageOrganizationalUnits]: null,
    [FrontendPermissionType.ManageReportingChannels]: null,
    [FrontendPermissionType.ManageUsers]: null,
    [FrontendPermissionType.Surveys]: null,
  }

  const getPermissionInputDisabledMessage = (permissionType: FrontendPermissionType) => {
    if (member?.isAccountOwner) {
      return null
    }

    if (!accessRights.isVisibleForPermission[permissionType]) {
      if (
        defaultValues.permissions.find(permission => permission.type === permissionType)
          ?.enabled === false
      ) {
        return <FormattedMessage {...messages.insufficientPermissions} />
      }
    }

    return null
  }

  return (
    <ModalForm
      title={title}
      submitButtonText={modalVariant === 'add' ? 'add' : 'save'}
      isSubmitButtonDisabled={isSubmitButtonDisabled}
      open={open}
      onClose={onClose}
      onSubmit={async values => {
        const getCategoryIds: () => string[] | null = () => {
          if (!values.permissions.ReportAccess) {
            return []
          }
          if (values.reportAccessVariant === 'all') {
            return null
          }
          if (values.reportAccessVariant === 'specific') {
            return values.categoryIds ?? []
          }
          return []
        }
        const categoryIds = getCategoryIds()
        const getInstitutionIds: () => string[] = () => {
          const allOrganizationalUnitsIds =
            institution.organizationalStructure?.map(organizationalUnit => organizationalUnit.id) ??
            []
          if (categoryIds && categoryIds.length > 0) {
            return values.institutionIds ?? allOrganizationalUnitsIds
          }
          return allOrganizationalUnitsIds
        }
        const result = await onSubmit({
          email: values.email,
          institutionIds: getInstitutionIds(),
          permissions: Object.entries(getPermissions(values.permissions, values.openedSection)).map(
            ([type, enabled]) => ({
              type: type as FrontendPermissionType,
              enabled,
              additionalData:
                type === FrontendPermissionType.ReportAccess
                  ? {
                      categoryIds,
                    }
                  : undefined,
            })
          ),
        })
        form.reset()
        return result
      }}
      form={form}
      width={620}
    >
      <TextInput
        control={form.control}
        name='email'
        disabled={
          !isKredenc && form.formState.defaultValues && form.formState.defaultValues.email !== ''
        }
        label={<FormattedMessage {...sharedMessages.emailLabel} />}
        data-test='member-modal-email-input'
      />
      <div className='flex flex-col gap-8'>
        <Typography.Title level={4}>
          <FormattedMessage {...messages.accessSettings} />
        </Typography.Title>
        {(Object.entries(accesses) as [AccessKey, Access][])
          .filter(([, access]) => {
            if (access.isVisible === undefined) {
              return true
            }
            return access.isVisible({ featureFlags })
          })
          .map(([key, access]) => {
            const disabledInputMessage = access.type
              ? getPermissionInputDisabledMessage(access.type)
              : null
            const overriddenDisabledMessage = overrideDisabledMessageForSection(
              key,
              disabledInputMessage
            )
            return (
              <Upsell key={key} upsell={upsell.schoolEditUserPermissions}>
                <Segment
                  title={
                    <>
                      <FormattedMessage {...permissionGroupMessages[key].title} />
                      <UpsellIcon />
                    </>
                  }
                  hint={
                    'hint' in permissionGroupMessages[key] && (
                      <FormattedMessage {...permissionGroupMessages[key].hint} />
                    )
                  }
                  extra={
                    <TooltipCannotSetAccess disabledReason={overriddenDisabledMessage}>
                      <Switch
                        control={form.control}
                        name={access.type ? `permissions.${access.type}` : `openedSection.${key}`}
                        disabled={Boolean(overriddenDisabledMessage)}
                      />
                    </TooltipCannotSetAccess>
                  }
                >
                  <Collapse
                    in={
                      form.watch(
                        access.type ? `permissions.${access.type}` : `openedSection.${key}`
                      ) === true
                    }
                  >
                    <Space direction='vertical' size={8}>
                      {access.subItems?.map(permission => {
                        const disabledInputMessage = getPermissionInputDisabledMessage(
                          permission.type
                        )
                        const overriddenDisabledMessage = overrideDisabledMessageForType(
                          permission.type,
                          disabledInputMessage
                        )
                        const permissionTypeMessages = permissionMessages[permission.type]
                        return (
                          <Fragment key={permission.type}>
                            <TooltipCannotSetAccess disabledReason={overriddenDisabledMessage}>
                              <Checkbox
                                control={form.control}
                                name={`permissions.${permission.type}`}
                                disabled={Boolean(overriddenDisabledMessage) || permission.disabled}
                                label={
                                  <FormattedMessage
                                    {...permissionMessages[permission.type].title}
                                  />
                                }
                                hint={
                                  'hint' in permissionTypeMessages && (
                                    <FormattedMessage {...permissionTypeMessages.hint} />
                                  )
                                }
                              />
                            </TooltipCannotSetAccess>
                            {customContent[permission.type]}
                          </Fragment>
                        )
                      })}
                    </Space>
                  </Collapse>
                </Segment>
              </Upsell>
            )
          })}
      </div>
    </ModalForm>
  )
}

type TooltipCannotSetAccessProps = {
  children: ReactNode
  disabledReason: ReactNode | null
}

const TooltipCannotSetAccess = (props: TooltipCannotSetAccessProps) => {
  const { children, disabledReason } = props
  if (!disabledReason) {
    return <div>{children}</div>
  }

  return (
    <Tooltip title={disabledReason} placement='topLeft'>
      <div>{children}</div>
    </Tooltip>
  )
}

type SegmentProps = {
  title: ReactNode
  hint: ReactNode
  extra: ReactNode
  children: ReactNode
}

const Segment = ({ title, hint, extra, children }: SegmentProps) => {
  return (
    <div className='flex flex-col gap-[20px]'>
      <div className='flex justify-between gap-4'>
        <Typography.Title level={5} className='flex gap-4'>
          {title}
          {hint && <HintIcon title={hint} />}
        </Typography.Title>

        <div>{extra}</div>
      </div>
      {children}
      <Divider />
    </div>
  )
}

type CollapseProps = {
  in: boolean
  children: ReactNode
}

const Collapse = (props: CollapseProps) => {
  const { ref, height } = useElementSize()
  return (
    <div
      className={`overflow-hidden transition-all`}
      style={{
        height: props.in ? height : 0,
      }}
    >
      <div ref={ref}>{props.children}</div>
    </div>
  )
}
