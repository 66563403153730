import { transitionDuration } from '@faceup/ui'
import type { ReactNode } from 'react'
import { useRedaction } from './RedactionProvider'

type NotRedactableWrapperProps = {
  children: ReactNode
}

export const NotRedactableWrapper = (props: NotRedactableWrapperProps) => {
  const { children } = props
  const { isRedactionActive } = useRedaction()
  return (
    <div className={isRedactionActive ? 'cursor-not-allowed' : ''}>
      <div
        style={{
          ...(isRedactionActive ? { opacity: 0.5, pointerEvents: 'none', userSelect: 'none' } : {}),
          transition: `opacity ${transitionDuration}`,
        }}
      >
        {children}
      </div>
    </div>
  )
}
