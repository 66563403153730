import { useMutation } from '@apollo/client'
import { UntitledIcon, type UntitledIconData } from '@faceup/icons'
import { ulEdit05 } from '@faceup/icons/ulEdit05'
import { ulPlusCircle } from '@faceup/icons/ulPlusCircle'
import { ulTranslate01 } from '@faceup/icons/ulTranslate01'
import { ulTrash03 } from '@faceup/icons/ulTrash03'
import { Button, Tooltip, useMessage, useModal } from '@faceup/ui-base'
import { FormItemType } from '@faceup/utils'
import { forwardRef } from 'react'
import { DragListItem } from '../../../../Components/DragListItem'
import { sharedMessages } from '../../../../Shared/translations'
import { FormattedMessage, defineMessages, useIntl } from '../../../../TypedIntl'
import { type FragmentType, getFragmentData, graphql } from '../../../../__generated__'
import { ucDoubleCheck } from '../../../../customIcons/ucDoubleCheck'
import { ucFormItemDate } from '../../../../customIcons/ucFormItemDate'
import { ucFormItemEmail } from '../../../../customIcons/ucFormItemEmail'
import { ucFormItemLargeText } from '../../../../customIcons/ucFormItemLargeText'
import { ucFormItemPhoneNumber } from '../../../../customIcons/ucFormItemPhoneNumber'
import { ucFormItemSelect } from '../../../../customIcons/ucFormItemSelect'
import { ucFormItemSmallText } from '../../../../customIcons/ucFormItemSmallText'
import { ucScale } from '../../../../customIcons/ucScale'
import { useMotherId } from '../../../../hooks/useMotherId'
import { specialFormItemsDef } from '../InstitutionCustomizationOverviewView/Components/FormItem/FormItemForm'
import { Asterisk } from './Asterisk'
import { ListItemLabel } from './ListItemLabel'

const hideTranslationsCountForNow = true

const messages = defineMessages({
  deleteFormItemMessage: 'Administration.customization.formItems.deleteFormItemMessage',
  translations: 'Administration.customization.formItems.translations',
  addToCategory: 'Administration.customization.formItems.addToCategory',
  notDeletable: 'Administration.customization.formItems.notDeletable',
})

export const formItemsIcons: Record<FormItemType, UntitledIconData> = {
  [FormItemType.Select]: ucFormItemSelect,
  [FormItemType.Scale]: ucScale,
  [FormItemType.MultiSelect]: ucDoubleCheck,
  [FormItemType.SimpleText]: ucFormItemSmallText,
  [FormItemType.Classroom]: ucFormItemLargeText,
  [FormItemType.MoreInformation]: ucFormItemLargeText,
  [FormItemType.Category]: ucFormItemSelect,
  [FormItemType.OrganizationalUnit]: ucFormItemSelect,
  [FormItemType.SenderName]: ucFormItemLargeText,
  [FormItemType.MultilineText]: ucFormItemLargeText,
  [FormItemType.Email]: ucFormItemEmail,
  [FormItemType.Date]: ucFormItemDate,
  [FormItemType.PhoneNumber]: ucFormItemPhoneNumber,
}

const fragments = {
  FormItemsListItem_formItem: graphql(`
    fragment FormItemsListItem_formItem on FormItem {
      id
      formItemId
      isRequired
      type

      labelTranslations {
        language
        translation
      }
      ...FormItemsListItemLabel_formItem
    }
  `),
  FormItemsListItem_reportSource: graphql(`
    fragment FormItemsListItem_reportSource on ReportSource {
      id
      defaultLanguage
      languages
      config {
        ... on FormReportSourceConfiguration {
          id
          formItems(categoryId: $categoryId) {
            id
            ...FormItemsListItem_formItem
          }
        }
      }
      ...FormItemsListItemLabel_reportSource
    }
  `),
}

const mutations = {
  AddFormItemToCategory: graphql(`
    mutation AddFormItemToCategory($input: AddFormItemToCategoryInput!) {
      addFormItemToCategory(input: $input) {
        config {
          id
        }
      }
    }
  `),
}

type FormItemsListItemProps = {
  formItem: FragmentType<typeof fragments.FormItemsListItem_formItem>
  reportSource: FragmentType<typeof fragments.FormItemsListItem_reportSource>
  isDraggable: boolean
  identifier: string
  onClose: () => void
  refetchQuery: () => void
  isDeletable: boolean
  onDelete: () => void
  onEdit: () => void
  onEditTranslations: () => void
  disabled?: boolean
  selectedCategoryId: string | null
}

export const FormItemsListItem = forwardRef<HTMLDivElement, FormItemsListItemProps>(
  (props, ref) => {
    const {
      reportSource: _reportSource,
      formItem: _formItem,
      isDraggable,
      identifier,
      isDeletable,
      onEdit,
      onEditTranslations,
      onDelete,
      onClose,
      refetchQuery,
      disabled = false,
      selectedCategoryId,
      ...passProps
    } = props
    const reportSource = getFragmentData(fragments.FormItemsListItem_reportSource, _reportSource)
    const formItem = getFragmentData(fragments.FormItemsListItem_formItem, _formItem)
    const { formatMessage } = useIntl()
    const { getMotherId } = useMotherId()
    const message = useMessage()
    const modal = useModal()
    const countLanguages = formItem.labelTranslations.length
    const countTranslatedLanguages = formItem.labelTranslations.filter(
      translation => translation.translation
    ).length

    const [addFormItemToCategory] = useMutation(mutations.AddFormItemToCategory, {
      onError: error => {
        console.error(error)
        modal.alert.error({
          title: formatMessage(sharedMessages.apiError),
          content: error.message,
        })
      },
    })

    return (
      <DragListItem
        ref={ref}
        {...passProps}
        disabled={disabled}
        icon={formItemsIcons[formItem.type]}
        isDraggable={isDraggable}
        identifier={identifier}
        isClickable
        onClick={onEdit}
        isFocused={false}
        label={
          <>
            <ListItemLabel reportSource={reportSource} formItem={formItem} />
            {formItem.isRequired && (
              <>
                &nbsp;
                <Asterisk />
              </>
            )}
          </>
        }
        secondary={
          <div className='flex gap-8 items-center'>
            {reportSource.languages.length > 1 && (
              <Tooltip title={<FormattedMessage {...messages.translations} />}>
                <Button
                  size='small'
                  type='text'
                  icon={<UntitledIcon icon={ulTranslate01} size={20} />}
                  onClick={onEditTranslations}
                >
                  {!hideTranslationsCountForNow &&
                    (specialFormItemsDef.includes(formItem.type) ? (
                      <>
                        {countLanguages}/{countLanguages}
                      </>
                    ) : (
                      <>
                        {countTranslatedLanguages}/{countLanguages}
                      </>
                    ))}
                </Button>
              </Tooltip>
            )}
            <div className='flex'>
              <Tooltip title={<FormattedMessage {...sharedMessages.edit} />}>
                <Button
                  type='text'
                  onClick={onEdit}
                  icon={<UntitledIcon icon={ulEdit05} size={20} />}
                />
              </Tooltip>
              <Tooltip
                title={
                  <FormattedMessage
                    {...(isDeletable ? sharedMessages.delete : messages.notDeletable)}
                  />
                }
              >
                <div>
                  <Button
                    type='text'
                    onClick={() => {
                      modal.confirm.danger({
                        title: formatMessage(sharedMessages.delete),
                        content: formatMessage(messages.deleteFormItemMessage),
                        onConfirm: onDelete,
                      })
                    }}
                    disabled={!isDeletable}
                    icon={<UntitledIcon icon={ulTrash03} size={20} />}
                  />
                </div>
              </Tooltip>
            </div>
            {disabled && selectedCategoryId && (
              <Tooltip title={<FormattedMessage {...messages.addToCategory} />}>
                <Button
                  type='text'
                  onClick={async () => {
                    const result = await addFormItemToCategory({
                      variables: {
                        input: {
                          motherId: getMotherId(),
                          channelId: reportSource.id,
                          categoryId: selectedCategoryId,
                          formItemId: formItem.formItemId,
                        },
                      },
                    })
                    if (!result.errors) {
                      refetchQuery()
                      message.success(formatMessage(sharedMessages.savedMessage))
                    }
                  }}
                  icon={<UntitledIcon icon={ulPlusCircle} size={20} />}
                />
              </Tooltip>
            )}
          </div>
        }
      />
    )
  }
)
