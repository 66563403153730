import type { ReactNode } from 'react'
import styles from './OverTheTopSection.module.css'

type OverTheTopSectionProps = {
  children: ReactNode
}

export const OverTheTopSection = ({ children }: OverTheTopSectionProps) => {
  return <div className={styles.root}>{children}</div>
}
