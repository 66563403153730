import { Country, type EuCountries } from './localization'

export const STRING_MIN_LENGTH = 1

export const SHORT_STRING_MAX_LENGTH = 255
export const MEDIUM_STRING_MAX_LENGTH = 1_000
export const LONG_STRING_MAX_LENGTH = 10_000
export const TEXT_MAX_LENGTH = 100_000

export const INSTITUTION_PASSWORD_MIN_LENGTH = 5
export const INSTITUTION_PASSWORD_MAX_LENGTH = 30
// institution password must contain letters and digits only (no whitespace and no diacritics)
export const INSTITUTION_PASSWORD_VALIDATION = /^[a-zA-Z0-9]{5,30}$/
export const OPAQUE_SHORT_ID_VALIDATION = /^[a-z0-9]{5,30}$/

export const USER_PASSWORD_MIN_LENGTH = 8
export const USER_PASSWORD_MAX_LENGTH = 30

// regex for user name; it allows all lower/upper/diacritics and `-'. `; for 2 groups - each 2-50 chars
export const USER_NAME_REGEX = /^[a-zA-ZÀ-ž\-'. ]{2,50}(?: [a-zA-ZÀ-ž\-'. ]{2,50})+$/

export const ATTACHMENT_MAX_FILES_COUNT = 20
export const ATTACHMENT_MAX_FILE_SIZE = 52_428_800 // 50 * 2^20 B ~ 50 MB

export const MAX_SELECT_FORM_ITEM_OPTIONS_COUNT = 400

type MimetypeType = 'application' | 'audio' | 'image' | 'text' | 'video'

export const ALLOWED_IMAGE_MIME_TYPE_EXTENSION_MAP: Record<`image/${string}`, string[]> = {
  'image/jpeg': ['jpeg', 'jpg'],
  'image/png': ['png'],
}

export const ALLOWED_FILE_MIME_TYPE_EXTENSION_MAP: Record<`${MimetypeType}/${string}`, string[]> = {
  ...ALLOWED_IMAGE_MIME_TYPE_EXTENSION_MAP,
  'video/mp4': ['mp4'],
  'video/quicktime': ['mov'],
  'video/x-msvideo': ['avi'],
  'audio/mpeg': ['mp3'],
  'audio/x-wav': ['wav'],
  'audio/wav': ['wav'],
  'audio/ogg': ['oga'],
  'application/pdf': ['pdf'],
  'application/x-latex': ['latex'],
  'application/x-tex': ['tex'],
  'text/plain': ['c', 'cc', 'h', 'txt', 'log', 'json'],
  'application/msword': ['doc'],
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['docx'],
  'application/vnd.oasis.opendocument.text': ['odt'],
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['xlsx'],
  'application/vnd.ms-excel': ['xls'],
  'application/vnd.oasis.opendocument.spreadsheet': ['ods'],
}

export const ALLOWED_FILE_MIME_TYPE_EXTENSION_PLUS_DEPRECATED_MAP: Record<
  `${MimetypeType}/${string}`,
  string[]
> = {
  ...ALLOWED_FILE_MIME_TYPE_EXTENSION_MAP,
  // see commit 5b3672e15fabe92ca3a52f2b2dd6b7752761aaf0
  'application/octet-stream': ['mp3'],
}

type EuVatCountry = EuCountries | Country.Gb

// Countries which have VAT identification in European Union (including United Kingdom) https://github.com/yolk/valvat/blob/master/lib/valvat/syntax.rb
export const EU_VAT_REGEX: Record<EuVatCountry, RegExp> = {
  [Country.At]: /^ATU[0-9]{8}$/,
  [Country.Be]: /^BE[0-1][0-9]{9}$/,
  [Country.Bg]: /^BG[0-9]{9,10}$/,
  [Country.Cy]: /^CY(?!12)[0-69][0-9]{7}[A-Z]$/,
  [Country.Cz]: /^CZ[0-9]{8,10}$/,
  [Country.De]: /^DE[0-9]{9}$/,
  [Country.Dk]: /^DK[0-9]{8}$/,
  [Country.Ee]: /^EE10[0-9]{7}$/,
  [Country.Gr]: /^EL[0-9]{9}$/,
  [Country.Es]: /^ES([A-Z][0-9]{8}|[0-9]{8}[A-Z]|[A-Z][0-9]{7}[A-Z])$/,
  [Country.Fi]: /^FI[0-9]{8}$/,
  [Country.Fr]: /^FR[A-HJ-NP-Z0-9]{2}[0-9]{9}$/,
  [Country.Gb]: /^(GB|XI)([0-9]{9}|[0-9]{12}|(HA|GD)[0-9]{3})$/,
  [Country.Hr]: /^HR[0-9]{11}$/,
  [Country.Hu]: /^HU[0-9]{8}$/,
  [Country.Ie]: /^IE([0-9][A-Z][0-9]{5}|[0-9]{7}[A-Z]?)[A-Z]$/,
  [Country.It]: /^IT[0-9]{11}$/,
  [Country.Lt]: /^LT([0-9]{7}1[0-9]|[0-9]{10}1[0-9])$/,
  [Country.Lu]: /^LU[0-9]{8}$/,
  [Country.Lv]: /^LV[0-9]{11}$/,
  [Country.Mt]: /^MT[0-9]{8}$/,
  [Country.Nl]: /^NL[0-9]{9}B[0-9]{2}$/,
  [Country.Pl]: /^PL[0-9]{10}$/,
  [Country.Pt]: /^PT[0-9]{9}$/,
  [Country.Ro]: /^RO[1-9][0-9]{1,9}$/,
  [Country.Se]: /^SE[0-9]{10}01$/,
  [Country.Si]: /^SI[0-9]{8}$/,
  [Country.Sk]: /^SK[0-9]{10}$/,
}

export const isEuVat = (country: Country, vat: string) =>
  country in EU_VAT_REGEX && EU_VAT_REGEX[country as EuVatCountry]?.test(vat)

export const MAX_API_KEYS_COUNT = 10

export const CASE_RATING_RANGE = [1, 2, 3, 4, 5] as const
export type CaseRating = (typeof CASE_RATING_RANGE)[number]
