import { UntitledIcon } from '@faceup/icons'
import { ulCheckCircle } from '@faceup/icons/ulCheckCircle'
import { FormItem } from '@faceup/ui'
import { Flex, Input, Typography, useUiBaseConfig } from '@faceup/ui-base'
import { FormItemType } from '@faceup/utils'
import type React from 'react'
import type { ReactNode } from 'react'

type SurveyItemType = {
  id: string
  title: ReactNode
  active: boolean
  large?: boolean
}

type QuestionProps = {
  title: ReactNode
  description?: ReactNode
  large?: boolean
  required?: boolean
  secondary?: ReactNode
  unanswered?: boolean
  disabled?: boolean
} & (
  | {
      answers: SurveyItemType[]
      answer?: never
      type: FormItemType.MultiSelect | FormItemType.Select
      start?: never
      end?: never
    }
  | {
      answers?: never
      answer: string
      type: FormItemType.SimpleText
      start?: never
      end?: never
    }
  | {
      answers: SurveyItemType[]
      answer?: never
      type: FormItemType.Scale
      start: string
      end: string
    }
)

export const Question = ({
  title,
  description,
  answers,
  type,
  answer,
  secondary,
  start,
  end,
  large = false,
  required = false,
  disabled = false,
}: QuestionProps) => {
  const getComponent: (type: FormItemType) => ReactNode = type => {
    const componentLookup: Partial<Record<FormItemType, ReactNode>> = {
      [FormItemType.Scale]: (
        <Flex gap={12} vertical>
          <Flex gap={6} wrap>
            {start && (
              <Typography.Text>
                {answers?.[0]?.title} - {start}
                {start && end && ','}
              </Typography.Text>
            )}
            {end && (
              <Typography.Text>
                {answers?.[answers.length - 1]?.title} - {end}
              </Typography.Text>
            )}
          </Flex>
          <Flex gap={8} wrap>
            {answers?.map(item => (
              <ScaleItem key={item.id} item={item} disabled={disabled} />
            ))}
          </Flex>
        </Flex>
      ),
      [FormItemType.MultiSelect]: answers?.map(item => (
        <SurveyItem key={item.id} item={item} large={large} disabled={disabled} />
      )),
      [FormItemType.Select]: answers?.map(item => (
        <SurveyItem key={item.id} item={item} large={large} disabled={disabled} />
      )),
      [FormItemType.SimpleText]: <AnswerComponent answer={answer} disabled={disabled} />,
    }
    return componentLookup[type] ?? <AnswerComponent answer={answer} disabled={disabled} />
  }

  const renderedComponent = getComponent(type)

  return (
    <FormItem
      variant='black'
      label={title}
      description={description}
      withAsterisk={required}
      secondary={secondary}
    >
      <Flex vertical gap='8px' className='mt-[12px]'>
        {renderedComponent}
      </Flex>
    </FormItem>
  )
}

type AnswerComponentProps = {
  answer: string | undefined
  disabled?: boolean
}

const AnswerComponent = ({ answer, disabled }: AnswerComponentProps) =>
  answer ? <Typography.Text disabled={disabled}>{answer}</Typography.Text> : <Input disabled />

type SurveyItemProps = {
  item: SurveyItemType
  large?: boolean
  disabled?: boolean
}

const SurveyItem = ({ item, large = false, disabled = false }: SurveyItemProps) => {
  const { token } = useUiBaseConfig()

  const activeColor: React.CSSProperties = { color: '#FFFFFF' }
  const activeStyles: React.CSSProperties = {
    backgroundColor: token.colorPrimary,
    border: 'none',
    ...activeColor,
  }

  return (
    <Flex
      style={{
        minHeight: large ? '54px' : '48px',
        borderRadius: token.borderRadiusLG,
        backgroundColor: disabled ? '#9DB0DF14' : '#FFFFFF',
        border: `1px solid ${token.colorBorder}`,
        padding: '16px',
        ...(item.active ? activeStyles : {}),
      }}
      align='center'
      justify='space-between'
    >
      <Typography.Text
        style={{
          ...(item.active ? activeColor : {}),
        }}
        disabled={disabled}
      >
        {item.title}
      </Typography.Text>
      {item.active && <UntitledIcon icon={ulCheckCircle} size={20} />}
    </Flex>
  )
}

type ScaleItemProps = {
  item: SurveyItemType
  disabled?: boolean
}

const ScaleItem = ({ item, disabled = false }: ScaleItemProps) => {
  const { token } = useUiBaseConfig()

  const activeColor: React.CSSProperties = { color: '#FFFFFF' }
  const activeStyles: React.CSSProperties = {
    backgroundColor: token.colorPrimary,
    border: 'none',
    ...activeColor,
  }

  return (
    <Flex
      style={{
        borderRadius: token.borderRadiusLG,
        backgroundColor: disabled ? '#9DB0DF14' : '#FFFFFF',
        border: `1px solid ${token.colorBorder}`,
        padding: '16px',
        height: '44px',
        width: '44px',
        ...(item.active ? activeStyles : {}),
      }}
      align='center'
      justify='center'
    >
      <Typography.Text
        style={{
          wordBreak: 'keep-all',
          ...(item.active ? activeColor : {}),
        }}
        disabled={disabled}
      >
        {item.title}
      </Typography.Text>
    </Flex>
  )
}
