import type { UntitledIconData } from '../../UntitledIconData'

const ulArrowRight: UntitledIconData = {
  prefix: 'ul',
  name: 'arrow-right',
  paths: [
    {
      shape: 'M5 12H19M19 12L12 5M19 12L12 19',
    },
  ],
}

export default ulArrowRight
