import './assets/input-phone.css'
import type { Country } from '@faceup/utils'
import { useContext } from 'react'
import ReactPhoneInput, { type PhoneInputProps } from 'react-phone-input-2'
import { UiContext } from '../UiProvider/UiProvider'

// @ts-expect-error Hack due https://github.com/vitejs/vite/issues/4704
const PhoneInput: typeof ReactPhoneInput = ReactPhoneInput.default
  ? // @ts-expect-error Hack due https://github.com/vitejs/vite/issues/4704
    ReactPhoneInput.default
  : ReactPhoneInput

export type InputPhoneProps = {
  country?: Country | null
  'data-test'?: string
} & Omit<
  PhoneInputProps,
  | 'inputClass'
  | 'enableSearch'
  | 'searchPlaceholder'
  | 'localization'
  | 'disableSearchIcon'
  | 'country'
>

const InputPhone = ({ country, ...props }: InputPhoneProps) => {
  const { inputPhone } = useContext(UiContext)

  return (
    <PhoneInput
      {...inputPhone}
      {...props}
      inputProps={{
        'data-test': props['data-test'],
      }}
      country={country?.toLowerCase() ?? undefined}
      inputClass={`flex border-1 border-[#CDDDEE] hover:border-[#007AE1] active:border-[#007AE1] transition-all rounded-[12px] min-h-[44px] gap-[10px] px-[11px] w-full`}
      enableSearch
      disableSearchIcon
      onChange={(value, data, event, formattedValue) =>
        props?.onChange?.(value && `+${value}`, data, event, formattedValue)
      }
    />
  )
}

export default InputPhone
