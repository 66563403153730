import { Plan } from './enums'
import type { Language } from './localization'
import { storageGet, storageRemove, storageSet } from './storage'

type SourceLanguage = Language | 'auto'
type TargetLanguage = Language
type TranslateReportConfig = Record<string, `${SourceLanguage}-${TargetLanguage}`>

const TRANSLATE_REPORTS_STORAGE_KEY = 'TRANSLATE_REPORTS'
const VALUES_DELIMETER = '-'

export const planMapName: Record<Plan, string> = {
  [Plan.Unregistered]: 'Unregistered',
  [Plan.Free]: 'Free',
  [Plan.Basic]: 'Starter',
  [Plan.Standard]: 'Professional',
  [Plan.Premium]: 'Enterprise',
}

const getTranslateReportLanguageConfig = () => {
  // @todo: migrate the storage?
  const config: TranslateReportConfig = JSON.parse(
    storageGet('localStorage', TRANSLATE_REPORTS_STORAGE_KEY) ?? '{}'
  )

  return config
}

const setTranslateReportLanguageConfig = (config: TranslateReportConfig) => {
  storageSet('localStorage', TRANSLATE_REPORTS_STORAGE_KEY, JSON.stringify(config))
}

export const saveTranslateReportLanguage = (
  reportId: string,
  [sourceLanguage, targetLanguage]: [SourceLanguage, TargetLanguage]
) => {
  const value = `${sourceLanguage}${VALUES_DELIMETER}${targetLanguage}` as const
  const config = getTranslateReportLanguageConfig()

  setTranslateReportLanguageConfig({ ...config, [reportId]: value })
}

export const getTranslateReportLanguage = (reportId: string) => {
  const config = getTranslateReportLanguageConfig()
  const value = config[reportId]

  if (value) {
    return value.split(VALUES_DELIMETER) as [SourceLanguage, TargetLanguage]
  }

  return [null, null] as const
}

export const removeAllTranslateReportLanguage = () => {
  storageRemove('localStorage', TRANSLATE_REPORTS_STORAGE_KEY)
}

export const removeTranslateReportLanguage = (reportId: string) => {
  const config = getTranslateReportLanguageConfig()
  delete config[reportId]

  setTranslateReportLanguageConfig(config)
}

export const getTranslation = <D>(
  translations: { language: D; translation: string }[],
  language: D,
  defaultLanguage?: D
): string =>
  translations?.find(t => t.language === language)?.translation ??
  translations?.find(t => t.language === defaultLanguage)?.translation ??
  ''
