import { ChannelType } from '@faceup/utils'
import { createContext } from 'react'

type SharedRelativeMessages = {
  writeNote: { id: string }
  empty: { id: string }
  closedMessagesPerChannel: Record<ChannelType, { id: string }>
  archivedMessagesPerChannel: Record<ChannelType, { id: string }>
  addAttachments: { id: string }
  attachmentsTitle: { id: string }
  noteLabel: { id: string }
  detectLanguage: { id: string }
  translate: { id: string }
  showOriginal: { id: string }
  to: { id: string }
  sameLanguageError: { id: string }
  disabledReasonE2EE: { id: string }
  onUseWarningTitle: { id: string }
  onUseWarningContent: { id: string }
  onUseWarningDoNotShowAgain: { id: string }
}

type RelativeMessages = (
  | {
      type: 'Admin'
      hideMyName: { id: string }
      nameHidden: { id: string }
      functionalityUnsupported: { id: string }
    }
  | {
      type: 'Victim'
      administrator: { id: string }
      reopen: { id: string }
      leaveQuestion: { id: string }
    }
  | {
      type: 'None'
    }
) &
  SharedRelativeMessages

export const RelativeMessagesContext = createContext<RelativeMessages>({
  type: 'None',
  empty: { id: '' },
  writeNote: { id: '' },
  closedMessagesPerChannel: {
    [ChannelType.Survey]: { id: '' },
    [ChannelType.AutomatedHotline]: { id: '' },
    [ChannelType.LiveHotline]: { id: '' },
    [ChannelType.ReportingSystem]: { id: '' },
  },
  archivedMessagesPerChannel: {
    [ChannelType.Survey]: { id: '' },
    [ChannelType.AutomatedHotline]: { id: '' },
    [ChannelType.LiveHotline]: { id: '' },
    [ChannelType.ReportingSystem]: { id: '' },
  },
  addAttachments: { id: '' },
  attachmentsTitle: { id: '' },
  noteLabel: { id: '' },
  detectLanguage: { id: '' },
  translate: { id: '' },
  showOriginal: { id: '' },
  to: { id: '' },
  sameLanguageError: { id: '' },
  disabledReasonE2EE: { id: '' },
  onUseWarningTitle: { id: '' },
  onUseWarningContent: { id: '' },
  onUseWarningDoNotShowAgain: { id: '' },
})

type Props = {
  relativeMessages: RelativeMessages
  children: React.ReactNode
}

export const RelativeMessagesProvider = ({ relativeMessages, children }: Props) => (
  <RelativeMessagesContext.Provider value={relativeMessages}>
    {children}
  </RelativeMessagesContext.Provider>
)
