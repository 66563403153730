import { UntitledIcon } from '@faceup/icons'
import { ulClock } from '@faceup/icons/ulClock'
import { forwardRef } from 'react'
import { BaseDatePicker, type BaseDatePickerProps } from './bases/BaseDatePicker'

export interface TimePickerProps extends Omit<BaseDatePickerProps, 'picker' | 'mode'> {}

// biome-ignore lint/suspicious/noExplicitAny:
export const TimePicker = forwardRef<any, TimePickerProps>((props, ref) => (
  <BaseDatePicker
    ref={ref}
    {...props}
    picker='time'
    mode={undefined}
    suffixIcon={props.suffixIcon ?? <UntitledIcon icon={ulClock} />}
  />
))

TimePicker.displayName = 'TimePicker'
