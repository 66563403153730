import { UntitledIcon } from '@faceup/icons'
import { Alert as AntAlert, type AlertProps as AntAlertProps } from 'antd'
import { statusIcons } from './helpers/alertHelper'

type AlertProps = Omit<AntAlertProps, 'showIcon'>

export const Alert = (props: AlertProps) => {
  return (
    <AntAlert
      icon={<UntitledIcon icon={statusIcons[props.type ?? 'info']} />}
      showIcon={props.icon !== null}
      {...props}
    />
  )
}
