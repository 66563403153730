import styled from '@emotion/styled'
import { Alert, Button } from '@faceup/ui-base'
import { ChargebeeSubscriptionStatus, ContactIssue } from '@faceup/utils'
import { useEffect, useState } from 'react'
import { useProductFruitsApi } from 'react-product-fruits'
import { FormattedMessage, defineMessages } from '../../TypedIntl'
import { type FragmentType, getFragmentData, graphql } from '../../__generated__'
import { ContactSalesAlertState, usePersistentUserStorage } from '../../utils/persistentUserStorage'
import ContactSalesModal from '../ContactSalesModal'

const messages = defineMessages({
  contactSalesQuestion: 'Administration.contactSales.question',
  contactSalesLink: 'Administration.contactSales.linkToContact',
})

const fragments = {
  ContactSalesAlertFragments_company: graphql(`
    fragment ContactSalesAlertFragments_company on Company {
      id

      billing {
        id
        subscriptionStatus
      }
    }
  `),
}

type ContactSalesAlertProps = {
  company: FragmentType<typeof fragments.ContactSalesAlertFragments_company>
}

const ContactSalesAlert = ({ company: _company }: ContactSalesAlertProps) => {
  const company = getFragmentData(fragments.ContactSalesAlertFragments_company, _company)
  const [canShowContactSalesAlert, setCanShowContactSalesAlert] = useState(true)
  const [isContactSalesModalOpened, setIsContactSalesModalOpened] = useState<boolean>(false)
  const { contactSalesAlertState, setContactSalesAlertState } = usePersistentUserStorage()

  useEffect(() => {
    if (company?.billing?.subscriptionStatus !== ChargebeeSubscriptionStatus.InTrial) {
      setContactSalesAlertState(ContactSalesAlertState.ClosedPermanently)
    }
  }, [company, setContactSalesAlertState])

  useProductFruitsApi(api => {
    const isSomeTourRunning = api?.tours
      ?.getTours?.()
      ?.some((tour: { isRunning: boolean }) => tour.isRunning)
    const hideContactSalesAlert = () => {
      setCanShowContactSalesAlert(false)
    }
    const showContactSalesAlert = () => {
      setCanShowContactSalesAlert(true)
    }
    if (isSomeTourRunning) {
      hideContactSalesAlert()
    }
    api?.tours.listen?.('tour-advanced', hideContactSalesAlert)
    api?.tours.listen?.('tour-skipped', showContactSalesAlert)
    api?.tours.listen?.('tour-finished', showContactSalesAlert)
  }, [])

  if (
    !canShowContactSalesAlert ||
    contactSalesAlertState === ContactSalesAlertState.ClosedPermanently
  ) {
    return null
  }

  return (
    <>
      <ContactSalesAlertWrapper>
        <div>
          <Alert
            className='!bg-[#062D46]'
            icon={null}
            message={
              <>
                <FormattedMessage {...messages.contactSalesQuestion} />{' '}
                <Button
                  ghost
                  size='small'
                  onClick={() => {
                    setIsContactSalesModalOpened(true)
                  }}
                >
                  <FormattedMessage {...messages.contactSalesLink} />
                </Button>
              </>
            }
            closable
            onClose={() => {
              if (contactSalesAlertState === ContactSalesAlertState.ClosedOnce) {
                setContactSalesAlertState(ContactSalesAlertState.ClosedPermanently)
              } else {
                setContactSalesAlertState(ContactSalesAlertState.ClosedOnce)
              }
            }}
          />
        </div>
      </ContactSalesAlertWrapper>
      <ContactSalesModal
        contactIssue={ContactIssue.ContactDuringTrial}
        onClose={() => setIsContactSalesModalOpened(false)}
        open={isContactSalesModalOpened}
      />
    </>
  )
}

const ContactSalesAlertWrapper = styled.div`
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translate(-50%, -50%);

  .ant-alert-message,
  .anticon-close svg {
    color: #fff !important;
    fill: #fff !important;
  }
`

export default ContactSalesAlert
