import { Button, Flex, Typography } from '@faceup/ui-base'

type ScaleProps = {
  startLabel?: string
  endLabel?: string
  options: {
    id: string
    label: string
    isActive?: boolean
  }[]
  onChange: (values: string[]) => void
}

const Scale = ({ startLabel, endLabel, options, onChange }: ScaleProps) => {
  return (
    <Flex gap={12} vertical>
      <Flex gap={6} wrap>
        {startLabel && (
          <Typography.Text>
            {options?.[0]?.label} - {startLabel}
            {startLabel && endLabel && ','}
          </Typography.Text>
        )}
        {endLabel && (
          <Typography.Text>
            {options?.[options.length - 1]?.label} - {endLabel}
          </Typography.Text>
        )}
      </Flex>
      <Flex gap={8} wrap>
        {options.map(option => {
          return (
            <Button
              key={option.id}
              type={option.isActive ? 'primary' : 'default'}
              onClick={() => onChange([option.label])}
              className='w-[44px] h-[44px]'
            >
              {option.label}
            </Button>
          )
        })}
      </Flex>
    </Flex>
  )
}

export default Scale
