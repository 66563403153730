import { Tooltip, Typography } from '@faceup/ui-base'
import { type ReactNode, useEffect, useRef, useState } from 'react'
import styles from './assets/AnswerCard.module.css'

type AnswerCardProps = {
  children: ReactNode
}

export const AnswerCard = ({ children }: AnswerCardProps) => {
  return <div className={styles.answerCard}>{children}</div>
}

const AnswerCardTitle = ({ title }: { title: ReactNode }) => {
  const titleRef = useRef(null)
  const [isTruncated, setIsTruncated] = useState(false)

  useEffect(() => {
    const checkEllipsis = () => {
      if (titleRef.current) {
        const { scrollWidth, clientWidth } = titleRef.current
        setIsTruncated(scrollWidth > clientWidth)
      }
    }
    checkEllipsis()
    window.addEventListener('resize', checkEllipsis)
    return () => window.removeEventListener('resize', checkEllipsis)
  }, [])

  return (
    <Tooltip title={isTruncated ? title : ''}>
      <Typography.Title level={5} className='max-h-[25px] truncate' ref={titleRef}>
        {title}
      </Typography.Title>
    </Tooltip>
  )
}

AnswerCard.Title = AnswerCardTitle
