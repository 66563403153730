import { UntitledIcon } from '@faceup/icons'
import { usAlertCircle } from '@faceup/icons/usAlertCircle'
import { Space } from '@faceup/ui-base'
import type { ReactNode } from 'react'
import { FormattedMessage, defineMessages } from '../../TypedIntl'
import { type FragmentType, getFragmentData, graphql } from '../../__generated__'
import useSubscriptionDetail, {
  type SubscriptionDetailReturn,
} from '../../hooks/useSubscriptionDetail'

const messages = defineMessages({
  trialEndsIn: 'Administration.trial.endsIn',
  trialEnded: 'Administration.trial.ended',
  subscriptionEnded: 'Administration.paymentNotice.subscriptionEnded',
  subscriptionValidTo: 'Administration.paymentNotice.subscriptionValidTo',
})

const fragments = {
  PaymentNoticeMessage_company: graphql(`
    fragment PaymentNoticeMessage_company on Company {
      id

      ...SubscriptionDetail_company
    }
  `),
}

type PaymentNoticeMessageProps = {
  institution: FragmentType<typeof fragments.PaymentNoticeMessage_company>
  isWrapped?: boolean
  isSingleColor?: boolean
}

export const PaymentNoticeMessage = ({
  institution: _institution,
  isWrapped = true,
  isSingleColor = false,
}: PaymentNoticeMessageProps) => {
  const institution = getFragmentData(fragments.PaymentNoticeMessage_company, _institution)
  const subscription = useSubscriptionDetail({ institution })
  const { warningColor } = subscription

  if (!isWrapped) {
    return <Message subscription={subscription} isSingleColor={isSingleColor} />
  }

  return (
    <Space data-test='PaymentNoticeMessage'>
      <UntitledIcon icon={usAlertCircle} color={warningColor} />
      <Message subscription={subscription} isSingleColor={isSingleColor} />
    </Space>
  )
}

type MessageProps = {
  subscription: SubscriptionDetailReturn
  isSingleColor: boolean
}

const Message = ({
  subscription: { state, warningColor, humanizedSubscriptionEnd, humanizedSubscriptionEndDate },
  isSingleColor,
}: MessageProps) => {
  const messageMap: Record<SubscriptionDetailReturn['state'], () => ReactNode> = {
    trialExpired: () => <FormattedMessage {...messages.trialEnded} />,
    cancelled: () => <FormattedMessage {...messages.subscriptionEnded} />,
    inTrial: () => (
      <span>
        <FormattedMessage {...messages.trialEndsIn} />{' '}
        <span style={{ color: isSingleColor ? undefined : warningColor }}>
          {humanizedSubscriptionEnd}
        </span>
      </span>
    ),
    nonRenewing: () => (
      <span>
        <FormattedMessage {...messages.subscriptionValidTo} />{' '}
        <span>{humanizedSubscriptionEndDate}</span>
      </span>
    ),
    active: () => null,
    future: () => null,
  }

  return messageMap[state]()
}
