import type { UntitledIconData } from '../../UntitledIconData'

const ulAlignCenter: UntitledIconData = {
  prefix: 'ul',
  name: 'align-center',
  paths: [
    {
      shape: 'M18 10H6M21 6H3M21 14H3M18 18H6',
    },
  ],
}

export default ulAlignCenter
