import type { UntitledIconData } from '@faceup/icons'

export const ucHeadlineH1: UntitledIconData = {
  prefix: 'custom',
  name: 'ucHeadlineH1',
  viewBox: '0 0 24 24',
  paths: [
    {
      shape:
        'M4 4V12M4 4H6M4 4H2M4 12V20M4 12L14 12M4 20H6M4 20H2M14 4V12M14 4H16M14 4H12M14 12V20M14 20H16M14 20H12M18 13.6L20.4 12V20M18.4 20H22.4',
      stroke: 'currentColor',
      strokeWidth: 1.25,
      strokeLinecap: 'round',
      strokeLinejoin: 'round',
    },
  ],
}
