import { useMutation } from '@apollo/client'
import type { PageTranslations } from '@faceup/ui'
import { notification } from '@faceup/ui-base'
import { ChannelPageType, type Language } from '@faceup/utils'
import type { ResultOf } from '@graphql-typed-document-node/core'
import { useEffect } from 'react'
import { sharedMessages } from '../../../../../Shared/translations'
import { useIntl } from '../../../../../TypedIntl'
import { type FragmentType, getFragmentData, graphql } from '../../../../../__generated__'
import { useMotherId } from '../../../../../hooks/useMotherId'
import usePageTemplate from '../../../../../hooks/usePageTemplate'
import PageFormAbstract from './PageFormAbstract'

type EditPageForm_reportSource = ResultOf<typeof fragments.EditPageForm_reportSource>
type EditPageForm_reportSource_pages = EditPageForm_reportSource['pages'][number]

const fragments = {
  EditPageForm_reportSource: graphql(`
    fragment EditPageForm_reportSource on ReportSource {
      id
      isDefault
      defaultLanguage
      ...PageFormAbstract_reportSource
      pages(language: $language) {
        ... on ChannelPage {
          id
          title
          content
          icon
          language
          order
          type
        }
        ... on Page {
          id
          title
          content
          icon
          language
          order
        }
      }
    }
  `),
}

const mutations = {
  EditPageMutation: graphql(`
    mutation EditPageMutation($input: EditPageInput!, $language: Language!) {
      editPage(input: $input) {
        channel {
          id
          ...PagesList_reportSource
        }
      }
    }
  `),
  SurveyBeforeSendMutation: graphql(`
    mutation EditSurveyBeforeSendMutation(
      $input: EditSurveyBeforeSendInput!
      $language: Language!
    ) {
      editSurveyBeforeSend(input: $input) {
        survey {
          id
          ...EditPageForm_reportSource
        }
      }
    }
  `),
  SurveyAfterSendMutation: graphql(`
    mutation EditSurveyAfterSendMutation(
      $input: EditSurveyAfterSendInput!
      $language: Language!
    ) {
      editSurveyAfterSend(input: $input) {
        survey {
          id
          ...EditPageForm_reportSource
        }
      }
    }
  `),
}

type EditPageFormProps = {
  reportSource: FragmentType<typeof fragments.EditPageForm_reportSource>
  language: Language
  page:
    | {
        __typename: 'ChannelPage'
        id: string
        type: ChannelPageType
        title: string | null
        content: string | null
        icon: string
        language: Language
        order: number
      }
    | {
        __typename: 'Page'
        id: string
        title: string | null
        content: string | null
        icon: string
        language: Language
        order: number
      }
}

const EditPageForm = ({
  reportSource: _reportSource,
  page: pageUnfilled,
  language,
}: EditPageFormProps) => {
  const reportSource = getFragmentData(fragments.EditPageForm_reportSource, _reportSource)
  const {
    fillObject,
    isTitleDefault,
    isContentDefault,
    setIsDefaultForm,
    isSurveyContentDefault,
    isSurveyTitleDefault,
  } = usePageTemplate(language)

  // here it gets passed
  const getPage = (): PageTranslations<EditPageForm_reportSource_pages> => {
    if (pageUnfilled.__typename === 'ChannelPage') {
      return fillObject({
        ...pageUnfilled,
        type: pageUnfilled.type as ChannelPageType,
      })
    }
    return fillObject(pageUnfilled)
  }

  const page = getPage()

  const { formatMessage } = useIntl()
  const { getMotherId } = useMotherId()

  useEffect(() => {
    setIsDefaultForm(reportSource.isDefault)
  }, [reportSource.isDefault, setIsDefaultForm])

  const [updatePage] = useMutation(mutations.EditPageMutation, {
    onError: error => {
      console.error(error)
      notification.error({
        message: formatMessage(sharedMessages.apiError),
        description: error.message,
      })
    },
  })

  const [editSurveyBeforeSend] = useMutation(mutations.SurveyBeforeSendMutation, {
    onError: error => {
      console.error(error)
      notification.error({
        message: formatMessage(sharedMessages.apiError),
        description: error.message,
      })
    },
  })

  const [editSurveyAfterSend] = useMutation(mutations.SurveyAfterSendMutation, {
    onError: error => {
      console.error(error)
      notification.error({
        message: formatMessage(sharedMessages.apiError),
        description: error.message,
      })
    },
  })

  if (page.__typename === 'Page') {
    return (
      <PageFormAbstract
        buttonVariant='update'
        type='reportForm'
        onSubmit={async ({ title, content, icon }) => {
          const result = await updatePage({
            variables: {
              language: page.language,
              input: {
                channelId: reportSource.id,
                pageId: page.id,
                title: isTitleDefault(title.trim()) ? null : title.trim(),
                content: isContentDefault(content.trim()) ? null : content.trim(),
                icon,
                language: page.language,
                motherId: getMotherId(),
              },
            },
          })
          return !result.errors
        }}
        values={page}
      />
    )
  }

  switch (page.type) {
    case ChannelPageType.BeforeSend:
      return (
        <PageFormAbstract
          buttonVariant='update'
          type={ChannelPageType.BeforeSend}
          onSubmit={async values => {
            const title = values.title?.trim() ?? ''
            const content = values.content?.trim() ?? ''

            const result = await editSurveyBeforeSend({
              variables: {
                input: {
                  motherId: getMotherId(),
                  channelId: reportSource.id,
                  title: isSurveyTitleDefault(title, page) ? null : title.trim(),
                  description: isSurveyContentDefault(content, page) ? null : content.trim(),
                },
                language: reportSource.defaultLanguage,
              },
            })
            return !result.errors
          }}
          values={page}
          channel={reportSource}
        />
      )
    case ChannelPageType.FormItems:
      return (
        <PageFormAbstract
          buttonVariant='update'
          type={ChannelPageType.FormItems}
          channel={reportSource}
        />
      )
    case ChannelPageType.AfterSend:
      return (
        <PageFormAbstract
          buttonVariant='update'
          type={ChannelPageType.AfterSend}
          onSubmit={async values => {
            const title = values.title?.trim() ?? ''
            const content = values.content?.trim() ?? ''

            const result = await editSurveyAfterSend({
              variables: {
                input: {
                  motherId: getMotherId(),
                  channelId: reportSource.id,
                  title: isSurveyTitleDefault(title, page) ? null : title.trim(),
                  description: isSurveyContentDefault(content, page) ? null : content.trim(),
                },
                language: reportSource.defaultLanguage,
              },
            })
            return !result.errors
          }}
          values={page}
          channel={reportSource}
        />
      )
  }

  return null
}

export default EditPageForm
