type Step = 1 | 2 | 3

type StepperProps = {
  step: Step
}

export const Stepper = (props: StepperProps) => {
  const { step } = props
  return (
    <div className='flex gap-4'>
      {[...Array(3).keys()].map(i => (
        <div
          key={i}
          className={`h-1 w-full rounded-[3px] transition-all ${i < step ? 'bg-[#0085FF]' : 'bg-[#E6ECF9]'}`}
        />
      ))}
    </div>
  )
}
