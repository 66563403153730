import { UntitledIcon } from '@faceup/icons'
import { ulDotsVertical } from '@faceup/icons/ulDotsVertical'
import { Button, Popover, type PopoverProps } from '@faceup/ui-base'

type MoreOptionsAntProps = PopoverProps

type MoreOptionsProps = MoreOptionsAntProps

const MoreOptions = (props: MoreOptionsProps) => {
  return (
    <Popover
      placement='bottomRight'
      trigger={['click']}
      arrow={{ pointAtCenter: true }}
      styles={{ body: { padding: 0, overflow: 'hidden' } }}
      {...props}
    >
      <Button
        type='text'
        size='middle'
        icon={<UntitledIcon icon={ulDotsVertical} />}
        data-onboarding='more-options-button'
      />
    </Popover>
  )
}

export default MoreOptions
