import { UntitledIcon, type UntitledIconData } from '@faceup/icons'
import { ulAlignCenter } from '@faceup/icons/ulAlignCenter'
import { ulAlignJustify } from '@faceup/icons/ulAlignJustify'
import { ulAlignLeft } from '@faceup/icons/ulAlignLeft'
import { ulAlignRight } from '@faceup/icons/ulAlignRight'
import { ulBold01 } from '@faceup/icons/ulBold01'
import { ulBrush02 } from '@faceup/icons/ulBrush02'
import { ulCode02 } from '@faceup/icons/ulCode02'
import { ulDotpoints01 } from '@faceup/icons/ulDotpoints01'
import { ulEraser } from '@faceup/icons/ulEraser'
import { ulItalic01 } from '@faceup/icons/ulItalic01'
import { ulLink01 } from '@faceup/icons/ulLink01'
import { ulLinkBroken02 } from '@faceup/icons/ulLinkBroken02'
import { ulStrikethrough01 } from '@faceup/icons/ulStrikethrough01'
import { ulUnderline01 } from '@faceup/icons/ulUnderline01'
import {
  UnstyledButton,
  twMerge,
  ucHeadlineH1,
  ucHeadlineH2,
  ucHeadlineH3,
  ucHeadlineH4,
  ucHorizontalLine,
  ucNumberedList,
  ucQuote,
  ucSubscript,
  ucSuperscript,
} from '@faceup/ui'
import { Divider, Typography } from '@faceup/ui-base'
import CharacterCount from '@tiptap/extension-character-count'
import Highlight from '@tiptap/extension-highlight'
import Link from '@tiptap/extension-link'
import SubScript from '@tiptap/extension-subscript'
import Superscript from '@tiptap/extension-superscript'
import TextAlign from '@tiptap/extension-text-align'
import Underline from '@tiptap/extension-underline'
import { EditorContent, useEditor } from '@tiptap/react'
import StarterKit from '@tiptap/starter-kit'
import { useCallback, useEffect } from 'react'

export type TextEditorProps = {
  content: string
  onChangeContent: (content: string) => void
  maxLength?: number
}

const TextEditor = ({ content, onChangeContent, maxLength }: TextEditorProps) => {
  const editor = useEditor({
    extensions: [
      CharacterCount.configure({
        mode: 'nodeSize',
        limit: maxLength,
      }),
      StarterKit,
      Underline,
      Superscript,
      SubScript,
      Highlight,
      TextAlign.configure({ types: ['heading', 'paragraph'] }),
      Link.configure({
        openOnClick: false,
        autolink: true,
        defaultProtocol: 'https',
        protocols: ['http', 'https'],
        isAllowedUri: (url, ctx) => {
          try {
            const parsedUrl = url.includes(':')
              ? new URL(url)
              : new URL(`${ctx.defaultProtocol}://${url}`)
            if (!ctx.defaultValidate(parsedUrl.href)) {
              return false
            }
            const disallowedProtocols = ['ftp', 'file', 'mailto']
            const protocol = parsedUrl.protocol.replace(':', '')
            if (disallowedProtocols.includes(protocol)) {
              return false
            }
            const allowedProtocols = ctx.protocols.map(p => (typeof p === 'string' ? p : p.scheme))
            if (!allowedProtocols.includes(protocol)) {
              return false
            }
            return true
          } catch {
            return false
          }
        },
      }),
    ],
    content,
    editorProps: {
      attributes: {
        class: 'outline-none px-3 min-h-32',
      },
    },
    onUpdate: ({ editor }) => {
      onChangeContent?.(editor.getHTML())
    },
  })

  const setLink = useCallback(() => {
    if (!editor) {
      return
    }
    const previousUrl = editor.getAttributes('link')['href']
    const url = window.prompt('URL', previousUrl)

    // cancelled
    if (url === null) {
      return
    }

    // empty
    if (url === '') {
      editor.chain().focus().extendMarkRange('link').unsetLink().run()

      return
    }

    // update link
    try {
      editor.chain().focus().extendMarkRange('link').setLink({ href: url }).run()
    } catch (e) {
      console.error('TextEditor problem with setting link', e)
      alert("Couldn't set link")
    }
  }, [editor])

  useEffect(() => {
    if (content !== editor?.getHTML()) {
      editor?.commands?.setContent(content)
    }
  }, [content, editor])

  return (
    <div className='flex flex-col gap-2 border-1 border-[#CDDDEE] rounded-[12px] p-1'>
      <div className='flex gap-x-3 gap-y-2 flex-wrap items-center px-3 py-1 bg-[#9DB0DF1A] rounded-lg'>
        <div className='flex gap-1'>
          <EditorButton
            icon={ulBold01}
            title='Bold'
            active={editor?.isActive('bold')}
            onClick={() => editor?.chain().focus().toggleBold().run()}
          />
          <EditorButton
            icon={ulItalic01}
            title='Italic'
            active={editor?.isActive('italic')}
            onClick={() => editor?.chain().focus().toggleItalic().run()}
          />
          <EditorButton
            icon={ulUnderline01}
            title='Underline'
            active={editor?.isActive('underline')}
            onClick={() => editor?.chain().focus().toggleUnderline().run()}
          />
          <EditorButton
            icon={ulStrikethrough01}
            title='Strikethrough'
            active={editor?.isActive('strike')}
            onClick={() => editor?.chain().focus().toggleStrike().run()}
          />
          <EditorButton
            icon={ulBrush02}
            title='Highlight text'
            active={editor?.isActive('highlight')}
            onClick={() => editor?.chain().focus().toggleHighlight().run()}
          />
          <EditorButton
            icon={ulCode02}
            title='Code'
            active={editor?.isActive('code')}
            onClick={() => editor?.chain().focus().toggleCode().run()}
          />
          <EditorButton
            icon={ulEraser}
            title='Clear all formatting'
            onClick={() => editor?.chain().focus().clearNodes().unsetAllMarks().run()}
          />
        </div>
        <Divider type='vertical' />
        <div className='flex gap-1'>
          <EditorButton
            icon={ucHeadlineH1}
            title='Heading 1'
            active={editor?.isActive('heading', { level: 1 })}
            onClick={() => editor?.chain().focus().toggleHeading({ level: 1 }).run()}
          />
          <EditorButton
            icon={ucHeadlineH2}
            title='Heading 2'
            active={editor?.isActive('heading', { level: 2 })}
            onClick={() => editor?.chain().focus().toggleHeading({ level: 2 }).run()}
          />
          <EditorButton
            icon={ucHeadlineH3}
            title='Heading 3'
            active={editor?.isActive('heading', { level: 3 })}
            onClick={() => editor?.chain().focus().toggleHeading({ level: 3 }).run()}
          />
          <EditorButton
            icon={ucHeadlineH4}
            title='Heading 4'
            active={editor?.isActive('heading', { level: 4 })}
            onClick={() => editor?.chain().focus().toggleHeading({ level: 4 }).run()}
          />
        </div>
        <Divider type='vertical' />
        <div className='flex gap-1'>
          <EditorButton
            icon={ucQuote}
            title='Blockquote'
            active={editor?.isActive('blockquote')}
            onClick={() => editor?.chain().focus().toggleBlockquote().run()}
          />
          <EditorButton
            icon={ucHorizontalLine}
            title='Horizontal line'
            onClick={() => editor?.chain().focus().setHorizontalRule().run()}
          />
          <EditorButton
            icon={ulDotpoints01}
            title='Bullet list'
            active={editor?.isActive('bulletList')}
            onClick={() => editor?.chain().focus().toggleBulletList().run()}
          />
          <EditorButton
            icon={ucNumberedList}
            title='Ordered list'
            active={editor?.isActive('orderedList')}
            onClick={() => editor?.chain().focus().toggleOrderedList().run()}
          />
          <EditorButton
            icon={ucSubscript}
            title='Subscript'
            active={editor?.isActive('subscript')}
            onClick={() => editor?.chain().focus().toggleSubscript().run()}
          />
          <EditorButton
            icon={ucSuperscript}
            title='Superscript'
            active={editor?.isActive('superscript')}
            onClick={() => editor?.chain().focus().toggleSuperscript().run()}
          />
        </div>
        <Divider type='vertical' />
        <div className='flex gap-1'>
          <EditorButton
            icon={ulLink01}
            title='Link'
            active={editor?.isActive('link')}
            onClick={setLink}
          />
          <EditorButton
            icon={ulLinkBroken02}
            title='Remove Link'
            onClick={() => editor?.chain().focus().unsetLink().run()}
          />
        </div>
        <Divider type='vertical' />
        <div className='flex gap-1'>
          <EditorButton
            icon={ulAlignLeft}
            title='Align text: left'
            active={editor?.isActive({ textAlign: 'left' })}
            onClick={() => editor?.chain().focus().setTextAlign('left').run()}
          />
          <EditorButton
            icon={ulAlignCenter}
            title='Align text: center'
            active={editor?.isActive({ textAlign: 'center' })}
            onClick={() => editor?.chain().focus().setTextAlign('center').run()}
          />
          <EditorButton
            icon={ulAlignJustify}
            title='Align text: justify'
            active={editor?.isActive({ textAlign: 'justify' })}
            onClick={() => editor?.chain().focus().setTextAlign('justify').run()}
          />
          <EditorButton
            icon={ulAlignRight}
            title='Align text: right'
            active={editor?.isActive({ textAlign: 'right' })}
            onClick={() => editor?.chain().focus().setTextAlign('right').run()}
          />
        </div>
      </div>
      <Typography.Text>
        <EditorContent editor={editor} />
      </Typography.Text>
    </div>
  )
}

type EditorButtonProps = {
  icon: UntitledIconData
  title: string
  onClick: () => void
  active?: boolean
}

const EditorButton = ({ onClick, icon, title, active }: EditorButtonProps) => {
  return (
    <UnstyledButton
      type='button'
      tabIndex={0}
      onClick={onClick}
      title={title}
      className={twMerge(
        'w-6 h-6 transition-all flex items-center justify-center !text-[#6F91B7] hover:!text-[#0085FF]',
        active && '!text-[#0085FF] bg-[#D6E4FF] rounded-sm'
      )}
    >
      <UntitledIcon
        icon={icon}
        size={16}
        style={{
          verticalAlign: 0,
        }}
      />
    </UnstyledButton>
  )
}

export default TextEditor
