import {
  type CaseClosingReason,
  CaseDeadlineFilter,
  CaseJustification,
  CasePriority,
  CaseSourceType,
  type CaseStatus,
  ChannelStatus,
  type Day,
  EmployeeInvolvement,
  EmployeeStatus,
  FormItemType,
  type FrontendPermissionType,
  Institution,
  RegistrationReason,
  WebhookStatus,
} from '@faceup/utils'
import { defineMessages } from '../TypedIntl'
import { CreationOriginFilter } from '../utils/reportFilters'

export const sharedMessages = defineMessages({
  functionNotReady: 'Administration.functionNotReady',
  apiError: 'Shared.global.apiError',
  savedMessage: 'Administration.global.saved',
  encryptionError: 'Shared.global.encryptionError',

  attachmentsTooManyFiles: 'Shared.attachments.tooManyFiles',
  attachmentsTooLargeFile: 'Shared.attachments.tooLargeFile',
  attachmentsLocked: 'Administration.attachments.lockedFeature',
  metadataRemoved: 'Shared.attachments.metadataRemoved',
  metadataUnableToRemove: 'Shared.attachments.metadataUnableToRemove',
  unsupportedMimetype: 'Shared.attachments.unsupportedMimetype',

  upgradeAlert: 'Administration.upgrade.alert',
  upgradeButton: 'Administration.upgrade.button',

  permissionEditableOnlyMotherTooltip: 'Administration.permissions.editableOnlyMother.tooltip',

  // errors
  invalidNameError: 'Shared.global.invalidInput.name',
  invalidEmailError: 'Administration.global.invalidInput.email',
  invalidPhoneError: 'Administration.global.invalidInput.phone',
  invalidPasswordError: 'Administration.global.invalidInput.password',
  invalidAddressError: 'Administration.global.invalidInput.address',
  passwordsDontMatchError: 'Administration.global.passwordsDontMatchError',
  invalidInputError: 'Shared.global.invalidInput',

  // labels
  emailLabel: 'Administration.label.email',
  nameLabel: 'Administration.label.name',
  bioLabel: 'Administration.label.bio',
  fullNameLabel: 'Administration.label.nameFull',
  phoneLabel: 'Administration.label.phone',
  passwordLabel: 'Administration.label.password',
  organizationLabel: 'Shared.report.organization',
  categoryLabel: 'Shared.report.category',
  moreInfoLabel: 'Shared.report.moreInfo',
  classNameLabel: 'Shared.report.classRoom',
  globalNameLabel: 'Shared.global.name',

  bio: 'Shared.bio',
  partnerBio: 'Shared.bio.partner',

  // actions
  send: 'Shared.action.send',
  delete: 'Shared.action.delete',
  save: 'Shared.action.save',
  next: 'Administration.action.next',
  enable: 'Administration.action.enable',
  disable: 'Administration.action.disable',
  edit: 'Administration.action.edit',
  copy: 'Shared.action.copy',
  print: 'Administration.action.print',
  show: 'Administration.action.show',
  download: 'Administration.action.download',
  backHome: 'Administration.action.backHome',
  add: 'Administration.action.add',
  removeFilter: 'Administration.action.removeFilter',
  back: 'Administration.action.back',
  reset: 'Shared.action.reset',
  ok: 'Shared.ok',
  cancel: 'Shared.cancel',
  sendAnonymously: 'Shared.report.sendAnonymously',

  copied: 'Shared.global.copied',
  leaveQuestion: 'Administration.customization.formItems.unsavedChangesMessage',
})

export const reportClosingReasonMessages = defineMessages<CaseClosingReason>({
  Other: 'Shared.report.closingReason.Other',
  Rejected: 'Shared.report.closingReason.Rejected',
  Resolved: 'Shared.report.closingReason.Resolved',
  Spam: 'Shared.report.closingReason.Spam',
})

export const creationOriginFilterMessages = defineMessages<CreationOriginFilter>({
  [CreationOriginFilter.Original]: 'Administration.reports.filter.creationOrigin.original',
  [CreationOriginFilter.Redacted]: 'Administration.reports.filter.creationOrigin.redacted',
})

export const reportStatusMessages = defineMessages<CaseStatus>({
  Open: 'Shared.report.status.Open',
  Closed: 'Shared.report.status.Closed',
  Archived: 'Shared.report.status.Archived',
})

export const reportSourceMessages = defineMessages<CaseSourceType>({
  Email: 'Administration.report.source.Email',
  Phone: 'Administration.report.source.Phone',
  InPerson: 'Administration.report.source.InPerson',
  Other: 'Administration.report.source.Other',
  ReportingSystem: 'Administration.report.source.ReportingSystem',
  AutomatedHotline: 'Administration.report.source.AutomatedHotline',
  LiveHotline: 'Administration.report.source.LiveHotline',
  Survey: 'Administration.report.source.Survey',
})

export const reportPrioritiesMessages = defineMessages<CasePriority>({
  [CasePriority.Urgent]: 'Administration.priority.urgent',
  [CasePriority.High]: 'Administration.priority.high',
  [CasePriority.Medium]: 'Administration.priority.medium',
  [CasePriority.Low]: 'Administration.priority.low',
})

export const reportDeadlineFilterMessages = defineMessages<CaseDeadlineFilter>({
  [CaseDeadlineFilter.Today]: 'Administration.deadline.today',
  [CaseDeadlineFilter.Soon]: 'Administration.deadline.soon',
  [CaseDeadlineFilter.Later]: 'Administration.deadline.later',
  [CaseDeadlineFilter.Overdue]: 'Administration.deadline.overdue',
})

export const dayMessages = defineMessages<Day>({
  Monday: 'Administration.day.Monday',
  Tuesday: 'Administration.day.Tuesday',
  Wednesday: 'Administration.day.Wednesday',
  Thursday: 'Administration.day.Thursday',
  Friday: 'Administration.day.Friday',
  Saturday: 'Administration.day.Saturday',
  Sunday: 'Administration.day.Sunday',
})

export const reportSourcesMessages = defineMessages<CaseSourceType>({
  [CaseSourceType.Email]: 'Administration.reportSource.email',
  [CaseSourceType.AutomatedHotline]: 'Administration.reportSource.automatedHotline',
  [CaseSourceType.LiveHotline]: 'Administration.reportSource.liveHotline',
  [CaseSourceType.InPerson]: 'Administration.reportSource.inPerson',
  [CaseSourceType.ReportingSystem]: 'Administration.reportSource.reportingSystem',
  [CaseSourceType.Phone]: 'Administration.reportSource.phone',
  [CaseSourceType.Other]: 'Administration.reportSource.other',
  [CaseSourceType.Survey]: 'Administration.reportSource.survey',
})

export const reportJustificationMessages = defineMessages<CaseJustification>({
  [CaseJustification.Unknown]: 'Administration.reportJustification.unknown',
  [CaseJustification.Justified]: 'Administration.reportJustification.justified',
  [CaseJustification.Unjustified]: 'Administration.reportJustification.unjustified',
})

export const permissionTypeMessages = defineMessages<FrontendPermissionType>({
  ReportAccess: 'Administration.settings.permissions.ReportAccess.title',
  SettingsAccess: 'Administration.settings.permissions.SettingsAccess.title',
  BillingAccess: 'Administration.settings.permissions.BillingAccess.title',
  ManageCategories: 'Administration.settings.permissions.ManageCategories.title',
  ManageOrganizationalUnits: 'Administration.settings.permissions.ManageOrganizationalUnits.title',
  ManageReportingChannels: 'Administration.settings.permissions.ManageReportingChannels.title',
  ManageUsers: 'Administration.settings.permissions.ManageUsers.title',
  Analytics: 'Administration.settings.permissions.Analytics.title',
  EditCases: 'Administration.settings.permissions.EditCases.title',
  ExportCases: 'Administration.settings.permissions.ExportCases.title',
  DeleteCases: 'Administration.settings.permissions.DeleteCases.title',
  WriteInternalComments: 'Administration.settings.permissions.WriteInternalComments.title',
  WriteMessages: 'Administration.settings.permissions.WriteMessages.title',
  Surveys: 'Administration.settings.permissions.Surveys.title',
})

export const institutionMessages = defineMessages<Institution>({
  [Institution.Company]:
    'Administration.institutions.createInstitutionModal.organisationType.company',
  [Institution.School]:
    'Administration.institutions.createInstitutionModal.organisationType.school',
})

export const webhookStatusMessages = defineMessages<WebhookStatus>({
  [WebhookStatus.Enabled]: 'Administration.webhooks.status.enabled',
  [WebhookStatus.Disabled]: 'Administration.webhooks.status.disabled',
  [WebhookStatus.Stopped]: 'Administration.webhooks.status.stopped',
})

export const formItemTypeMessages = defineMessages<FormItemType>({
  [FormItemType.MultiSelect]: 'Shared.FormItemType.MultiSelect',
  [FormItemType.Scale]: 'Shared.FormItemType.Scale',
  [FormItemType.Select]: 'Shared.FormItemType.Select',
  [FormItemType.SimpleText]: 'Shared.FormItemType.SimpleText',
  [FormItemType.Classroom]: 'Shared.report.classRoom',
  [FormItemType.MoreInformation]: 'Shared.report.moreInfo',
  [FormItemType.Category]: 'Shared.report.category',
  [FormItemType.OrganizationalUnit]: 'Shared.report.organization',
  [FormItemType.SenderName]: 'Shared.report.senderName',
  [FormItemType.MultilineText]: 'Shared.FormItemType.MultilineText',
  [FormItemType.Email]: 'Shared.FormItemType.Email',
  [FormItemType.Date]: 'Shared.FormItemType.Date',
  [FormItemType.PhoneNumber]: 'Shared.FormItemType.PhoneNumber',
})

export const channelStatusMessages = defineMessages<ChannelStatus>({
  [ChannelStatus.Enabled]: 'Administration.channel.status.enabled',
  [ChannelStatus.Disabled]: 'Administration.channel.status.disabled',
})

export const registrationReasonMessages = defineMessages<RegistrationReason>({
  [RegistrationReason.Hr]: 'Administration.registration.reason.hr',
  [RegistrationReason.Compliance]: 'Administration.registration.reason.compliance',
  [RegistrationReason.Speakup]: 'Administration.registration.reason.speakup',
  [RegistrationReason.Survey]: 'Administration.registration.reason.survey',
  [RegistrationReason.Other]: 'Administration.registration.reason.other',
})

export const employeeStatusMessages = defineMessages<EmployeeStatus>({
  [EmployeeStatus.Active]: 'Administration.employee.status.active',
  [EmployeeStatus.Inactive]: 'Administration.employee.status.inactive',
})

export const employeeInvolvementMessages = defineMessages<EmployeeInvolvement>({
  [EmployeeInvolvement.Involved]: 'Administration.report.employees.involved',
  [EmployeeInvolvement.Reporter]: 'Administration.report.employees.reporter',
  [EmployeeInvolvement.Witness]: 'Administration.report.employees.witness',
})
