import { Form, Prompt } from '@faceup/ui'
import { Checkbox, Divider } from '@faceup/ui-base'
import { Button, Col, Flex, Input, MultiSelect, Row, Select } from '@faceup/ui-base'
import { FormItemType, type Language, SHORT_STRING_MAX_LENGTH } from '@faceup/utils'
import { type Dispatch, type SetStateAction, useCallback, useEffect, useState } from 'react'
import { formItemTypeMessages, sharedMessages } from '../../../../../../Shared/translations'
import { FormattedMessage, defineMessages, useIntl } from '../../../../../../TypedIntl'
import { type FragmentType, getFragmentData, graphql } from '../../../../../../__generated__'
import { OptionsList } from './OptionsList'
import type { ScaleOption, SelectOption } from './hooks/useFormItemForm'

const messages = defineMessages({
  allCategories: 'Administration.customization.formItems.allCategories',
  labelCategories: 'Administration.customization.formItems.label.categories',
  labelType: 'Administration.customization.formItems.label.type',
  labelIsRequired: 'Shared.global.invalidInput',
  leaveQuestion: 'Administration.customization.formItems.unsavedChangesMessage',
  labelLabel: 'Administration.customization.formItems.label.label',
  labelAddHint: 'Administration.customization.formItems.label.addHint',
  labelHint: 'Administration.customization.formItems.label.hint',
  firstValueLabel: 'Administration.customization.formItems.scale.firstValueLabel',
  lastValueLabel: 'Administration.customization.formItems.scale.lastValueLabel',
  firstValueNameLabel: 'Administration.customization.formItems.scale.firstValueNameLabel',
  lastValueNameLabel: 'Administration.customization.formItems.scale.lastValueNameLabel',
})

const messagesButton = defineMessages<ButtonVariant>({
  create: 'Administration.customization.formItems.form.button.create',
  update: 'Administration.customization.formItems.form.button.update',
})

const fragments = {
  FormItemForm_reportSource: graphql(`
    fragment FormItemForm_reportSource on ReportSource {
      id
      categories {
        id
        nameTranslations {
          translation
          language
        }
      }
    }
  `),
}

export const notMovableSpecialFormItemsDef: FormItemType[] = [
  FormItemType.Category,
  FormItemType.OrganizationalUnit,
  FormItemType.Classroom,
]

export const specialFormItemsDef: FormItemType[] = [
  ...notMovableSpecialFormItemsDef,
  FormItemType.MoreInformation,
  FormItemType.SenderName,
]

type ButtonVariant = 'create' | 'update'

const allValue = 'all'

export type Value<T> = { value: T; error?: boolean }

export type FormItemFormValues = {
  label: Value<string>
  hint: Value<string>
  isRequired: Value<boolean>
  categories: Value<string[]>
  type: Value<FormItemType | null>
  options: Value<SelectOption[]>
  scaleOptions: Value<[ScaleOption, ScaleOption]>
}

export type FormItemFormSetters = {
  setLabel: Dispatch<SetStateAction<Value<string>>>
  setCategories: Dispatch<SetStateAction<Value<string[]>>>
  setHint: Dispatch<SetStateAction<Value<string>>>
  setIsRequired: Dispatch<SetStateAction<Value<boolean>>>
  setType: Dispatch<SetStateAction<Value<FormItemType | null>>>
  setOptions: Dispatch<SetStateAction<Value<SelectOption[]>>>
  setScaleOptions: Dispatch<SetStateAction<Value<[ScaleOption, ScaleOption]>>>
}

type FormItemFormProps = {
  reportSource: FragmentType<typeof fragments.FormItemForm_reportSource>
  onClose: () => void
  buttonVariant: ButtonVariant
  onSubmit: () => void
  values: FormItemFormValues
  setters: FormItemFormSetters
  isSubmitting: boolean
  hasUnsavedChanges: boolean
  isSubmitButtonDisabled: boolean
  language: Language
  onOptionsReorder: (fromIndex: number, toIndex: number) => void
  isTypeDisabled: boolean
  onDeleteOption: (optionId: string) => void
}

export const firstScaleOptions = Array.from({ length: 2 }, (_, i) => ({
  value: i,
  label: String(i),
}))
export const lastScaleOptions = Array.from({ length: 9 }, (_, i) => ({
  value: i + 2,
  label: String(i + 2),
}))

const FormItemForm = ({
  reportSource: _reportSource,
  onClose,
  onSubmit,
  values: { label, categories, hint, isRequired, type, options, scaleOptions },
  setters: {
    setLabel,
    setCategories,
    setHint,
    setIsRequired,
    setType,
    setOptions,
    setScaleOptions,
  },
  buttonVariant,
  isSubmitting,
  isSubmitButtonDisabled,
  hasUnsavedChanges,
  language,
  onOptionsReorder,
  isTypeDisabled,
  onDeleteOption,
}: FormItemFormProps) => {
  const reportSource = getFragmentData(fragments.FormItemForm_reportSource, _reportSource)
  const [wasCategoryChanged, setWasCategoryChanged] = useState(false)
  const { formatMessage } = useIntl()

  const availableOptionsWithoutAllOption = reportSource.categories.map(category => ({
    label:
      category.nameTranslations?.find(translation => translation.language === language)
        ?.translation ?? '',
    value: category.id,
  }))
  const availableOptionsWithAllOption = [
    { value: allValue, label: formatMessage(messages.allCategories) },
    ...availableOptionsWithoutAllOption,
  ]

  useEffect(() => {
    if (categories.value.length === 0 && !wasCategoryChanged && buttonVariant === 'create') {
      // I feel this shouldn't be here: the intention is to use all available categories as
      // default value when creating new form item
      setCategories({ value: reportSource.categories.map(({ id }) => id) })
    }
  }, [categories, reportSource.categories, setCategories, wasCategoryChanged, buttonVariant])

  const availableCategories = reportSource.categories

  const handleScaleChange = useCallback(
    (index: number) => (e: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target

      setScaleOptions(prev => {
        const newValue = prev.value.map((option, i) =>
          i === index ? { ...option, label: value } : option
        ) as [ScaleOption, ScaleOption]

        return { ...prev, value: newValue }
      })
    },
    [setScaleOptions]
  )

  const handleScaleChangeValue = useCallback(
    (index: number) => (value: number) => {
      setScaleOptions(prev => {
        const newValue = prev.value.map((option, i) =>
          i === index ? { ...option, value: value } : option
        ) as [ScaleOption, ScaleOption]

        return { ...prev, value: newValue }
      })
    },
    [setScaleOptions]
  )

  const [openedHint, setOpenedHint] = useState(Boolean(hint.value))
  return (
    <Form onSubmit={onSubmit}>
      <Prompt when={hasUnsavedChanges} message={formatMessage(messages.leaveQuestion)} />
      <Row>
        <Col span={24}>
          <div className='flex flex-col gap-4'>
            <Form.Item
              label={<FormattedMessage {...messages.labelLabel} />}
              withAsterisk
              {...(label.error && {
                validateStatus: 'error',
                help: <FormattedMessage {...sharedMessages.invalidInputError} />,
              })}
            >
              <Input value={label.value} onChange={e => setLabel({ value: e.target.value })} />
            </Form.Item>
            <Form.Item
              label={<FormattedMessage {...messages.labelType} />}
              withAsterisk
              {...(type?.error && {
                validateStatus: 'error',
                help: <FormattedMessage {...sharedMessages.invalidInputError} />,
              })}
            >
              <Select
                allowClear
                disabled={isTypeDisabled}
                value={type.value ?? undefined}
                style={{ width: '100%' }}
                onChange={values => {
                  setType({
                    value: values ?? null,
                  })
                }}
                options={Object.values(FormItemType)
                  .filter(formItemType => !specialFormItemsDef.includes(formItemType))
                  .map(formItemType => ({
                    value: formItemType,
                    label: formatMessage(formItemTypeMessages[formItemType]),
                  }))}
              />
            </Form.Item>
            {(type.value === FormItemType.Select || type.value === FormItemType.MultiSelect) && (
              <OptionsList
                options={options.value}
                setOptions={setOptions}
                onReorder={onOptionsReorder}
                onDeleteOption={onDeleteOption}
                shouldDisplayDeleteButton={
                  type.value === FormItemType.MultiSelect
                    ? options.value.length > 2
                    : options.value.length > 1
                }
              />
            )}
            {type.value === FormItemType.Scale && (
              <Flex wrap justify='space-between'>
                <Row gutter={16}>
                  <Col span={12}>
                    <Form.Item
                      label={<FormattedMessage {...messages.firstValueLabel} />}
                      withAsterisk
                    >
                      <Select<number, string>
                        options={firstScaleOptions}
                        defaultValue={scaleOptions.value[0].value ?? firstScaleOptions[0]?.value}
                        onChange={handleScaleChangeValue(0)}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item label={<FormattedMessage {...messages.firstValueNameLabel} />}>
                      <Input
                        value={scaleOptions.value[0].label}
                        onChange={handleScaleChange(0)}
                        maxLength={SHORT_STRING_MAX_LENGTH}
                        style={{ width: '100%' }}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      label={<FormattedMessage {...messages.lastValueLabel} />}
                      withAsterisk
                    >
                      <Select
                        options={lastScaleOptions}
                        defaultValue={
                          scaleOptions.value[1].value ??
                          lastScaleOptions[lastScaleOptions.length - 1]?.value
                        }
                        onChange={handleScaleChangeValue(1)}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item label={<FormattedMessage {...messages.lastValueNameLabel} />}>
                      <Input
                        value={scaleOptions.value[1].label}
                        onChange={handleScaleChange(1)}
                        maxLength={SHORT_STRING_MAX_LENGTH}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Flex>
            )}
            <Form.Item
              label={<FormattedMessage {...messages.labelCategories} />}
              withAsterisk
              {...(categories?.error && {
                validateStatus: 'error',
                help: <FormattedMessage {...sharedMessages.invalidInputError} />,
              })}
            >
              <MultiSelect
                value={availableCategories
                  .filter(
                    availableCategory => categories.value.includes(availableCategory.id) ?? false
                  )
                  .map(category => category.id)}
                onChange={values => {
                  setWasCategoryChanged(true)
                  if (values.includes(allValue)) {
                    setCategories(prev => ({
                      ...prev,
                      value: availableCategories.map(({ id }) => id),
                    }))
                  } else {
                    setCategories(prev => ({
                      ...prev,
                      value: values,
                    }))
                  }
                }}
                options={availableOptionsWithAllOption}
              />
            </Form.Item>
            <Checkbox
              onChange={e => {
                if (!e.target.checked) {
                  setHint({ value: '' })
                }
                setOpenedHint(e.target.checked)
              }}
              checked={openedHint}
            >
              <FormattedMessage {...messages.labelAddHint} />
            </Checkbox>
            {openedHint && (
              <Form.Item
                label={<FormattedMessage {...messages.labelHint} />}
                {...(hint.error && {
                  validateStatus: 'error',
                  help: <FormattedMessage {...sharedMessages.invalidInputError} />,
                })}
              >
                <Input value={hint.value} onChange={e => setHint({ value: e.target.value })} />
              </Form.Item>
            )}
            <Divider />
            <Form.Item>
              <Checkbox
                onChange={() => {
                  setIsRequired(prev => ({ ...prev, value: !prev.value }))
                }}
                checked={isRequired.value}
              >
                <FormattedMessage {...messages.labelIsRequired} />
              </Checkbox>
            </Form.Item>
            <Divider />
          </div>
        </Col>

        <Col span={24}>
          <Form.ButtonGroup>
            <Button onClick={onClose}>
              <FormattedMessage {...sharedMessages.cancel} />
            </Button>
            <Button
              type='primary'
              htmlType='submit'
              loading={isSubmitting}
              disabled={isSubmitButtonDisabled}
            >
              <FormattedMessage {...messagesButton[buttonVariant]} />
            </Button>
          </Form.ButtonGroup>
        </Col>
      </Row>
    </Form>
  )
}

export default FormItemForm
