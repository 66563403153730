import type { UntitledIconData } from '@faceup/icons'

export const ucHorizontalLine: UntitledIconData = {
  prefix: 'custom',
  name: 'ucHorizontalLine',
  viewBox: '0 0 24 24',
  paths: [
    {
      shape:
        'M7 12.625C7.34518 12.625 7.625 12.3452 7.625 12C7.625 11.6548 7.34518 11.375 7 11.375V12.625ZM2 11.375C1.65482 11.375 1.375 11.6548 1.375 12C1.375 12.3452 1.65482 12.625 2 12.625V11.375ZM14.5 12.625C14.8452 12.625 15.125 12.3452 15.125 12C15.125 11.6548 14.8452 11.375 14.5 11.375V12.625ZM9.5 11.375C9.15482 11.375 8.875 11.6548 8.875 12C8.875 12.3452 9.15482 12.625 9.5 12.625V11.375ZM22 12.625C22.3452 12.625 22.625 12.3452 22.625 12C22.625 11.6548 22.3452 11.375 22 11.375V12.625ZM17 11.375C16.6548 11.375 16.375 11.6548 16.375 12C16.375 12.3452 16.6548 12.625 17 12.625V11.375ZM7 11.375H2V12.625H7V11.375ZM14.5 11.375H9.5V12.625H14.5V11.375ZM22 11.375H17V12.625H22V11.375Z',
      fill: 'currentColor',
    },
  ],
}
