import { useLazyQuery } from '@apollo/client'
import { sharedMessages } from '@faceup/localization'
import {
  FormItemType,
  LONG_STRING_MAX_LENGTH,
  SHORT_STRING_MAX_LENGTH,
  STRING_MIN_LENGTH,
  isEmail,
  isValidPhoneNumber,
} from '@faceup/utils'
import type { Dispatch, SetStateAction } from 'react'
import { defineMessages, useIntl } from '../TypedIntl'
import { type FragmentType, graphql } from '../__generated__'
import type { Answers } from './useManageReportCustomInputs'

const messages = defineMessages({
  invalidPhoneNumber: 'Report.invalidPhoneNumber',
  invalidEmail: 'Report.invalidEmail',
  max: 'Shared.validation.string.max',
  min: 'Shared.validation.string.min',
})

const fragments = {
  usePrepareFormItemAnswersForMutationFragments_answer: graphql(`
    fragment usePrepareFormItemAnswersForMutationFragments_answer on CaseAnswer {
      id
      formItem {
        id
        type
        formItemId
      }
      values
    }
  `),
}

const query = graphql(`
  query HookCustomFormItemsQuery(
    $categoryId: CompanyReportCategoryGlobalId
    $reportSourceId: ID!
  ) {
    formItems(categoryId: $categoryId, reportSourceId: $reportSourceId) {
      id
      formItemId
      isRequired
      type
    }
  }
`)

const omitHardcodedFormItem = (
  formItem: NonNullable<
    FragmentType<
      typeof fragments.usePrepareFormItemAnswersForMutationFragments_answer
    >[' $fragmentRefs']
  >['usePrepareFormItemAnswersForMutationFragments_answerFragment']['formItem']
): boolean =>
  ![
    FormItemType.Category,
    FormItemType.OrganizationalUnit,
    FormItemType.MoreInformation,
    FormItemType.SenderName,
  ].includes(formItem.type)

type ValidateAnswersFn = (
  answers: Answers,
  setAnswers: Dispatch<SetStateAction<Answers>>
) => Promise<boolean>

type GetValuesForMutationFn = (
  answers: Answers
) => Promise<{ formItemId: string; values: string[] | null }[]>

type PrepareFormFn = (
  reportSourceId: string,
  categoryId: string | null
) => {
  validateAnswers: ValidateAnswersFn
  getValuesForMutation: GetValuesForMutationFn
}

type UsePrepareFormItemAnswersForMutationReturn = {
  prepareForm: PrepareFormFn
}

export const usePrepareFormItemAnswersForMutation =
  (): UsePrepareFormItemAnswersForMutationReturn => {
    const [_getData] = useLazyQuery(query)
    const { formatMessage } = useIntl()

    const prepareForm: PrepareFormFn = (reportSourceId, categoryId) => {
      const getData = async () =>
        await _getData({
          variables: {
            reportSourceId,
            categoryId,
          },
        })

      const validateAnswers: ValidateAnswersFn = async (answers, setAnswers) => {
        const { data } = await getData()
        let ok = true
        data?.formItems.filter(omitHardcodedFormItem).forEach(item => {
          const validate: Record<FormItemType, (value: string) => null | string> = {
            [FormItemType.Scale]: () => null,
            [FormItemType.Select]: () => null,
            [FormItemType.MultiSelect]: () => null,
            [FormItemType.SimpleText]: () => null,
            [FormItemType.MultilineText]: value =>
              value.length > LONG_STRING_MAX_LENGTH
                ? formatMessage(messages.max, { max: LONG_STRING_MAX_LENGTH })
                : null,
            [FormItemType.Date]: () => null,
            // here
            [FormItemType.MoreInformation]: () => {
              throw new Error('Implement validation for hardcoded form item')
            },
            [FormItemType.Classroom]: value => {
              if (value.trim().length < STRING_MIN_LENGTH) {
                return formatMessage(messages.min, { min: STRING_MIN_LENGTH })
              }
              if (value.trim().length > SHORT_STRING_MAX_LENGTH) {
                return formatMessage(messages.max, { max: SHORT_STRING_MAX_LENGTH })
              }
              return null
            },
            [FormItemType.OrganizationalUnit]: () => {
              throw new Error('Implement validation for hardcoded form item')
            },
            [FormItemType.SenderName]: () => {
              throw new Error('Implement validation for hardcoded form item')
            },
            [FormItemType.Email]: value =>
              isEmail(value) ? null : formatMessage(messages.invalidEmail),
            [FormItemType.PhoneNumber]: value =>
              isValidPhoneNumber(value) ? null : formatMessage(messages.invalidPhoneNumber),
            [FormItemType.Category]: () => {
              throw new Error('Implement validation for hardcoded form item')
            },
          }
          const answerValue = answers[item.formItemId]?.values?.[0]
          if (answerValue) {
            const errorMessage = validate[item.type](answerValue)
            if (errorMessage) {
              setAnswers(prevState => ({
                ...prevState,
                [item.formItemId]: {
                  values: prevState[item.formItemId]?.values ?? [],
                  error: errorMessage,
                },
              }))
              ok = false
            }
          }
          if (item.isRequired && !answerValue) {
            setAnswers(prevState => ({
              ...prevState,
              [item.formItemId]: {
                values: prevState[item.formItemId]?.values ?? [],
                error: formatMessage(sharedMessages.fieldRequired),
              },
            }))

            ok = false
          }
        })
        return ok
      }

      const getValuesForMutation: GetValuesForMutationFn = async answers => {
        const { data } = await getData()
        return Object.entries(answers)
          .filter(([formItemId]) => {
            const formItem = data?.formItems?.find(formItem => formItem.formItemId === formItemId)
            if (!formItem) {
              return false
            }
            return omitHardcodedFormItem(formItem)
          })
          .map(([formItemId, { values }]) => ({
            formItemId,
            values: values?.map(value => value?.trim()).filter(Boolean) ?? null,
          }))
      }

      return {
        validateAnswers,
        getValuesForMutation,
      }
    }

    return {
      prepareForm,
    }
  }
