import { Button, Form, Input, ModalAlert, Typography } from '@faceup/ui-base'
import { Suspense, lazy, useState } from 'react'
import { sharedMessages } from '../../Shared/translations'
import { FormattedMessage, defineMessages, useIntl } from '../../TypedIntl'

// We want to load pdf downloader lazy
const DownloadRecoveryKeyButton = lazy(() => import('./DownloadRecoveryKeyButton'))

const { Paragraph } = Typography

const messages = defineMessages({
  title: 'Administration.login.recovery.title',
  content: 'Administration.login.recovery.content',
})

type Props = {
  recoveryKey: string
  visible: boolean
}

const Recovery = ({ recoveryKey, visible }: Props) => {
  const [didDownload, setDidDownload] = useState(false)
  const { formatMessage } = useIntl()

  return (
    <ModalAlert
      open={visible}
      title={formatMessage(messages.title)}
      confirmState={{ disabled: !didDownload, loading: !recoveryKey }}
      onConfirm={() => window.location.replace('/')}
      width='50%'
    >
      <Paragraph>
        <FormattedMessage {...messages.content} values={{ b: text => <b>{text}</b>, br: <br /> }} />
      </Paragraph>
      <Form layout='inline'>
        <Form.Item style={{ flex: 1, marginRight: 30 }}>
          <Input value={recoveryKey} disabled />
        </Form.Item>
        <Form.Item>
          <Suspense
            fallback={
              <Button type='primary' loading>
                <FormattedMessage {...sharedMessages.download} />
              </Button>
            }
          >
            <DownloadRecoveryKeyButton
              recoveryKey={recoveryKey}
              onClick={() => {
                setDidDownload(true)
              }}
            >
              <FormattedMessage {...sharedMessages.download} />
            </DownloadRecoveryKeyButton>
          </Suspense>
        </Form.Item>
      </Form>
    </ModalAlert>
  )
}

export default Recovery
