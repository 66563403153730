import { HintIcon } from '@faceup/ui'
import {
  Checkbox as AntdCheckbox,
  type CheckboxProps as AntdCheckboxProps,
  Flex,
} from '@faceup/ui-base'
import type { FieldValues, UseControllerProps } from 'react-hook-form'
import { useCustomController } from '../hooks'
import type { FormItemWrapperProps } from '../typings'

type CheckboxProps<T extends FieldValues> = UseControllerProps<T> &
  Omit<AntdCheckboxProps & FormItemWrapperProps, 'value' | 'checked' | 'defaultValue'>
export const Checkbox = <T extends FieldValues>(props: CheckboxProps<T>) => {
  const {
    formItemProps: { withAsterisk, ...formItemProps },
    inputProps: { value, onChange: fieldOnChange, ...inputProps },
  } = useCustomController(props)

  const hint = props.hint

  return (
    <Flex gap={8} align='center'>
      <AntdCheckbox
        {...inputProps}
        value={value}
        checked={value}
        onChange={e => {
          fieldOnChange(e)
          props.onChange?.(e)
        }}
      >
        {formItemProps.label}
      </AntdCheckbox>
      {hint && <HintIcon title={hint} />}
    </Flex>
  )
}
