import type { ReactNode } from 'react'
import styles from './HubSpotCard.module.css'
import './HubSpot.css'
import { Card } from '../Card'

type HubSpotCardProps = {
  children: ReactNode
  isVisible: boolean
}

export const HubSpotCard = ({ children, isVisible }: HubSpotCardProps) => {
  return <Card className={`${styles.card} ${isVisible ? '' : styles.hidden}`}>{children}</Card>
}
