import { Button, Modal, type ModalProps } from '@faceup/ui-base'
import { useId } from '@mantine/hooks'
import type { ReactNode } from 'react'
import type { FieldValues } from 'react-hook-form'
import { useFormLocalization } from '../FormLocalizationProvider'
import { FormItemsWrapper } from './FormItemsWrapper'
import { type ReactHookFormProps, useFormLogic } from './useFormLogic'

export type ModalFormProps<FV extends FieldValues = FieldValues> = ModalProps &
  ReactHookFormProps<FV> & {
    onCancel?: () => void
    isCancelButtonHidden?: boolean
    submitUnchanged?: boolean
  }

export const ModalForm = <FV extends FieldValues = FieldValues>({
  onCancel,
  isCancelButtonHidden = false,
  ...props
}: ModalFormProps<FV>) => {
  const { modalForm } = useFormLocalization()
  const formId = useId()
  const { children, formProps, modalProps } = splitProps(props)
  const handleClose = () => {
    props.form.reset()
    modalProps.onClose?.()
  }
  const { onSubmit, submitButtonText, additionalButtons, isLoading, isSubmitButtonDisabled } =
    useFormLogic(formProps)

  return (
    <Modal
      {...modalProps}
      onClose={handleClose}
      footer={
        <div data-onboarding='modal-form-buttons' className='flex justify-end gap-2'>
          {additionalButtons}
          {!isCancelButtonHidden && (
            <Button type='default' onClick={onCancel ?? handleClose}>
              {modalForm.cancelButton}
            </Button>
          )}
          <Button
            type='primary'
            loading={isLoading}
            disabled={isSubmitButtonDisabled}
            form={formId}
            data-test='modal-form-submit-button'
            htmlType='submit'
          >
            {submitButtonText}
          </Button>
        </div>
      }
    >
      <form id={formId} onSubmit={onSubmit} className='w-full'>
        <FormItemsWrapper>{children}</FormItemsWrapper>
      </form>
    </Modal>
  )
}

type FnSplitProps = <FV extends FieldValues = FieldValues>(
  props: ModalFormProps<FV>
) => {
  children?: ReactNode
  modalProps: Omit<ModalProps, 'children'>
  formProps: ReactHookFormProps<FV>
}
// Props must be separated because onSubmit in Modal made problems
const splitProps: FnSplitProps = <FV extends FieldValues = FieldValues>(
  props: ModalFormProps<FV>
) => {
  const {
    children,
    onSubmit,
    form,
    submitButtonText,
    customSubmitButtonText,
    customSubmitSuccessText,
    additionalButtons,
    isSubmitButtonDisabled,
    isSuccessMessageDisplayed,
    submitUnchanged,
    ...modalProps
  } = props
  const formProps: ReactHookFormProps<FV> = {
    onSubmit,
    form,
    submitButtonText,
    customSubmitButtonText,
    customSubmitSuccessText,
    additionalButtons,
    isSubmitButtonDisabled,
    isSuccessMessageDisplayed,
    submitUnchanged,
  } as ReactHookFormProps<FV>

  return {
    formProps,
    modalProps,
    children,
  }
}
