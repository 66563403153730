import { UntitledIcon } from '@faceup/icons'
import { ulAlertCircle } from '@faceup/icons/ulAlertCircle'
import { Avatar, Modal, Typography } from '@faceup/ui-base'
import { ContactIssue } from '@faceup/utils'
import { FormattedMessage, defineMessages, useIntl } from '../TypedIntl'
import { type FragmentType, getFragmentData, graphql } from '../__generated__'
import useSubscriptionDetail from '../hooks/useSubscriptionDetail'
import { ContactSalesButton } from './ContactSalesButton'

const messages = defineMessages({
  heading: 'Administration.SubscriptionFutureModal.heading',
  description: 'Administration.SubscriptionFutureModal.description',
})

const fragments = {
  SubscriptionFutureModal_institution: graphql(`
    fragment SubscriptionFutureModal_institution on Company {
      id
      billing {
        id
        subscriptionStart
      }

      ...SubscriptionDetail_company
    }
  `),
}

type SubscriptionFutureModalProps = {
  institution: FragmentType<typeof fragments.SubscriptionFutureModal_institution>
}

export const SubscriptionFutureModal = ({
  institution: _institution,
}: SubscriptionFutureModalProps) => {
  const institution = getFragmentData(fragments.SubscriptionFutureModal_institution, _institution)
  const { formatDate } = useIntl()
  const subscription = useSubscriptionDetail({ institution })

  return (
    <Modal open={subscription.state === 'future'}>
      <div className='flex flex-col gap-6 items-center'>
        <Avatar size={48}>
          <UntitledIcon icon={ulAlertCircle} size={20} />
        </Avatar>
        <div className='flex flex-col gap-2 items-center'>
          <Typography.Text strong>
            <FormattedMessage
              {...messages.heading}
              values={{
                date: formatDate(institution.billing?.subscriptionStart ?? ''),
              }}
            />
          </Typography.Text>
          <Typography.Text type='secondary' className='text-center'>
            <FormattedMessage {...messages.description} />
          </Typography.Text>
        </div>
        <ContactSalesButton contactIssue={ContactIssue.ContactLicenceStartInFuture} />
      </div>
    </Modal>
  )
}
