import { useQuery } from '@apollo/client'
import { ulBuilding05 } from '@faceup/icons/ulBuilding05'
import { ulCreditCard02 } from '@faceup/icons/ulCreditCard02'
import { ulDatabase02 } from '@faceup/icons/ulDatabase02'
import { ulFolder } from '@faceup/icons/ulFolder'
import { ulHeartHand } from '@faceup/icons/ulHeartHand'
import { ulHomeLine } from '@faceup/icons/ulHomeLine'
import { ulInbox01 } from '@faceup/icons/ulInbox01'
import { ulLogOut04 } from '@faceup/icons/ulLogOut04'
import { ulPieChart01 } from '@faceup/icons/ulPieChart01'
import { ulSettings01 } from '@faceup/icons/ulSettings01'
import { ulUser01 } from '@faceup/icons/ulUser01'
import { ulUsers01 } from '@faceup/icons/ulUsers01'
import { UserContext, useAccessRights } from '@faceup/member'
import { useMatchPath, useNavigate } from '@faceup/router'
import {
  AdminMenu,
  type AdminMenuItem,
  CustomizationContext,
  PageContentSkeleton,
  AdminLayout as UiAdminLayout,
  type UserMenu,
  type UserMenuItem,
  ucLinking,
} from '@faceup/ui'
import { ucIntrow } from '@faceup/ui'
import { FrontendPermissionType } from '@faceup/utils'
import { type ReactNode, useContext, useEffect, useMemo } from 'react'
import { routes } from '../App/AppRouter/useRoutes'
import { ThemeContext } from '../ThemeContext'
import { FormattedMessage, defineMessages, useIntl } from '../TypedIntl'
import { graphql } from '../__generated__'
import { ucClipboard } from '../customIcons/ucClipboard'
import { ucReportingChannels } from '../customIcons/ucReportingChannels'
import { useMotherId } from '../hooks/useMotherId'
import { usePartner } from '../hooks/usePartner'
import { ThemeType } from '../locales'
import { usePermissions } from '../utils/auth'
import AccountVerificationModal from './AccountVerificationModal'
import ContactSalesAlert from './AdminLayout/ContactSalesAlert'
import PartnerAlertInfo from './AdminLayout/PartnerAlertInfo'
import PaymentNotice from './AdminLayout/PaymentNotice'
import { NewVersionAlertBar } from './NewVersionAlertBar'
import { ProductFruitsLifeRing } from './ProductFruitsLifeRing'
import { SubscriptionFutureModal } from './SubscriptionFutureModal'

const messages = defineMessages({
  dashboard: 'Administration.page.dashboard',
  statistics: 'Administration.page.statistics',
  reports: 'Administration.page.reports',
  surveys: 'Administration.page.surveys',
  settings: 'Administration.page.settings',
  partnerSettings: 'Administration.page.partnerSettings',
  materials: 'Administration.page.materials',
  customization: 'Administration.page.customization',
  services: 'Administration.page.services',
  institutions: 'Administration.page.institutions',
  integrations: 'Administration.page.integrations',
  userMenuProfile: 'Administration.page.settings.profile',
  dataManagement: 'Administration.page.dataManagement',
  userMenuOrganisationSettings: 'Administration.page.settings.institution',
  userMenuMembers: 'Administration.administrators',
  userMenuInvoicing: 'Administration.settings.tabInvoicing',
  userMenuLogout: 'Shared.logout',
})

const useActiveRoute = () => {
  const matchPath = useMatchPath()

  const reportsRoute = matchPath(routes.reports) || matchPath(routes.report)

  if (reportsRoute) {
    return 'reports'
  }

  const partnerReportsRoute = matchPath(routes.partnerReports)
  if (partnerReportsRoute) {
    return 'partner-reports'
  }

  const settingsRoute = matchPath(routes.settings, {
    wildcard: true,
  })
  if (settingsRoute) {
    return 'settings'
  }

  const materialsRoute =
    matchPath(routes.materials) ||
    matchPath(routes.partnerMaterialsManagement) ||
    matchPath(routes.materialsTable)
  if (materialsRoute) {
    return 'materials'
  }

  const institutionCustomizationRoute = matchPath(routes.institutionCustomization, {
    wildcard: true,
  })
  if (institutionCustomizationRoute) {
    return 'institution-customization'
  }

  // const partnerCustomizationRoute = matchPath(routes.partnerCustomization, { wildcard: true })
  // if (partnerCustomizationRoute) {
  //   return 'partnerCustomization'
  // }

  const servicesRoute =
    matchPath(routes.institutionServices) ||
    matchPath(routes.institutionService) ||
    matchPath(routes.partnerServices) ||
    matchPath(routes.partnerService)
  if (servicesRoute) {
    return 'services'
  }

  const institutionsRoute = matchPath(routes.institutions)
  if (institutionsRoute) {
    return 'institutions'
  }

  const partnerSettingsRoute = matchPath(routes.partnerSettings, {
    wildcard: true,
  })
  if (partnerSettingsRoute) {
    return 'partner-settings'
  }

  const statisticsRoute = matchPath(routes.statistics)
  if (statisticsRoute) {
    return 'statistics'
  }

  const partnerStatisticsRoute = matchPath(routes.partnerStatistics)
  if (partnerStatisticsRoute) {
    return 'partner-statistics'
  }

  if (
    matchPath(routes.dataManagement, {
      wildcard: true,
    })
  ) {
    return 'data-management'
  }

  if (
    matchPath(routes.surveys, {
      wildcard: true,
    })
  ) {
    return 'surveys'
  }

  if (
    matchPath(routes.integrations, {
      wildcard: true,
    })
  ) {
    return 'integrations'
  }

  const introwRoute = matchPath(routes.partnerIntrowPage)
  if (introwRoute) {
    return 'introw'
  }

  const dashboardRoute = matchPath(routes.dashboard)
  if (dashboardRoute) {
    return 'dashboard'
  }

  return ''
}

export const query = {
  AdminLayoutQuery: graphql(`
    query AdminLayoutQuery($motherId: UUID!) {
      memberViewer {
        id
        unviewedMessagesCount(motherId: $motherId)
        unviewedReportsCount(motherId: $motherId)

        mother(motherId: $motherId) {
          id
          type
          defaultForm {
            id
            shortLink
          }
          hasAvailableServices
          realInstitution
          editable
          isVerified
          country

          billing {
            id
            plan
            subscriptionStatus
            subscriptionStart
          }

          config {
            id
            squareLogoUrl
            primaryColor
            darkColor
            isBrandingHidden
            institutionName
          }

          partner {
            id
            config {
              id
              squareLogoUrl
              primaryColor
              darkColor
              isBrandingHidden
            }
          }

          ...PaymentNotice_company
          ...ContactSalesAlertFragments_company
          ...AccountVerificationModal_companyRegistration
          ...SubscriptionFutureModal_institution
          ...ProductFruitsLifeRing_institution
        }

        companies(motherId: $motherId) {
          id
          parentId
          organizationalUnitName
        }
      }
    }
  `),
  AdminLayoutPartnerQuery: graphql(`
    query AdminLayoutPartnerQuery {
      memberViewer {
        id
        partnerUnviewedMessagesCount
        partnerUnviewedReportsCount
        isIntrowEnabled
        partner {
          id
          config {
            id
            squareLogoUrl
            primaryColor
            darkColor
            isBrandingHidden
          }
          ...AccountVerificationModal_partnerInstitution
        }
      }
    }
  `),
}

const getBrandingHiddenValue = (...hiddenBrandings: (boolean | null | undefined)[]): boolean => {
  return hiddenBrandings.find(value => typeof value === 'boolean') ?? false
}

type Props = {
  children: ReactNode
  isHidden?: boolean
  logout: () => void
}

const AdminLayout = (props: Props) => {
  const { hasPermissions } = usePermissions()
  const { isPartnerAdministration } = usePartner()
  const { formatMessage } = useIntl()
  const { project } = useContext(ThemeContext)
  const navigate = useNavigate()
  const {
    isAllowedSettingsAccess,
    isAllowedStatistics,
    isAllowedSurveys,
    isVisibleForPermission,
    mothersWithPermission,
    isAllowedReportsBadge,
  } = useAccessRights()
  const activeRoute = useActiveRoute()

  const { getMotherIdWithNull } = useMotherId()

  const { data, loading: loadingInstitution } = useQuery(query.AdminLayoutQuery, {
    variables: {
      // It's ugly because same component is used in partner administration
      motherId: getMotherIdWithNull() ?? '',
    },
    skip: !getMotherIdWithNull(),
  })

  const { viewer } = useContext(UserContext)
  const isPartner = viewer?.isPartner === true

  const { data: dataPartner, loading: loadingPartner } = useQuery(query.AdminLayoutPartnerQuery, {
    skip: !isPartner,
  })

  const { changeColors, changeIsBrandingHidden } = useContext(CustomizationContext)

  const mother = data?.memberViewer?.mother
  const reportsCount =
    (data?.memberViewer?.unviewedReportsCount ?? 0) +
    (data?.memberViewer?.unviewedMessagesCount ?? 0)
  const partnerReportsCount =
    (dataPartner?.memberViewer?.partnerUnviewedReportsCount ?? 0) +
    (dataPartner?.memberViewer?.partnerUnviewedMessagesCount ?? 0)

  const activeKey = activeRoute

  const motherConfig = mother?.config
  const partnerConfigOnMother = mother?.partner?.config
  const partnerConfig = dataPartner?.memberViewer?.partner?.config

  const logo: string = useMemo(() => {
    const squareLogoUrl =
      motherConfig?.squareLogoUrl ??
      partnerConfigOnMother?.squareLogoUrl ??
      partnerConfig?.squareLogoUrl ??
      undefined
    if (squareLogoUrl) {
      return squareLogoUrl
    }

    return project === ThemeType.NNTB ? '/logo/N-logo.svg' : '/logo/U-logo.svg'
  }, [
    partnerConfig?.squareLogoUrl,
    motherConfig?.squareLogoUrl,
    partnerConfigOnMother?.squareLogoUrl,
    project,
  ])

  const loading = loadingInstitution || loadingPartner

  useEffect(() => {
    changeColors({
      primaryColor:
        motherConfig?.primaryColor ??
        partnerConfigOnMother?.primaryColor ??
        partnerConfig?.primaryColor ??
        undefined,
      darkColor:
        motherConfig?.darkColor ??
        partnerConfigOnMother?.darkColor ??
        partnerConfig?.darkColor ??
        undefined,
    })
  }, [
    changeColors,
    motherConfig?.darkColor,
    motherConfig?.primaryColor,
    partnerConfig?.darkColor,
    partnerConfig?.primaryColor,
    partnerConfigOnMother?.darkColor,
    partnerConfigOnMother?.primaryColor,
  ])

  useEffect(() => {
    changeIsBrandingHidden(
      getBrandingHiddenValue(
        motherConfig?.isBrandingHidden,
        partnerConfigOnMother?.isBrandingHidden,
        partnerConfig?.isBrandingHidden
      )
    )
  }, [
    changeIsBrandingHidden,
    motherConfig?.isBrandingHidden,
    partnerConfig?.isBrandingHidden,
    partnerConfigOnMother?.isBrandingHidden,
  ])

  if (props.isHidden) {
    return <>{props.children}</>
  }

  // mock loading instead of spinner
  if (loading || !viewer?.id) {
    return (
      <UiAdminLayout
        menu={
          <AdminMenu
            logo={logo}
            homepageLink={routes => routes.home()}
            items={[]}
            activeKey={activeKey}
            logoBackground='#ccc'
          />
        }
      >
        <PageContentSkeleton />
      </UiAdminLayout>
    )
  }

  const customizationIcon = ucReportingChannels
  const servicesIcon = ulHeartHand
  const dataManagementIcon = ulDatabase02
  const settingsIcon = ulSettings01

  const institutionMenu: AdminMenuItem[] = [
    {
      key: 'dashboard',
      label: formatMessage(messages.dashboard),
      icon: ulHomeLine,
      link: routes => routes.dashboard(),
    },
    {
      key: 'statistics',
      label: formatMessage(messages.statistics),
      icon: ulPieChart01,
      link: routes => routes.statistics(),
      visible: isAllowedStatistics,
    },
    {
      key: 'reports',
      label: formatMessage(messages.reports),
      icon: ulInbox01,
      link: routes => routes.reports(),
      badgeValue: isAllowedReportsBadge ? reportsCount : undefined,
      visible: hasPermissions(FrontendPermissionType.ReportAccess),
      badgeProps: {
        'data-test': 'admin-layout-reports-badge',
      },
    },
    {
      key: 'surveys',
      label: formatMessage(messages.surveys),
      icon: ucClipboard,
      visible: isAllowedSurveys,
      link: routes => routes.surveys(),
    },
    {
      key: 'materials',
      label: formatMessage(messages.materials),
      icon: ulFolder,
      link: routes => routes.materials(),
    },
    {
      key: 'institution-customization',
      label: formatMessage(messages.customization),
      icon: customizationIcon,
      link: routes => routes.institutionCustomization(),
    },
    {
      key: 'data-management',
      label: formatMessage(messages.dataManagement),
      icon: dataManagementIcon,
      link: routes => routes.dataManagementCategories(),
      visible: isVisibleForPermission.ManageCategories,
    },
    {
      key: 'services',
      label: formatMessage(messages.services),
      icon: servicesIcon,
      link: routes => routes.institutionServices(),
      visible: mother?.hasAvailableServices ?? false,
    },
    {
      key: 'integrations',
      label: formatMessage(messages.integrations),
      icon: ucLinking,
      link: routes => routes.integrationsApps(),
    },
    {
      key: 'settings',
      label: formatMessage(messages.settings),
      icon: settingsIcon,
      link: routes => routes.settings(),
    },
  ]

  const partnerMenu: AdminMenuItem[] = [
    {
      key: 'institutions',
      label: formatMessage(messages.institutions),
      icon: ulBuilding05,
      link: routes => routes.institutions(),
    },
    {
      key: 'partner-statistics',
      label: formatMessage(messages.statistics),
      icon: ulPieChart01,
      link: routes => routes.partnerStatistics(),
      visible: Boolean(mothersWithPermission[FrontendPermissionType.Analytics].length),
    },
    {
      key: 'partner-reports',
      label: formatMessage(messages.reports),
      icon: ulInbox01,
      link: routes => routes.partnerReports(),
      badgeValue: partnerReportsCount,
      badgeProps: {
        'data-test': 'admin-partner-layout-reports-badge',
      },
      visible: Boolean(mothersWithPermission[FrontendPermissionType.ReportAccess].length),
    },
    {
      key: 'materials',
      label: formatMessage(messages.materials),
      icon: ulFolder,
      link: routes => routes.partnerMaterialsManagement(),
    },
    // {
    //   key: 'partnerCustomization',
    //   label: formatMessage(messages.customization),
    //   icon: customizationIcon,
    //   link: routes => routes.partnerCustomizationFormInputs(),
    //   menuItemProps: {
    //     'data-onboarding': 'menu-partner-customization',
    //   },
    // },
    {
      key: 'services',
      label: formatMessage(messages.services),
      icon: servicesIcon,
      link: routes => routes.partnerServices(),
    },
    // {
    //   key: 'dataManagement',
    //   label: formatMessage(messages.dataManagement),
    //   icon: dataManagementIcon,
    //   link: routes => routes.dataManagementCategories(),
    // },
    {
      key: 'introw',
      label: 'Introw',
      icon: ucIntrow,
      link: routes => routes.partnerIntrowPage(),
      visible: Boolean(dataPartner?.memberViewer?.isIntrowEnabled),
    },
    {
      key: 'partner-settings',
      label: formatMessage(messages.partnerSettings),
      icon: settingsIcon,
      link: routes => routes.partnerSettingsGeneral(),
    },
  ]

  const institutionName = mother?.config.institutionName

  const getUserMenu = (type: 'member' | 'partner'): UserMenu | undefined => {
    if (!viewer) {
      return undefined
    }
    const institutionMenuData: UserMenuItem[] = [
      { type: 'divider' },
      { type: 'title', value: institutionName, visible: isAllowedSettingsAccess },
      {
        type: 'button',
        value: <FormattedMessage {...messages.userMenuOrganisationSettings} />,
        icon: settingsIcon,
        onClick: () => navigate(routes => routes.settingsInstitution()),
        visible: isAllowedSettingsAccess,
      },
      {
        type: 'button',
        value: <FormattedMessage {...messages.userMenuMembers} />,
        icon: ulUsers01,
        onClick: () => navigate(routes => routes.settingsUsers()),
        visible: isAllowedSettingsAccess,
      },
      {
        type: 'button',
        value: <FormattedMessage {...messages.userMenuInvoicing} />,
        icon: ulCreditCard02,
        onClick: () => navigate(routes => routes.settingsInvoicing()),
        visible: isAllowedSettingsAccess && Boolean(mother?.realInstitution),
      },
    ]

    switch (type) {
      case 'partner':
        return {
          data: viewer,
          menu: [
            { type: 'title', value: viewer.name },
            {
              type: 'button',
              value: <FormattedMessage {...messages.userMenuProfile} />,
              icon: ulUser01,
              onClick: () =>
                navigate(
                  isPartnerAdministration
                    ? routes => routes.partnerAccountGeneral()
                    : routes => routes.accountGeneral()
                ),
            },
            ...(isPartnerAdministration ? [] : institutionMenuData),
            { type: 'divider' },
            {
              type: 'button',
              value: <FormattedMessage {...messages.userMenuLogout} />,
              icon: ulLogOut04,
              onClick: () => props.logout(),
            },
          ],
        }
      case 'member':
        return {
          data: viewer,
          menu: [
            { type: 'title', value: viewer.name },
            {
              type: 'button',
              value: <FormattedMessage {...messages.userMenuProfile} />,
              icon: ulUser01,
              onClick: () => navigate(routes => routes.accountGeneral()),
            },
            ...institutionMenuData,
            { type: 'divider', visible: isAllowedSettingsAccess },
            {
              type: 'button',
              value: <FormattedMessage {...messages.userMenuLogout} />,
              icon: ulLogOut04,
              onClick: () => props.logout(),
            },
          ],
        }
    }
  }

  const additionalItems = <ProductFruitsLifeRing mother={mother ?? undefined} />

  return (
    <UiAdminLayout
      header={
        <>
          <NewVersionAlertBar />
          {mother && <PaymentNotice mother={mother} variant='alertBar' />}
        </>
      }
      menu={
        isPartner ? (
          <AdminMenu
            logo={logo}
            homepageLink={routes => routes.institutions()}
            items={isPartnerAdministration ? partnerMenu : institutionMenu}
            activeKey={activeKey}
            user={getUserMenu('partner')}
            additionalItems={additionalItems}
          />
        ) : (
          <AdminMenu
            logo={logo}
            // getMotherIdWithNull() because we load the layout before the motherId is set
            homepageLink={routes => (getMotherIdWithNull() === null ? '/' : routes.dashboard())}
            items={getMotherIdWithNull() === null ? [] : institutionMenu}
            activeKey={activeKey}
            user={getUserMenu('member')}
            additionalItems={additionalItems}
          />
        )
      }
    >
      <div className='min-h-full flex flex-col'>
        {props.children}
        {mother?.__typename === 'Company' && mother && <ContactSalesAlert company={mother} />}
      </div>

      {isPartner && getMotherIdWithNull() && <PartnerAlertInfo />}

      <AccountVerificationModal
        isUserPartner={isPartner}
        mother={mother ?? null}
        partnerInstitution={dataPartner?.memberViewer?.partner ?? null}
      />
      {mother && <SubscriptionFutureModal institution={mother} />}
    </UiAdminLayout>
  )
}

export default AdminLayout
