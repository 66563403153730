import { Select as AntSelect } from 'antd'
import type { ComponentProps, HTMLAttributes, ReactNode } from 'react'

export type Option<Value, Label> = {
  value: Value
  label: Label
  disabled?: boolean
}

export type MultiSelectProps<Value, Label = ReactNode> = {
  value?: Value[]
  options?: Option<Value, Label>[]
  onChange?: (values: Value[]) => void
  onSelect?: (value: Value) => void
  filterOption?: boolean | ((search: string, option: Option<Value, Label>) => boolean)
  onSearch?: (value: string) => void
  allowClear?: boolean
  showSearch?: boolean
  optionRender?: (option: Option<Value, Label>) => ReactNode
  labelRender?: (option: Option<Value, Label>) => ReactNode
} & Pick<
  ComponentProps<typeof AntSelect>,
  | 'disabled'
  | 'loading'
  | 'size'
  | 'variant'
  | 'placeholder'
  | 'onDropdownVisibleChange'
  | 'dropdownRender'
  | 'maxTagPlaceholder'
  | 'placement'
> &
  Omit<HTMLAttributes<HTMLElement>, 'value' | 'onChange' | 'defaultValue' | 'onSelect'>

export const MultiSelect = <Value = string, Label = ReactNode>({
  filterOption,
  labelRender,
  optionRender,
  ...props
}: MultiSelectProps<Value, Label>) => {
  return (
    <AntSelect
      {...props}
      mode='multiple'
      optionFilterProp='label'
      filterOption={
        typeof filterOption === 'function'
          ? (search, option) =>
              option ? filterOption(search, option as Option<Value, Label>) : false
          : filterOption
      }
      labelRender={labelRender ? option => labelRender(option as Option<Value, Label>) : undefined}
      optionRender={
        optionRender ? option => optionRender(option as unknown as Option<Value, Label>) : undefined
      }
    />
  )
}
