import type { UntitledIconData } from '@faceup/icons'

export const ucQuote: UntitledIconData = {
  prefix: 'custom',
  name: 'ucQuote',
  viewBox: '0 0 24 24',
  paths: [
    {
      shape:
        'M21 9.25H13M21 4H13M21 14.75H3M21 20H3M5.5 4C4.443 4.93956 3.54894 6.78946 3.25402 8.5M3.25402 8.5C4.16471 8.26678 4.55198 8.51363 4.55379 8.94492C4.55881 10.1462 3.04991 9.68384 3.25402 8.5ZM9.5 4C8.443 4.93956 7.54894 6.78946 7.25402 8.5M7.25402 8.5C8.16471 8.26678 8.55198 8.51363 8.55379 8.94492C8.55881 10.1462 7.04991 9.68384 7.25402 8.5Z',
      stroke: 'currentColor',
      strokeWidth: 1.25,
      strokeLinecap: 'round',
      strokeLinejoin: 'round',
    },
  ],
}
