import { UntitledIcon } from '@faceup/icons'
import { ulAlertCircle } from '@faceup/icons/ulAlertCircle'
import { ulXClose } from '@faceup/icons/ulXClose'
import { Typography } from '@faceup/ui-base'
import { useState } from 'react'
import { Card } from '../Card'
import { UnstyledButton } from '../UnstyledButton'

type EnviromentMessageProps = {
  environment: string
}

export const EnvironmentMessage = ({ environment }: EnviromentMessageProps) => {
  const [isVisible, setIsVisible] = useState<boolean>(true)
  if (!isVisible) {
    return null
  }
  return (
    <div className='fixed bottom-4 left-20 z-1000'>
      <Card className='px-4 py-2 relative capitalize flex gap-2 items-center select-none'>
        <UntitledIcon icon={ulAlertCircle} className='text-orange' />
        <Typography.Text strong>{environment} environment</Typography.Text>
        <div
          className={
            'absolute left-0 right-0 top-0 bottom-0 flex justify-start items-center opacity-0 hover:opacity-100 transition-all'
          }
        >
          <UnstyledButton className='bg-white ps-4 pe-2 h-full' onClick={() => setIsVisible(false)}>
            <UntitledIcon icon={ulXClose} />
          </UnstyledButton>
        </div>
      </Card>
    </div>
  )
}
