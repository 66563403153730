import { Flex, Modal, Typography } from '@faceup/ui-base'
import { planMapName } from '@faceup/utils'
import { FormattedMessage, defineMessages } from '../../TypedIntl'
import { UpsellIcon } from './UpsellIcon'
import { UpgradeButtons } from './components'
import type { UpsellData } from './upsellData'

const { Title, Text } = Typography

const messages = defineMessages({
  upgradeTo: 'Administration.upsell.upgradePlanTo',
})

type UpsellModalProps = {
  open: boolean
  onClose: () => void
  upsell: UpsellData
}

export const UpsellModal = ({ open, onClose, upsell }: UpsellModalProps) => {
  const { title, description } = upsell

  return (
    <Modal
      open={open}
      onClose={onClose}
      width={800}
      title={
        <Flex gap='small' align='center'>
          <UpsellIcon />
          <FormattedMessage
            {...messages.upgradeTo}
            values={{ plan: planMapName[upsell.upgradeTo] }}
          />
        </Flex>
      }
      footer={
        <Flex justify='flex-end'>
          <UpgradeButtons upsell={upsell} />
        </Flex>
      }
    >
      {/* TODO: hide the style when modal is refactored */}
      <Flex vertical gap='large' style={{ overflow: 'clip' }}>
        <Title level={4}>{title}</Title>
        <Text type='secondary'>{description}</Text>
      </Flex>
    </Modal>
  )
}
