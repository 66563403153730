import { Modal, type ModalProps } from '@faceup/ui-base'
import { Institution } from '@faceup/utils'
import { useContext } from 'react'
import { ThemeContext } from '../../ThemeContext'
import { FormattedMessage, defineMessages, useIntl } from '../../TypedIntl'
import { ThemeType, useGlobalInfo } from '../../locales'

const messages = defineMessages({
  title: 'Administration.contactSalesModal.byMeeting.title',
})

const iframeUrl: Record<ThemeType, Record<Institution, string>> = {
  [ThemeType.NNTB]: {
    [Institution.Company]: `https://whistleblowing.faceup.com/meetings/zbynek-pechr/nntb-demo?embed=true&parentPageUrl=${window.location.href}`,
    [Institution.School]: `https://whistleblowing.faceup.com/meetings/veronika-novakova?embed=true&parentPageUrl=${window.location.href}`,
  },
  [ThemeType.FaceUp]: {
    [Institution.Company]: `https://whistleblowing.faceup.com/meetings/thomas-nesensohn/faceup-whistleblowing-demo?embed=true&parentPageUrl=${window.location.href}`,
    [Institution.School]: `https://whistleblowing.faceup.com/meetings/veronika-novakova?embed=true&parentPageUrl=${window.location.href}`,
  },
}

type ContactSalesModalByMeetingProps = Required<Pick<ModalProps, 'open' | 'onClose'>>

const ContactSalesModalByMeeting = (props: ContactSalesModalByMeetingProps) => {
  const { project } = useContext(ThemeContext)
  const { institution } = useGlobalInfo()
  const { formatMessage } = useIntl()
  return (
    <Modal {...props} title={<FormattedMessage {...messages.title} />} width={950}>
      <iframe
        src={iframeUrl[project][institution ?? Institution.Company]}
        title={formatMessage(messages.title)}
        className='w-full h-[80vh] border-0'
      />
    </Modal>
  )
}

export default ContactSalesModalByMeeting
