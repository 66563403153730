import { UntitledIcon, type UntitledIconData } from '@faceup/icons'
import { ulBriefcase02 } from '@faceup/icons/ulBriefcase02'
import { ulGraduationHat02 } from '@faceup/icons/ulGraduationHat02'
import { ulUsers01 } from '@faceup/icons/ulUsers01'
import { UnstyledButton } from '@faceup/ui'
import { Avatar, Typography, useUiBaseConfig } from '@faceup/ui-base'
import { Institution } from '@faceup/utils'
import {
  FormattedMessage,
  type TypedMessageDescriptor,
  defineMessages,
} from '../../../../TypedIntl'
import { StepHeading } from './StepHeading'

const messages = defineMessages({
  title: 'Administration.registration.fillInfo.step2.title',
  description: 'Administration.registration.fillInfo.step2.description',
})

const institutionMessages: Record<InstitutionType, Record<TypeMessages, TypedMessageDescriptor>> = {
  Partner: defineMessages<TypeMessages>({
    label: 'Administration.registration.fillInfo.step2.partner.label',
    description: 'Administration.registration.fillInfo.step2.partner.description',
  }),
  [Institution.School]: defineMessages<TypeMessages>({
    label: 'Administration.registration.fillInfo.step2.school.label',
    description: 'Administration.registration.fillInfo.step2.school.description',
  }),
  [Institution.Company]: defineMessages<TypeMessages>({
    label: 'Administration.registration.fillInfo.step2.company.label',
    description: 'Administration.registration.fillInfo.step2.company.description',
  }),
}

const institutionIcons: Record<InstitutionType, UntitledIconData> = {
  Partner: ulUsers01,
  [Institution.School]: ulGraduationHat02,
  [Institution.Company]: ulBriefcase02,
}

export type InstitutionType = Institution | 'Partner'

type TypeMessages = 'label' | 'description'

type RegistrationVariant = 'trial' | 'purchase'

const institutionsRegistration: Record<RegistrationVariant, InstitutionType[]> = {
  trial: [Institution.Company, Institution.School, 'Partner'],
  purchase: [Institution.Company, Institution.School],
}

type Step2Props = {
  variant: RegistrationVariant
  institutionType: InstitutionType
  setInstitutionType: (institutionType: InstitutionType) => void
}

export const Step2 = (props: Step2Props) => {
  const { variant, institutionType, setInstitutionType } = props
  const { colors } = useUiBaseConfig()
  const institutions = institutionsRegistration[variant]

  return (
    <>
      <StepHeading
        title={<FormattedMessage {...messages.title} />}
        description={<FormattedMessage {...messages.description} />}
      />
      <div className='flex flex-col gap-4'>
        {institutions.map(institution => {
          const isSelected = institution === institutionType
          return (
            <UnstyledButton
              key={institution}
              onClick={() => setInstitutionType(institution)}
              data-test={`FillInfoContent-type-${institution}`}
              className='border-1 rounded-xl cursor-pointer transition-all'
              style={{
                borderColor: isSelected ? colors.primary6 : colors.primary1,
              }}
            >
              <div className='flex gap-5 px-6 py-4 items-center'>
                <div>
                  <Avatar
                    size={48}
                    className={`transition-all ${isSelected ? '!text-white !bg-[#0085FF]' : ''}`}
                    icon={<UntitledIcon icon={institutionIcons[institution]} />}
                  />
                </div>
                <div className='flex flex-col gap-1'>
                  <Typography.Text strong>
                    <FormattedMessage {...institutionMessages[institution].label} />
                  </Typography.Text>
                  <Typography.Text>
                    <FormattedMessage {...institutionMessages[institution].description} />
                  </Typography.Text>
                </div>
              </div>
            </UnstyledButton>
          )
        })}
      </div>
    </>
  )
}
