import { UntitledIcon } from '@faceup/icons'
import { ulPlus } from '@faceup/icons/ulPlus'
import { ulTrash03 } from '@faceup/icons/ulTrash03'
import { Button, Flex, Input } from '@faceup/ui-base'
import { type ReactNode, useContext } from 'react'
import ReactDragListView from 'react-drag-listview'
import { UiContext } from '../UiProvider/UiProvider'
import { SimpleDragListItem } from './SimpleDragListItem'
import styles from './assets/EditableSortableList.module.css'

const dragListItemIdentifier = `select-form-item-option`

const defaultMinItemsCount = 0

type Item = { id: string; label: string }

export type EditableSortableListProps = {
  items: Item[]
  shouldDisplayDeleteButton: boolean
  onChange: (items: Item[]) => void
  onAddOption: () => void
  inputMaxLength: number
  canAddItem: boolean
}

export const EditableSortableList = ({
  shouldDisplayDeleteButton,
  inputMaxLength,
  onChange,
  ...props
}: EditableSortableListProps) => (
  <EditableSortableListAbstract
    {...props}
    onMove={(fromIndex, toIndex) => {
      onChange(
        props.items.map((item, index) => {
          if (index === fromIndex) {
            return props.items[toIndex] as Item
          }
          if (index === toIndex) {
            return props.items[fromIndex] as Item
          }
          return item
        })
      )
    }}
  >
    {({ item, config }) => (
      <Input
        suffix={
          shouldDisplayDeleteButton ? (
            <Button
              type='text'
              disabled={!config.isDeletable}
              onClick={() => {
                onChange(props.items.filter(option => option.id !== item.id))
              }}
            >
              <UntitledIcon icon={ulTrash03} />
            </Button>
          ) : null
        }
        value={item.label}
        onChange={event =>
          onChange(
            props.items.map(option => {
              if (option.id === item.id) {
                return { ...option, label: event.currentTarget.value }
              }

              return option
            })
          )
        }
        maxLength={inputMaxLength}
      />
    )}
  </EditableSortableListAbstract>
)

export type EditableSortableListAbstractProps = {
  items: Item[]
  onMove: (fromIndex: number, toIndex: number) => void
  onAddOption: () => void
  canAddItem: boolean
  children: (data: {
    item: Item
    key: number
    config: {
      isDeletable: boolean
    }
  }) => ReactNode
  disabled?: boolean
  config?: {
    minItems?: number
  }
}

const EditableSortableListAbstract = (props: EditableSortableListAbstractProps) => {
  const {
    items,
    onMove,
    onAddOption,
    canAddItem,
    children,
    disabled = false,
    config = {
      minItems: undefined,
    },
  } = props
  const { editableSortableList } = useContext(UiContext)
  return (
    <>
      <input className={styles.inputToFixLabel} />
      <Flex vertical>
        <ReactDragListView
          handleSelector={`.draggable.${dragListItemIdentifier}`}
          nodeSelector={`.${dragListItemIdentifier}-item`}
          onDragEnd={(fromIndex, toIndex) => {
            // toIndex is -1 when the item is dragged outside the list
            if (toIndex < 0) {
              return
            }
            onMove(fromIndex, toIndex)
          }}
          lineClassName='drag-list-line'
        >
          <Flex vertical gap={8}>
            {items.map((item, key) => (
              <SimpleDragListItem
                key={item.id}
                isDraggable={!disabled}
                identifier={dragListItemIdentifier}
              >
                {children({
                  item,
                  key,
                  config: {
                    isDeletable: items.length > (config?.minItems ?? defaultMinItemsCount),
                  },
                })}
              </SimpleDragListItem>
            ))}
          </Flex>
        </ReactDragListView>
        {canAddItem && (
          <Flex justify='left' className={styles.addItemWrapper}>
            <Button
              disabled={disabled}
              onClick={onAddOption}
              icon={<UntitledIcon icon={ulPlus} />}
              type='primary'
              ghost
            >
              {editableSortableList?.addItem ?? 'Add option'}
            </Button>
          </Flex>
        )}
      </Flex>
    </>
  )
}

EditableSortableList.Abstract = EditableSortableListAbstract
