import { useLocalization } from '@faceup/localization'
import { FormItemType, type Language, getTranslation } from '@faceup/utils'
import moment from 'moment-timezone'
import { type FragmentType, getFragmentData, graphql } from '../__generated__'
import type { FormItemAnswer } from '../components'

const fragments = {
  useFormItemsAnswers_caseAnswer: graphql(`
    fragment useFormItemsAnswers_caseAnswer on CaseAnswer {
      id
      values
      translatedValues: values(
        sourceLanguage: $sourceLanguage
        targetLanguage: $targetLanguage
      )
      formItem {
        id
        formItemId
        type
        labelTranslations {
          language
          translation
        }
        options(includeDeleted: true) {
          id
          order
          labelTranslations(
            sourceLanguage: $sourceLanguage
            targetLanguage: $targetLanguage
          ) {
            language
            translation
          }
        }
        scaleStartLabelTranslations {
          language
          translation
        }
        scaleEndLabelTranslations {
          language
          translation
        }
      }
    }
  `),
}

export const useFormItemsAnswers = () => {
  const { language } = useLocalization()
  return (
    _answers: FragmentType<typeof fragments.useFormItemsAnswers_caseAnswer>[],
    defaultLanguage?: Language
  ): FormItemAnswer[] =>
    [...getFragmentData(fragments.useFormItemsAnswers_caseAnswer, _answers)].map(answer => {
      const getFormItemAnswerWithCustomValue = ({
        answer,
        value,
      }: {
        answer: NonNullable<
          FragmentType<typeof fragments.useFormItemsAnswers_caseAnswer>[' $fragmentRefs']
        >['useFormItemsAnswers_caseAnswerFragment']
        value: string
      }) => {
        return {
          id: answer.id,
          formItemId: answer.formItem.formItemId,
          type: answer.formItem.type,
          label: getTranslation(answer.formItem.labelTranslations, language, defaultLanguage),
          value,
        }
      }

      const getSelectFormItemAnswerWithCustomValue = () => {
        const optionTranslations =
          answer.formItem.options.find(option => option.id === answer.values[0])
            ?.labelTranslations ?? []

        const optionTranslationValue = getTranslation(optionTranslations, language, defaultLanguage)

        return getFormItemAnswerWithCustomValue({
          answer,
          value: optionTranslationValue,
        })
      }

      const getMultiSelectFormItemAnswerWithCustomValue = () => {
        const translatedMultiValues = answer.translatedValues ?? []
        const translatedOptions = answer.formItem.options.map(option => ({
          id: option.id,
          label: getTranslation(option.labelTranslations, language, defaultLanguage),
        }))
        const selectedOptions = translatedOptions.filter(option =>
          translatedMultiValues.includes(option.id)
        )
        return getFormItemAnswerWithCustomValue({
          answer,
          value: selectedOptions.map(option => option.label).join(', '),
        })
      }

      const getFormItemAnswerWithSimpleValue = ({
        answer,
      }: {
        answer: NonNullable<
          FragmentType<typeof fragments.useFormItemsAnswers_caseAnswer>[' $fragmentRefs']
        >['useFormItemsAnswers_caseAnswerFragment']
      }) => getFormItemAnswerWithCustomValue({ answer, value: answer.translatedValues[0] ?? '' })

      const getScaleFormItemAnswerWithCustomValue = ({
        answer,
        value,
        labelExtra,
      }: {
        answer: NonNullable<
          FragmentType<typeof fragments.useFormItemsAnswers_caseAnswer>[' $fragmentRefs']
        >['useFormItemsAnswers_caseAnswerFragment']
        value: string
        labelExtra: string
      }) => {
        return {
          id: answer.id,
          formItemId: answer.formItem.formItemId,
          type: answer.formItem.type,
          label: `${getTranslation(answer.formItem.labelTranslations, language, defaultLanguage)} ${labelExtra}`,
          value,
        }
      }

      const formItems: Record<FormItemType, () => FormItemAnswer> = {
        [FormItemType.Scale]: () => {
          const startLabel = answer.formItem.scaleStartLabelTranslations ?? []
          const endLabel = answer.formItem.scaleEndLabelTranslations ?? []
          const startTranslation = getTranslation(startLabel, language, defaultLanguage)
            ? `-${getTranslation(startLabel, language, defaultLanguage)}`
            : ''
          const endTranslation = getTranslation(endLabel, language, defaultLanguage)
            ? `-${getTranslation(endLabel, language, defaultLanguage)}`
            : ''
          const optionsWithOrder = answer.formItem.options.filter(option => option.order !== null)
          const labelExtra = `${answer.formItem.options[0]?.labelTranslations[0]?.translation}${startTranslation}${endTranslation && startTranslation ? ', ' : '-'}${optionsWithOrder[optionsWithOrder.length - 1]?.labelTranslations[0]?.translation}${endTranslation}`

          return getScaleFormItemAnswerWithCustomValue({
            answer,
            value: `${answer.values[0]}`,
            labelExtra: `(${labelExtra})`,
          })
        },
        [FormItemType.Select]: getSelectFormItemAnswerWithCustomValue,
        [FormItemType.MultiSelect]: getMultiSelectFormItemAnswerWithCustomValue,
        [FormItemType.SimpleText]: () => getFormItemAnswerWithSimpleValue({ answer }),
        [FormItemType.MultilineText]: () => getFormItemAnswerWithSimpleValue({ answer }),
        [FormItemType.Date]: () =>
          getFormItemAnswerWithCustomValue({ answer, value: moment(answer.values[0]).format('L') }),
        [FormItemType.MoreInformation]: () => getFormItemAnswerWithSimpleValue({ answer }),
        [FormItemType.Classroom]: () => getFormItemAnswerWithSimpleValue({ answer }),
        [FormItemType.OrganizationalUnit]: () => getFormItemAnswerWithSimpleValue({ answer }),
        [FormItemType.SenderName]: () => getFormItemAnswerWithSimpleValue({ answer }),
        [FormItemType.Email]: () => getFormItemAnswerWithSimpleValue({ answer }),
        [FormItemType.PhoneNumber]: () => getFormItemAnswerWithSimpleValue({ answer }),
        [FormItemType.Category]: () => getFormItemAnswerWithSimpleValue({ answer }),
      }

      return formItems[answer.formItem.type]()
    })
}
