import { forwardRef } from 'react'
import {
  Link as RRDLink,
  type LinkProps as RRDLinkProps,
  type Location as RRDLocation,
  Navigate as RRDNavigate,
  type NavigateProps as RRDNavigateProps,
  Outlet as RRDOutlet,
  Route as RRDRoute,
  type RouteObject as RRDRouteObject,
  type RouteProps as RRDRouteProps,
  RouterProvider as RRDRouterProvider,
  Routes as RRDRoutes,
  ScrollRestoration as ScrollRRDRestoration,
  createBrowserRouter as createRRDBrowserRouter,
  createRoutesFromChildren as createRRDRoutesFromChildren,
  generatePath as generateRRDPath,
  matchPath,
  matchRoutes as matchRRDRoutes,
  unstable_usePrompt as unstable_useRRDPrompt,
  useLocation as useRRDLocation,
  useNavigate as useRRDNavigate,
  useNavigationType as useRRDNavigationType,
  useParams as useRRDParams,
  useRouteError as useRRDRouteError,
  useSearchParams as useRRDSearchParams,
} from 'react-router'
import { useRoutes } from './RoutesProvider'
import type { Routes } from './interfaces'

export type RouteCallback = (routes: Routes) => string

export const useGetRoute = () => {
  const routes = useRoutes()
  return (callback: RouteCallback) => callback(routes)
}

type NavigateParams = {
  search?: string
  replace?: boolean
  preventScrollReset?: boolean
  state?: Record<string, string>
}

export const useNavigate = (): ((routes: RouteCallback, params?: NavigateParams) => void) => {
  const navigate = useRRDNavigate()
  const getRoute = useGetRoute()

  return (callback: RouteCallback, params?: NavigateParams) => {
    const result = getRoute(callback)
    navigate(`${result}${params?.search ?? ''}`, {
      replace: params?.replace,
      preventScrollReset: params?.preventScrollReset,
      state: params?.state,
    })
  }
}

export const useNavigateGoBack = (): ((delta: number) => void) => {
  const navigate = useRRDNavigate()

  return (delta: number) => {
    navigate(delta)
  }
}

type NavigateProps = {
  to: RouteCallback
} & Omit<RRDNavigateProps, 'to'>

export const Navigate = ({ to, ...props }: NavigateProps) => {
  const getRoute = useGetRoute()
  return <RRDNavigate {...props} to={getRoute(to)} />
}

type LinkProps = {
  to: RouteCallback
} & Omit<RRDLinkProps, 'to'>

export const Link = forwardRef<HTMLAnchorElement, LinkProps>(({ to, ...props }, ref) => {
  const getRoute = useGetRoute()

  return <RRDLink ref={ref} {...props} to={getRoute(to)} />
})

export type MatchPathParams = {
  pathname?: string
  wildcard?: boolean
}

export const useMatchPath = () => {
  const getRoute = useGetRoute()
  const { pathname } = useRRDLocation()
  return (path: RouteCallback | string, params?: MatchPathParams) => {
    const result = typeof path === 'string' ? path : getRoute(path)
    return matchPath(`${result}${params?.wildcard ? '/*' : ''}`, params?.pathname ?? pathname)
  }
}

export const useLocationHash = <T = string>(defaultValue: T) => {
  const { hash } = useRRDLocation()
  if (hash === '') {
    return defaultValue
  }
  return hash.replace('#', '') as T
}

export const useParams = useRRDParams

export type Location = RRDLocation
export const useLocation = useRRDLocation

export const useSearchParams = useRRDSearchParams

export const Outlet = RRDOutlet

export type RouteObject = RRDRouteObject

export const RouterProvider = RRDRouterProvider

export const createBrowserRouter = createRRDBrowserRouter
export const generatePath = generateRRDPath

export const useRouteError = useRRDRouteError

export const ScrollRestoration = ScrollRRDRestoration

export const useNavigationType = useRRDNavigationType

export const createRoutesFromChildren = createRRDRoutesFromChildren

export const matchRoutes = matchRRDRoutes

export const unstable_usePrompt = unstable_useRRDPrompt

export type RouteProps = RRDRouteProps

export const RouterRoutes = RRDRoutes

export const Route = RRDRoute
