import { useMutation } from '@apollo/client'
import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { sharedMessages, useIntl } from '@faceup/localization'
import { Alert, Button, notification } from '@faceup/ui-base'
import { CaseStatus } from '@faceup/utils'
import { useContext } from 'react'
import { FormattedMessage } from 'react-intl'
import { type FragmentType, getFragmentData, graphql } from '../../__generated__'
import { ChatPopupAlertType, PopupsContext } from './PopupsContext'
import { RelativeMessagesContext } from './RelativeMessagesContext'

const fragments = {
  ReportStatusAlert_case: graphql(`
    fragment ReportStatusAlert_case on CaseInterface {
      id
      status
      isDemo
      channel {
        id
        sourceType
      }
      organizationalUnit {
        id
        workingHours {
          id
          enabled
          outsideWorkingHoursMessage
        }
      }
      victim {
        id
      }
    }
  `),
}

const mutations = {
  ReopenCompanyReportMutation: graphql(`
    mutation ReopenCompanyReportMutation {
      reopenCompanyReport {
        case {
          id
          status
        }
      }
    }
  `),
}

type Props = {
  userId: string
  case: FragmentType<typeof fragments.ReportStatusAlert_case>
  isFirstMessage?: boolean
}

const ReportStatusAlert = ({ case: _case, userId, isFirstMessage }: Props) => {
  const aCase = getFragmentData(fragments.ReportStatusAlert_case, _case)
  const relativeMessages = useContext(RelativeMessagesContext)
  const { formatMessage } = useIntl()
  const { alertType, closeNotOpenReportAlert } = useContext(PopupsContext)
  const [reopenChat] = useMutation(mutations.ReopenCompanyReportMutation, {
    onError: error => {
      console.error(error.message)
      notification.error({
        message: formatMessage(sharedMessages.apiError),
        description: error.message,
      })
    },
    onCompleted: () => {
      closeNotOpenReportAlert()
    },
  })

  const userIsVictim = Boolean(aCase?.victim?.id) && userId === aCase?.victim?.id
  const isArchived = aCase.status === CaseStatus.Archived
  const isFollowUp = relativeMessages.type === 'Victim'

  if (alertType === ChatPopupAlertType.ReportStatus) {
    return (
      <div className='flex flex-col items-center'>
        <AlertMessage
          data-test='report-status-alert-report-closed'
          message={
            <FormattedMessage
              {...(isArchived
                ? relativeMessages.archivedMessagesPerChannel[aCase.channel.sourceType]
                : relativeMessages.closedMessagesPerChannel[aCase.channel.sourceType])}
            />
          }
          type={isArchived ? 'info' : 'success'}
          status={aCase.status}
          isFollowUp={isFollowUp}
        />
        {userIsVictim && (
          <div>
            <Button type='primary' onClick={() => reopenChat()}>
              {isFollowUp && <FormattedMessage {...relativeMessages.reopen} />}
            </Button>
          </div>
        )}
      </div>
    )
  }

  if (alertType === ChatPopupAlertType.OutsideWorkingHours && userIsVictim && isFirstMessage) {
    return (
      <AlertMessage
        data-test='report-status-alert-outside-working-hours'
        message={aCase.organizationalUnit.workingHours?.outsideWorkingHoursMessage ?? ''}
        type='warning'
        closable
        afterClose={() => closeNotOpenReportAlert()}
        status={aCase.status}
        isFollowUp
      />
    )
  }

  return null
}

const AlertMessage = styled(Alert)<{ isFollowUp?: boolean; status: CaseStatus | null }>`
  width: 100%;
  margin: 0 auto 20px;
  padding: 16px;
  border-radius: 6px;

  ${({ isFollowUp }) =>
    isFollowUp &&
    css`
      border: 0;
      font-weight: 500;
      line-height: 20px;
      font-size: 14px;
    `}

  background: ${({ status }) =>
    status === CaseStatus.Archived
      ? '#0e9af714'
      : status === CaseStatus.Closed
        ? '#0cce6b14'
        : '#f7990e14'};

  .ant-alert-message {
    color: ${({ status }) =>
      status === CaseStatus.Archived
        ? '#0e9af7'
        : status === CaseStatus.Closed
          ? '#0cce6b'
          : '#f7990e'};
  }

  button svg {
    color: ${({ status }) =>
      status === CaseStatus.Archived
        ? '#0e9af7'
        : status === CaseStatus.Closed
          ? '#0cce6b'
          : '#f7990e'};
  }
`

export default ReportStatusAlert
