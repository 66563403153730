import { forwardRef } from 'react'
import { BaseDatePicker, type BaseDatePickerProps } from './bases/BaseDatePicker'

export interface DateTimePickerProps extends Omit<BaseDatePickerProps, 'showTime' | 'mode'> {}

// biome-ignore lint/suspicious/noExplicitAny:
export const DateTimePicker = forwardRef<any, DateTimePickerProps>((props, ref) => (
  <BaseDatePicker {...props} showTime mode={undefined} ref={ref} />
))

DateTimePicker.displayName = 'DateTimePicker'
