import type { UntitledIconData } from '@faceup/icons'
import { ulUpload03 } from '@faceup/icons/ulUpload03'
import { Plan } from '@faceup/utils'
import type { ReactNode } from 'react'

export type UpsellData = {
  name: string
  title: ReactNode
  description: ReactNode
  features: ReactNode[]
  icon: UntitledIconData
  image?: ReactNode
  upgradeTo: Plan
  showUpsell: boolean
}

export const upsellDisabled: UpsellData = {
  title: '',
  description: '',
  features: [],
  icon: ulUpload03,
  upgradeTo: Plan.Unregistered, // it should not be used, so I used plan that would stand out
  showUpsell: false,
  name: '',
}
