import { UntitledIcon, type UntitledIconData } from '@faceup/icons'
import { ulPlus } from '@faceup/icons/ulPlus'
import { Upload, type UploadFile, type UploadProps } from '@faceup/ui-base'
import { type ReactNode, useContext } from 'react'
import { UiContext } from '../UiProvider/UiProvider'

export type UploadSinglePictureProps = {
  listType: Extract<NonNullable<UploadProps['listType']>, 'picture-circle' | 'picture-card'>
  file?: UploadFile | null
  emptyContent?: ReactNode
  onChange?: (file: UploadFile | null) => void
  content?: {
    icon?: UntitledIconData
    text?: ReactNode
  }
  width?: number
  height?: number
} & Omit<UploadProps, 'listType' | 'fileList' | 'defaultFileList' | 'onChange'>

export const UploadSinglePicture = ({
  file,
  emptyContent,
  onChange,
  ...props
}: UploadSinglePictureProps) => {
  return (
    <Upload
      {...props}
      fileList={file ? [file] : undefined}
      onChange={onChange ? ({ fileList }) => onChange(fileList[0] ?? null) : undefined}
      maxCount={1}
    >
      {!file && <EmptyContent emptyContent={emptyContent} />}
    </Upload>
  )
}

type EmptyContentProps = {
  emptyContent: ReactNode | undefined
  content?: {
    icon?: UntitledIconData
    text?: ReactNode
  }
}

const EmptyContent = ({ emptyContent, content }: EmptyContentProps) => {
  const { upload } = useContext(UiContext)
  if (emptyContent) {
    return emptyContent
  }
  return (
    <div className='text-[#0085FF] flex flex-col items-center gap-[4px]'>
      <UntitledIcon icon={content?.icon ?? ulPlus} size={20} />
      <div className='font-semibold'>{content?.text ?? upload?.text ?? 'Upload'}</div>
    </div>
  )
}

UploadSinglePicture.getFileObjectFromUrl = (url: string): UploadFile => {
  return { uid: 'uploaded', name: 'image.png', status: 'done', url }
}
