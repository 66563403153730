import './styles/a.css'
import './styles/alert.css'
import './styles/avatar.css'
import './styles/badge.css'
import './styles/button.css'
import './styles/calendar.css'
import './styles/collapse.css'
import './styles/colorPicker.css'
import './styles/drawer.css'
import './styles/checkbox.css'
import './styles/datePicker.css'
import './styles/divider.css'
import './styles/dropdown.css'
import './styles/empty.css'
import './styles/form.css'
import './styles/floatButton.css'
import './styles/pagination.css'
import './styles/global.css'
import './styles/list.css'
import './styles/menu.css'
import './styles/modal.css'
import './styles/message.css'
import './styles/popconfirm.css'
import './styles/notification.css'
import './styles/radio.css'
import './styles/select.css'
import './styles/steps.css'
import './styles/skeleton.css'
import './styles/switch.css'
import './styles/upload.css'
import './styles/table.css'
import './styles/tabs.css'
import './styles/tag.css'
import './styles/tooltip.css'
import './styles/typography.css'
import './styles/input.css'
import './styles/progress.css'
import './styles/inputNumber.css'
import './styles/slider.css'
import 'antd/dist/reset.css'
import { App } from 'antd'
import type { DirectionType } from 'antd/es/config-provider'
import type { AliasToken } from 'antd/es/theme/internal'
import { shade, tint } from 'polished'
import { type PropsWithChildren, createContext, useContext } from 'react'
import { ConfigProvider, type Locale } from './'
import { Empty } from './Empty'

type UiBaseProviderProps = PropsWithChildren<{
  locale: Locale | undefined
  colorPrimary?: string
  colorDark?: string
  direction: DirectionType
}>
type CustomTokensPrimary = Record<
  | 'primary1'
  | 'primary2'
  | 'primary3'
  | 'primary4'
  | 'primary5'
  | 'primary6'
  | 'primary7'
  | 'primary8'
  | 'primary9'
  | 'primary10',
  string
>
type TokensBlue = Pick<
  AliasToken,
  'blue1' | 'blue2' | 'blue3' | 'blue4' | 'blue5' | 'blue6' | 'blue7' | 'blue8' | 'blue9' | 'blue10'
>
type TokensGreen = Pick<
  AliasToken,
  | 'green1'
  | 'green2'
  | 'green3'
  | 'green4'
  | 'green5'
  | 'green6'
  | 'green7'
  | 'green8'
  | 'green9'
  | 'green10'
>
type TokensRed = Pick<
  AliasToken,
  'red1' | 'red2' | 'red3' | 'red4' | 'red5' | 'red6' | 'red7' | 'red8' | 'red9' | 'red10'
>

type TokensPurple = Pick<
  AliasToken,
  | 'purple1'
  | 'purple2'
  | 'purple3'
  | 'purple4'
  | 'purple5'
  | 'purple6'
  | 'purple7'
  | 'purple8'
  | 'purple9'
  | 'purple10'
>
type TokensOrange = Pick<
  AliasToken,
  | 'orange1'
  | 'orange2'
  | 'orange3'
  | 'orange4'
  | 'orange5'
  | 'orange6'
  | 'orange7'
  | 'orange8'
  | 'orange9'
  | 'orange10'
>
type TokensPrimary = Pick<
  AliasToken,
  | 'colorPrimary'
  | 'colorPrimaryHover'
  | 'colorPrimaryActive'
  | 'colorPrimaryBg'
  | 'colorPrimaryBgHover'
  | 'colorPrimaryBorder'
  | 'colorPrimaryBorderHover'
  | 'colorPrimaryText'
  | 'colorPrimaryTextHover'
  | 'colorPrimaryTextActive'
>
type TokensBorder = Pick<AliasToken, 'colorBorder'>
type TokensLink = Pick<AliasToken, 'colorLink' | 'colorLinkHover' | 'colorLinkActive'>
type TokensText = Pick<
  AliasToken,
  | 'colorText'
  | 'colorTextSecondary'
  | 'colorTextTertiary'
  | 'colorTextQuaternary'
  | 'colorTextLightSolid'
>
type TokensTextExtra = Pick<
  AliasToken,
  | 'colorTextHeading'
  | 'colorTextLabel'
  | 'colorTextDescription'
  | 'colorTextDisabled'
  | 'colorTextPlaceholder'
>
type TokensBorderRadius = Pick<
  AliasToken,
  'borderRadiusXS' | 'borderRadiusSM' | 'borderRadius' | 'borderRadiusLG'
>
type TokensError = Pick<
  AliasToken,
  | 'colorError'
  | 'colorErrorActive'
  | 'colorErrorBg'
  | 'colorErrorBgActive'
  | 'colorErrorBgFilledHover'
  | 'colorErrorHover'
  | 'colorErrorBorderHover'
>
type TokensSuccess = Pick<
  AliasToken,
  | 'colorSuccess'
  | 'colorSuccessHover'
  | 'colorSuccessActive'
  | 'colorSuccessBg'
  | 'colorSuccessBgHover'
  | 'colorSuccessBorder'
  | 'colorSuccessBorderHover'
  | 'colorSuccessText'
  | 'colorSuccessTextHover'
  | 'colorSuccessTextActive'
>
type TokensWarning = Pick<
  AliasToken,
  | 'colorWarning'
  | 'colorWarningHover'
  | 'colorWarningActive'
  | 'colorWarningBg'
  | 'colorWarningBgHover'
  | 'colorWarningBorder'
  | 'colorWarningBorderHover'
  | 'colorWarningText'
  | 'colorWarningTextHover'
  | 'colorWarningTextActive'
  | 'colorWarningOutline'
>
type CustomColors = TokensRed &
  TokensGreen &
  TokensPurple &
  TokensBlue &
  CustomTokensPrimary &
  TokensOrange
type Tokens = TokensGreen &
  TokensRed &
  TokensPurple &
  TokensBlue &
  TokensOrange &
  TokensPrimary &
  TokensLink &
  TokensText &
  TokensTextExtra &
  TokensBorderRadius &
  TokensBorder &
  TokensError &
  TokensWarning &
  TokensSuccess &
  Pick<
    AliasToken,
    | 'fontFamily'
    | 'colorBorderSecondary'
    | 'colorBgSpotlight'
    | 'colorBgContainerDisabled'
    | 'colorBgTextHover'
    | 'colorBgTextActive'
  >

export const UiBaseProvider = ({
  colorPrimary,
  colorDark,
  locale,
  direction,
  children,
}: UiBaseProviderProps) => {
  const blue: TokensBlue = {
    blue1: '#E6F3FF',
    blue2: '#B9DDFF',
    blue3: '#71BBFF',
    blue4: '#48A7FF',
    blue5: '#0085FF',
    blue6: '#007AE1',
    blue7: '#0070CD',
    blue8: '#0061B1',
    blue9: '#005296',
    blue10: '#003A68',
  }
  const customPrimary: CustomTokensPrimary = {
    primary1: colorPrimary ? tint(0.9, colorPrimary) : blue.blue1, // původně 0.45
    primary2: colorPrimary ? tint(0.72, colorPrimary) : blue.blue2, // původně 0.36
    primary3: colorPrimary ? tint(0.44, colorPrimary) : blue.blue3, // původně 0.22
    primary4: colorPrimary ? tint(0.28, colorPrimary) : blue.blue4, // původně 0.14
    primary5: colorPrimary ? colorPrimary : blue.blue5, // beze změn
    primary6: colorPrimary ? shade(0.12, colorPrimary) : blue.blue6, // původně 0.06
    primary7: colorPrimary ? shade(0.2, colorPrimary) : blue.blue7, // původně 0.10
    primary8: colorPrimary ? shade(0.3, colorPrimary) : blue.blue8, // původně 0.15
    primary9: colorPrimary ? shade(0.42, colorPrimary) : blue.blue9, // původně 0.21
    primary10: colorPrimary ? shade(0.6, colorPrimary) : blue.blue10, // původně 0.30
  }
  const green: TokensGreen = {
    green1: '#EBF9F2',
    green2: '#D6F3E5',
    green3: '#ADE7CA',
    green4: '#84DAB0',
    green5: '#32C27B',
    green6: '#2CA86A',
    green7: '#269A60',
    green8: '#1F8954',
    green9: '#187546',
    green10: '#115E37',
  }
  const red: TokensRed = {
    red1: '#FEECF1',
    red2: '#FDD9E2',
    red3: '#FBB2C6',
    red4: '#F98CA9',
    red5: '#F54070',
    red6: '#E42457',
    red7: '#D01748',
    red8: '#BB0E3C',
    red9: '#A50731',
    red10: '#7D0726',
  }
  const purple: TokensPurple = {
    purple1: '#F5EEFC',
    purple2: '#EBDDFA',
    purple3: '#D7BAF5',
    purple4: '#C498EF',
    purple5: '#9C53E5',
    purple6: '#8E4CD1',
    purple7: '#7C34C4',
    purple8: '#6821B0',
    purple9: '#5A179E',
    purple10: '#480F82',
  }
  const orange: TokensOrange = {
    orange1: '#FEF4ED',
    orange2: '#FEE9DB',
    orange3: '#FDD3B7',
    orange4: '#FCBD93',
    orange5: '#FA914B',
    orange6: '#F08035',
    orange7: '#E57225',
    orange8: '#D46317',
    orange9: '#B9520D',
    orange10: '#A24404',
  }
  const error: TokensError = {
    colorError: red.red5,
    colorErrorBg: red.red1,
    colorErrorHover: red.red6,
    colorErrorBgFilledHover: red.red6,
    colorErrorBorderHover: red.red6,
    colorErrorActive: red.red7,
    colorErrorBgActive: red.red7,
  }
  const warning: TokensWarning = {
    colorWarning: orange.orange5,
    colorWarningHover: orange.orange6,
    colorWarningActive: orange.orange7,
    colorWarningBg: orange.orange1,
    colorWarningBgHover: orange.orange2,
    colorWarningBorder: orange.orange3,
    colorWarningBorderHover: orange.orange4,
    colorWarningText: orange.orange5,
    colorWarningTextHover: orange.orange6,
    colorWarningTextActive: orange.orange7,
    colorWarningOutline: '#FA914B1A',
  }
  const success: TokensSuccess = {
    colorSuccess: green.green5,
    colorSuccessHover: green.green6,
    colorSuccessActive: green.green7,
    colorSuccessBg: green.green1,
    colorSuccessBgHover: green.green2,
    colorSuccessBorder: green.green3,
    colorSuccessBorderHover: green.green4,
    colorSuccessText: green.green5,
    colorSuccessTextHover: green.green6,
    colorSuccessTextActive: green.green7,
  }
  const primary: TokensPrimary = {
    colorPrimary: customPrimary.primary5,
    colorPrimaryHover: customPrimary.primary6,
    colorPrimaryActive: customPrimary.primary7,
    colorPrimaryBg: customPrimary.primary1,
    colorPrimaryBgHover: customPrimary.primary2,
    colorPrimaryBorder: customPrimary.primary3,
    colorPrimaryBorderHover: customPrimary.primary4,
    colorPrimaryText: customPrimary.primary5,
    colorPrimaryTextHover: customPrimary.primary6,
    colorPrimaryTextActive: customPrimary.primary7,
  }

  const defaultTokens: TokensBorder = {
    colorBorder: '#CDDDEE',
  }

  const link: TokensLink = {
    colorLink: primary.colorPrimary,
    colorLinkHover: primary.colorPrimaryHover,
    colorLinkActive: primary.colorPrimaryActive,
  }
  const text: TokensText = {
    colorText: '#062D46',
    colorTextSecondary: '#6F91B7',
    colorTextTertiary: '#547599',
    colorTextQuaternary: '#92B4DB',
    colorTextLightSolid: '#FFFFFF',
  }
  const textExtra: TokensTextExtra = {
    colorTextHeading: text.colorText,
    colorTextLabel: text.colorTextSecondary,
    // this $#%&* is used as secondary text color
    colorTextDescription: text.colorTextSecondary,
    colorTextDisabled: text.colorTextQuaternary,
    colorTextPlaceholder: text.colorTextQuaternary,
  }
  const borderRadius: TokensBorderRadius = {
    borderRadiusXS: 4,
    borderRadiusSM: 6,
    borderRadius: 8,
    borderRadiusLG: 12,
  }
  const token: Tokens = {
    fontFamily: 'Inter, sans-serif',
    colorBorderSecondary: '#ECF0FA',
    ...primary,
    ...green,
    ...red,
    ...purple,
    ...blue,
    ...orange,
    ...link,
    ...text,
    ...textExtra,
    ...borderRadius,
    ...error,
    ...warning,
    ...success,
    ...defaultTokens,

    colorLink: customPrimary.primary5,
    colorLinkHover: customPrimary.primary6,
    colorLinkActive: customPrimary.primary7,
    colorBgSpotlight: '#062D46',
    colorBgContainerDisabled: '#9DB0DF14',
    colorBgTextHover: '#9DB0DF1A',
    colorBgTextActive: '#fff',
  }

  const customColors: CustomColors = {
    ...red,
    ...green,
    ...purple,
    ...blue,
    ...orange,
    ...customPrimary,
  }

  return (
    <App>
      <UiBaseInnerProvider token={token} colors={customColors} locale={locale}>
        <ConfigProvider
          componentSize='large'
          locale={locale}
          direction={direction}
          renderEmpty={() => <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
          theme={{
            cssVar: false,
            hashed: false,
            token,
            components: {
              Alert: {
                colorWarningBg: '#FEF4ED',
                colorWarningBorder: '#FDD3B7',
                colorWarning: '#FA914B',
              },
              Typography: {
                fontSizeHeading1: 32,
                fontSizeHeading2: 28,
                fontSizeHeading3: 24,
                fontSizeHeading4: 20,
                fontSizeHeading5: 16,
                titleMarginBottom: 0,
                titleMarginTop: 0,
                lineHeight: 1.6,
                lineHeightHeading1: 1.2,
                lineHeightHeading2: 1.2,
                lineHeightHeading3: 1.2,
                lineHeightHeading4: 1.2,
                lineHeightHeading5: 1.2,
              },
              Badge: {
                textFontWeight: 'semibold',
              },
              Table: {
                rowHoverBg: '#9DB0DF1A',
                fixedHeaderSortActiveBg: '#9DB0DF1A',
                headerFilterHoverBg: '#9DB0DF1A',
                headerSortHoverBg: '#9DB0DF1A',
                headerSplitColor: '#9DB0DF1A',
              },
              Menu: {
                darkItemBg: colorDark ?? '#062D46',
                darkItemSelectedBg: '#5A7A9A40',
                darkPopupBg: colorDark ?? '#062D46',
                darkItemColor: '#6F91B7',
              },
              Tabs: {
                horizontalMargin: '0',
                itemColor: '#547599',
              },
              Form: {
                labelColor: token.colorTextLabel,
                marginLG: 16,
              },
              Input: {
                inputFontSizeSM: 14,
                inputFontSize: 14,
                inputFontSizeLG: 14,
                paddingBlockLG: 10.5,
                borderRadiusSM: borderRadius.borderRadiusSM,
                borderRadius: borderRadius.borderRadius,
                borderRadiusLG: borderRadius.borderRadiusLG,
                activeShadow: '0 0 0 2px rgba(14, 154, 247, 0.10)',
                addonBg: '#ffffff',
                colorWarningBorderHover: warning.colorWarningHover,
              },
              Select: {
                singleItemHeightLG: 44,
                multipleItemHeightLG: 36,
                fontSizeLG: 14,
                multipleItemBg: token.blue1,
                colorIcon: token.blue5,
                optionActiveBg: token.blue1,
                optionSelectedBg: token.blue1,
              },
              List: {
                colorBorder: '#E6ECF9',
                colorSplit: '#E6ECF9',
              },
              Skeleton: {
                gradientFromColor: '#9DB0DF1A',
                gradientToColor: '#9DB0DF33',
              },
              DatePicker: {
                fontSizeLG: 14,
                cellHoverBg: '#E6F3FF',
                controlHeight: 32,
                controlHeightLG: 44,
                controlHeightSM: 24,
                borderRadiusSM: borderRadius.borderRadiusSM,
                borderRadius: borderRadius.borderRadius,
                borderRadiusLG: borderRadius.borderRadiusLG,
                colorWarningBorderHover: warning.colorWarningHover,
              },
              InputNumber: {
                borderRadius: borderRadius.borderRadiusLG,
                borderRadiusLG: borderRadius.borderRadiusLG,
                controlHeight: 32,
                controlHeightLG: 44,
                controlHeightSM: 24,
              },
              Checkbox: {
                borderRadiusSM: borderRadius.borderRadiusXS,
              },
              Button: {
                fontSizeLG: 14,
                fontWeight: 700,
                colorPrimaryText: primary.colorPrimaryText,
                colorPrimaryHover: primary.colorPrimaryHover,
                colorPrimaryActive: primary.colorPrimaryActive,
                colorBorder: defaultTokens.colorBorder,
                dangerShadow: 'none',
                borderColorDisabled: '#ffffff00',
                textHoverBg: '#ffffff00',
                colorBgTextActive: '#ffffff00',
                textTextActiveColor: primary.colorPrimaryActive,
                textTextHoverColor: primary.colorPrimaryHover,
              },
              Empty: {
                colorTextDescription: '#92B4DB',
              },
              Switch: {
                handleShadow: 'none',
              },
              Tag: {
                ...blue,
                ...green,
                ...red,
                ...purple,
                defaultBg: '#9DB0DF26',
              },
              Modal: {
                colorBgMask: '#062D46B2',
                borderRadiusLG: 20,
              },
              Progress: {
                circleIconFontSize: '32px',
                circleTextFontSize: '20px',
              },
              Dropdown: {
                controlItemBgHover: '#9DB0DF26',
              },
              Steps: {
                navArrowColor: '#CDDDEE',
                fontSizeSM: 14,
              },
              Calendar: {
                controlItemBgHover: 'rgba(157, 176, 223, 0.15)',
                monthControlWidth: 80,
              },
              Slider: {
                // rail
                railSize: 8,
                trackBg: '#0085FF',
                trackHoverBg: '#0085FF',
                railBg: 'rgba(157, 176, 223, 0.15)',
                railHoverBg: 'rgba(157, 176, 223, 0.20)',

                // handle
                handleColor: '#0085FF',
                handleActiveColor: '#0085FF',
                colorPrimaryBorderHover: '#0085FF', // handle color on hover
                lineWidthFocus: 2,
                handleLineWidthHover: 4,
                handleSize: 10,
                handleSizeHover: 12,
                handleActiveOutlineColor: '#00000000', // transparent -- outline should not be visible
                handleColorDisabled: '#92B4DB',
                handleLineWidth: 2,

                // slider marks
                dotSize: 12,
                dotBorderColor: 'rgba(157, 176, 223, 0.15)',
                dotActiveBorderColor: '#0085FF',
                colorFillContentHover: 'rgba(157, 176, 223, 0.20)',
              },
              Radio: {
                dotSize: 8,
                controlHeightLG: 44,
                controlHeight: 32,
                controlHeightSM: 24,
              },
              FloatButton: {
                controlHeightLG: 44,
              },
              Drawer: {
                colorBgMask: 'rgba(6, 45, 70, 0.7)',
              },
            },
          }}
        >
          {children}
        </ConfigProvider>
      </UiBaseInnerProvider>
    </App>
  )
}

type UiBaseContextProps = {
  token: Tokens
  colors: CustomColors
  locale: Locale | undefined
}

type UiBaseInnerProviderProps = PropsWithChildren<UiBaseContextProps>

const UiBaseContext = createContext<UiBaseContextProps>({
  token: {} as Tokens,
  colors: {} as CustomColors,
  locale: undefined,
})

const UiBaseInnerProvider = ({ children, ...props }: UiBaseInnerProviderProps) => (
  <UiBaseContext.Provider value={props}>{children}</UiBaseContext.Provider>
)

export const useUiBaseConfig = () => useContext(UiBaseContext)
