import { forwardRef } from 'react'
import { BaseDateRangePicker, type BaseDateRangePickerProps } from './bases/BaseDatePicker'

export type DateRangePickerProps = BaseDateRangePickerProps

// biome-ignore lint/suspicious/noExplicitAny:
export const DateRangePicker = forwardRef<any, DateRangePickerProps>((props, ref) => (
  <BaseDateRangePicker ref={ref} {...props} />
))

DateRangePicker.displayName = 'DateRangePicker'
