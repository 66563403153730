import { ChargebeeSubscriptionStatus, Institution } from '@faceup/utils'
import moment from 'moment-timezone'
import { type FragmentType, getFragmentData, graphql } from '../__generated__'

const fragments = {
  SubscriptionDetail_institutionsOverview: graphql(`
    fragment SubscriptionDetail_institutionsOverview on InstitutionOverview {
      id
      type

      billing {
        id
        subscriptionStatus
        subscriptionEnd
        employees
      }
    }
  `),
  SubscriptionDetail_company: graphql(`
    fragment SubscriptionDetail_company on Company {
      id
      type

      billing {
        id
        subscriptionStatus
        subscriptionEnd
        employees
      }
    }
  `),
}

export type SubscriptionState =
  | 'inTrial'
  | 'future'
  | 'cancelled'
  | 'trialExpired'
  | 'active'
  | 'nonRenewing'

export type SubscriptionDetailReturn = {
  state: SubscriptionState
  diffUntilSubscriptionEnd: number
  daysUntilSubscriptionEnd: number
  humanizedSubscriptionEnd: string
  humanizedSubscriptionEndDate: string
  warningColor: string
}

type SubscriptionDetail = (
  props:
    | {
        institution: FragmentType<typeof fragments.SubscriptionDetail_company>
        institutionOverview?: never
      }
    | {
        institution?: never
        institutionOverview: FragmentType<
          typeof fragments.SubscriptionDetail_institutionsOverview
        > | null
      }
) => SubscriptionDetailReturn

const useSubscriptionDetail: SubscriptionDetail = props => {
  const __institution = getFragmentData(fragments.SubscriptionDetail_company, props.institution)
  const __institutionOverview = getFragmentData(
    fragments.SubscriptionDetail_institutionsOverview,
    props.institutionOverview
  )
  const institution = __institution ?? __institutionOverview
  if (!institution) {
    // Should never happen
    throw new Error('institution in useSubscriptionDetail is null')
  }
  const billing = institution.billing

  const getState = (): SubscriptionState => {
    if (billing?.subscriptionStatus === null) {
      /*
       * When school is created and waiting for approval, subscriptionStatus is null
       * We want to show the school as active.
       * Company always has subscriptionStatus
       */
      if (institution.type === Institution.School) {
        return 'active'
      }
      throw new Error('subscriptionStatus is null')
    }
    // employees === 1 is trial, so basically employees are not set
    const hasSetEmployees = Boolean((billing?.employees ?? 0) > 1)
    const isInTrial = billing?.subscriptionStatus === ChargebeeSubscriptionStatus.InTrial
    const isActive = billing?.subscriptionStatus === ChargebeeSubscriptionStatus.Active
    const isFuture = billing?.subscriptionStatus === ChargebeeSubscriptionStatus.Future
    const isNonRenewing = billing?.subscriptionStatus === ChargebeeSubscriptionStatus.NonRenewing

    if (isFuture) {
      return 'future'
    }
    if (isInTrial) {
      return 'inTrial'
    }
    if (isActive) {
      return 'active'
    }
    if (isNonRenewing) {
      return 'nonRenewing'
    }
    if (!hasSetEmployees) {
      return 'trialExpired'
    }
    return 'cancelled'
  }

  const getDiffUntilSubscriptionEnd = () => {
    const today = moment()
    return moment(billing?.subscriptionEnd).diff(today)
  }

  const getDaysUntilSubscriptionEnd = () => {
    const today = moment()
    // can't use .diff(today, 'days'), it differs with humanize()
    const diffInDaysFloat = moment(billing?.subscriptionEnd).diff(today) / 1000 / 60 / 60 / 24
    return Math.round(diffInDaysFloat)
  }

  const getHumanizedSubscriptionEnd = () => {
    const today = moment()
    return moment.duration(moment(billing?.subscriptionEnd).diff(today)).humanize()
  }

  const getHumanizedSubscriptionEndDate = () => moment(billing?.subscriptionEnd).format('L')

  const getWarningColor = () => {
    const status = billing?.subscriptionStatus
    const subscriptionEnd = billing?.subscriptionEnd
    const today = moment()
    const isNotPaid =
      today.isAfter(subscriptionEnd) || status === ChargebeeSubscriptionStatus.Cancelled
    return isNotPaid ? '#ef4a45' : '#fd9f13'
  }

  return {
    state: getState(),
    diffUntilSubscriptionEnd: getDiffUntilSubscriptionEnd(),
    daysUntilSubscriptionEnd: getDaysUntilSubscriptionEnd(),
    humanizedSubscriptionEnd: getHumanizedSubscriptionEnd(),
    humanizedSubscriptionEndDate: getHumanizedSubscriptionEndDate(),
    warningColor: getWarningColor(),
  }
}

export default useSubscriptionDetail
