import { UntitledIcon } from '@faceup/icons'
import { ulCheck } from '@faceup/icons/ulCheck'
import { ulXClose } from '@faceup/icons/ulXClose'
import { Progress as AntProgress, type ProgressProps as AntProgressProps } from 'antd'

export type ProgressProps = AntProgressProps

export const Progress = (_props: ProgressProps) => {
  const props = getProps(_props)
  const isTypeCircleOrDashboard = ['circle', 'dashboard'].includes(props.type)
  const isTypeLine = ['line'].includes(props.type)
  const isSteps = props.steps
  const getSize: () => typeof props.size = () => {
    if (isTypeLine && isSteps) {
      if (props.size === 'default') {
        return { width: 25, height: 4 }
      }
      if (props.size === 'small') {
        return { width: 2, height: 6 }
      }
    }
    return props.size
  }
  const size = getSize()

  return (
    <AntProgress
      {...props}
      size={size}
      format={
        isTypeCircleOrDashboard
          ? (percent, successPercent) => {
              if (props.format) {
                return props.format(percent, successPercent)
              }

              if (props.status === 'success' || (percent ?? 0) >= 100) {
                return <UntitledIcon className='anticon' icon={ulCheck} />
              }
              if (props.status === 'exception') {
                return <UntitledIcon className='anticon' icon={ulXClose} />
              }
              return `${percent}%`
            }
          : props.format
      }
    />
  )
}

// We set default props here to avoid multiple source of truth
const getProps: (
  props: ProgressProps
) => ProgressProps & Required<Pick<ProgressProps, 'type' | 'size'>> = ({
  type = 'line',
  size = 'default',
  ...props
}) => ({
  ...props,
  type,
  size,
})
