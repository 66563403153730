import { UntitledIcon, type UntitledIconData } from '@faceup/icons'
import { ulArrowLeft } from '@faceup/icons/ulArrowLeft'
import { type RouteCallback, useNavigate } from '@faceup/router'
import { Button, Col, Row, Space, Tooltip, Typography } from '@faceup/ui-base'
import { type ReactNode, useEffect, useRef, useState } from 'react'
import { HintIcon } from './HintIcon'

type ContentHeaderProps = {
  title: ReactNode
  titleHint?: string
  description?: ReactNode
  centerContent?: ReactNode
  secondaryContent?: ReactNode
  arrowGoBackTo?: RouteCallback
  action?: {
    icon: UntitledIconData
    onAction: () => void
  }
}

export const ContentHeader = ({
  title,
  titleHint,
  description,
  centerContent,
  secondaryContent,
  arrowGoBackTo,
  action,
}: ContentHeaderProps) => {
  const titleRef = useRef<HTMLDivElement | null>(null)
  const [isTruncated, setIsTruncated] = useState(false)
  const navigate = useNavigate()

  useEffect(() => {
    const checkEllipsis = () => {
      if (titleRef.current) {
        const { scrollWidth, clientWidth } = titleRef.current
        setIsTruncated(scrollWidth > clientWidth)
      }
    }
    checkEllipsis()
    window.addEventListener('resize', checkEllipsis)
    return () => window.removeEventListener('resize', checkEllipsis)
  }, [])

  return (
    <Space direction='vertical' size={20} style={{ width: '100%' }}>
      <div className='flex items-center justify-between nowrap'>
        <div className='flex items-center nowrap max-w-[calc(100% - 664px)]'>
          {arrowGoBackTo && (
            <Button
              type='text'
              onClick={() => navigate(arrowGoBackTo)}
              icon={<UntitledIcon icon={ulArrowLeft} size={24} />}
            />
          )}
          <Tooltip title={isTruncated ? title : ''}>
            <Typography.Title ellipsis level={3} ref={titleRef}>
              {title}
            </Typography.Title>
          </Tooltip>
          {action && (
            <Button
              onClick={action.onAction}
              type='text'
              icon={<UntitledIcon icon={action.icon} size={16} />}
            />
          )}
          {titleHint && <HintIcon title={titleHint} variant='heading' />}
        </div>
        {centerContent && <div>{centerContent}</div>}
        {secondaryContent && (
          <div
            className='flex items-center justify-end'
            style={{
              flexGrow: centerContent ? undefined : 1,
            }}
          >
            {secondaryContent}
          </div>
        )}
      </div>
      {description && (
        <Row>
          <Col span={24}>{description}</Col>
        </Row>
      )}
    </Space>
  )
}
