import { FormItem } from '@faceup/ui'
import { Radio as AntdRadio, Typography } from '@faceup/ui-base'
import type { ComponentProps } from 'react'
import type { FieldValues, UseControllerProps } from 'react-hook-form'
import { useCustomController } from '../hooks'
import type { FormItemWrapperProps } from '../typings'

type RadioProps<T extends FieldValues> = UseControllerProps<T> &
  Omit<
    ComponentProps<typeof AntdRadio> & FormItemWrapperProps,
    'value' | 'checked' | 'defaultValue'
  >
export const Radio = <T extends FieldValues>(props: RadioProps<T>) => {
  const {
    formItemProps,
    inputProps: { onChange: fieldOnChange, ...inputProps },
  } = useCustomController(props)

  return (
    <AntdRadio
      {...formItemProps}
      {...inputProps}
      className='form-radio'
      onChange={e => {
        fieldOnChange(e)
        props.onChange?.(e)
      }}
    >
      {formItemProps.label}
    </AntdRadio>
  )
}

type RadioGroupProps<T extends FieldValues> = UseControllerProps<T> &
  Omit<ComponentProps<typeof AntdRadio.Group> & FormItemWrapperProps, 'value' | 'defaultValue'>
export const RadioGroup = <T extends FieldValues>(props: RadioGroupProps<T>) => {
  const {
    formItemProps,
    inputProps: { onChange: fieldOnChange, ...inputProps },
  } = useCustomController(props)

  return (
    <FormItem {...formItemProps}>
      <AntdRadio.Group
        {...inputProps}
        className='form-radio-group'
        onChange={e => {
          fieldOnChange(e)
          // props.onChange?.(e)
        }}
      >
        <Typography.Text>{formItemProps.label}</Typography.Text>
        {inputProps.children}
      </AntdRadio.Group>
    </FormItem>
  )
}

Radio.Group = RadioGroup
Radio.Item = AntdRadio
