import type { UntitledIconData } from '../../UntitledIconData'

const ulChevronRightDouble: UntitledIconData = {
  prefix: 'ul',
  name: 'chevron-right-double',
  paths: [
    {
      shape: 'M6 17L11 12L6 7M13 17L18 12L13 7',
    },
  ],
}

export default ulChevronRightDouble
