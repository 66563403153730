import type { ReactNode } from 'react'

type InputFakeProps = {
  value?: ReactNode
  prefix?: ReactNode
  suffix?: ReactNode
}

export const InputFake = ({ value, prefix, suffix }: InputFakeProps) => {
  return (
    <span className='flex border-1 border-[#CDDDEE] hover:border-[#007AE1] active:border-[#007AE1] transition-all rounded-[12px] min-h-[44px] gap-[10px] px-[11px]'>
      {prefix && <span className='h-[44px] flex items-center'>{prefix}</span>}
      <div className='w-full flex justify-center flex-col'>{value}</div>
      {suffix && <span className='h-[44px] flex items-center'>{suffix}</span>}
    </span>
  )
}
