import { Result as AntResult, type ResultProps as AntResultProps } from 'antd'

export type ResultProps = AntResultProps

export const Result = (props: ResultProps) => {
  return (
    <AntResult
      {...props}
      icon={props.icon ? <span className='anticon'>{props.icon}</span> : undefined}
    />
  )
}
