import { UntitledIcon } from '@faceup/icons'
import { ulInfoCircle } from '@faceup/icons/ulInfoCircle'
import { Tooltip, type TooltipProps } from '@faceup/ui-base'

export type HintIconProps = {
  variant?: 'normal' | 'heading'
} & Omit<TooltipProps, 'children'>

export const HintIcon = ({ variant, ...props }: HintIconProps) => (
  <Tooltip {...props}>
    <UntitledIcon
      icon={ulInfoCircle}
      className={variant === 'heading' ? 'text-[16px] text-[#6A8190]' : ''}
    />
  </Tooltip>
)
