import type { UntitledIconData } from '../../UntitledIconData'

const ulAlignLeft: UntitledIconData = {
  prefix: 'ul',
  name: 'align-left',
  paths: [
    {
      shape: 'M16 10H3M20 6H3M20 14H3M16 18H3',
    },
  ],
}

export default ulAlignLeft
