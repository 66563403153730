import type { UntitledIconData } from '../../UntitledIconData'

const ulAlignRight: UntitledIconData = {
  prefix: 'ul',
  name: 'align-right',
  paths: [
    {
      shape: 'M21 10H8M21 6H4M21 14H4M21 18H8',
    },
  ],
}

export default ulAlignRight
