import type {
  CaseCreationOrigin,
  CaseJustification,
  CasePriority,
  CaseSourceType,
  CaseStatus,
  ChannelType,
  Institution,
  MessageAction,
  Plan,
} from '@faceup/utils'
import posthog from 'posthog-js'
import { useCallback } from 'react'
import { useMotherId } from '../hooks/useMotherId'
import { useGlobalInfo } from '../locales'

/*
  Suffixes for event tracking:
  - Created
  - Changed
  - Opened
*/

const ALLOW_TRACKING = import.meta.env.VITE_ENVIRONMENT === 'production'

if (import.meta.env.VITE_POSTHOG_TOKEN) {
  posthog.init(import.meta.env.VITE_POSTHOG_TOKEN, {
    api_host: 'https://eu.i.posthog.com',
    autocapture: false,
    session_recording: {
      maskAllInputs: true,
      maskTextSelector: '.sensitive',
    },
  })
}

// this function is the only function, which we need to be outside of the hook, because it is used in the `CustomApolloProvider.tsx`
export const reset = () => posthog.reset()

const useAnalytics = () => {
  const {
    viewerId,
    institution,
    realInstitution,
    motherInstitutionName,
    employees,
    subscriptionStatus,
    plan,
    institutionUuid,
  } = useGlobalInfo()
  const { getMotherIdWithNull } = useMotherId()
  const motherIdWithNull = getMotherIdWithNull()

  const trackPageView = () => {
    if (realInstitution === null || realInstitution === true) {
      posthog.capture('$pageview')
    }
  }

  const identify = (name: string, email: string) => {
    if (!viewerId) {
      return
    }

    const isDemoInstitution = realInstitution === false
    // Setting the 'demo' person property to true allows filtering out session
    // recordings where the user logs into the demo account.
    posthog.identify(viewerId, { email, name }, { demo: isDemoInstitution })

    if (isDemoInstitution) {
      // disable any further event tracking and session recording
      posthog.opt_out_capturing()
    }

    if (institutionUuid && motherInstitutionName) {
      posthog.group('institution', institutionUuid, {
        name: motherInstitutionName,
        plan: plan?.toLowerCase(),
        type: institution?.toLowerCase(),
        status: subscriptionStatus?.toLowerCase(),
        size: employees,
      })
    }
  }

  // settings
  const trackMemberCreated = () => posthog.capture('Member created')
  const trackMemberInvitationCreated = () => posthog.capture('Member invitation created')
  const trackOrganizationalUnitCreated = (level: number) =>
    posthog.capture('Organizational unit created', { level })
  const trackInstitutionFromPartnerCreated = (type: Institution) =>
    posthog.capture('Institution from partner created', { type: type.toLowerCase() })

  const trackLogsExported = () => posthog.capture('Logs exported')
  const trackEncryptionChanged = (encryptionType: 'standard' | 'e2e') =>
    posthog.capture('Encryption changed', { encryptionType })
  const trackSSOToggled = (status: 'enabled' | 'disabled') =>
    posthog.capture('SSO toggled', { status })

  const trackAutoDueDateToggled = (status: 'enabled' | 'disabled') =>
    posthog.capture('Automatic due date toggled', { status })
  const trackAutoDueDateChanged = (value: number) =>
    posthog.capture('Automatic due date value changed', { value })

  const trackAutoReportDeletionToggled = (status: 'enabled' | 'disabled') =>
    posthog.capture('Automatic report deletion toggled', { status })

  const trackAutoReportDeletionChanged = (value: number) =>
    posthog.capture('Automatic report deletion value changed', { value })

  const trackWhiteLabelLogoUploaded = () => posthog.capture('White label logo uploaded')
  const trackWhiteLabelLogoRemoved = () => posthog.capture('White label logo removed')
  const trackRemoveFaceUpBradingToggled = (status: 'enabled' | 'disabled') =>
    posthog.capture('White label remove FaceUp branding toggled', { status })
  const trackWhiteLabelPrimaryColorChanged = (hex: string) =>
    posthog.capture('White label primary color changed', { hex })
  const trackWhiteLabelSecondaryColorChanged = (hex: string) =>
    posthog.capture('White label secondary color changed', { hex })

  const trackAddOUButtonClicked = () => posthog.capture('Add organization unit button clicked')
  const trackOrganizationUnitDeleted = () => posthog.capture('Organization unit deleted')
  const trackOrganizationUnitEdited = () => posthog.capture('Organization unit edited')
  const trackAddMemberButtonClicked = () => posthog.capture('Add member button clicked')

  // sign up/login tracking
  const trackLogin = () => posthog.capture('Login')
  const trackSignUpProgress = (step: number, type: 'purchase' | 'trial') =>
    posthog.capture('Sign Up progress', { step, type: type.toLowerCase() })
  const trackSignUp = (institution: Institution | 'Partner', type: 'purchase' | 'trial') =>
    posthog.capture('Sign Up', {
      institution: institution.toLowerCase(),
      type: type.toLowerCase(),
    })

  // report tracking
  const trackMessageOpened = () => posthog.capture('Message opened')
  const trackMessageCreated = () => posthog.capture('Message created')
  const trackInternalCommentCreated = () => posthog.capture('Internal comment created')
  const trackReportOpened = (source: CaseSourceType, created: CaseCreationOrigin) =>
    posthog.capture('Report opened', {
      source: source.toLowerCase(),
      created: created.toLowerCase(),
    })

  // report
  const trackReportCreated = () => posthog.capture('Report created')
  const trackReportAssignedMemberChanged = () => posthog.capture('Report assigned member changed')
  const trackReportPriorityChanged = (priority: CasePriority) =>
    posthog.capture('Report priority changed', { priority: priority.toLowerCase() })
  const trackReportLabelChanged = () => posthog.capture('Report label changed')
  const trackReportJustificationChanged = (justification: CaseJustification) =>
    posthog.capture('Report justification changed', { justification: justification.toLowerCase() })
  const trackReportDeadlineChanged = () => posthog.capture('Report deadline changed')
  const trackReportStatusChanged = (status: CaseStatus) =>
    posthog.capture('Report status changed', { status: status.toLowerCase() })
  const trackReportRedaction = (status: 'started' | 'completed') =>
    posthog.capture('Report redaction', { status })
  const trackReportPrintClicked = () => posthog.capture('Report print clicked')

  const trackReportOrganizationUnitChanged = () =>
    posthog.capture('Report organization unit changed')
  const trackReportCategoryChanged = () => posthog.capture('Report category changed')

  const trackReportClosed = (closingReason: string) =>
    posthog.capture('Report closed', { reason: closingReason.toLowerCase() })

  const trackReportFilterUsed = (filters: Record<string, boolean>) =>
    posthog.capture('Report filter used', filters)

  const trackStatisticsFilterUsed = (filters: Record<string, boolean>) =>
    posthog.capture('Statistics filter used', filters)

  // channel tracking
  const trackChannelCreated = (type: ChannelType) =>
    posthog.capture('Channel created', { type: type.toLowerCase() })

  // billing (chargebee)
  const trackBuyActionClicked = () => posthog.capture('Buy action clicked')
  const trackCheckoutStarted = (plan: Plan) =>
    posthog.capture('Checkout started', { plan: plan.toLowerCase() })
  const trackPurchaseCompleted = (plan: Plan) =>
    posthog.capture('Purchase completed', { plan: plan.toLowerCase() })

  // ai assistant
  const trackAiAssistantTextHelperOpened = () => posthog.capture('AI assistant text helper opened')
  const trackAiAssistantTextHelper = (action: MessageAction) =>
    posthog.capture('AI assistant text helper changed', { action: action.toLowerCase() })

  // data management
  const trackAddCategoryButtonClicked = () => posthog.capture('Add category button clicked')
  const trackCategoryAdded = () => posthog.capture('Category added')
  const trackCategoryDeleted = () => posthog.capture('Category deleted')
  const trackCategoryEdited = () => posthog.capture('Category edited')

  // services
  const trackServiceClicked = (serviceName: string) =>
    posthog.capture('Service clicked', { serviceName: serviceName.toLowerCase() })
  const trackServiceEmailSent = (serviceName: string) =>
    posthog.capture('Service email sent', { serviceName: serviceName.toLowerCase() })

  // materials
  const trackMaterialDownloaded = (materialName: string, language: string) =>
    posthog.capture('Material downloaded', {
      materialName: materialName.toLowerCase(),
      language: language.toLowerCase(),
    })

  // surveys
  const trackNewSurveyButtonClicked = () => posthog.capture('New survey button clicked')
  const trackSurveyCreationAborted = () => posthog.capture('Survey creation aborted')
  const trackSurveyCreated = () => posthog.capture('Survey created')
  const trackPublishSurveyButtonClicked = () => posthog.capture('Publish survey button clicked')

  // integrations
  const trackIntegrationDocumentationClicked = (integrationName: string) =>
    posthog.capture('Integration documentation clicked', {
      integrationName: integrationName.toLowerCase(),
    })

  const trackIntegrationAddButtonClicked = (integrationName: string) =>
    posthog.capture('Integration add button clicked', {
      integrationName: integrationName.toLowerCase(),
    })

  const trackIntegrationAdded = (integrationName: string) =>
    posthog.capture('Integration added', {
      integrationName: integrationName.toLowerCase(),
    })

  const analytics = {
    // biome-ignore lint/correctness/useExhaustiveDependencies(realInstitution):
    trackPageView: useCallback(trackPageView, [realInstitution]),
    // biome-ignore lint/correctness/useExhaustiveDependencies(employees):
    // biome-ignore lint/correctness/useExhaustiveDependencies(institution):
    // biome-ignore lint/correctness/useExhaustiveDependencies(motherIdWithNull):
    // biome-ignore lint/correctness/useExhaustiveDependencies(motherInstitutionName):
    // biome-ignore lint/correctness/useExhaustiveDependencies(plan):
    // biome-ignore lint/correctness/useExhaustiveDependencies(realInstitution):
    // biome-ignore lint/correctness/useExhaustiveDependencies(subscriptionStatus):
    // biome-ignore lint/correctness/useExhaustiveDependencies(viewerId):
    identify: useCallback(identify, [
      employees,
      institution,
      motherIdWithNull,
      motherInstitutionName,
      plan,
      realInstitution,
      subscriptionStatus,
      viewerId,
    ]),
    reset: useCallback(reset, []),
    trackMemberCreated: useCallback(trackMemberCreated, []),
    trackMemberInvitationCreated: useCallback(trackMemberInvitationCreated, []),
    trackOrganizationalUnitCreated: useCallback(trackOrganizationalUnitCreated, []),
    trackInstitutionFromPartnerCreated: useCallback(trackInstitutionFromPartnerCreated, []),
    trackLogin: useCallback(trackLogin, []),
    trackSignUpProgress: useCallback(trackSignUpProgress, []),
    trackSignUp: useCallback(trackSignUp, []),
    trackMessageOpened: useCallback(trackMessageOpened, []),
    trackMessageCreated: useCallback(trackMessageCreated, []),
    trackInternalCommentCreated: useCallback(trackInternalCommentCreated, []),
    trackReportOpened: useCallback(trackReportOpened, []),
    trackReportCreated: useCallback(trackReportCreated, []),
    trackReportAssignedMemberChanged: useCallback(trackReportAssignedMemberChanged, []),
    trackReportPriorityChanged: useCallback(trackReportPriorityChanged, []),
    trackReportLabelChanged: useCallback(trackReportLabelChanged, []),
    trackReportJustificationChanged: useCallback(trackReportJustificationChanged, []),
    trackReportDeadlineChanged: useCallback(trackReportDeadlineChanged, []),
    trackReportStatusChanged: useCallback(trackReportStatusChanged, []),
    trackReportRedaction: useCallback(trackReportRedaction, []),
    trackChannelCreated: useCallback(trackChannelCreated, []),
    trackBuyActionClicked: useCallback(trackBuyActionClicked, []),
    trackCheckoutStarted: useCallback(trackCheckoutStarted, []),
    trackPurchaseCompleted: useCallback(trackPurchaseCompleted, []),
    trackAiAssistantTextHelperOpened: useCallback(trackAiAssistantTextHelperOpened, []),
    trackAiAssistantTextHelper: useCallback(trackAiAssistantTextHelper, []),
    trackLogsExported: useCallback(trackLogsExported, []),
    trackEncryptionChanged: useCallback(trackEncryptionChanged, []),
    trackSSOToggled: useCallback(trackSSOToggled, []),
    trackAutoDueDateToggled: useCallback(trackAutoDueDateToggled, []),
    trackAutoDueDateChanged: useCallback(trackAutoDueDateChanged, []),
    trackAutoReportDeletionToggled: useCallback(trackAutoReportDeletionToggled, []),
    trackAutoReportDeletionChanged: useCallback(trackAutoReportDeletionChanged, []),
    trackWhiteLabelLogoUploaded: useCallback(trackWhiteLabelLogoUploaded, []),
    trackWhiteLabelLogoRemoved: useCallback(trackWhiteLabelLogoRemoved, []),
    trackRemoveFaceUpBradingToggled: useCallback(trackRemoveFaceUpBradingToggled, []),
    trackWhiteLabelPrimaryColorChanged: useCallback(trackWhiteLabelPrimaryColorChanged, []),
    trackWhiteLabelSecondaryColorChanged: useCallback(trackWhiteLabelSecondaryColorChanged, []),
    trackAddOUButtonClicked: useCallback(trackAddOUButtonClicked, []),
    trackOrganizationUnitDeleted: useCallback(trackOrganizationUnitDeleted, []),
    trackOrganizationUnitEdited: useCallback(trackOrganizationUnitEdited, []),
    trackAddMemberButtonClicked: useCallback(trackAddMemberButtonClicked, []),
    trackCategoryAdded: useCallback(trackCategoryAdded, []),
    trackCategoryDeleted: useCallback(trackCategoryDeleted, []),
    trackCategoryEdited: useCallback(trackCategoryEdited, []),
    trackAddCategoryButtonClicked: useCallback(trackAddCategoryButtonClicked, []),
    trackServiceClicked: useCallback(trackServiceClicked, []),
    trackServiceEmailSent: useCallback(trackServiceEmailSent, []),
    trackMaterialDownloaded: useCallback(trackMaterialDownloaded, []),
    trackReportPrintClicked: useCallback(trackReportPrintClicked, []),
    trackReportFilterUsed: useCallback(trackReportFilterUsed, []),
    trackStatisticsFilterUsed: useCallback(trackStatisticsFilterUsed, []),
    trackSurveyCreated: useCallback(trackSurveyCreated, []),
    trackSurveyCreationAborted: useCallback(trackSurveyCreationAborted, []),
    trackNewSurveyButtonClicked: useCallback(trackNewSurveyButtonClicked, []),
    trackPublishSurveyButtonClicked: useCallback(trackPublishSurveyButtonClicked, []),
    trackReportOrganizationUnitChanged: useCallback(trackReportOrganizationUnitChanged, []),
    trackReportCategoryChanged: useCallback(trackReportCategoryChanged, []),
    trackReportClosed: useCallback(trackReportClosed, []),
    trackIntegrationAddButtonClicked: useCallback(trackIntegrationAddButtonClicked, []),
    trackIntegrationDocumentationClicked: useCallback(trackIntegrationDocumentationClicked, []),
    trackIntegrationAdded: useCallback(trackIntegrationAdded, []),
  }

  if (ALLOW_TRACKING) {
    return analytics
  }

  const logger = (name: string, ...args: unknown[]) => console.info(`[Analytics]: ${name}`, args)

  const mockAnalytics = Object.keys(analytics).reduce(
    (acc, key) => ({
      ...acc,
      // biome-ignore lint/correctness/useHookAtTopLevel:
      [key]: useCallback((...args: unknown[]) => logger(key, args), [key]),
    }),
    {} as Record<keyof typeof analytics, () => void>
  )

  return mockAnalytics
}

export default useAnalytics
