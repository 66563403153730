import { UntitledIcon } from '@faceup/icons'
import { ulChevronDown } from '@faceup/icons/ulChevronDown'
import { Dropdown as AntDropdown, type DropdownProps as AntDropdownProps } from 'antd'
import { Button, type ButtonProps } from './Button'

export type DropdownProps = AntDropdownProps

const Dropdown = (props: DropdownProps) => <AntDropdown trigger={['click']} {...props} />

const DropdownButton = ({ children, icon, iconPosition, ...props }: ButtonProps) => {
  return (
    <Button {...props} icon={<UntitledIcon icon={ulChevronDown} />} iconPosition='end'>
      <div
        style={{
          display: 'flex',
          flexDirection: iconPosition === 'start' ? 'row' : 'row-reverse',
          gap: '8px',
        }}
      >
        {icon}
        {children}
      </div>
    </Button>
  )
}

DropdownButton.displayName = 'Dropdown.Button'
Dropdown.Button = DropdownButton

export { Dropdown }
