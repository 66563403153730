import { ulBrush01 } from '@faceup/icons/ulBrush01'
import { ulFile02 } from '@faceup/icons/ulFile02'
import { ulFolder } from '@faceup/icons/ulFolder'
import { ulInbox01 } from '@faceup/icons/ulInbox01'
import { ulPhoneCall01 } from '@faceup/icons/ulPhoneCall01'
import { ulPhoneOutgoing02 } from '@faceup/icons/ulPhoneOutgoing02'
import { ulPieChart01 } from '@faceup/icons/ulPieChart01'
import { ulSearchRefraction } from '@faceup/icons/ulSearchRefraction'
import { ulSettings01 } from '@faceup/icons/ulSettings01'
import { ulZap } from '@faceup/icons/ulZap'
import { useLocalization } from '@faceup/localization'
import { useAccessRights, useGlobalInfo, usePartner } from '@faceup/member'
import { ucLinking, ucMessageSettings } from '@faceup/ui'
import {
  Feature,
  Institution,
  Language,
  Plan,
  canAddMoreMembers,
  canAddMoreReportingForms,
} from '@faceup/utils'
import { type TypedMessageDescriptor, defineMessages, useIntl } from '../../../TypedIntl'
import { ucClipboard } from '../../../customIcons/ucClipboard'
import type { UpsellData } from '../upsellData'
import imageOrganizationStructureCs from './assets/upsell-organization-structure-cs.png'
import imageOrganizationStructureEn from './assets/upsell-organization-structure-en.png'
import imageReportsCs from './assets/upsell-reports-cs.png'
import imageStatisticsCs from './assets/upsell-statistics-cs.png'
import imageStatisticsEn from './assets/upsell-statistics-en.png'
import imageSurveysCs from './assets/upsell-surveys-cs.png'
import imageSurveysEn from './assets/upsell-surveys-en.png'
import imageWhitelabelCs from './assets/upsell-whitelabel-cs.png'
import imageWhitelabelEn from './assets/upsell-whitelabel-en.png'

const messages: Record<UpsellFor, Record<Messages, TypedMessageDescriptor>> = {
  reports: defineMessages<Messages>({
    title: 'Administration.upsell.reports.title',
    features: 'Administration.upsell.reports.features',
    description: 'Administration.upsell.reports.description',
  }),
  statistics: defineMessages<Messages>({
    title: 'Administration.upsell.statistics.title',
    features: 'Administration.upsell.statistics.features',
    description: 'Administration.upsell.statistics.description',
  }),
  organisationStructure: defineMessages<Messages>({
    title: 'Administration.upsell.organisationStructure.title',
    features: 'Administration.upsell.organisationStructure.features',
    description: 'Administration.upsell.organisationStructure.description',
  }),
  categories: defineMessages<Messages>({
    title: 'Administration.upsell.categories.title',
    features: 'Administration.upsell.categories.features',
    description: 'Administration.upsell.categories.description',
  }),
  formAppearance: defineMessages<Messages>({
    title: 'Administration.upsell.formAppearance.title',
    features: 'Administration.upsell.formAppearance.features',
    description: 'Administration.upsell.formAppearance.description',
  }),
  addChannelLiveHotline: defineMessages<Messages>({
    title: 'Administration.upsell.addChannelLiveHotline.title',
    features: 'Administration.upsell.addChannelLiveHotline.features',
    description: 'Administration.upsell.addChannelLiveHotline.description',
  }),
  addChannelHotline: defineMessages<Messages>({
    title: 'Administration.upsell.addChannelHotline.title',
    features: 'Administration.upsell.addChannelHotline.features',
    description: 'Administration.upsell.addChannelHotline.description',
  }),
  addChannelForm: defineMessages<Messages>({
    title: 'Administration.upsell.addChannelForm.title',
    features: 'Administration.upsell.addChannelForm.features',
    description: 'Administration.upsell.addChannelForm.description',
  }),
  addMember: defineMessages<Messages>({
    title: 'Administration.upsell.addMember.title',
    features: 'Administration.upsell.addMember.features',
    description: 'Administration.upsell.addMember.description',
  }),
  automaticDueDate: defineMessages<Messages>({
    title: 'Administration.upsell.automaticDueDate.title',
    features: 'Administration.upsell.automaticDueDate.features',
    description: 'Administration.upsell.automaticDueDate.description',
  }),
  endToEndEncryption: defineMessages<Messages>({
    title: 'Administration.upsell.endToEndEncryption.title',
    features: 'Administration.upsell.endToEndEncryption.features',
    description: 'Administration.upsell.endToEndEncryption.description',
  }),
  schoolEnableAccessKey: defineMessages<Messages>({
    title: 'Administration.upsell.schoolEnableAccessKey.title',
    features: 'Administration.upsell.schoolEnableAccessKey.features',
    description: 'Administration.upsell.schoolEnableAccessKey.description',
  }),
  materials: defineMessages<Messages>({
    title: 'Administration.upsell.materials.title',
    features: 'Administration.upsell.materials.features',
    description: 'Administration.upsell.materials.description',
  }),
  sso: defineMessages<Messages>({
    title: 'Administration.upsell.sso.title',
    features: 'Administration.upsell.sso.features',
    description: 'Administration.upsell.sso.description',
  }),
  webhooks: defineMessages<Messages>({
    title: 'Administration.upsell.webhooks.title',
    features: 'Administration.upsell.webhooks.features',
    description: 'Administration.upsell.webhooks.description',
  }),
  apiKeys: defineMessages<Messages>({
    title: 'Administration.upsell.apiKeys.title',
    features: 'Administration.upsell.apiKeys.features',
    description: 'Administration.upsell.apiKeys.description',
  }),
  whitelabel: defineMessages<Messages>({
    title: 'Administration.upsell.whitelabel.title',
    features: 'Administration.upsell.whitelabel.features',
    description: 'Administration.upsell.whitelabel.description',
  }),
  schoolEditUserPermissions: defineMessages<Messages>({
    title: 'Administration.upsell.schoolEditUserPermissions.title',
    features: 'Administration.upsell.schoolEditUserPermissions.features',
    description: 'Administration.upsell.schoolEditUserPermissions.description',
  }),
  integrations: defineMessages<Messages>({
    title: 'Administration.upsell.integrations.title',
    features: 'Administration.upsell.integrations.features',
    description: 'Administration.upsell.integrations.description',
  }),
  surveys: defineMessages<Messages>({
    title: 'Administration.upsell.surveys.title',
    features: 'Administration.upsell.surveys.features',
    description: 'Administration.upsell.surveys.description',
  }),
  investigations: defineMessages<Messages>({
    title: 'Administration.upsell.investigations.title',
    features: 'Administration.upsell.investigations.features',
    description: 'Administration.upsell.investigations.description',
  }),
}

type Messages = 'title' | 'description' | 'features'

type UpsellFor =
  | 'reports'
  | 'statistics'
  | 'organisationStructure'
  | 'categories'
  | 'addChannelLiveHotline'
  | 'addChannelHotline'
  | 'addChannelForm'
  | 'addMember'
  | 'automaticDueDate'
  | 'formAppearance'
  | 'endToEndEncryption'
  | 'schoolEnableAccessKey'
  | 'schoolEditUserPermissions'
  | 'sso'
  | 'materials'
  | 'whitelabel'
  | 'surveys'
  | 'webhooks'
  | 'apiKeys'
  | 'integrations'
  | 'investigations'

type Material = {
  id: string
  isAccessible: boolean
  downloadLink: string | null
}

type UpsellReturnType = Record<Exclude<UpsellFor, 'materials'>, UpsellData> & {
  materials: (material: Material) => UpsellData
}

export const useUpsell = (): UpsellReturnType => {
  const { formatMessage } = useIntl()
  const { usePaidVersion, plan, institution, membersCount, formsCount, entitlements } =
    useGlobalInfo()
  const {
    isAllowedE2EE,
    isAllowedSSO,
    isAllowedWebhooks,
    isAllowedWhitelabel,
    isAllowedApi,
    isAllowedMultipleReportSources,
  } = useAccessRights()
  const { isPartnerAdministration } = usePartner()
  const { language } = useLocalization()

  const plans = [Plan.Unregistered, Plan.Free, Plan.Basic, Plan.Standard, Plan.Premium]
  const higherPlan = plan ? plans[plans.indexOf(plan) + 1] : undefined
  const highestPlan = institution === Institution.Company ? Plan.Premium : Plan.Standard

  const isEntitlementEnabled = (feature: Feature) =>
    entitlements.find(entitlement => entitlement.id === feature)?.isEnabled ?? false

  const membersEntitlement = entitlements?.find(
    entitlement => entitlement.id === Feature.TeamMembers
  )
  const canAddMoreUsers =
    membersEntitlement?.isEnabled && canAddMoreMembers(membersEntitlement?.value, membersCount)

  const reportingFormsEntitlement = entitlements?.find(
    entitlement => entitlement.id === Feature.ReportingForms
  )
  const canAddMoreForms =
    reportingFormsEntitlement?.isEnabled &&
    canAddMoreReportingForms(reportingFormsEntitlement?.value, formsCount)

  const showCzechImage = [Language.Cs, Language.Sk].includes(language)

  return {
    reports: {
      name: 'reports',
      icon: ulInbox01,
      title: formatMessage(messages.reports.title),
      features: formatMessage(messages.reports.features).split(`\n`).filter(Boolean),
      description: formatMessage(messages.reports.description),
      image: imageReportsCs,
      upgradeTo: Plan.Standard,
      showUpsell: !isPartnerAdministration && !usePaidVersion,
    },
    statistics: {
      name: 'statistics',
      icon: ulPieChart01,
      title: formatMessage(messages.statistics.title),
      features: formatMessage(messages.statistics.features).split(`\n`).filter(Boolean),
      description: formatMessage(messages.statistics.description),
      image: showCzechImage ? imageStatisticsCs : imageStatisticsEn,
      upgradeTo: Plan.Standard,
      showUpsell: !isPartnerAdministration && !usePaidVersion,
    },
    organisationStructure: {
      name: 'organisationStructure',
      icon: ulSettings01,
      title: formatMessage(messages.organisationStructure.title),
      features: formatMessage(messages.organisationStructure.features).split(`\n`).filter(Boolean),
      description: formatMessage(messages.organisationStructure.description),
      image: showCzechImage ? imageOrganizationStructureCs : imageOrganizationStructureEn,
      upgradeTo: Plan.Standard,
      showUpsell: !usePaidVersion,
    },
    categories: {
      name: 'categories',
      icon: ucMessageSettings,
      title: formatMessage(messages.categories.title),
      features: formatMessage(messages.categories.features).split(`\n`).filter(Boolean),
      description: formatMessage(messages.categories.description),
      upgradeTo: Plan.Standard,
      showUpsell: !usePaidVersion,
    },
    formAppearance: {
      name: 'formAppearance',
      icon: ucMessageSettings,
      title: formatMessage(messages.formAppearance.title),
      features: formatMessage(messages.formAppearance.features).split(`\n`).filter(Boolean),
      description: formatMessage(messages.formAppearance.description),
      upgradeTo: Plan.Standard,
      showUpsell: !usePaidVersion,
    },
    addChannelLiveHotline: {
      name: 'addChannelLiveHotline',
      icon: ulPhoneOutgoing02,
      title: formatMessage(messages.addChannelLiveHotline.title),
      features: formatMessage(messages.addChannelLiveHotline.features).split(`\n`).filter(Boolean),
      description: formatMessage(messages.addChannelLiveHotline.description),
      upgradeTo: Plan.Standard,
      // We don't want to show upsell yet, prepared for future
      showUpsell: false,
    },
    addChannelHotline: {
      name: 'addChannelHotline',
      icon: ulPhoneCall01,
      title: formatMessage(messages.addChannelHotline.title),
      features: formatMessage(messages.addChannelHotline.features).split(`\n`).filter(Boolean),
      description: formatMessage(messages.addChannelHotline.description),
      upgradeTo: Plan.Standard,
      showUpsell: !isAllowedMultipleReportSources,
    },
    addChannelForm: {
      name: 'addChannelForm',
      icon: ulFile02,
      title: formatMessage(messages.addChannelForm.title),
      features: formatMessage(messages.addChannelForm.features).split(`\n`).filter(Boolean),
      description: formatMessage(messages.addChannelForm.description),
      upgradeTo: higherPlan ?? highestPlan,
      showUpsell: !canAddMoreForms,
    },
    addMember: {
      name: 'addMember',
      icon: ulSettings01,
      title: formatMessage(messages.addMember.title),
      features: formatMessage(messages.addMember.features).split(`\n`).filter(Boolean),
      description: formatMessage(messages.addMember.description),
      upgradeTo: higherPlan ?? highestPlan,
      showUpsell: !canAddMoreUsers,
    },
    automaticDueDate: {
      name: 'automaticDueDate',
      icon: ulSettings01,
      title: formatMessage(messages.automaticDueDate.title),
      features: formatMessage(messages.automaticDueDate.features).split(`\n`).filter(Boolean),
      description: formatMessage(messages.automaticDueDate.description),
      upgradeTo: Plan.Standard,
      showUpsell: !usePaidVersion,
    },
    endToEndEncryption: {
      name: 'endToEndEncryption',
      icon: ulSettings01,
      title: formatMessage(messages.endToEndEncryption.title),
      features: formatMessage(messages.endToEndEncryption.features).split(`\n`).filter(Boolean),
      description: formatMessage(messages.endToEndEncryption.description),
      upgradeTo: Plan.Standard,
      showUpsell: !isAllowedE2EE,
    },
    schoolEnableAccessKey: {
      name: 'schoolEnableAccessKey',
      icon: ucMessageSettings,
      title: formatMessage(messages.schoolEnableAccessKey.title),
      features: formatMessage(messages.schoolEnableAccessKey.features).split(`\n`).filter(Boolean),
      description: formatMessage(messages.schoolEnableAccessKey.description),
      upgradeTo: Plan.Standard,
      showUpsell: !usePaidVersion,
    },
    materials: material => ({
      name: 'materials',
      icon: ulFolder,
      title: formatMessage(messages.materials.title),
      features: formatMessage(messages.materials.features).split(`\n`).filter(Boolean),
      description: formatMessage(messages.materials.description),
      upgradeTo: Plan.Standard,
      showUpsell: !material.downloadLink || !material.isAccessible,
    }),
    sso: {
      name: 'sso',
      icon: ulSettings01,
      title: formatMessage(messages.sso.title),
      features: formatMessage(messages.sso.features).split(`\n`).filter(Boolean),
      description: formatMessage(messages.sso.description),
      upgradeTo: Plan.Premium,
      showUpsell: !isAllowedSSO,
    },
    webhooks: {
      name: 'webhooks',
      icon: ulZap,
      title: formatMessage(messages.webhooks.title),
      features: formatMessage(messages.webhooks.features).split(`\n`).filter(Boolean),
      description: formatMessage(messages.webhooks.description),
      upgradeTo: Plan.Standard,
      showUpsell: !isAllowedWebhooks,
    },
    apiKeys: {
      name: 'apiKeys',
      icon: ulZap,
      title: formatMessage(messages.apiKeys.title),
      features: formatMessage(messages.apiKeys.features).split(`\n`).filter(Boolean),
      description: formatMessage(messages.apiKeys.description),
      upgradeTo: Plan.Standard,
      showUpsell: !isAllowedApi,
    },
    whitelabel: {
      name: 'whitelabel',
      icon: ulBrush01,
      title: formatMessage(messages.whitelabel.title),
      features: formatMessage(messages.whitelabel.features).split(`\n`).filter(Boolean),
      description: formatMessage(messages.whitelabel.description),
      upgradeTo: Plan.Premium,
      image: showCzechImage ? imageWhitelabelCs : imageWhitelabelEn,
      showUpsell: !isAllowedWhitelabel,
    },
    schoolEditUserPermissions: {
      name: 'schoolEditUserPermissions',
      icon: ulFolder,
      title: formatMessage(messages.schoolEditUserPermissions.title),
      features: formatMessage(messages.schoolEditUserPermissions.features)
        .split(`\n`)
        .filter(Boolean),
      description: formatMessage(messages.schoolEditUserPermissions.description),
      upgradeTo: Plan.Standard,
      showUpsell: institution === Institution.School && !usePaidVersion,
    },
    integrations: {
      name: 'integrations',
      icon: ucLinking,
      title: formatMessage(messages.integrations.title),
      features: formatMessage(messages.integrations.features).split(`\n`).filter(Boolean),
      description: formatMessage(messages.integrations.description),
      upgradeTo: Plan.Standard,
      showUpsell: !usePaidVersion,
    },
    surveys: {
      name: 'surveys',
      icon: ucClipboard,
      title: formatMessage(messages.surveys.title),
      features: formatMessage(messages.surveys.features).split(`\n`).filter(Boolean),
      description: formatMessage(messages.surveys.description),
      upgradeTo: Plan.Standard,
      image: showCzechImage ? imageSurveysCs : imageSurveysEn,
      showUpsell: !isEntitlementEnabled(Feature.Surveys),
    },
    investigations: {
      name: 'investigations',
      icon: ulSearchRefraction,
      title: formatMessage(messages.investigations.title),
      features: formatMessage(messages.investigations.features).split(`\n`).filter(Boolean),
      description: formatMessage(messages.investigations.description),
      upgradeTo: Plan.Standard,
      showUpsell: !isEntitlementEnabled(Feature.Investigations),
    },
  }
}
