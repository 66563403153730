import { useQuery } from '@apollo/client'
import { UntitledIcon } from '@faceup/icons'
import { ulPlus } from '@faceup/icons/ulPlus'
import { Card } from '@faceup/ui'
import { Button, Divider, Flex, Skeleton, Typography, notification } from '@faceup/ui-base'
import { ChannelType, type Language } from '@faceup/utils'
import { useEffect, useMemo, useState } from 'react'
import LanguageDropdown from '../../../Components/LanguageDropdown'
import { sharedMessages } from '../../../Shared/translations'
import { FormattedMessage, defineMessages, useIntl } from '../../../TypedIntl'
import { graphql } from '../../../__generated__'
import type { InstitutionCustomizationChildrenViewProps } from '../InstitutionCustomizationView'
import { CreatePageForm } from './InstitutionCustomizationOverviewView/Components/CreatePageForm'
import EditPageForm from './InstitutionCustomizationOverviewView/Components/EditPageForm'
import { PagesList } from './InstitutionCustomizationOverviewView/Components/PagesList'

const messages = defineMessages({
  pages: 'Administration.customization.tab.pages',
  buttonAddNewPage: 'Administration.customization.pages.button.addNewPage',
})

const query = {
  InstitutionCustomizationPagesViewQuery: graphql(`
    query InstitutionCustomizationPagesViewQuery(
      $language: Language
      $channelId: ReportSourceGlobalId!
    ) {
      reportSource(reportSourceId: $channelId) {
        id
        languages
        defaultLanguage
        sourceType

        pages(language: $language) {
          ... on Page {
            id
            title
            content
            icon
            language
            order
          }
          ... on ChannelPage {
            id
            type
            title
            content
            icon
            language
            order
          }
        }

        ...PagesList_reportSource
        ...EditPageForm_reportSource
      }
    }
  `),
}

const InstitutionCustomizationPagesView = ({
  formId,
}: InstitutionCustomizationChildrenViewProps) => {
  const [createdNewPageId, setCreatedNewPageId] = useState<string>()
  // undefined - waiting to load data, null - new page
  const [activePageId, setActivePageId] = useState<string | null>()
  const [selectedLanguage, setSelectedLanguage] = useState<Language>()
  const { formatMessage } = useIntl()

  const { data, loading } = useQuery(query.InstitutionCustomizationPagesViewQuery, {
    fetchPolicy: 'cache-and-network',
    variables: {
      language: selectedLanguage,
      channelId: formId,
    },
    onError: error => {
      console.error(error)
      notification.error({
        message: formatMessage(sharedMessages.apiError),
        description: error.message,
      })
    },
  })

  const pages = useMemo(() => data?.reportSource?.pages ?? [], [data?.reportSource?.pages])

  const isSurvey = data?.reportSource?.sourceType === ChannelType.Survey

  useEffect(() => {
    if (activePageId !== undefined && activePageId !== null) {
      const doesActualPageIdExist = pages?.some(page => page?.id === activePageId)
      if (!doesActualPageIdExist) {
        setActivePageId(undefined)
      }
    }
    if (activePageId === undefined && pages && pages.length > 0) {
      if (isSurvey) {
        setActivePageId(pages[1]?.id)
      } else {
        setActivePageId(pages[0]?.id)
      }
    }
  }, [activePageId, isSurvey, pages])

  /**
   * We need to lazy load the created page after it was created.
   * It's not possible to do ASAP because of refetching the data.
   */
  useEffect(() => {
    if (pages.some(page => page.id === createdNewPageId)) {
      setActivePageId(createdNewPageId)
      setCreatedNewPageId(undefined)
    }
  }, [pages, createdNewPageId])

  const reportSource = data?.reportSource

  useEffect(() => {
    if (reportSource?.defaultLanguage) {
      setSelectedLanguage(prevState => prevState ?? reportSource.defaultLanguage)
    }
  }, [reportSource?.defaultLanguage])

  if (loading || selectedLanguage === undefined) {
    return <Skeleton />
  }

  if (!reportSource) {
    return null
  }

  const canManipulateWithPages = reportSource.sourceType !== ChannelType.Survey
  const activePage =
    pages.find(page => page.id === activePageId) ?? (activePageId === null ? null : undefined)

  return (
    <Card
      style={{
        padding: 0,
        display: 'flex',
        flexDirection: 'row',
        minHeight: '847px',
        gap: '32px',
        background: '#FFFFFF',
      }}
      className='relative'
    >
      <div className='absolute top-[32px] right-[32px]'>
        {canManipulateWithPages && selectedLanguage && (
          <LanguageDropdown
            selectedLanguage={selectedLanguage}
            onClickLanguage={language => setSelectedLanguage(language)}
            allowedLanguages={reportSource.languages ?? undefined}
          />
        )}
      </div>
      <div className='ps-[32px] w-[408px]'>
        <Typography.Title level={5} className='pt-[32px]'>
          <FormattedMessage {...messages.pages} />
        </Typography.Title>
        <PagesList
          reportSource={reportSource}
          language={selectedLanguage}
          activePage={activePage}
          setActivePageId={setActivePageId}
          isNewPageActive={activePageId === null}
        />
        {canManipulateWithPages && (
          <div className='flex justify-end'>
            <Button
              ghost
              type='primary'
              icon={<UntitledIcon icon={ulPlus} />}
              onClick={() => setActivePageId(null)}
              data-onboarding='add-new-page-button'
            >
              <FormattedMessage {...messages.buttonAddNewPage} />
            </Button>
          </div>
        )}
      </div>
      <Divider type='vertical' style={{ height: 'auto', margin: 0 }} />
      <Flex justify='center' style={{ width: '100%', padding: '67px 120px 67px 120px' }}>
        <div className='w-full'>
          {activePage === null && (
            <CreatePageForm
              reportSourceId={reportSource.id}
              language={selectedLanguage}
              onPageCreated={setCreatedNewPageId}
            />
          )}
          {activePage !== null && activePage !== undefined && (
            <EditPageForm
              reportSource={reportSource}
              language={selectedLanguage}
              page={activePage}
            />
          )}
        </div>
      </Flex>
    </Card>
  )
}

export default InstitutionCustomizationPagesView
