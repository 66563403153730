import { Feature, FeatureType, Plan } from '../enums'

export const defaultFeatures: Record<
  Feature,
  Record<Plan, { enabled: boolean; value: `${boolean}` | `${number}`; type: FeatureType }>
> = {
  [Feature.Sso]: {
    [Plan.Unregistered]: { enabled: false, value: 'false', type: FeatureType.Switch },
    [Plan.Free]: { enabled: false, value: 'false', type: FeatureType.Switch },
    [Plan.Basic]: { enabled: false, value: 'false', type: FeatureType.Switch },
    [Plan.Standard]: { enabled: false, value: 'false', type: FeatureType.Switch },
    [Plan.Premium]: { enabled: true, value: 'true', type: FeatureType.Switch },
  },
  [Feature.TeamMembers]: {
    [Plan.Unregistered]: { enabled: true, value: '1', type: FeatureType.Custom },
    [Plan.Free]: { enabled: true, value: '10', type: FeatureType.Custom },
    [Plan.Basic]: { enabled: true, value: '5', type: FeatureType.Custom },
    [Plan.Standard]: { enabled: true, value: '10', type: FeatureType.Custom },
    [Plan.Premium]: { enabled: true, value: '1000', type: FeatureType.Custom },
  },
  [Feature.Webhooks]: {
    [Plan.Unregistered]: { enabled: false, value: 'false', type: FeatureType.Switch },
    [Plan.Free]: { enabled: false, value: 'false', type: FeatureType.Switch },
    [Plan.Basic]: { enabled: true, value: 'true', type: FeatureType.Switch },
    [Plan.Standard]: { enabled: true, value: 'true', type: FeatureType.Switch },
    [Plan.Premium]: { enabled: true, value: 'true', type: FeatureType.Switch },
  },
  [Feature.ReportingForms]: {
    [Plan.Unregistered]: { enabled: true, value: '1', type: FeatureType.Custom },
    [Plan.Free]: { enabled: true, value: '1', type: FeatureType.Custom },
    [Plan.Basic]: { enabled: true, value: '1', type: FeatureType.Custom },
    [Plan.Standard]: { enabled: true, value: '1000', type: FeatureType.Custom },
    [Plan.Premium]: { enabled: true, value: '1000', type: FeatureType.Custom },
  },
  [Feature.Api]: {
    [Plan.Unregistered]: { enabled: false, value: 'false', type: FeatureType.Switch },
    [Plan.Free]: { enabled: false, value: 'false', type: FeatureType.Switch },
    [Plan.Basic]: { enabled: true, value: 'true', type: FeatureType.Switch },
    [Plan.Standard]: { enabled: true, value: 'true', type: FeatureType.Switch },
    [Plan.Premium]: { enabled: true, value: 'true', type: FeatureType.Switch },
  },
  [Feature.Whitelabel]: {
    [Plan.Unregistered]: { enabled: false, value: 'false', type: FeatureType.Switch },
    [Plan.Free]: { enabled: false, value: 'false', type: FeatureType.Switch },
    [Plan.Basic]: { enabled: false, value: 'false', type: FeatureType.Switch },
    [Plan.Standard]: { enabled: false, value: 'false', type: FeatureType.Switch },
    [Plan.Premium]: { enabled: true, value: 'true', type: FeatureType.Switch },
  },
  [Feature.Surveys]: {
    [Plan.Unregistered]: { enabled: false, value: 'false', type: FeatureType.Switch },
    [Plan.Free]: { enabled: false, value: 'false', type: FeatureType.Switch },
    [Plan.Basic]: { enabled: false, value: 'false', type: FeatureType.Switch },
    [Plan.Standard]: { enabled: true, value: 'true', type: FeatureType.Switch },
    [Plan.Premium]: { enabled: true, value: 'true', type: FeatureType.Switch },
  },
  [Feature.Integrations]: {
    [Plan.Unregistered]: { enabled: false, value: 'false', type: FeatureType.Switch },
    [Plan.Free]: { enabled: false, value: 'false', type: FeatureType.Switch },
    [Plan.Basic]: { enabled: true, value: 'true', type: FeatureType.Switch },
    [Plan.Standard]: { enabled: true, value: 'true', type: FeatureType.Switch },
    [Plan.Premium]: { enabled: true, value: 'true', type: FeatureType.Switch },
  },
  [Feature.Investigations]: {
    [Plan.Unregistered]: { enabled: false, value: 'false', type: FeatureType.Switch },
    [Plan.Basic]: { enabled: false, value: 'false', type: FeatureType.Switch },
    [Plan.Free]: { enabled: false, value: 'false', type: FeatureType.Switch },
    [Plan.Premium]: { enabled: true, value: 'true', type: FeatureType.Switch },
    [Plan.Standard]: { enabled: true, value: 'true', type: FeatureType.Switch },
  },
}
