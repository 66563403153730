import type { TableProps } from '@faceup/ui-base'
import { useContext, useState } from 'react'
import { UiContext } from '../UiProvider/UiProvider'

type UsePaginatorProps = {
  rowsPerPage?: number
}

type UsePaginatorReturn = {
  page: number
  setPage: (page: number) => void
  rowsPerPage: number
  getTablePaginator: (props: { totalRows: number }) => TableProps['pagination']
}

export const usePaginator: (props?: UsePaginatorProps) => UsePaginatorReturn = props => {
  const { paginator } = useContext(UiContext)
  const [rowsPerPage, setRowsPerPage] = useState<number>(props?.rowsPerPage ?? 10)
  const [page, setPage] = useState<number>(0)

  return {
    page,
    setPage,
    rowsPerPage,
    getTablePaginator: ({ totalRows }) => ({
      defaultPageSize: rowsPerPage,
      current: page + 1,
      total: totalRows,
      onChange(page, pageSize) {
        setPage(page - 1)
        setRowsPerPage(pageSize)
      },
      showSizeChanger: false,
      showTotal: totalRows => paginator?.totalRows(totalRows) ?? `Total ${totalRows} items`,
    }),
  }
}
