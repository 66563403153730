import type { UntitledIconData } from '@faceup/icons'

export const ucScale: UntitledIconData = {
  prefix: 'custom',
  name: 'ucScale',
  viewBox: '0 0 16 16',
  paths: [
    {
      shape:
        'M6.43848 6.49844V9.59844M9.61885 6.49844V9.59844M3.4348 9.59844H12.5636C12.6937 9.59844 12.7992 9.41935 12.7992 9.19844V6.79844C12.7992 6.57752 12.6937 6.39844 12.5636 6.39844H3.4348C3.30469 6.39844 3.19922 6.57752 3.19922 6.79844V9.19844C3.19922 9.41935 3.30469 9.59844 3.4348 9.59844Z',
      stroke: '#062D46',
      strokeWidth: '1.24999',
      strokeLinecap: 'round',
      strokeLinejoin: 'round',
    },
  ],
  rects: [
    {
      x: '0.625',
      y: '0.625',
      width: '14.75',
      height: '14.75',
      rx: '5.375',
    },
    {
      x: '0.625',
      y: '0.625',
      width: '14.75',
      height: '14.75',
      rx: '5.375',
      stroke: '#CDDDEE',
      strokeWidth: '1.25',
    },
  ],
}
