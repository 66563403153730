/* eslint-disable */
import * as types from './graphql';
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';

/**
 * Map of all GraphQL operations in the project.
 *
 * This map has several performance disadvantages:
 * 1. It is not tree-shakeable, so it will include all operations in the project.
 * 2. It is not minifiable, so the string of a GraphQL query will be multiple times inside the bundle.
 * 3. It does not support dead code elimination, so it will add unused operations.
 *
 * Therefore it is highly recommended to use the babel or swc plugin for production.
 * Learn more about it here: https://the-guild.dev/graphql/codegen/plugins/presets/preset-client#reducing-bundle-size
 */
type Documents = {
    "\n    fragment AbstractMemberModal_member on Member {\n      id\n      isAccountOwner(motherId: $motherId)\n\n      companyIds(motherId: $motherId)\n      keys {\n        permissions(motherId: $motherId) {\n          type\n          enabled\n          additionalData {\n            categoryIds\n          }\n        }\n      }\n    }\n  ": typeof types.AbstractMemberModal_memberFragmentDoc,
    "\n    fragment AbstractMemberModal_institution on Company {\n      id\n      config {\n        id\n        reportCategories {\n          id\n          name\n        }\n      }\n      organizationalStructure {\n        id\n        organizationalUnitName\n      }\n    }\n  ": typeof types.AbstractMemberModal_institutionFragmentDoc,
    "\n    fragment CreateMemberModal_member on Member {\n      id\n      isAccountOwner(motherId: $motherId)\n\n      companies(motherId: $motherId) {\n        id\n      }\n\n      keys {\n        id\n        permissions(motherId: $motherId) {\n          type\n          enabled\n          additionalData {\n            categoryIds\n          }\n        }\n      }\n\n      ...AbstractMemberModal_member\n    }\n  ": typeof types.CreateMemberModal_memberFragmentDoc,
    "\n    fragment CreateMemberModal_institution on Company {\n      id\n      config {\n        id\n        reportCategories {\n          id\n        }\n      }\n      organizationalStructure {\n        id\n        organizationalUnitName\n      }\n\n      ...AbstractMemberModal_institution\n    }\n  ": typeof types.CreateMemberModal_institutionFragmentDoc,
    "\n    mutation CreateMemberMutation($input: CreateMemberInput!, $motherId: UUID!) {\n      createMember(input: $input) {\n        createdMember {\n          id\n          name\n          phone\n          email\n\n          # need up-to-date count for addMember upsell\n          mother(motherId: $motherId) {\n            id\n            countOfMembers\n          }\n        }\n      }\n    }\n  ": typeof types.CreateMemberMutationDocument,
    "\n    fragment EditMemberModal_member on Member {\n      id\n      isAccountOwner(motherId: $motherId)\n\n      ...AbstractMemberModal_member\n    }\n  ": typeof types.EditMemberModal_memberFragmentDoc,
    "\n    fragment EditMemberModal_memberToEdit on Member {\n      id\n      isAccountOwner(motherId: $motherId)\n      name\n      phone\n      companyIds(motherId: $motherId)\n      email\n      mother(motherId: $motherId) {\n        id\n        config {\n          id\n          reportCategories {\n            id\n          }\n        }\n      }\n      keys {\n        permissions(motherId: $motherId) {\n          type\n          enabled\n          additionalData {\n            categoryIds\n          }\n        }\n      }\n    }\n  ": typeof types.EditMemberModal_memberToEditFragmentDoc,
    "\n    fragment EditMemberModal_institution on Company {\n      id\n      ...AbstractMemberModal_institution\n    }\n  ": typeof types.EditMemberModal_institutionFragmentDoc,
    "\n    mutation EditMemberMutation($input: EditMemberInput!, $motherId: UUID!) {\n      editMember(input: $input) {\n        member {\n          id\n          name\n          companyIds(motherId: $motherId)\n        }\n      }\n    }\n  ": typeof types.EditMemberMutationDocument,
};
const documents: Documents = {
    "\n    fragment AbstractMemberModal_member on Member {\n      id\n      isAccountOwner(motherId: $motherId)\n\n      companyIds(motherId: $motherId)\n      keys {\n        permissions(motherId: $motherId) {\n          type\n          enabled\n          additionalData {\n            categoryIds\n          }\n        }\n      }\n    }\n  ": types.AbstractMemberModal_memberFragmentDoc,
    "\n    fragment AbstractMemberModal_institution on Company {\n      id\n      config {\n        id\n        reportCategories {\n          id\n          name\n        }\n      }\n      organizationalStructure {\n        id\n        organizationalUnitName\n      }\n    }\n  ": types.AbstractMemberModal_institutionFragmentDoc,
    "\n    fragment CreateMemberModal_member on Member {\n      id\n      isAccountOwner(motherId: $motherId)\n\n      companies(motherId: $motherId) {\n        id\n      }\n\n      keys {\n        id\n        permissions(motherId: $motherId) {\n          type\n          enabled\n          additionalData {\n            categoryIds\n          }\n        }\n      }\n\n      ...AbstractMemberModal_member\n    }\n  ": types.CreateMemberModal_memberFragmentDoc,
    "\n    fragment CreateMemberModal_institution on Company {\n      id\n      config {\n        id\n        reportCategories {\n          id\n        }\n      }\n      organizationalStructure {\n        id\n        organizationalUnitName\n      }\n\n      ...AbstractMemberModal_institution\n    }\n  ": types.CreateMemberModal_institutionFragmentDoc,
    "\n    mutation CreateMemberMutation($input: CreateMemberInput!, $motherId: UUID!) {\n      createMember(input: $input) {\n        createdMember {\n          id\n          name\n          phone\n          email\n\n          # need up-to-date count for addMember upsell\n          mother(motherId: $motherId) {\n            id\n            countOfMembers\n          }\n        }\n      }\n    }\n  ": types.CreateMemberMutationDocument,
    "\n    fragment EditMemberModal_member on Member {\n      id\n      isAccountOwner(motherId: $motherId)\n\n      ...AbstractMemberModal_member\n    }\n  ": types.EditMemberModal_memberFragmentDoc,
    "\n    fragment EditMemberModal_memberToEdit on Member {\n      id\n      isAccountOwner(motherId: $motherId)\n      name\n      phone\n      companyIds(motherId: $motherId)\n      email\n      mother(motherId: $motherId) {\n        id\n        config {\n          id\n          reportCategories {\n            id\n          }\n        }\n      }\n      keys {\n        permissions(motherId: $motherId) {\n          type\n          enabled\n          additionalData {\n            categoryIds\n          }\n        }\n      }\n    }\n  ": types.EditMemberModal_memberToEditFragmentDoc,
    "\n    fragment EditMemberModal_institution on Company {\n      id\n      ...AbstractMemberModal_institution\n    }\n  ": types.EditMemberModal_institutionFragmentDoc,
    "\n    mutation EditMemberMutation($input: EditMemberInput!, $motherId: UUID!) {\n      editMember(input: $input) {\n        member {\n          id\n          name\n          companyIds(motherId: $motherId)\n        }\n      }\n    }\n  ": types.EditMemberMutationDocument,
};

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 *
 *
 * @example
 * ```ts
 * const query = graphql(`query GetUser($id: ID!) { user(id: $id) { name } }`);
 * ```
 *
 * The query argument is unknown!
 * Please regenerate the types.
 */
export function graphql(source: string): unknown;

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    fragment AbstractMemberModal_member on Member {\n      id\n      isAccountOwner(motherId: $motherId)\n\n      companyIds(motherId: $motherId)\n      keys {\n        permissions(motherId: $motherId) {\n          type\n          enabled\n          additionalData {\n            categoryIds\n          }\n        }\n      }\n    }\n  "): (typeof documents)["\n    fragment AbstractMemberModal_member on Member {\n      id\n      isAccountOwner(motherId: $motherId)\n\n      companyIds(motherId: $motherId)\n      keys {\n        permissions(motherId: $motherId) {\n          type\n          enabled\n          additionalData {\n            categoryIds\n          }\n        }\n      }\n    }\n  "];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    fragment AbstractMemberModal_institution on Company {\n      id\n      config {\n        id\n        reportCategories {\n          id\n          name\n        }\n      }\n      organizationalStructure {\n        id\n        organizationalUnitName\n      }\n    }\n  "): (typeof documents)["\n    fragment AbstractMemberModal_institution on Company {\n      id\n      config {\n        id\n        reportCategories {\n          id\n          name\n        }\n      }\n      organizationalStructure {\n        id\n        organizationalUnitName\n      }\n    }\n  "];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    fragment CreateMemberModal_member on Member {\n      id\n      isAccountOwner(motherId: $motherId)\n\n      companies(motherId: $motherId) {\n        id\n      }\n\n      keys {\n        id\n        permissions(motherId: $motherId) {\n          type\n          enabled\n          additionalData {\n            categoryIds\n          }\n        }\n      }\n\n      ...AbstractMemberModal_member\n    }\n  "): (typeof documents)["\n    fragment CreateMemberModal_member on Member {\n      id\n      isAccountOwner(motherId: $motherId)\n\n      companies(motherId: $motherId) {\n        id\n      }\n\n      keys {\n        id\n        permissions(motherId: $motherId) {\n          type\n          enabled\n          additionalData {\n            categoryIds\n          }\n        }\n      }\n\n      ...AbstractMemberModal_member\n    }\n  "];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    fragment CreateMemberModal_institution on Company {\n      id\n      config {\n        id\n        reportCategories {\n          id\n        }\n      }\n      organizationalStructure {\n        id\n        organizationalUnitName\n      }\n\n      ...AbstractMemberModal_institution\n    }\n  "): (typeof documents)["\n    fragment CreateMemberModal_institution on Company {\n      id\n      config {\n        id\n        reportCategories {\n          id\n        }\n      }\n      organizationalStructure {\n        id\n        organizationalUnitName\n      }\n\n      ...AbstractMemberModal_institution\n    }\n  "];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    mutation CreateMemberMutation($input: CreateMemberInput!, $motherId: UUID!) {\n      createMember(input: $input) {\n        createdMember {\n          id\n          name\n          phone\n          email\n\n          # need up-to-date count for addMember upsell\n          mother(motherId: $motherId) {\n            id\n            countOfMembers\n          }\n        }\n      }\n    }\n  "): (typeof documents)["\n    mutation CreateMemberMutation($input: CreateMemberInput!, $motherId: UUID!) {\n      createMember(input: $input) {\n        createdMember {\n          id\n          name\n          phone\n          email\n\n          # need up-to-date count for addMember upsell\n          mother(motherId: $motherId) {\n            id\n            countOfMembers\n          }\n        }\n      }\n    }\n  "];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    fragment EditMemberModal_member on Member {\n      id\n      isAccountOwner(motherId: $motherId)\n\n      ...AbstractMemberModal_member\n    }\n  "): (typeof documents)["\n    fragment EditMemberModal_member on Member {\n      id\n      isAccountOwner(motherId: $motherId)\n\n      ...AbstractMemberModal_member\n    }\n  "];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    fragment EditMemberModal_memberToEdit on Member {\n      id\n      isAccountOwner(motherId: $motherId)\n      name\n      phone\n      companyIds(motherId: $motherId)\n      email\n      mother(motherId: $motherId) {\n        id\n        config {\n          id\n          reportCategories {\n            id\n          }\n        }\n      }\n      keys {\n        permissions(motherId: $motherId) {\n          type\n          enabled\n          additionalData {\n            categoryIds\n          }\n        }\n      }\n    }\n  "): (typeof documents)["\n    fragment EditMemberModal_memberToEdit on Member {\n      id\n      isAccountOwner(motherId: $motherId)\n      name\n      phone\n      companyIds(motherId: $motherId)\n      email\n      mother(motherId: $motherId) {\n        id\n        config {\n          id\n          reportCategories {\n            id\n          }\n        }\n      }\n      keys {\n        permissions(motherId: $motherId) {\n          type\n          enabled\n          additionalData {\n            categoryIds\n          }\n        }\n      }\n    }\n  "];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    fragment EditMemberModal_institution on Company {\n      id\n      ...AbstractMemberModal_institution\n    }\n  "): (typeof documents)["\n    fragment EditMemberModal_institution on Company {\n      id\n      ...AbstractMemberModal_institution\n    }\n  "];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    mutation EditMemberMutation($input: EditMemberInput!, $motherId: UUID!) {\n      editMember(input: $input) {\n        member {\n          id\n          name\n          companyIds(motherId: $motherId)\n        }\n      }\n    }\n  "): (typeof documents)["\n    mutation EditMemberMutation($input: EditMemberInput!, $motherId: UUID!) {\n      editMember(input: $input) {\n        member {\n          id\n          name\n          companyIds(motherId: $motherId)\n        }\n      }\n    }\n  "];

export function graphql(source: string) {
  return (documents as any)[source] ?? {};
}

export type DocumentType<TDocumentNode extends DocumentNode<any, any>> = TDocumentNode extends DocumentNode<  infer TType,  any>  ? TType  : never;