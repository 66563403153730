import {
  FloatButton as AntFloatButton,
  type FloatButtonGroupProps as AntFloatButtonGroupProps,
  type FloatButtonProps as AntFloatButtonProps,
} from 'antd'

export type FloatButtonProps = AntFloatButtonProps
export type FloatButtonGroupProps = AntFloatButtonGroupProps

const FloatButton = ({ icon, ...props }: FloatButtonProps) => {
  return <AntFloatButton {...props} icon={<span className='anticon'>{icon}</span>} />
}

const FloatButtonGroup = ({ icon, ...props }: FloatButtonGroupProps) => {
  return <AntFloatButton.Group {...props} icon={<span className='anticon'>{icon}</span>} />
}

FloatButtonGroup.displayName = 'FloatButton.Group'
FloatButton.Group = FloatButtonGroup

export { FloatButton }
