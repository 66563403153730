import { Typography } from '@faceup/ui-base'
import { useElementSize } from '@mantine/hooks'
import type { ReactNode } from 'react'
import { MoreInfo } from '../IconWithLogic'
import styles from './assets/FormItem.module.css'

export type FormItemProps = {
  children: ReactNode
  label?: ReactNode
  description?: ReactNode
  hint?: ReactNode
  secondary?: ReactNode
  withAsterisk?: boolean
  errorMessage?: ReactNode
  beforeInput?: ReactNode
  afterInput?: ReactNode
  variant?: 'gray' | 'black'
  'data-test'?: string
}

export const FormItem = ({
  children,
  label,
  hint,
  description,
  secondary,
  withAsterisk,
  errorMessage,
  beforeInput,
  afterInput,
  variant = 'gray',
  'data-test': dataTest,
}: FormItemProps) => {
  const { ref: secondarySizeRef, width: secondaryWidth } = useElementSize()

  const isSomethingInLabelRow = label || hint || secondary

  if (!isSomethingInLabelRow) {
    return (
      <div className={styles.formItem} data-test={dataTest}>
        {children}
        {errorMessage && <Typography.Text type='danger'>{errorMessage}</Typography.Text>}
      </div>
    )
  }

  return (
    <div className={styles.formItem} data-test={dataTest}>
      {secondary && (
        <div className={styles.formItemSecondary} ref={secondarySizeRef}>
          {secondary}
        </div>
      )}
      {/* biome-ignore lint/a11y/noLabelWithoutControl: */}
      <label>
        <div className={styles.formItemInnerLabel} style={{ gap: isSomethingInLabelRow ? 4 : 0 }}>
          <div style={{ width: `calc(100% - ${secondaryWidth}px)` }}>
            {variant === 'gray' ? (
              <Typography.Text strong className={styles.formItemText}>
                <span style={{ color: errorMessage ? '#F54070' : 'unset' }}>{label}</span>{' '}
                {withAsterisk && (
                  <Typography.Text type='danger' style={{ fontSize: '12px' }}>
                    *
                  </Typography.Text>
                )}{' '}
                {hint && <MoreInfo title={hint} />}{' '}
              </Typography.Text>
            ) : (
              <Typography.Title level={4}>
                <span style={{ color: errorMessage ? '#F54070' : 'unset' }}>{label}</span>{' '}
                {withAsterisk && <span style={{ color: '#F54070' }}>*</span>}{' '}
                {hint && <MoreInfo title={hint} />}{' '}
              </Typography.Title>
            )}
          </div>
          {description && <Typography.Text type='secondary'>{description}</Typography.Text>}
          <div className={styles.formItemWrapper}>
            {beforeInput && <div>{beforeInput}</div>}
            <div className={styles.formItemInnerWrapper}>{children}</div>
            {afterInput && <div>{afterInput}</div>}
          </div>
        </div>
      </label>
      {errorMessage && <Typography.Text type='danger'>{errorMessage}</Typography.Text>}
    </div>
  )
}
