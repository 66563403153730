import { Col, Row } from '@faceup/ui-base'
import type { ReactNode } from 'react'

type ButtonGroupProps = {
  children: ReactNode
}

export const ButtonGroup = (props: ButtonGroupProps) => {
  const { children } = props
  return (
    <Row justify='end'>
      <Col className='flex gap-2'>{children}</Col>
    </Row>
  )
}
