import { useLanguageName } from '@faceup/localization'
import { type RefSelectProps, Select, type SelectProps } from '@faceup/ui-base'
import type { Language } from '@faceup/utils'
import { forwardRef, useMemo } from 'react'

export type LanguageSelectProps = SelectProps<Language> & {
  // Can´t be omitted due to some TS problem
  options?: never
  allowedLanguages?: Language[]
}

export const LanguageSelect = forwardRef<RefSelectProps, LanguageSelectProps>(
  ({ allowedLanguages, allowClear, ...props }, ref) => {
    const { getLanguageList } = useLanguageName()

    const languages = getLanguageList(allowedLanguages)

    const options = useMemo(
      () =>
        languages.map(({ language, name }) => ({
          label: name,
          value: language,
        })),
      [languages]
    )
    return <Select ref={ref} {...props} options={options} />
  }
)
