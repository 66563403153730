import { AnswerCard } from '@faceup/ui'
import { Typography } from '@faceup/ui-base'
import type { ReactNode } from 'react'
import PieChartWrapper from '../../../Components/Statistics/Charts/PieChart'
import { FormattedMessage, defineMessages } from '../../../TypedIntl'
import { type FragmentType, getFragmentData, graphql } from '../../../__generated__'
import { useColors } from '../../../hooks/charts/useColors'

const messages = defineMessages({
  xAnswers: 'Administration.surveys.submissions.statistics.xAnswers',
})

type SurveyPieChartProps = {
  label: ReactNode
  answersStatistics: FragmentType<typeof fragments.AnswersPieChart_submissionStatistics>
}

export const fragments = {
  AnswersPieChart_submissionStatistics: graphql(`
    fragment AnswersPieChart_submissionStatistics on AnswersStatistics {
      answersCount
      statistics {
        label
        count
      }
    }
  `),
}

export const AnswersPieChart = ({
  label,
  answersStatistics: _answersStatistics,
}: SurveyPieChartProps) => {
  const answersStatistics = getFragmentData(
    fragments.AnswersPieChart_submissionStatistics,
    _answersStatistics
  )
  const { answersCount, statistics } = answersStatistics
  const colors = useColors(statistics.reduce((acc, { label }) => ({ ...acc, [label]: 0 }), {}))

  const data = answersStatistics.statistics.map(({ label, count }) => {
    return {
      name: label,
      value: count,
      color: colors[label] ?? '',
    }
  })

  return (
    <AnswerCard>
      <div className='p-[24px]'>
        <AnswerCard.Title title={label} />
        <Typography.Text size='sm' type='secondary' strong>
          <FormattedMessage {...messages.xAnswers} values={{ count: answersCount }} />
        </Typography.Text>
        <PieChartWrapper data={data} legendPosition='right' height={250} />
      </div>
    </AnswerCard>
  )
}
