import { UntitledIcon } from '@faceup/icons'
import { ulPlus } from '@faceup/icons/ulPlus'
import { ulTrash03 } from '@faceup/icons/ulTrash03'
import { Upload } from '@faceup/ui-base'
import { ALLOWED_IMAGE_MIME_TYPE_EXTENSION_MAP } from '@faceup/utils'
import { useContext } from 'react'
import { UiContext } from '../UiProvider/UiProvider'
import { useThemeColors } from '../hooks'

type DropzoneImageProps = {
  setLogo: (file: File) => void
  onRemove: () => void
  shownLogo: string | null
  loading: boolean
  width?: string
  height?: string
  minHeight?: string
}

export const DropzoneImage = ({
  setLogo,
  shownLogo,
  loading,
  onRemove,
  width,
  height,
  minHeight,
}: DropzoneImageProps) => {
  const { dropzoneImage } = useContext(UiContext)
  const { getColorFromTheme } = useThemeColors()

  return (
    <div className='relative' style={{ width, height, minHeight }}>
      <Upload
        accept={Object.keys(ALLOWED_IMAGE_MIME_TYPE_EXTENSION_MAP).join(',')}
        maxCount={1}
        listType='picture-card'
        fileList={
          shownLogo ? [{ uid: 'uploaded', name: 'image.png', status: 'done', url: shownLogo }] : []
        }
        onRemove={onRemove}
        onChange={info => {
          if (info.file.originFileObj) {
            setLogo(info.file.originFileObj)
          }
        }}
        showUploadList={{
          removeIcon: (
            <UntitledIcon
              icon={ulTrash03}
              color={getColorFromTheme('white')}
              style={{ cursor: 'pointer' }}
            />
          ),
          showPreviewIcon: false,
        }}
      >
        {!shownLogo && (
          <button
            style={{
              border: 0,
              background: 'none',
              color: getColorFromTheme('primary.100'),
              cursor: 'pointer',
            }}
            type='button'
            disabled={loading}
          >
            <UntitledIcon icon={ulPlus} size={20} />
            <div className='font-semibold'>{dropzoneImage?.upload ?? 'Upload'}</div>
          </button>
        )}
      </Upload>
    </div>
  )
}
