import { type AvailableCurrency, Country, Currency, HotlineLanguage } from './localization'
import type { CaseRating } from './validationConfig'

/**
 * Intentionally used non-obvious name to conceal the intention from user.
 */
export const CHARGEBEE_REDIRECT_PARTNER_PARAM_NAME = 'isPartner'
export const CHARGEBEE_UNLIMITED_MEMBERS = 99999999
export const DEFAULT_COUNTRY = Country.Us
export const DEFAULT_CURRENCY = Currency.USD
export const VONAGE_BILLING_CURRENCY = Currency.EUR
export const WEBHOOK_TIMEOUT = 1_000
export const WEBHOOKS_LIMIT = 100
// chosen arbitrarily, no special reason for this particular timezone
export const DEFAULT_TIMEZONE = 'Europe/London'

export const MAX_INT_32 = 2147483647 // 2 ** 31 - 1

export const homePageOrder = 0

export const HOTLINE_THRESHOLDS: Record<'first' | 'second', Record<AvailableCurrency, number>> = {
  first: {
    [Currency.USD]: 5 * 100,
    [Currency.EUR]: 5 * 100,
    [Currency.GBP]: 5 * 100,
    [Currency.CZK]: 50 * 100,
  },
  second: {
    [Currency.USD]: 1 * 100,
    [Currency.EUR]: 1 * 100,
    [Currency.GBP]: 1 * 100,
    [Currency.CZK]: 25 * 100,
  },
}

export const errorMessageRecaptchaScoreTooLow = 'Recaptcha score low'
export const errorMessageOpaqueShortIdAlreadyTaken = 'Opaque short id already taken'

export const regionHeaderName = 'X-Region'
export const versionHeaderName = 'X-Version'

// Deadline duration in days
export const DEADLINE_ALLOWED_INTERVALS_DAYS = [5, 7, 14, 30, 60, 90, 120] as const
export type DeadlineDuration = (typeof DEADLINE_ALLOWED_INTERVALS_DAYS)[number]

export const DEADLINE_INTERVAL_DEFAULT_DAYS: DeadlineDuration = 30

// Delete reports duration in months
export const DELETE_REPORTS_ALLOWED_INTERVALS_MONTHS = [3, 6, 9, 12, 24, 36, 48, 60] as const
export type DeleteReportsDuration = (typeof DELETE_REPORTS_ALLOWED_INTERVALS_MONTHS)[number]

export const DELETE_REPORTS_INTERVAL_DEFAULT_MONTHS: DeleteReportsDuration = 12

// FairWhistle (`FairData Professionals a.s.`) ID on production `PartnerInstitution:2`
export const FAIRWHISTLE_ID = 'UGFydG5lckluc3RpdHV0aW9uOjI='

export const INTERNAL_WEBHOOK_SOURCE = 'faceup'

export const ALLOWED_HOTLINE_LANGUAGES = Object.keys(HotlineLanguage) as HotlineLanguage[]

export const RATING_EMOJIS: Record<CaseRating, string> = {
  1: '😖',
  2: '😔',
  3: '😐',
  4: '😊',
  5: '🤩',
}

export const INVALID_LOGIN_CREDENTIALS_ERROR = 'Invalid login credentials'

// Null UUID (`00000000-0000-7000-a000-000000000000`) is valid and cannot occur randomly. More info: https://buildkite.com/resources/blog/goodbye-integers-hello-uuids
export const NULL_UUID = '00000000-0000-7000-a000-000000000000'

export const TrialLength = {
  Default: 14,
  Extended: 30,
} as const

export type TrialLength = (typeof TrialLength)[keyof typeof TrialLength]

export const SURVEY_SOURCE_PARAM_NAME = 's'

export const investigationSteps = [1, 2, 3] as const
export type InvestigationStep = (typeof investigationSteps)[number]
