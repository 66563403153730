import { FormItem } from '@faceup/ui'
import {
  MultiSelect as AntMultiSelect,
  type MultiSelectProps as AntMultiSelectProps,
} from '@faceup/ui-base'
import type { ReactNode } from 'react'
import type { FieldValues, UseControllerProps } from 'react-hook-form'
import { useCustomController } from '../hooks'
import type { FormItemWrapperProps } from '../typings'

export type MultiSelectProps<
  T extends FieldValues,
  V extends string = string,
  L extends ReactNode = ReactNode,
> = UseControllerProps<T> &
  Omit<AntMultiSelectProps<V, L> & FormItemWrapperProps, 'value' | 'defaultValue'>

export const MultiSelect = <T extends FieldValues, V extends string>(
  props: MultiSelectProps<T, V>
) => {
  const {
    formItemProps,
    inputProps: { onChange: fieldOnChange, value, ...inputProps },
  } = useCustomController(props)
  return (
    <FormItem {...formItemProps}>
      <AntMultiSelect
        {...inputProps}
        value={value ?? []}
        onChange={e => {
          fieldOnChange(e)
          props.onChange?.(e as V[])
        }}
      />
    </FormItem>
  )
}
