import { Switch, Typography } from '@faceup/ui-base'
import type { ReactNode } from 'react'
import './switch-box.css'

export type SwitchBoxProps = {
  children?: ReactNode
}

const SwitchBoxGroup = ({ children }: SwitchBoxProps) => {
  return (
    <div className='ui-switch-box group border-[#CDDDEE] border-1 rounded-[12px] transition-all'>
      {children}
    </div>
  )
}

type SwitchBoxItemProps = {
  checked?: boolean
  onChange: (checked: boolean) => void
  disabled?: boolean
  label: ReactNode
  secondaryText: ReactNode
  children?: ReactNode
  loading?: boolean
}

const SwitchBoxItem = ({
  checked,
  loading,
  onChange,
  label,
  secondaryText,
  disabled,
  children,
}: SwitchBoxItemProps) => {
  return (
    <div
      className={`p-[16px] border-b-1 border-[#E6ECF9] last:border-b-0 ${disabled ? 'cursor-not-allowed' : 'cursor-pointer'}`}
      onClick={() => !disabled && onChange(!checked)}
    >
      <div className='flex flex-col gap-1'>
        <div className='flex items-center gap-3'>
          <span onClick={e => e.stopPropagation()}>
            <Switch
              checked={checked}
              loading={loading}
              onChange={checked => onChange(checked)}
              disabled={disabled}
              size='default'
            />
          </span>
          <Typography.Text strong>{label}</Typography.Text>
        </div>
        <div className='ps-[48px] flex flex-col gap-1'>
          {secondaryText && <Typography.Text type='secondary'>{secondaryText}</Typography.Text>}
          {children && <div onClick={e => e.stopPropagation()}>{children}</div>}
        </div>
      </div>
    </div>
  )
}

export const SwitchBox = ({ checked = false, ...props }: SwitchBoxItemProps) => {
  return (
    <SwitchBoxGroup>
      <SwitchBoxItem {...props} checked={checked} />
    </SwitchBoxGroup>
  )
}

SwitchBox.Group = SwitchBoxGroup
SwitchBox.Item = SwitchBoxItem
