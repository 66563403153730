import PDFDownloader from '../Components/PDFGenerators/PDFDownloader'
import PDFRecoveryKey, { type _messages } from '../Pages/RecoveryKey/PDFRecoveryKey'
import { getTheme } from '../locales'

const theme = getTheme()

const toDataURL = async (url: string) => {
  const response = await fetch(url)
  const blob = await response.blob()
  return URL.createObjectURL(blob)
}

export const downloadImage = async (url: string, fileName: string) => {
  const a = document.createElement('a')
  a.href = await toDataURL(url)
  a.download = fileName
  document.body.appendChild(a)
  a.click()
  document.body.removeChild(a)
}

export const downloadPDFRecoveryFile = (
  recoveryKey: string,
  motherInstitutionName: string,
  translations: Record<keyof typeof _messages, string>
) => {
  PDFDownloader(
    `${theme} - ${translations.recoveryKeyTitle}`,
    <PDFRecoveryKey
      recoveryKey={recoveryKey}
      name={motherInstitutionName}
      createdAt={new Date()}
      messages={translations}
    />
  )
}
