import styled from '@emotion/styled'
import { UntitledIcon } from '@faceup/icons'
import { usAttachment02 } from '@faceup/icons/usAttachment02'
import { Button, Space, Tooltip, Typography, useUiBaseConfig } from '@faceup/ui-base'
import { ucShieldSuccess, ucShieldWarning } from '../customIcons'

const { Text } = Typography

export type Attachment = {
  file: File
  compareKey: string
  loading: boolean
  processed: boolean
}

export type AlreadyUploadedAttachment = {
  id: string
  name: string
}

export type EditingAttachmentsProps = {
  alreadyUploadedAttachments?: AlreadyUploadedAttachment[]
  markAttachmentForDeletion?: (attachmentId: string) => void
}

export type NotEditingAttachmentsProps = {
  alreadyUploadedAttachments?: never
  markAttachmentForDeletion?: never
}

export type AttachmentUploadListProps = {
  translations: {
    metadataRemoved: string
    metadataUnableToRemove: string
    delete: string
  }
  attachments: Attachment[]
  setAttachments: (attachments: Attachment[]) => void
} & (EditingAttachmentsProps | NotEditingAttachmentsProps)

const AttachmentUploadList = ({
  alreadyUploadedAttachments = [],
  markAttachmentForDeletion = () => undefined,
  attachments,
  setAttachments,
  translations,
}: AttachmentUploadListProps) => {
  const { colors } = useUiBaseConfig()
  const deleteAttachment = (index: number) => {
    if (attachments.every(a => !a.loading)) {
      setAttachments(attachments.filter((_, i) => i !== index))
    }
  }

  if (!attachments.length && !alreadyUploadedAttachments.length) {
    return null
  }

  return (
    <AttachmentsWrapper>
      {alreadyUploadedAttachments.map(attachment => (
        <AttachmentWrap key={attachment.id}>
          <Tooltip placement='top' title={translations.metadataRemoved}>
            <LimitedSpace>
              <CenteredIcon icon={usAttachment02} />
              <FileName className='sensitive' type='success'>
                {attachment.name}
              </FileName>
              <CenteredIcon icon={ucShieldSuccess} color={colors.green5} />
            </LimitedSpace>
          </Tooltip>
          <Button
            size='small'
            type='link'
            danger
            onClick={() => markAttachmentForDeletion(attachment.id)}
          >
            {translations.delete}
          </Button>
        </AttachmentWrap>
      ))}
      {attachments.map((attachment, index) => (
        <AttachmentWrap key={attachment.compareKey}>
          <Tooltip
            placement='top'
            title={
              attachment.processed
                ? translations.metadataRemoved
                : translations.metadataUnableToRemove
            }
            {...(attachment.loading && { visible: false })}
          >
            <LimitedSpace>
              <CenteredIcon icon={usAttachment02} />
              <FileName className='sensitive' type={attachment.processed ? 'success' : 'warning'}>
                {attachment.file.name}
              </FileName>
              <CenteredIcon
                icon={attachment.processed ? ucShieldSuccess : ucShieldWarning}
                color={attachment.processed ? colors.green5 : colors.orange5}
              />
            </LimitedSpace>
          </Tooltip>
          <Button
            type='link'
            danger
            size='small'
            loading={attachment.loading}
            onClick={() => deleteAttachment(index)}
          >
            {translations.delete}
          </Button>
        </AttachmentWrap>
      ))}
    </AttachmentsWrapper>
  )
}

const AttachmentsWrapper = styled.div`
  border: 1px solid #e5e7eb;
  border-radius: 6px;

  > div {
    border-bottom: 1px solid #e5e7eb;

    :nth-last-of-type(1) {
      border-bottom: none;
    }
  }
`

const LimitedSpace = styled(Space)`
  max-width: calc(100% - 150px);

  .ant-space-item {
    max-width: 100%;
  }
`

const FileName = styled(Text)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 600;
  display: block;
`

const CenteredIcon = styled(UntitledIcon)`
  display: flex;
  font-size: 20px;
  color: #688699;
`

const AttachmentWrap = styled.div`
  padding: 7px 7px 7px 18px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export default AttachmentUploadList
