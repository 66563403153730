import type { ModalLocale } from 'antd/lib/modal'
import type { ReactNode } from 'react'
import { Button } from './Button'
import { Modal, type ModalProps } from './Modal'
import { useUiBaseConfig } from './UiBaseProvider'

export type ModalAlertProps = {
  confirmText?: ReactNode | string
  onConfirm: () => void
  confirmState?: {
    loading?: boolean
    disabled?: boolean
    danger?: boolean
  }
  afterClose?: () => void
} & Omit<ModalProps, 'footer'>

export const ModalAlert = ({
  children,
  afterClose,
  confirmText,
  onConfirm,
  confirmState,
  ...props
}: ModalAlertProps) => {
  const { locale } = useUiBaseConfig()
  const modalContext: ModalLocale = locale?.Modal ?? {
    cancelText: 'Cancel',
    okText: 'OK',
    justOkText: 'OK',
  }

  const dataTest = props['data-test']
  const dataOnboarding = props['data-onboarding']

  return (
    <Modal
      {...props}
      footer={
        <Button
          type='primary'
          onClick={() => {
            onConfirm()
            afterClose?.()
          }}
          loading={confirmState?.loading}
          disabled={confirmState?.disabled}
          danger={confirmState?.danger}
          data-test={dataTest ? `${dataTest}-confirm-button` : undefined}
          data-onboarding={dataOnboarding ? `${dataOnboarding}-confirm-button` : undefined}
        >
          {confirmText ?? modalContext.okText}
        </Button>
      }
    >
      {children}
    </Modal>
  )
}
