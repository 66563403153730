import { UntitledIcon } from '@faceup/icons'
import { ulArrowRight } from '@faceup/icons/ulArrowRight'
import { ulCalendar } from '@faceup/icons/ulCalendar'
import { ulChevronLeft } from '@faceup/icons/ulChevronLeft'
import { ulChevronLeftDouble } from '@faceup/icons/ulChevronLeftDouble'
import { ulChevronRight } from '@faceup/icons/ulChevronRight'
import { ulChevronRightDouble } from '@faceup/icons/ulChevronRightDouble'
import { DatePicker as AntDatePicker } from 'antd'
import type { Moment } from 'moment-timezone'
import momentGenerateConfig from 'rc-picker/lib/generate/moment'
import { type ComponentProps, forwardRef } from 'react'

const _DatePicker = AntDatePicker.generatePicker<Moment>(momentGenerateConfig)

export type BaseDatePickerProps = Omit<
  ComponentProps<typeof _DatePicker<Moment>>,
  'prevIcon' | 'nextIcon' | 'superPrevIcon' | 'superNextIcon'
>

// biome-ignore lint/suspicious/noExplicitAny:
export const BaseDatePicker = forwardRef<any, BaseDatePickerProps>((props, ref) => (
  <_DatePicker
    ref={ref}
    {...props}
    prevIcon={<UntitledIcon icon={ulChevronLeft} />}
    nextIcon={<UntitledIcon icon={ulChevronRight} />}
    superPrevIcon={<UntitledIcon icon={ulChevronLeftDouble} />}
    superNextIcon={<UntitledIcon icon={ulChevronRightDouble} />}
    suffixIcon={props?.suffixIcon ?? <UntitledIcon icon={ulCalendar} />}
  />
))

export type BaseDateRangePickerProps = Omit<
  ComponentProps<typeof _DatePicker.RangePicker>,
  'prevIcon' | 'nextIcon' | 'superPrevIcon' | 'superNextIcon' | 'separator'
>

// biome-ignore lint/suspicious/noExplicitAny:
export const BaseDateRangePicker = forwardRef<any, BaseDateRangePickerProps>((props, ref) => (
  <_DatePicker.RangePicker
    ref={ref}
    {...props}
    prevIcon={<UntitledIcon icon={ulChevronLeft} />}
    nextIcon={<UntitledIcon icon={ulChevronRight} />}
    superPrevIcon={<UntitledIcon icon={ulChevronLeftDouble} />}
    superNextIcon={<UntitledIcon icon={ulChevronRightDouble} />}
    separator={
      <div className='ant-picker-separator'>
        <UntitledIcon icon={ulArrowRight} />
      </div>
    }
    suffixIcon={props?.suffixIcon ?? <UntitledIcon icon={ulCalendar} />}
  />
))
