import { createReportWithoutVictim } from '@faceup/crypto'
import type { Attachment } from '@faceup/ui'
import { type FragmentType, graphql } from '../__generated__'

const fragments = {
  usePrepareReportPayloadFragments_company: graphql(`
    fragment usePrepareReportPayloadFragments_company on Company {
      id
      recipients(caseType: Report) {
        id
        keys {
          id
          publicKey
        }
      }
    }
  `),
  usePrepareReportPayloadFragments_system: graphql(`
    fragment usePrepareReportPayloadFragments_system on System {
      id
      publicKey
    }
  `),
}

export const usePrepareReportPayload =
  () =>
  async (
    company: NonNullable<
      FragmentType<typeof fragments.usePrepareReportPayloadFragments_company>[' $fragmentRefs']
    >['usePrepareReportPayloadFragments_companyFragment'],
    system: NonNullable<
      FragmentType<typeof fragments.usePrepareReportPayloadFragments_system>[' $fragmentRefs']
    >['usePrepareReportPayloadFragments_systemFragment'],
    isE2EE: boolean,
    values: {
      victimName: string | null
      moreInfo: string
    },
    attachments: Attachment[]
  ) => {
    const systemPublicKey = system.publicKey

    const recipientsKeys =
      company.recipients?.map(recipient => ({
        id: recipient?.id ?? '',
        key: recipient?.keys?.publicKey ?? '',
      })) ?? []

    return await createReportWithoutVictim(
      {
        victimName: values.victimName,
        moreInfo: values.moreInfo.trim(),
        attachments: attachments.map(({ file }) => file),
      },
      recipientsKeys,
      undefined,
      isE2EE ? undefined : systemPublicKey
    )
  }
