import { useMutation } from '@apollo/client'
import { sharedMessages } from '@faceup/localization'
import { transitionDuration } from '@faceup/ui'
import { Flex, Typography, notification, useMessage, useUiBaseConfig } from '@faceup/ui-base'
import { type CaseRating, CaseStatus, RATING_EMOJIS } from '@faceup/utils'
import moment from 'moment-timezone'
import { useEffect, useRef, useState } from 'react'
import { useIntl } from 'react-intl'
import { FormattedMessage, defineMessages } from '../../TypedIntl'
import { type FragmentType, getFragmentData, graphql } from '../../__generated__'
import { NotRedactableWrapper } from '../Redaction'

const mutations = {
  UpdateReportRating: graphql(`
    mutation UpdateReportRating($input: UpdateReportRatingInput!) {
      updateReportRating(input: $input) {
        case {
          id
          rating
          ratedAt
        }
      }
    }
  `),
}

const fragments = {
  ReportRating_case: graphql(`
    fragment ReportRating_case on CaseInterface {
      id
      status
      victim {
        id
      }
      rating
      ratedAt
    }
  `),
}

const messages = defineMessages({
  ratingTitle: 'Report.rating.title',
  ratingSaved: 'Report.rating.saved',
  victimHasRated: 'Report.rating.victimHasRated',
  iHaveRated: 'Report.rating.iHaveRated',
})

const ratingMessages = defineMessages<`${CaseRating}`>({
  1: 'Shared.rating.1',
  2: 'Shared.rating.2',
  3: 'Shared.rating.3',
  4: 'Shared.rating.4',
  5: 'Shared.rating.5',
})

type ReportRatingProps = {
  report: FragmentType<typeof fragments.ReportRating_case>
  userId: string
  onRatingChange?: (rating: CaseRating) => void
}

export const ReportRating = ({ report: _report, userId, onRatingChange }: ReportRatingProps) => {
  const report = getFragmentData(fragments.ReportRating_case, _report)
  const { token } = useUiBaseConfig()
  const { formatMessage } = useIntl()
  const message = useMessage()

  const [updateRating, { loading }] = useMutation(mutations.UpdateReportRating, {
    onError: error => {
      console.error(error)
      notification.error({
        message: formatMessage(sharedMessages.apiError),
        description: error.message,
      })
    },
    onCompleted: () => {
      message.success(formatMessage(messages.ratingSaved))
    },
  })

  const isUserVictim = Boolean(report?.victim?.id) && userId === report?.victim?.id
  const hasRating = Boolean(report?.rating)
  const reportRating = report?.rating
  const isReportClosed = report?.status === CaseStatus.Closed

  const textRefs = useRef<{ [key in CaseRating]?: HTMLSpanElement | null }>({})
  const [maxWidth, setMaxWidth] = useState<number>(0)

  useEffect(() => {
    if (textRefs.current) {
      const widths = Object.values(textRefs.current).map(
        ref => ref?.getBoundingClientRect().width || 0
      )
      setMaxWidth(Math.max(...widths) + 4)
    }
  }, [])

  if (!isReportClosed) {
    return null
  }

  if (isUserVictim && !hasRating) {
    return (
      <div
        style={{
          transition: `opacity ${transitionDuration} ease`,
          fontSize: '28px',
        }}
      >
        <Flex
          vertical
          justify='center'
          align='center'
          gap='24px'
          style={{
            border: `1px solid ${token.colorBorderSecondary}`,
            borderRadius: token.borderRadius,
            padding: '24px',
          }}
        >
          <Typography.Text strong>
            <FormattedMessage {...messages.ratingTitle} />
          </Typography.Text>
          <Flex justify='center' align='center'>
            {Object.entries(RATING_EMOJIS).map(([rating, emoji]) => (
              <Flex
                key={rating}
                vertical
                justify='center'
                align='center'
                className='child-hover'
                style={{
                  width: maxWidth ? `${maxWidth}px` : 'auto',
                  minWidth: '64px',
                  borderRight: `1px ${token.colorBorderSecondary}`,
                  cursor: 'pointer',
                  ...(loading && { pointerEvents: 'none', opacity: 0.5, cursor: undefined }),
                }}
                onClick={() => {
                  updateRating({
                    variables: {
                      input: {
                        rating: Number(rating) as CaseRating,
                      },
                    },
                  })
                  onRatingChange?.(Number(rating) as CaseRating)
                }}
              >
                <span className='hover-target' style={{ fontSize: '28px' }}>
                  {emoji}
                </span>
                <span ref={el => (textRefs.current[Number(rating) as CaseRating] = el)}>
                  <Typography.Text>
                    {formatMessage(ratingMessages[rating as `${CaseRating}`])}
                  </Typography.Text>
                </span>
              </Flex>
            ))}
          </Flex>
        </Flex>
      </div>
    )
  }

  if (reportRating) {
    return (
      <NotRedactableWrapper>
        <Flex align='center' justify='center' gap='3px' vertical>
          <div>
            <span style={{ fontSize: '20px', marginRight: '5px' }}>
              {RATING_EMOJIS[reportRating]}
            </span>
            <span>
              {isUserVictim ? (
                <FormattedMessage {...messages.iHaveRated} />
              ) : (
                <FormattedMessage {...messages.victimHasRated} />
              )}
            </span>
            {'  '}
            <span style={{ fontWeight: 'bold' }}>
              {formatMessage(ratingMessages[reportRating])}
            </span>
          </div>

          <Typography.Text type='secondary' size='sm'>
            {moment(report.ratedAt).format('LLL')}
          </Typography.Text>
        </Flex>
      </NotRedactableWrapper>
    )
  }

  return null
}
