import { FormItem } from '@faceup/ui'
import { Select as AntSelect, type SelectProps as AntSelectProps } from '@faceup/ui-base'
import type { ReactNode } from 'react'
import type { FieldValues, UseControllerProps } from 'react-hook-form'
import { useCustomController } from '../hooks'
import type { FormItemWrapperProps } from '../typings'

export type SelectProps<
  T extends FieldValues,
  V extends string = string,
  L extends ReactNode = ReactNode,
> = {
  onChange?: (value: V) => void
} & UseControllerProps<T> &
  Omit<AntSelectProps<V, L> & FormItemWrapperProps, 'value' | 'defaultValue' | 'onChange'>

export const Select = <T extends FieldValues, V extends string>(props: SelectProps<T, V>) => {
  const {
    formItemProps,
    inputProps: { onChange: fieldOnChange, value, ...inputProps },
  } = useCustomController(props)

  return (
    <FormItem {...formItemProps}>
      <AntSelect
        {...inputProps}
        styles={{
          dropdown: {
            zIndex: '1100!important',
          },
        }}
        value={value ?? null}
        onChange={value => {
          fieldOnChange(value ?? null)
          props.onChange?.(value as V)
        }}
      />
    </FormItem>
  )
}
