import { useMutation } from '@apollo/client'
import { ModalForm, TextInput, useForm, yup } from '@faceup/form'
import { useMotherId } from '@faceup/institution'
import { notification } from '@faceup/ui-base'
import { SHORT_STRING_MAX_LENGTH, STRING_MIN_LENGTH } from '@faceup/utils'
import { sharedMessages } from '../../Shared/translations'
import { FormattedMessage, defineMessages, useIntl } from '../../TypedIntl'
import { graphql } from '../../__generated__'

const messages = defineMessages({
  editReportSourceTitle: 'Administration.customization.overview.settings.title',
  invalidName: 'Administration.customization.overview.settings.invalidName',
})

const mutations = {
  EditChannelName: graphql(`
    mutation EditChannelNameMutation($input: EditChannelNameInput!) {
      editChannelName(input: $input) {
        channel {
          id
          name
        }
      }
    }
  `),
}

export const EditReportSourceNameModal = ({
  editModalOpen,
  setEditModalOpen,
  name,
  id,
}: {
  editModalOpen: boolean
  setEditModalOpen: React.Dispatch<React.SetStateAction<boolean>>
  name: string
  id: string
}) => {
  const { getMotherId } = useMotherId()
  const { formatMessage } = useIntl()

  const [editChannelName] = useMutation(mutations.EditChannelName, {
    onError: error => {
      console.error(error)
      notification.error({
        message: formatMessage(sharedMessages.apiError),
        description: error.message,
      })
    },
    onCompleted: () => {
      setEditModalOpen(false)
    },
    refetchQueries: ['InstitutionCustomizationFormListQuery'],
  })
  const schema = yup.object().shape({
    name: yup.string().min(STRING_MIN_LENGTH).max(SHORT_STRING_MAX_LENGTH).required(),
  })
  const form = useForm({
    schema,
    values: {
      name,
    },
    afterSubmit: 'persistValues',
  })
  return (
    <ModalForm
      open={editModalOpen}
      onClose={() => setEditModalOpen(false)}
      form={form}
      submitButtonText='save'
      onSubmit={async values => {
        const result = await editChannelName({
          variables: {
            input: {
              motherId: getMotherId(),
              channelId: id,
              name: values.name,
            },
          },
        })
        if (result.errors) {
          return false
        }
        return true
      }}
    >
      <TextInput
        control={form.control}
        name='name'
        label={<FormattedMessage {...messages.editReportSourceTitle} />}
      />
    </ModalForm>
  )
}
