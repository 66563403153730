import styled from '@emotion/styled'
import { Spin } from '@faceup/ui-base'
import type { ComponentProps } from 'react'

type Size = 'lg' | 'sm'
const mapSize: Record<Size, ComponentProps<typeof Spin>['size']> = {
  lg: 'large',
  sm: 'small',
}

const LoadingSpinner = ({ size = 'lg' }: { size?: 'lg' | 'sm' }) => (
  <SpinnerWrapper>
    <Spin size={mapSize[size]} />
  </SpinnerWrapper>
)

export const SpinnerWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

export default LoadingSpinner
