import { useMutation } from '@apollo/client'
import { readComment } from '@faceup/crypto'
import { UntitledIcon } from '@faceup/icons'
import { ulDotsVertical } from '@faceup/icons/ulDotsVertical'
import { ulEye } from '@faceup/icons/ulEye'
import { ulEyeOff } from '@faceup/icons/ulEyeOff'
import { ulSend01 } from '@faceup/icons/ulSend01'
import { ulTrash01 } from '@faceup/icons/ulTrash01'
import { useMotherId } from '@faceup/institution'
import { Link } from '@faceup/router'
import {
  Button,
  Dropdown,
  Flex,
  Modal,
  Table,
  type TableColumns,
  Tooltip,
  Typography,
  notification,
  useModal,
} from '@faceup/ui-base'
import { FormItemType, type SurveySource } from '@faceup/utils'
import type { ResultOf } from '@graphql-typed-document-node/core'
import moment from 'moment-timezone'
import { type Dispatch, useEffect, useMemo, useState } from 'react'
import { sharedMessages } from '../../../Shared/translations'
import { FormattedMessage, defineMessages, useIntl } from '../../../TypedIntl'
import { type FragmentType, getFragmentData, graphql } from '../../../__generated__'
import { Question } from './Question'

const messages = defineMessages({
  delete: 'Administration.surveys.options.delete',
  cantDeleteSubmissionWithComment:
    'Administration.surveys.options.delete.cantDeleteSubmissionWithComment',
  viewDetails: 'Administration.surveys.options.viewDetails',
  hide: 'Administration.surveys.options.hide',
  show: 'Administration.surveys.options.show',
  submissionNumber: 'Administration.surveys.submissions.submissionNumber',
  submittedNumber: 'Administration.surveys.submissionsDetail.submittedNumber',
  submittedAt: 'Administration.surveys.submissionsDetail.submittedAt',
  lastComment: 'Administration.surveys.submissionsDetail.lastComment',
  source: 'Administration.surveys.submissionsDetail.source',
  time: 'Administration.surveys.submissionsDetail.time',
  emptyLabel: 'Administration.surveys.submissionsDetail.emptyLabel',
  emptyDescription: 'Administration.surveys.submissionsDetail.emptyDescription',
  followUp: 'Administration.surveys.submissionsDetail.followUp',
  cantHaveFollowUp: 'Administration.surveys.submissionsDetail.followUp.cantHaveFollowUp',
  deleteSubmissionTitle: 'Administration.submissions.delete.confirmationModal.title',
  deleteSubmissionContent: 'Administration.submissions.delete.confirmationModal.content',
  deletedSubmission: 'Administration.submissions.deleted',
  hideSubmissionTitle: 'Administration.submissions.hide.confirmationModal.title',
  hideSubmissionContent: 'Administration.submissions.hide.confirmationModal.content',
  hiddenSubmission: 'Administration.submissions.hidden',
  showSubmissionTitle: 'Administration.surveys.show.confirmationModal.title',
  showSubmissionContent: 'Administration.surveys.show.confirmationModal.content',
})

const surveySourceMessages = defineMessages<SurveySource>({
  Direct: 'Administration.surveys.submissionsDetail.directLink',
  Email: 'Administration.surveys.submissionsDetail.email',
  Qr: 'Administration.surveys.submissionsDetail.qrCode',
  Unknown: 'Administration.surveys.submissionsDetail.unknown',
})

const fragments = {
  SubmissionDetails_reportSource: graphql(`
    fragment SubmissionDetails_reportSource on ReportSource {
      id
      config {
        ... on SurveyChannelConfiguration {
          formItems {
            id
            options {
              id
              order
              labelTranslations {
                translation
                language
              }
            }
            type
            labelTranslations {
              translation
              language
            }
            scaleStartLabelTranslations {
              language
              translation
            }
            scaleEndLabelTranslations {
              language
              translation
            }
          }
        }
      }
      allSubmissions: submissions(
        page: 0
        rowsPerPage: 1000
        filter: { includeHiddenAndDeleted: true }
      ) {
        totalCount
        items {
          ... on Submission {
            id
            submittedAt
            isHidden
            isDeleted
            surveySource
            durationInMs
            respondentIndex
            canHaveFollowUp
            encryptionKey @include(if: $hasReportAccess) # used to decrypt lastComment
            canBeDeleted
            lastComment @include(if: $hasReportAccess) {
              id
              translation {
                ciphertext
                nonce
                sourceLanguage
                targetLanguage
              }
            }

            answers {
              id
              values
              formItem {
                id
              }
            }
          }
        }
      }
    }
  `),
}

const mutations = {
  DeleteSurveySubmission: graphql(`
    mutation DeleteSurveySubmission($input: DeleteSurveySubmissionInput!) {
      deleteSurveySubmission(input: $input) {
        channel {
          id
          submissions(page: 0, rowsPerPage: 1000) {
            items {
              ... on Submission {
                id
                isDeleted
              }
            }
          }
        }
      }
    }
  `),
  ToggleSurveySubmissionVisibility: graphql(`
    mutation ToggleSurveySubmissionVisibility(
      $input: ToggleSurveySubmissionVisibilityInput!
    ) {
      toggleSurveySubmissionVisibility(input: $input) {
        case {
          id
          ... on Submission {
            isHidden
          }
        }
      }
    }
  `),
}

type SubmissionDetails_reportSource = ResultOf<typeof fragments.SubmissionDetails_reportSource>
type Submission = Extract<
  SubmissionDetails_reportSource['allSubmissions']['items'][number],
  { __typename: 'Submission' }
>
type SubmissionWithOrder = Submission & { order: number; lastCommentDecryptedContent: string }

export const SubmissionsDetails = ({
  data: _data,
  refetch,
}: {
  data: FragmentType<typeof fragments.SubmissionDetails_reportSource>
  refetch: () => void // need to refetch the data for the other tab
}) => {
  const data = getFragmentData(fragments.SubmissionDetails_reportSource, _data)

  const [submissionsWithOrders, setSubmissionsWithOrders] = useState<SubmissionWithOrder[]>([])

  const [selectedSubmissionId, setSelectedSubmissionId] = useState<
    SubmissionWithOrder['id'] | null
  >()
  const { formatMessage } = useIntl()

  useEffect(() => {
    const cases = data.allSubmissions
    const decryptComments = async () => {
      const submissions = await Promise.all(
        cases?.items
          .filter(aCase => aCase.__typename === 'Submission')
          .map(async (submission, index) => {
            const message = submission.lastComment
            const payload = await readComment(
              message?.translation?.ciphertext ?? '',
              message?.translation?.nonce ?? '',
              submission.encryptionKey ?? ''
            )

            if (payload.isErr()) {
              console.error(payload)
              notification.error({
                message: formatMessage(sharedMessages.encryptionError),
                description: payload.error.message,
              })
              return
            }

            return {
              ...submission,
              lastCommentDecryptedContent: payload.value,
              order: index + 1,
            }
          })
      )
      setSubmissionsWithOrders(
        submissions
          .filter(comment => comment !== undefined)
          .sort((a, b) => a.respondentIndex - b.respondentIndex)
      )
    }
    decryptComments()
  }, [data.allSubmissions, formatMessage])

  const selectedSubmission = useMemo(
    () => submissionsWithOrders.find(submission => submission.id === selectedSubmissionId),
    [selectedSubmissionId, submissionsWithOrders]
  )

  const columns: TableColumns<(typeof dataSource)[number]> = [
    {
      key: 'submissionNumber',
      title: formatMessage(messages.submittedNumber),
      dataIndex: 'submissionNumber',
    },
    {
      key: 'submittedAt',
      title: formatMessage(messages.submittedAt),
      dataIndex: 'submittedAt',
    },
    {
      key: 'lastCommentDecryptedContent',
      title: formatMessage(messages.lastComment),
      dataIndex: 'lastCommentDecryptedContent',
    },
    {
      key: 'options',
      title: '',
      dataIndex: 'options',
      width: 54,
    },
  ]

  const dataSource = submissionsWithOrders.map(submission => {
    const lastCommentDecryptedContent = submission.lastCommentDecryptedContent
    return {
      submissionNumber: (
        <Flex gap='12px' align='center'>
          <Typography.Text
            type={submission.isDeleted || submission.isHidden ? 'secondary' : undefined}
          >
            #{submission.respondentIndex}
          </Typography.Text>
          {submission.isDeleted ? (
            <Tooltip title={<FormattedMessage {...messages.deletedSubmission} />}>
              <UntitledIcon icon={ulTrash01} color='#ef4a45' />
            </Tooltip>
          ) : (
            submission.isHidden && (
              <Tooltip title={<FormattedMessage {...messages.hiddenSubmission} />}>
                <UntitledIcon icon={ulEyeOff} />
              </Tooltip>
            )
          )}
        </Flex>
      ),
      submittedAt: (
        <Typography.Text
          type={submission.isDeleted || submission.isHidden ? 'secondary' : undefined}
        >
          {moment(submission.submittedAt).format('LLL')}
        </Typography.Text>
      ),
      lastCommentDecryptedContent: (
        <>
          {lastCommentDecryptedContent ? (
            <Link to={routes => routes.report({ id: submission.id })}>
              <Typography.Text
                type={submission.isDeleted || submission.isHidden ? 'secondary' : undefined}
              >
                {lastCommentDecryptedContent} <UntitledIcon icon={ulEye} />
              </Typography.Text>
            </Link>
          ) : (
            '-'
          )}
        </>
      ),
      options: !submission.isDeleted && (
        <CellOptions
          cell={submission}
          channel={data}
          selectedSubmission={selectedSubmission}
          setSelectedSubmissionId={setSelectedSubmissionId}
          refetch={refetch}
        />
      ),
    }
  })

  return <Table columns={columns} dataSource={dataSource} pagination={false} shadow />
}

const menuOptions = {
  viewItem: 'viewItem',
  toggleVisibilityItem: 'toggleVisibilityItem',
  deleteItem: 'deleteItem',
} as const

type ItemType = keyof typeof menuOptions

const CellOptions = ({
  cell: submission,
  channel,
  selectedSubmission,
  setSelectedSubmissionId,
  refetch,
}: {
  cell: SubmissionWithOrder
  channel: SubmissionDetails_reportSource
  selectedSubmission: SubmissionWithOrder | null | undefined
  setSelectedSubmissionId: Dispatch<SubmissionWithOrder['id'] | null>
  refetch: () => void
}) => {
  const modal = useModal()
  const { formatMessage } = useIntl()
  const { getMotherId } = useMotherId()

  const formItems =
    channel.config?.__typename === 'SurveyChannelConfiguration' ? channel.config.formItems : []

  const [deleteSurveySubmission] = useMutation(mutations.DeleteSurveySubmission, {
    onError: error => {
      console.error(error)
      notification.error({
        message: formatMessage(sharedMessages.apiError),
        description: error.message,
      })
    },
    onCompleted: () => {
      refetch()
    },
  })

  const [toggleSurveySubmissionVisibility] = useMutation(
    mutations.ToggleSurveySubmissionVisibility,
    {
      onError: error => {
        console.error(error)
        notification.error({
          message: formatMessage(sharedMessages.apiError),
          description: error.message,
        })
      },
      onCompleted: () => {
        refetch()
      },
    }
  )

  const confirmToggleVisibility = (submission: SubmissionWithOrder) => {
    const toggleVisibilityMessages = {
      isVisible: {
        title: messages.hideSubmissionTitle,
        content: messages.hideSubmissionContent,
      },
      isHidden: {
        title: messages.showSubmissionTitle,
        content: messages.showSubmissionContent,
      },
    }

    const { title, content } =
      toggleVisibilityMessages[submission.isHidden ? 'isHidden' : 'isVisible']

    modal.confirm.warning({
      title: formatMessage(title),
      content: formatMessage(content),
      onConfirm: async () => {
        await toggleSurveySubmissionVisibility({
          variables: {
            input: {
              motherId: getMotherId(),
              channelId: channel.id,
              caseId: submission.id,
              isVisible: !submission.isHidden,
            },
          },
        })
      },
    })
  }

  const actions: Record<ItemType, () => void> = {
    viewItem: () => {
      setSelectedSubmissionId(submission.id)
    },
    deleteItem: () => {
      modal.confirm.warning({
        title: formatMessage(messages.deleteSubmissionTitle),
        content: formatMessage(messages.deleteSubmissionContent),
        onConfirm: async () => {
          await deleteSurveySubmission({
            variables: {
              input: {
                motherId: getMotherId(),
                channelId: channel.id,
                caseId: submission.id,
              },
            },
          })

          setSelectedSubmissionId(null)

          return
        },
      })
    },
    toggleVisibilityItem: () => {
      confirmToggleVisibility(submission)
    },
  }

  const handleClick = (menuItem: ItemType) => {
    const action = actions[menuItem]
    action()
  }

  return (
    <>
      <Dropdown
        menu={{
          items: [
            {
              key: menuOptions.viewItem,
              label: <FormattedMessage {...messages.viewDetails} />,
            },
            {
              key: menuOptions.toggleVisibilityItem,
              label: submission.isHidden ? (
                <FormattedMessage {...messages.show} />
              ) : (
                <FormattedMessage {...messages.hide} />
              ),
            },
            {
              key: menuOptions.deleteItem,
              label: (
                <Tooltip
                  title={
                    !submission.canBeDeleted ? (
                      <FormattedMessage {...messages.cantDeleteSubmissionWithComment} />
                    ) : undefined
                  }
                >
                  {/* need span because Tooltip can't attach to disabled element directly */}
                  <span>
                    <FormattedMessage {...messages.delete} />
                  </span>
                </Tooltip>
              ),
              danger: true,
              disabled: !submission.canBeDeleted,
            },
          ],
          onClick: info => handleClick(info.key as ItemType),
        }}
      >
        <Button type='text' data-onboarding='more-options-button'>
          <UntitledIcon icon={ulDotsVertical} />
        </Button>
      </Dropdown>
      {selectedSubmission?.id === submission.id && (
        <Modal
          open={Boolean(selectedSubmission.id === submission.id)}
          width={644}
          onClose={() => setSelectedSubmissionId(null)}
          title={
            <Flex gap='8px' align='center'>
              <FormattedMessage
                {...messages.submissionNumber}
                values={{ number: selectedSubmission.respondentIndex }}
              />
              <UntitledIcon icon={selectedSubmission.isHidden ? ulEyeOff : ulEye} />
            </Flex>
          }
        >
          <Flex vertical gap='large'>
            <SubmissionInfoBar submission={selectedSubmission} />

            <Flex vertical gap='32px'>
              {formItems.map(formItem => {
                const answer = selectedSubmission.answers.find(
                  answer => answer.formItem.id === formItem.id
                )

                switch (formItem.type) {
                  case FormItemType.SimpleText:
                    return (
                      <Question
                        key={formItem.id}
                        title={formItem.labelTranslations?.[0]?.translation ?? ''}
                        type={formItem.type}
                        answer={answer?.values[0] ?? ''}
                      />
                    )
                  case FormItemType.MultiSelect:
                  case FormItemType.Select:
                    return (
                      <Question
                        key={formItem.id}
                        title={formItem.labelTranslations?.[0]?.translation}
                        type={formItem.type}
                        answers={formItem.options.map(option => ({
                          id: option.id,
                          title: option.labelTranslations?.[0]?.translation ?? '',
                          active: answer?.values.includes(option.id) ?? false,
                        }))}
                      />
                    )
                  case FormItemType.Scale:
                    return (
                      <Question
                        key={formItem.id}
                        title={formItem.labelTranslations?.[0]?.translation}
                        type={formItem.type}
                        answers={formItem.options.map(option => ({
                          active: !!(
                            option.labelTranslations?.[0]?.translation &&
                            answer?.values.includes(option.labelTranslations?.[0]?.translation)
                          ),
                          id: option.id,
                          title: option.labelTranslations?.[0]?.translation ?? '',
                        }))}
                        start={formItem.scaleStartLabelTranslations?.[0]?.translation ?? ''}
                        end={formItem.scaleEndLabelTranslations?.[0]?.translation ?? ''}
                      />
                    )
                  default:
                    return null
                }
              })}
            </Flex>
            <Flex gap='4px'>
              <Button
                type='text'
                size='small'
                onClick={() => {
                  confirmToggleVisibility(selectedSubmission)
                }}
                icon={<UntitledIcon icon={selectedSubmission.isHidden ? ulEye : ulEyeOff} />}
              >
                <FormattedMessage
                  {...(selectedSubmission.isHidden ? messages.show : messages.hide)}
                />
              </Button>
              <Link to={routes => routes.report({ id: selectedSubmission.id })}>
                <Tooltip
                  title={
                    !submission.canHaveFollowUp ? (
                      <FormattedMessage {...messages.cantHaveFollowUp} />
                    ) : undefined
                  }
                >
                  <Button
                    type='text'
                    size='small'
                    icon={<UntitledIcon icon={ulSend01} />}
                    disabled={!submission.canHaveFollowUp}
                  >
                    <FormattedMessage {...messages.followUp} />
                  </Button>
                </Tooltip>
              </Link>
              <Button
                type='text'
                size='small'
                icon={<UntitledIcon icon={ulTrash01} />}
                danger
                onClick={() => {
                  modal.confirm.warning({
                    title: formatMessage(messages.deleteSubmissionTitle),
                    content: formatMessage(messages.deleteSubmissionContent),
                    onConfirm: async () => {
                      await deleteSurveySubmission({
                        variables: {
                          input: {
                            motherId: getMotherId(),
                            channelId: channel.id,
                            caseId: selectedSubmission.id,
                          },
                        },
                      })

                      setSelectedSubmissionId(null)

                      return
                    },
                  })
                }}
              >
                <FormattedMessage {...messages.delete} />
              </Button>
            </Flex>
          </Flex>
        </Modal>
      )}
    </>
  )
}

type SurveyInfoBarProps = {
  submission: SubmissionWithOrder
}

const SubmissionInfoBar = ({ submission }: SurveyInfoBarProps) => {
  const { formatMessage } = useIntl()

  const submitttedAtMessage = formatMessage(messages.submittedAt)
  const submittedAt = moment(submission.submittedAt).format('LLL')
  const source = formatMessage(surveySourceMessages[submission.surveySource])

  const time = moment.duration(submission.durationInMs).humanize({ ss: 1, s: 60, m: 60, h: 24 })

  return (
    <Typography.Text size='sm' type='secondary'>
      <Flex gap='middle'>
        <div>{`${submitttedAtMessage}: ${submittedAt}`}</div>
        <div>/</div>
        <FormattedMessage {...messages.source} values={{ source }} />
        <div>/</div>
        <FormattedMessage {...messages.time} values={{ time }} />
      </Flex>
    </Typography.Text>
  )
}
