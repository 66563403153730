import { useMutation } from '@apollo/client'
import styled from '@emotion/styled'
import { Link } from '@faceup/router'
import { Button, Form, Input, Typography } from '@faceup/ui-base'
import { isEmail } from '@faceup/utils'
import { useEffect, useState } from 'react'
import RegisterLink from '../../Components/RegisterLink'
import { sharedMessages } from '../../Shared/translations'
import { FormattedMessage, defineMessages } from '../../TypedIntl'
import { graphql } from '../../__generated__'
import Auth from '../../utils/auth'
import useRegion from '../../utils/useRegion'
import PageTemplateUnlogged from '../PageTemplateUnlogged'
import { RegisterBox, SSOPageSwitch } from './Login'

const { Title: AntTitle } = Typography

const messages = defineMessages({
  title: 'Administration.login.title',
  login: 'Administration.login',
  ssoError: 'Administration.login.sso.error',
  switchToLogin: 'Administration.login.sso.switch',
})

const mutation = {
  login: graphql(`
    mutation UserSSOLoginMutation($input: UserSSOLoginInput!) {
      userSSOLogin(input: $input) {
        redirectUri
      }
    }
  `),
}

const SSO = () => {
  const [email, setEmail] = useState('')
  const [emailError, setEmailError] = useState(false)
  const [loading, setLoading] = useState(false)
  const [serverLoginFormError, setServerLoginFormError] = useState(false)
  const { discoverByEmail } = useRegion()

  const [login] = useMutation(mutation.login, {
    onCompleted: ({ userSSOLogin }) => {
      if (userSSOLogin?.redirectUri) {
        window.location.replace(userSSOLogin?.redirectUri)
      } else {
        setLoading(false)
        setServerLoginFormError(true)
      }
    },
    onError: () => {
      setLoading(false)
      setServerLoginFormError(true)
    },
  })

  useEffect(() => {
    loginOnLoad()
  }, [])

  const loginOnLoad = () => {
    const token = window.location.hash?.split('access_token=')?.[1]?.split('&')?.[0]
    if (token) {
      Auth.setJwt({
        jwt: token,
        persistent: true,
      })
      window.location.replace('/')
    }
  }

  const hasSSOFormValidationFailed = () => {
    let ok = true

    if (!isEmail(email.trim())) {
      setEmailError(true)
      ok = false
    }

    return !ok
  }

  return (
    <PageTemplateUnlogged
      isFooterShown
      contentUnderCard={
        <RegisterBox>
          <RegisterLink />
        </RegisterBox>
      }
    >
      <Title level={3}>
        <FormattedMessage {...messages.title} />
      </Title>
      <Form
        layout='vertical'
        style={{ width: '100%' }}
        noValidate
        onFinish={async () => {
          if (hasSSOFormValidationFailed() || loading) {
            return
          }

          setLoading(true)

          await discoverByEmail(email.trim())
          login({
            variables: {
              input: {
                email: email.trim(),
              },
            },
          })
        }}
      >
        <Form.Item
          label={<FormattedMessage {...sharedMessages.emailLabel} />}
          {...((emailError || serverLoginFormError) && {
            validateStatus: 'error',
            help: serverLoginFormError ? (
              <FormattedMessage {...messages.ssoError} />
            ) : (
              <FormattedMessage {...sharedMessages.invalidEmailError} />
            ),
          })}
        >
          <Input
            size='large'
            type='email'
            autoComplete='email'
            value={email}
            onChange={e => {
              setEmail(e.target.value)
              setEmailError(false)
            }}
          />
        </Form.Item>

        <Button htmlType='submit' type='primary' block loading={loading}>
          <FormattedMessage {...messages.login} />
        </Button>
        <SSOPageSwitch>
          <Link to={routes => routes.home()}>
            <FormattedMessage {...messages.switchToLogin} />
          </Link>
        </SSOPageSwitch>
      </Form>
    </PageTemplateUnlogged>
  )
}

const Title = styled(AntTitle)`
  text-align: center;
  margin-bottom: 20px !important;
`

export default SSO
