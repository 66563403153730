import { useMutation } from '@apollo/client'
import { type ModalProps, notification } from '@faceup/ui-base'
import type { FrontendPermissionType } from '@faceup/utils'
import type { JSX } from 'react'
import { sharedMessages } from '../../Shared/translations'
import { FormattedMessage, defineMessages, useIntl } from '../../TypedIntl'
import { type FragmentType, getFragmentData, graphql } from '../../__generated__'
import { useMotherId } from '../../providers'
import { AbstractMemberModal, type AccessKey, accesses } from './AbstractMemberModal'

const messages = defineMessages({
  title: 'Administration.settings.editUser.title',
  cantEditAccountOwner: 'Administration.settings.permissions.cantEditAccountOwner',
})

const fragments = {
  EditMemberModal_member: graphql(`
    fragment EditMemberModal_member on Member {
      id
      isAccountOwner(motherId: $motherId)

      ...AbstractMemberModal_member
    }
  `),
  EditMemberModal_memberToEdit: graphql(`
    fragment EditMemberModal_memberToEdit on Member {
      id
      isAccountOwner(motherId: $motherId)
      name
      phone
      companyIds(motherId: $motherId)
      email
      mother(motherId: $motherId) {
        id
        config {
          id
          reportCategories {
            id
          }
        }
      }
      keys {
        permissions(motherId: $motherId) {
          type
          enabled
          additionalData {
            categoryIds
          }
        }
      }
    }
  `),
  EditMemberModal_institution: graphql(`
    fragment EditMemberModal_institution on Company {
      id
      ...AbstractMemberModal_institution
    }
  `),
}

const mutations = {
  EditMember: graphql(`
    mutation EditMemberMutation($input: EditMemberInput!, $motherId: UUID!) {
      editMember(input: $input) {
        member {
          id
          name
          companyIds(motherId: $motherId)
        }
      }
    }
  `),
}

type EditMemberModalProps = {
  // null for kredenc
  member: FragmentType<typeof fragments.EditMemberModal_member> | null
  institution: FragmentType<typeof fragments.EditMemberModal_institution>
  memberToEdit: FragmentType<typeof fragments.EditMemberModal_memberToEdit>
  onCompleted: () => void
} & Required<Pick<ModalProps, 'open' | 'onClose'>>

export const EditMemberModal = ({
  open,
  member: _member,
  institution: _institution,
  onClose,
  memberToEdit: _memberToEdit,
  onCompleted,
}: EditMemberModalProps) => {
  const member = getFragmentData(fragments.EditMemberModal_member, _member)
  const institution = getFragmentData(fragments.EditMemberModal_institution, _institution)
  const memberToEdit = getFragmentData(fragments.EditMemberModal_memberToEdit, _memberToEdit)
  const { formatMessage } = useIntl()
  const { getMotherId } = useMotherId()

  const isKredenc = !member

  const [editMember] = useMutation(mutations.EditMember, {
    onError: error => {
      console.error(error)
      notification.error({
        message: formatMessage(sharedMessages.apiError),
        description: error.message,
      })
    },
    onCompleted,
  })

  const companyIds = memberToEdit.companyIds

  const defaultValues = {
    email: memberToEdit.email ?? '',
    institutionIds: companyIds,
    permissions: memberToEdit.keys.permissions,
  }

  return (
    <AbstractMemberModal
      modalVariant='edit'
      title={<FormattedMessage {...messages.title} />}
      member={member}
      institution={institution}
      open={open}
      onClose={onClose}
      overrideDisabledMessageForType={(
        permissionType: FrontendPermissionType,
        originalMessage: JSX.Element | null
      ) => {
        if (
          accesses.settings.subItems?.map(({ type }) => type).includes(permissionType) &&
          memberToEdit.isAccountOwner
        ) {
          return <FormattedMessage {...messages.cantEditAccountOwner} />
        }

        return originalMessage
      }}
      overrideDisabledMessageForSection={(
        section: AccessKey,
        originalMessage: JSX.Element | null
      ) => {
        if (section === 'settings' && memberToEdit.isAccountOwner) {
          return <FormattedMessage {...messages.cantEditAccountOwner} />
        }

        return originalMessage
      }}
      defaultValues={defaultValues}
      onSubmit={async values => {
        const result = await editMember({
          variables: {
            motherId: getMotherId(),
            input: {
              organizationalUnitIds: values.institutionIds,
              permissions: values.permissions,
              motherId: getMotherId(),
              memberId: memberToEdit.id,
              name: memberToEdit.name ?? '',
              ...(isKredenc && { email: values.email }),
            },
          },
        })

        if (result.errors) {
          return false
        }

        onClose()
        return true
      }}
    />
  )
}
