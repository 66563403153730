import { useQuery } from '@apollo/client'
import styled from '@emotion/styled'
import { useMatchPath, useNavigate } from '@faceup/router'
import { Alert, Button, Space, Typography, notification } from '@faceup/ui-base'
import { routes } from '../../App/AppRouter/useRoutes'
import { sharedMessages } from '../../Shared/translations'
import { FormattedMessage, defineMessages, useIntl } from '../../TypedIntl'
import { graphql } from '../../__generated__'
import { useMotherId } from '../../hooks/useMotherId'

const messages = defineMessages({
  info: 'Administration.partnerAlert.info',
  linkBackToPartnerAdministration: 'Administration.partnerAlert.linkBackToPartnerAdministration',
})

const query = {
  PartnerAlertInfo: graphql(`
    query PartnerAlertInfoQuery($motherId: UUID!) {
      partnerViewer {
        id
        partner {
          id
          name
        }

        mother(motherId: $motherId) {
          id
          organizationalUnitName
        }
      }
    }
  `),
}

const PartnerAlertInfo = () => {
  const { getMotherId } = useMotherId()
  const navigate = useNavigate()
  const { formatMessage } = useIntl()
  const matchPath = useMatchPath()

  const { data, loading } = useQuery(query.PartnerAlertInfo, {
    onError: error => {
      console.error(error)
      notification.error({
        message: formatMessage(sharedMessages.apiError),
        description: error.message,
      })
    },
    variables: {
      motherId: getMotherId(),
    },
  })

  if (loading) {
    return null
  }

  return (
    <AlertPartnerWrapper>
      <AlertPartner
        message={
          <Space style={{ width: '100%', justifyContent: 'space-between' }}>
            <Typography.Text>
              <FormattedMessage
                {...messages.info}
                values={{
                  companyName: data?.partnerViewer?.mother?.organizationalUnitName,
                  partnerName: data?.partnerViewer?.partner?.name,
                }}
              />
            </Typography.Text>
            <Button
              type='link'
              size='small'
              onClick={() => {
                // When on institution report detail,
                // go back to partner reports,
                // otherwise go back to institutions
                if (matchPath(routes.report)) {
                  navigate(routes => routes.partnerReports())
                } else {
                  navigate(routes => routes.institutions())
                }
              }}
            >
              <FormattedMessage {...messages.linkBackToPartnerAdministration} />
            </Button>
          </Space>
        }
        type='warning'
      />
    </AlertPartnerWrapper>
  )
}

const AlertPartnerWrapper = styled.div`
  position: sticky;
  width: 100%;
  bottom: 0;
  box-sizing: border-box;
  z-index: 1029;
  padding-inline: 20px;
  padding-bottom: 20px;
  pointer-events: none;

  /* hide for print */
  @media print {
    display: none;
  }
`

const AlertPartner = styled(Alert)`
  background: #2eb271;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  border: 0;
  pointer-events: auto;

  .ant-alert-message .ant-typography,
  .ant-alert-message .ant-btn-link,
  .ant-alert-icon {
    color: #fff;
  }
`

export default PartnerAlertInfo
