import type { UntitledIconData } from '@faceup/icons'

export const ucSuperscript: UntitledIconData = {
  prefix: 'custom',
  name: 'ucSuperscript',
  viewBox: '0 0 24 24',
  paths: [
    {
      shape:
        'M3 7.00325L13 17.0032M13 7.00325L3 17.0032M21 10.0033H17C17 8.5033 17.44 8.0033 18.5 7.5033C19.56 7.0033 21 6.3333 21 5.0033C21 4.5333 20.83 4.0733 20.52 3.7133C20.1999 3.3508 19.7649 3.10928 19.2879 3.02919C18.811 2.94909 18.321 3.03527 17.9 3.2733C17.48 3.5133 17.16 3.8933 17 4.3433',
      stroke: 'currentColor',
      strokeWidth: 1.25,
      strokeLinecap: 'round',
      strokeLinejoin: 'round',
    },
  ],
}
