import type { UntitledIconData } from '../../UntitledIconData'

const ulLoading02: UntitledIconData = {
  prefix: 'ul',
  name: 'loading-02',
  paths: [
    {
      shape:
        'M12 2V6M12 18V22M6 12H2M22 12H18M19.0784 19.0784L16.25 16.25M19.0784 4.99994L16.25 7.82837M4.92157 19.0784L7.75 16.25M4.92157 4.99994L7.75 7.82837',
    },
  ],
}

export default ulLoading02
