import { UntitledIcon } from '@faceup/icons'
import { ulLoading02 } from '@faceup/icons/ulLoading02'
import { FormItem, useAwait } from '@faceup/ui'
import { Input, type InputProps } from '@faceup/ui-base'
import type { FieldValues, UseControllerProps } from 'react-hook-form'
import { useCustomController } from '../hooks'
import type { FormItemWrapperProps } from '../typings'

export type TextInputProps<T extends FieldValues> = UseControllerProps<T> &
  Omit<InputProps & FormItemWrapperProps, 'value' | 'defaultValue' | 'placeholder'> & {
    placeholder?: string | Promise<string>
    loading?: boolean
  }

export const TextInput = <T extends FieldValues>(props: TextInputProps<T>) => {
  const {
    formItemProps,
    inputProps: { onChange: fieldOnChange, value, placeholder, loading, ...inputProps },
  } = useCustomController(props)
  const awaitedPlaceholder = useAwait(placeholder)

  return (
    <FormItem {...formItemProps}>
      <Input
        {...inputProps}
        placeholder={awaitedPlaceholder}
        onChange={e => {
          fieldOnChange(e)
          props.onChange?.(e)
        }}
        value={value ?? ''}
        prefix={loading ? <UntitledIcon icon={ulLoading02} isSpinning /> : inputProps.prefix}
      />
    </FormItem>
  )
}
