import { UntitledIcon } from '@faceup/icons'
import { ulSlashCircle01 } from '@faceup/icons/ulSlashCircle01'
import { Link } from '@faceup/router'
import { Avatar, Button, Col, Row, Typography } from '@faceup/ui-base'
import { sharedMessages } from './Shared/translations'
import { FormattedMessage, defineMessages } from './TypedIntl'

const messages = defineMessages({
  title: 'Administration.notFound.title',
})

const { Title } = Typography

const RouteNotFound = () => (
  <Row justify='center' align='middle' className='h-screen'>
    <Col className='text-center p-6 max-w-md flex flex-col items-center gap-4'>
      <Avatar size={80} className='bg-red! text-red!'>
        <UntitledIcon icon={ulSlashCircle01} size={32} />
      </Avatar>
      <Title level={1} className='mt-4'>
        <FormattedMessage {...messages.title} />
      </Title>
      <Link to={routes => routes.home()}>
        <Button ghost type='primary' className='mt-4'>
          <FormattedMessage {...sharedMessages.backHome} />
        </Button>
      </Link>
    </Col>
  </Row>
)

export default RouteNotFound
