import { UntitledIcon } from '@faceup/icons'
import { ulRefreshCw01 } from '@faceup/icons/ulRefreshCw01'
import { useRouteError } from '@faceup/router'
import { useSessionStorage } from '@faceup/ui'
import { Avatar, Button, Col, Row, Typography } from '@faceup/ui-base'
import { useEffect } from 'react'
import { version } from '../../package.json'
import { FormattedMessage, defineMessages } from '../TypedIntl'

const messages = defineMessages({
  title: 'Administration.appErrorBoundary.title',
  reload: 'Administration.appErrorBoundary.reload',
})

const { Title } = Typography

export const AppErrorBoundary = () => {
  useAutoReloadIfNewVersionAvailable()

  return (
    <Row justify='center' align='middle' className='h-screen'>
      <Col className='text-center p-6 max-w-md flex flex-col items-center gap-4'>
        <Avatar size={80}>
          <UntitledIcon icon={ulRefreshCw01} size={32} />
        </Avatar>
        <Title level={1}>500</Title>
        <FormattedMessage {...messages.title} />
        <Button type='primary' ghost onClick={() => window.location.reload()} className='mt-4'>
          <FormattedMessage {...messages.reload} />
        </Button>
      </Col>
    </Row>
  )
}

const useAutoReloadIfNewVersionAvailable = () => {
  const error = useRouteError()
  const [lastLoadedVersion, setLastLoadedVersion] = useSessionStorage<string, string | null>(
    'lastLoadedVersion',
    null
  )

  // biome-ignore lint/correctness/useExhaustiveDependencies(error):
  useEffect(() => {
    const isNewVersion = async () => {
      const response = await fetch('/version.json')
      // We don't care if failed, we just don't reload
      if (!response.ok) {
        return
      }
      const data = await response.json()
      const newVersion = data.version
      const isNewVersionAvailable = newVersion !== version
      const wasLoadedVersionReloadedWithNoUpdate = lastLoadedVersion === version
      if (isNewVersionAvailable && !wasLoadedVersionReloadedWithNoUpdate) {
        setLastLoadedVersion(version)
        window.location.reload()
      }
    }
    const shouldSkipCheck =
      import.meta.env.VITE_ENVIRONMENT === 'development' ||
      import.meta.env.VITE_ENVIRONMENT === 'preview'
    if (!shouldSkipCheck) {
      void isNewVersion()
    }
  }, [lastLoadedVersion, setLastLoadedVersion, error])
}
