import type { UntitledIconData } from '../../UntitledIconData'

const ulChevronLeft: UntitledIconData = {
  prefix: 'ul',
  name: 'chevron-left',
  paths: [
    {
      shape: 'M15 18L9 12L15 6',
    },
  ],
}

export default ulChevronLeft
