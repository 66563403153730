import { Prompt } from '@faceup/ui'
import { Button } from '@faceup/ui-base'
import { useId } from '@mantine/hooks'
import type { ReactNode } from 'react'
import type { FieldValues } from 'react-hook-form'
import { useFormLocalization } from '../FormLocalizationProvider'
import { FormItemsWrapper } from './FormItemsWrapper'
import { type ReactHookFormProps, useFormLogic } from './useFormLogic'

export type FormProps<FV extends FieldValues = FieldValues> = {
  children: ReactNode
  isUnsavedAlertDisabled?: boolean
} & (
  | ({ buttonsPosition?: 'affix' } & Omit<
      ReactHookFormProps<FV>,
      'submitButtonText' | 'customSubmitButtonText'
    >)
  | ({ buttonsPosition: 'under' | 'under-center' } & ReactHookFormProps<FV>)
)

const justifyContentMap = {
  'under-center': 'center',
  under: 'flex-end',
}

export const Form = <FV extends FieldValues = FieldValues>({
  children,
  buttonsPosition = 'affix',
  isUnsavedAlertDisabled,
  ...props
}: FormProps<FV>) => {
  const {
    modalForm,
    unsavedChanges,
    leaveUnsavedChanges,
    submitButton: { save },
  } = useFormLocalization()
  const formLogicProps: ReactHookFormProps<FV> = (
    buttonsPosition === 'affix' ? { ...props, submitButtonText: 'send' } : props
  ) as ReactHookFormProps<FV>
  const {
    submitButtonText,
    onSubmit,
    isLoading,
    additionalButtons,
    isSubmitButtonDisabled,
    dirtyFieldsCount,
    reset,
  } = useFormLogic(formLogicProps)
  const formId = useId()

  // We need to allow redirect if onSubmit succeeds, IDK if this can be written in a better way
  const showUnsavedAlert = !isSubmitButtonDisabled && !isLoading

  return (
    <form id={formId} onSubmit={onSubmit} className='w-full'>
      <Prompt when={showUnsavedAlert && !isUnsavedAlertDisabled} message={leaveUnsavedChanges} />
      {buttonsPosition === 'affix' ? (
        <>
          {' '}
          <FormItemsWrapper>{children}</FormItemsWrapper>
          <div
            className={`fixed right-4 transition-all ${isSubmitButtonDisabled ? 'pointer-events-none bottom-0 opacity-0' : 'bottom-12 opacity-100'}`}
          >
            <div className='flex items-center justify-between gap-6 p-2 ps-4 bg-[#062d46] text-white rounded-2xl font-semibold'>
              <div>{unsavedChanges(dirtyFieldsCount)}</div>
              <div className='flex flex-row gap-2'>
                {additionalButtons}
                <Button onClick={() => reset()} ghost>
                  {modalForm.cancelButton}
                </Button>
                <Button
                  type='primary'
                  htmlType='submit'
                  form={formId}
                  loading={isLoading}
                  disabled={isSubmitButtonDisabled}
                  data-test='form-submit-button'
                >
                  {save}
                </Button>
              </div>
            </div>
          </div>
        </>
      ) : (
        <FormItemsWrapper>
          {children}
          <div
            className='flex gap-4'
            style={{
              justifyContent: justifyContentMap[buttonsPosition],
            }}
          >
            {additionalButtons}
            <Button
              type='primary'
              htmlType='submit'
              loading={isLoading}
              disabled={isSubmitButtonDisabled}
              data-test='form-submit-button'
            >
              {submitButtonText}
            </Button>
          </div>
        </FormItemsWrapper>
      )}
    </form>
  )
}
