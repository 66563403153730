import { useLazyQuery } from '@apollo/client'
import { FormItemType, type Language, getTranslation } from '@faceup/utils'
import { defineMessages, useIntl } from '../../../TypedIntl'
import { graphql } from '../../../__generated__'
import useCsv from '../../../hooks/useCsv'
import useDownload from '../../../hooks/useDownload'
import { type ExportColumnSchema, useSurveyExportSchema } from '../../../hooks/useExportSchema'
import useXlsx from '../../../hooks/useXlsx'

const messages = defineMessages({
  survey: 'Administration.reportSource.survey',
})

const query = {
  SurveyExportQuery: graphql(`
    query SurveyExportQuery(
      $motherId: UUID!
      $channelId: ReportSourceGlobalId!
      $rowsPerPage: Int!
      $page: Int!
      $filter: SubmissionsFilterInput!
    ) {
      survey(motherId: $motherId, reportSourceId: $channelId) {
        id
        name
        defaultLanguage
        submissions(page: $page, rowsPerPage: $rowsPerPage, filter: $filter) {
          totalCount
          items {
            ... on Submission {
              id
              submittedAt
              respondentIndex
              tag
              answers {
                id
                values
                formItem {
                  id
                  formItemId
                  type
                  isRequired
                  labelTranslations {
                    language
                    translation
                  }
                  hintTranslations {
                    language
                    translation
                  }
                  options {
                    id
                    labelTranslations {
                      language
                      translation
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `),
}

export const useSurveyExport = () => {
  const { formatMessage } = useIntl()

  const createCsv = useCsv()
  const createXlsx = useXlsx()
  const downloadFile = useDownload()

  const [getSurveyForExport] = useLazyQuery(query.SurveyExportQuery, {
    fetchPolicy: 'network-only',
  })

  type Survey = NonNullable<
    NonNullable<Awaited<ReturnType<typeof getSurveyForExport>>['data']>['survey']
  >
  type Submission = Extract<Survey['submissions']['items'][number], { __typename: 'Submission' }>
  type Answer = Submission['answers'][number]

  const { surveyDetailSection } = useSurveyExportSchema()

  const fetchSurvey = async (motherId: string, channelId: string, selectedAnswers?: string[]) => {
    const pageSize = 100

    let totalCaseCount: number | undefined = undefined
    let page = 0
    const rawSubmissions: Submission[] = []
    let survey: Survey | null | undefined

    do {
      const { data } = await getSurveyForExport({
        variables: {
          motherId,
          rowsPerPage: pageSize,
          filter: { ids: selectedAnswers },
          page,
          channelId,
        },
      })

      rawSubmissions.push(
        ...(data?.survey?.submissions?.items?.filter(aCase => aCase.__typename === 'Submission') ??
          [])
      )

      survey = data?.survey

      // update totalCaseCount in the first iteration
      if (totalCaseCount === undefined) {
        totalCaseCount = data?.survey?.submissions?.totalCount ?? 0
      }

      page++
    } while (pageSize * page < totalCaseCount)

    return { rawSubmissions, survey }
  }

  const createSection = (answers: Answer[], defaultLanguage: Language) => {
    const formItems = Object.values(
      answers.reduce(
        (acc, answer) => ({
          ...acc,
          [answer.formItem.id]: answer.formItem,
        }),
        {} as Record<string, (typeof answers)[0]['formItem']>
      )
    )

    const columns = formItems.map<ExportColumnSchema<ReturnType<typeof createData>[number]>>(
      formItem => ({
        name:
          getTranslation(formItem.labelTranslations, defaultLanguage, defaultLanguage) +
          (formItem.isRequired ? '*' : ''),
        type: 'string',
        getValue: submission => submission.formItems[formItem.id] ?? '',
      })
    )

    const section = columns.reduce(
      (acc, column) => ({
        ...acc,
        columns: [...acc.columns, column],
      }),
      surveyDetailSection
    )

    return section
  }

  const createData = (answers: Answer[], submissions: Submission[], defaultLanguage: Language) => {
    const optionsToLabel = new Map(
      answers
        .flatMap(answer => answer.formItem.options)
        .map(
          option =>
            [
              option.id,
              getTranslation(option.labelTranslations, defaultLanguage, defaultLanguage),
            ] as const
        )
    )

    const data = submissions.map(submission => ({
      id: submission.respondentIndex,
      submittedAt: submission.__typename === 'Submission' ? submission.submittedAt : '-',
      formItems: submission.answers.reduce<Record<string, string>>((acc, answer) => {
        const values =
          answer.formItem.type === FormItemType.SimpleText
            ? answer.values
            : answer.values.map(value => optionsToLabel.get(value))

        return {
          ...acc,
          [answer.formItem.id]: values.join(','),
        }
      }, {}),
    }))

    return data
  }

  const exportSurvey =
    (
      formatter: (
        section: ReturnType<typeof createSection>,
        data: ReturnType<typeof createData>,
        name: string
      ) => Promise<File>
    ) =>
    async (motherId: string, reportSourceId: string, selectedAnswers?: string[]) => {
      const { survey, rawSubmissions } = await fetchSurvey(
        motherId,
        reportSourceId,
        selectedAnswers
      )
      if (!survey) {
        return
      }

      const submissions = rawSubmissions?.filter(
        submission => submission.__typename === 'Submission'
      )
      const answers = submissions?.flatMap(submission => submission.answers)
      const section = createSection(answers, survey.defaultLanguage)
      const data = createData(answers, submissions, survey.defaultLanguage)
      const name = `${formatMessage(messages.survey)}_${survey.name}`
      const file = await formatter(section, data, name)

      downloadFile(file)
    }

  const formatSurveyToXlsx = (
    section: ReturnType<typeof createSection>,
    data: ReturnType<typeof createData>,
    name: string
  ) => createXlsx({ sections: [section] }, [data], `${name}.xlsx`)

  const formatSurveyToCsv = (
    section: ReturnType<typeof createSection>,
    data: ReturnType<typeof createData>,
    name: string
  ) => Promise.resolve(createCsv(section, data, `${name}.csv`))

  return {
    exportSurveyToCsv: exportSurvey(formatSurveyToCsv),
    exportSurveyToXlsx: exportSurvey(formatSurveyToXlsx),
  }
}
