import { UntitledIcon, type UntitledIconData } from '@faceup/icons'
import { ulPlus } from '@faceup/icons/ulPlus'
import { Button, type ButtonProps, Typography } from '@faceup/ui-base'
import type { ReactNode } from 'react'

// if href is provided in buttonProps, onClick is not required
type OverlayButtonProps = {
  icon?: UntitledIconData
  label: ReactNode
} & (
  | {
      onClick: () => void
      buttonProps?: Omit<ButtonProps, 'children' | 'onClick' | 'icon' | 'href'>
    }
  | {
      onClick?: () => void
      buttonProps: Omit<ButtonProps, 'children' | 'onClick' | 'icon' | 'href'> &
        Required<Pick<ButtonProps, 'href'>>
    }
)

type NoDataOverlayProps = {
  visible: boolean
  icon: UntitledIconData
  label: ReactNode
  description?: ReactNode
  isTransparentBackground?: boolean
} & (
  | {
      button?: OverlayButtonProps
      buttons?: never
    }
  | {
      button?: never
      buttons?: (OverlayButtonProps & Pick<ButtonProps, 'type'>)[]
    }
)

export const NoDataOverlay = ({
  visible,
  icon,
  label,
  description,
  button,
  buttons,
  isTransparentBackground,
}: NoDataOverlayProps) => {
  if (!visible) {
    return null
  }
  return (
    <div
      className={`absolute top-0 right-0 bottom-0 left-0 z-1 content-center items-center ${isTransparentBackground ? '' : 'bg-white'}`}
    >
      <div className='flex flex-col items-center gap-[16px]'>
        <div className='flex justify-center items-center bg-[#e6f3ff] rounded-full w-[56px] h-[56px]'>
          <UntitledIcon icon={icon} size={20} color='#0085ff' />
        </div>
        <div className='flex flex-col items-center gap-[8px]'>
          <Typography.Title level={5}>{label}</Typography.Title>
          <Typography.Text type='secondary'>{description}</Typography.Text>
        </div>
        {button && (
          <Button
            {...button.buttonProps}
            type='primary'
            icon={<UntitledIcon icon={button.icon ?? ulPlus} />}
            onClick={button.onClick}
          >
            {button.label}
          </Button>
        )}
        {buttons && (
          <div className='flex flex-col gap-[8px]'>
            {buttons.map((button, index) => (
              <Button
                {...button.buttonProps}
                // biome-ignore lint/suspicious/noArrayIndexKey:
                key={index}
                type={button.type}
                icon={button.icon && <UntitledIcon icon={button.icon} />}
                onClick={button.onClick}
              >
                {button.label}
              </Button>
            ))}
          </div>
        )}
      </div>
    </div>
  )
}
