import { useQuery } from '@apollo/client'
import styled from '@emotion/styled'
import { Col, Row, Slider, Space, Typography, notification } from '@faceup/ui-base'
import {
  type ContactIssue,
  Currency,
  BillingFrequency as GlobalBillingFrequency,
  Institution,
  Plan,
} from '@faceup/utils'
import { useContext, useMemo, useState } from 'react'
import { FormattedNumber } from 'react-intl'
import ContactSalesModal from '../../Components/ContactSalesModal'
import InvoicingPlan, { type InvoicingPlanButtonProps } from '../../Components/InvoicingPlan'
import { sharedMessages } from '../../Shared/translations'
import { ThemeContext } from '../../ThemeContext'
import {
  FormattedMessage,
  type TypedMessageDescriptor,
  defineMessages,
  useIntl,
} from '../../TypedIntl'
import { type FragmentType, getFragmentData, graphql } from '../../__generated__'
import { mq } from '../../constants'
import { ThemeType } from '../../locales'
import { getContactSalesIssue, mapPriceDivider } from '../CompanySettings/CompanyInvoicing'
import { companyInvoicingInstitutionAndSchoolFragment } from '../CompanySettings/CompanyInvoicing/CompanyInvoicingInstitutionAndSchoolFragment'
import BillingPeriodSwitcher, {
  type BillingFrequency,
} from '../CompanySettings/Components/BillingPeriodSwitcher'
import PageTemplateUnlogged from '../PageTemplateUnlogged'

type CompanyInvoicingPriceListInstitutionFragment_versions_prices = NonNullable<
  NonNullable<
    NonNullable<
      NonNullable<
        NonNullable<
          NonNullable<
            NonNullable<
              NonNullable<
                FragmentType<typeof companyInvoicingInstitutionAndSchoolFragment>[' $fragmentRefs']
              >['CompanyInvoicingInstitutionAndSchoolFragmentFragment']
            >['company']
          >[number]
        >['versions']
      >[number]
    >['prices']
  >[number]
>

const globalMessages = defineMessages({
  howManyEmployees: 'Administration.companyInvoicing.howManyEmployees',
  lowerThan: 'Administration.companyInvoicing.employeeCount.lowerThan',
  higherThan: 'Administration.companyInvoicing.employeeCount.higherThan',
  individualPrice: 'Administration.companyInvoicing.individualPrice',
  freePrice: 'Administration.companyInvoicing.freePrice',
  buy: 'Administration.companyInvoicing.button.buy',
  vatInfo: 'Administration.companyInvoicing.vatInfo',
  contactSales: 'Administration.companyInvoicing.button.contactSales',
})

const schoolMessages = defineMessages<Extract<keyof typeof globalMessages, 'howManyEmployees'>>({
  howManyEmployees: 'Administration.companyInvoicing.howManyStudents',
})

type VariantMessages = 'lineText' | 'description' | 'benefits'

const variantSchoolFreeCzechMessages = defineMessages<VariantMessages>({
  lineText: 'Administration.companyInvoicing.school.czech.free.lineText',
  description: 'Administration.companyInvoicing.school.czech.free.description',
  benefits: 'Administration.companyInvoicing.school.czech.free.benefits',
})

const variantSchoolStandardCzechMessages = defineMessages<VariantMessages>({
  lineText: 'Administration.companyInvoicing.school.czech.standard.lineText',
  description: 'Administration.companyInvoicing.school.czech.standard.description',
  benefits: 'Administration.companyInvoicing.school.czech.standard.benefits',
})

const variantSchoolStandardMessages = defineMessages<Exclude<VariantMessages, 'description'>>({
  lineText: 'Administration.companyInvoicing.school.standard.lineText',
  benefits: 'Administration.companyInvoicing.school.standard.benefits',
})

const variantBasicMessages = defineMessages<VariantMessages>({
  lineText: 'Administration.companyInvoicing.basic.lineText',
  description: 'Administration.companyInvoicing.basic.description',
  benefits: 'Administration.companyInvoicing.basic.benefits',
})

const variantStandardMessages = defineMessages<VariantMessages>({
  lineText: 'Administration.companyInvoicing.standard.lineText',
  description: 'Administration.companyInvoicing.standard.description',
  benefits: 'Administration.companyInvoicing.standard.benefits',
})

const variantPremiumMessages = defineMessages<VariantMessages>({
  lineText: 'Administration.companyInvoicing.premium.lineText',
  description: 'Administration.companyInvoicing.premium.description',
  benefits: 'Administration.companyInvoicing.premium.benefits',
})

const query = {
  PurchasePlanPriceListQuery: graphql(`
    query PurchasePlanPriceListQuery {
      priceList {
        yearly {
          ...CompanyInvoicingInstitutionAndSchoolFragment
        }
        monthly {
          ...CompanyInvoicingInstitutionAndSchoolFragment
        }
        quarterly {
          ...CompanyInvoicingInstitutionAndSchoolFragment
        }
      }
    }
  `),
}

export type ClickBuyData = {
  employees: number
  plan: Plan
  isIndividualPrice: boolean
  billingFrequency: GlobalBillingFrequency
}

type PurchasePlanProps = {
  onClickBuy: (data: ClickBuyData) => void
  currency: Currency
  institution: Institution
}

const PurchasePlan = ({ onClickBuy, currency, institution }: PurchasePlanProps) => {
  const [billingFrequency, setBillingFrequency] = useState<BillingFrequency>('yearly')
  const [userLimit, setUserLimit] = useState(0)
  const [clickedButton, setClickedButton] = useState<Plan>()
  const { project } = useContext(ThemeContext)
  const [openedContactSalesModalIssue, setOpenedContactSalesModalIssue] = useState<ContactIssue>()

  const isNntbSchool = project === ThemeType.NNTB && institution === Institution.School

  const { formatMessage } = useIntl()
  const { data } = useQuery(query.PurchasePlanPriceListQuery, {
    onError: error => {
      console.error(error)
      notification.error({
        message: formatMessage(sharedMessages.apiError),
        description: error.message,
      })
    },
  })
  const priceListWithBillingFrequency = useMemo(
    () => ({
      monthly: getFragmentData(
        companyInvoicingInstitutionAndSchoolFragment,
        data?.priceList?.monthly
      ),
      quarterly: getFragmentData(
        companyInvoicingInstitutionAndSchoolFragment,
        data?.priceList?.quarterly
      ),
      yearly: getFragmentData(
        companyInvoicingInstitutionAndSchoolFragment,
        data?.priceList?.yearly
      ),
    }),
    [data?.priceList?.monthly, data?.priceList?.quarterly, data?.priceList?.yearly]
  )

  const priceListVariant = useMemo(
    () =>
      priceListWithBillingFrequency[billingFrequency]?.[
        institution === Institution.School ? 'school' : 'company'
      ]?.filter(
        // We want to include only variants with specified currency
        priceVariant =>
          priceVariant?.versions?.some(version =>
            version?.prices?.find(price => price?.currency === currency)
          )
      ) ?? null,
    [priceListWithBillingFrequency, billingFrequency, institution, currency]
  )

  const messages = {
    ...globalMessages,
    ...(institution === Institution.School ? schoolMessages : {}),
  }

  const handleClickBuy = async (plan: Plan, employees: number, isIndividualPrice: boolean) => {
    if (plan && employees && !clickedButton) {
      if (!isIndividualPrice) {
        setClickedButton(plan)
      } else {
        setOpenedContactSalesModalIssue(getContactSalesIssue(plan, false))
        return
      }
      const billingFrequencyMap: Record<BillingFrequency, GlobalBillingFrequency> = {
        monthly: GlobalBillingFrequency.Monthly,
        yearly: GlobalBillingFrequency.Yearly,
        quarterly: GlobalBillingFrequency.Quarterly,
      }
      onClickBuy({
        employees,
        plan,
        isIndividualPrice,
        billingFrequency: billingFrequencyMap[billingFrequency],
      })
    }
  }

  const employeesCount = (priceListVariant ?? [])
    .map(item => item?.members)
    .filter((item): item is number => Boolean(item))

  const marks = employeesCount.reduce(
    (acc, _emp, i) => {
      const employees = {
        minEmployeesCount: employeesCount?.[i - 1] ?? 0,
        maxEmployeesCount: employeesCount?.[i] ?? 0,
      }

      const translation = i === employeesCount.length - 1 ? messages.higherThan : messages.lowerThan

      return { ...acc, [i]: formatMessage(translation, employees) }
    },
    {} as Record<number, string>
  )

  const priceItemFree = priceListVariant?.[userLimit]?.versions
    ?.find(item => item?.version === Plan.Free)
    ?.prices?.find(price => price?.currency === currency)
  const priceItemBasic = priceListVariant?.[userLimit]?.versions
    ?.find(item => item?.version === Plan.Basic)
    ?.prices?.find(price => price?.currency === currency)
  const priceItemStandard = priceListVariant?.[userLimit]?.versions
    ?.find(item => item?.version === Plan.Standard)
    ?.prices?.find(price => price?.currency === currency)
  const priceItemPremium = priceListVariant?.[userLimit]?.versions
    ?.find(item => item?.version === Plan.Premium)
    ?.prices?.find(price => price?.currency === currency)

  const isCzechCurrency = currency === Currency.CZK

  const getTranslationsForPlan = (
    messages: Partial<Record<VariantMessages, TypedMessageDescriptor>>
  ) => ({
    description: messages?.description ? formatMessage(messages.description) : undefined,
    features: messages?.benefits ? formatMessage(messages.benefits).split('\n') : [],
    lineText: messages.lineText ? formatMessage(messages.lineText) : undefined,
  })

  const getButton = (
    companyStatus: Exclude<Plan, Plan.Unregistered>,
    priceItem?: CompanyInvoicingPriceListInstitutionFragment_versions_prices | null
  ): InvoicingPlanButtonProps | undefined => {
    const selectedMembersCount = priceListVariant?.[userLimit]?.members

    return {
      type: 'primary',
      ghost: companyStatus !== Plan.Standard,
      children:
        typeof priceItem?.billingPrice !== 'number' ? (
          <FormattedMessage {...messages.contactSales} />
        ) : (
          <FormattedMessage {...messages.buy} />
        ),
      onClick: () =>
        selectedMembersCount &&
        handleClickBuy(
          companyStatus,
          selectedMembersCount,
          typeof priceItem?.billingPrice !== 'number'
        ),
      loading: clickedButton === companyStatus,
      disabled: Boolean(clickedButton) && clickedButton !== companyStatus,
    }
  }

  const getPriceNode = (
    priceItem?: CompanyInvoicingPriceListInstitutionFragment_versions_prices | null
  ) => {
    if (typeof priceItem?.billingPrice !== 'number') {
      return (
        <IndividualPrice>
          <FormattedMessage {...messages.individualPrice} />
        </IndividualPrice>
      )
    }

    if (priceItem.billingPrice === 0) {
      return <FormattedMessage {...messages.freePrice} />
    }

    const shownPrice = priceItem.billingPrice / 100 / mapPriceDivider[billingFrequency]
    return (
      priceItem?.billingPrice &&
      priceItem?.currency && (
        <FormattedNumber
          style='currency'
          value={shownPrice}
          currency={priceItem.currency}
          maximumFractionDigits={0}
        />
      )
    )
  }

  const getOriginalPriceNode = (
    priceItem?: CompanyInvoicingPriceListInstitutionFragment_versions_prices | null
  ) => {
    if (priceItem?.originalPrice && priceItem?.currency) {
      const shownPrice = priceItem.originalPrice / 100 / mapPriceDivider[billingFrequency]
      return (
        <FormattedNumber
          style='currency'
          value={shownPrice}
          currency={priceItem.currency}
          maximumFractionDigits={0}
        />
      )
    }
    return null
  }

  return (
    <PageTemplateUnlogged
      cardStyles={{
        root: 'max-w-[990px]',
        content: 'pt-[3rem] px-[3rem] pb-[2rem] sm:px-[3rem]',
      }}
      publicHeaderProps={{ showLinkTo: 'signIn' }}
    >
      <Space direction='vertical' align='center' size='large'>
        <StyledEmployees>
          <EmployeesCardInnerWrapper>
            <EmployeesWrapper>
              <EmployeesText>
                <FormattedMessage {...messages.howManyEmployees} />
              </EmployeesText>
            </EmployeesWrapper>
            <Slider
              step={1}
              min={0}
              max={Object.keys(marks).length - 1}
              dots={false}
              value={userLimit}
              onChange={value => setUserLimit(value)}
              tooltip={{
                open: false,
                formatter: value => value && marks[value],
              }}
              disabled={Boolean(clickedButton)}
            />
          </EmployeesCardInnerWrapper>
          <Row wrap={false} style={{ paddingInline: 4 }} data-test='employees-slider'>
            {Object.keys(marks).map((_value, value) => (
              <Col
                // biome-ignore lint/suspicious/noArrayIndexKey:
                key={value}
                flex='1 1 0'
              >
                <SliderDescription>
                  {userLimit === value ? <Strong>{marks[value]}</Strong> : marks[value]}
                </SliderDescription>
              </Col>
            ))}
          </Row>
        </StyledEmployees>
        <Space direction='vertical' size={0}>
          {institution === Institution.Company && (
            <Space align='center' direction='vertical' style={{ width: '100%' }}>
              <BillingPeriodSwitcher
                frequency={billingFrequency}
                onChange={variant => setBillingFrequency(variant)}
              />
            </Space>
          )}
          {institution === Institution.School && (!isCzechCurrency || !isNntbSchool) && (
            <InvoicingPlan
              {...getTranslationsForPlan(variantSchoolStandardMessages)}
              name={Plan.Standard}
              billingFrequency={billingFrequency}
              price={getPriceNode(priceItemStandard)}
              originalPrice={getOriginalPriceNode(priceItemStandard)}
              discountTill={priceItemStandard?.discountTill}
              variant={priceItemStandard?.discountTill ? 'highlighted' : undefined}
              button={getButton(Plan.Standard, priceItemStandard)}
              isPricingInfoHidden={typeof priceItemStandard?.billingPrice !== 'number'}
            />
          )}

          <Row gutter={[24, 24]} style={{ justifyContent: 'center' }}>
            {institution === Institution.School ? (
              isCzechCurrency &&
              isNntbSchool && (
                <>
                  <Col span={24} lg={8}>
                    <InvoicingPlan
                      {...getTranslationsForPlan(variantSchoolFreeCzechMessages)}
                      name={Plan.Free}
                      billingFrequency={billingFrequency}
                      price={getPriceNode(priceItemFree)}
                      originalPrice={getOriginalPriceNode(priceItemFree)}
                      discountTill={priceItemFree?.discountTill}
                      variant={priceItemFree?.discountTill ? 'highlighted' : undefined}
                      button={getButton(Plan.Free, priceItemFree)}
                      isPricingInfoHidden
                    />
                  </Col>
                  <Col span={24} lg={8}>
                    <InvoicingPlan
                      {...getTranslationsForPlan(variantSchoolStandardCzechMessages)}
                      name={Plan.Standard}
                      billingFrequency={billingFrequency}
                      price={getPriceNode(priceItemStandard)}
                      originalPrice={getOriginalPriceNode(priceItemStandard)}
                      discountTill={priceItemStandard?.discountTill}
                      variant={priceItemStandard?.discountTill ? 'highlighted' : undefined}
                      button={getButton(Plan.Standard, priceItemStandard)}
                      isPricingInfoHidden={typeof priceItemStandard?.billingPrice !== 'number'}
                      featureIcon='plus'
                    />
                  </Col>
                </>
              )
            ) : (
              <>
                <Col span={24} lg={8}>
                  <InvoicingPlan
                    {...getTranslationsForPlan(variantBasicMessages)}
                    name={Plan.Basic}
                    billingFrequency={billingFrequency}
                    price={getPriceNode(priceItemBasic)}
                    originalPrice={getOriginalPriceNode(priceItemBasic)}
                    discountTill={priceItemBasic?.discountTill}
                    variant={priceItemBasic?.discountTill ? 'highlighted' : undefined}
                    button={getButton(Plan.Basic, priceItemBasic)}
                    isPricingInfoHidden={typeof priceItemBasic?.billingPrice !== 'number'}
                  />
                </Col>
                <Col span={24} lg={8}>
                  <InvoicingPlan
                    {...getTranslationsForPlan(variantStandardMessages)}
                    name={Plan.Standard}
                    billingFrequency={billingFrequency}
                    price={getPriceNode(priceItemStandard)}
                    originalPrice={getOriginalPriceNode(priceItemStandard)}
                    discountTill={priceItemStandard?.discountTill}
                    variant={priceItemStandard?.discountTill ? 'highlighted' : undefined}
                    button={getButton(Plan.Standard, priceItemStandard)}
                    isPricingInfoHidden={typeof priceItemStandard?.billingPrice !== 'number'}
                    featureIcon='plus'
                  />
                </Col>
                <Col span={24} lg={8}>
                  <InvoicingPlan
                    {...getTranslationsForPlan(variantPremiumMessages)}
                    name={Plan.Premium}
                    billingFrequency={billingFrequency}
                    price={getPriceNode(priceItemPremium)}
                    originalPrice={getOriginalPriceNode(priceItemPremium)}
                    discountTill={priceItemPremium?.discountTill}
                    variant={priceItemPremium?.discountTill ? 'highlighted' : undefined}
                    button={getButton(Plan.Premium, priceItemPremium)}
                    isPricingInfoHidden={typeof priceItemPremium?.billingPrice !== 'number'}
                    featureIcon='plus'
                  />
                </Col>
              </>
            )}
          </Row>
        </Space>
      </Space>
      <Typography.Text className='mt-8 text-center' size='sm' type='secondary'>
        <FormattedMessage {...globalMessages.vatInfo} />
      </Typography.Text>
      {openedContactSalesModalIssue && (
        <ContactSalesModal
          contactIssue={openedContactSalesModalIssue}
          onClose={() => setOpenedContactSalesModalIssue(undefined)}
          open
        />
      )}
    </PageTemplateUnlogged>
  )
}

const StyledEmployees = styled.div`
  border: 1px solid #e6eaec;
  border-radius: 10px;
  padding: 14px;
`

const EmployeesWrapper = styled.div`
  display: flex;
  gap: 24px;
  margin-block-start: 14px;
  margin-block-end: 28px;
  justify-content: center;
`

const EmployeesText = styled(Typography.Title)`
  margin: 0;
  font-size: 20px;
  line-height: 24px;
`

const SliderDescription = styled(Typography.Text)`
  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 22px;
  color: #9babb5;
  text-align: center;
  display: block;
`

const Strong = styled.strong`
  color: #062d46;
`

const EmployeesCardInnerWrapper = styled.div`
  ${mq.smUp} {
    padding-inline: 24px;
  }
`

const IndividualPrice = styled.div`
  font-size: 24px;
  font-family: Inter, sans-serif;
  font-weight: 600;
`

export default PurchasePlan
