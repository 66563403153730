import type { UntitledIconData } from '@faceup/icons'
import { ulAlertCircle } from '@faceup/icons/ulAlertCircle'
import { ulCheckCircle } from '@faceup/icons/ulCheckCircle'
import { ulInfoCircle } from '@faceup/icons/ulInfoCircle'
import { ulXCircle } from '@faceup/icons/ulXCircle'
import type { AlertProps, MessageArgsProps } from 'antd'

type AlertType = NonNullable<AlertProps['type']>
export type MessageStatusType = NonNullable<MessageArgsProps['type']>

export const statusIcons: Record<
  AlertType | Exclude<MessageStatusType, 'loading'>,
  UntitledIconData
> = {
  error: ulXCircle,
  info: ulInfoCircle,
  success: ulCheckCircle,
  warning: ulAlertCircle,
}
