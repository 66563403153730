import { ModalForm, MultiSelect, Select, useForm, yup } from '@faceup/form'
import { useMotherId } from '@faceup/institution'
import { sharedMessages } from '@faceup/localization'
import type { ModalProps } from '@faceup/ui-base'
import { FormattedMessage, defineMessages, useIntl } from '../../../TypedIntl'
import { type FragmentType, getFragmentData, graphql } from '../../../__generated__'
import { useSurveyExport } from '../hooks/useSurveyExport'

type Props = Required<Pick<ModalProps, 'open' | 'onClose'>> & {
  reportSourceId: string
  survey?: FragmentType<typeof fragments.SurveyExportModal_Channel>
}

const formats = ['csv', 'xlsx'] as const
const results = ['all', 'individual'] as const

const messages = defineMessages({
  format: 'Administration.surveys.export.format',
  results: 'Administration.surveys.export.results',
  answersRequired: 'Administration.surveys.export.error.answersRequired',
  all: 'Administration.surveys.export.results.all',
  individual: 'Administration.surveys.export.results.individual',
  answer: 'Administration.surveys.export.answer',
  title: 'Administration.surveys.export.title',
})

const fragments = {
  SurveyExportModal_Channel: graphql(`
    fragment SurveyExportModal_ReportSource on ReportSource {
      submissions(page: 0, rowsPerPage: 1000) {
        items {
          ... on Submission {
            id
            respondentIndex
          }
        }
      }
    }
  `),
}

export const SurveyExportModal = ({
  reportSourceId,
  onClose,
  survey: _survey,
  ...modalProps
}: Props) => {
  const survey = getFragmentData(fragments.SurveyExportModal_Channel, _survey)
  const { getMotherId } = useMotherId()
  const { exportSurveyToCsv, exportSurveyToXlsx } = useSurveyExport()
  const { formatMessage } = useIntl()
  const motherId = getMotherId()

  const schema = yup.object({
    format: yup.mixed().oneOf(formats).required(),
    results: yup.mixed().oneOf(results).required(),
    answers: yup.array(yup.string().required()).when('results', {
      is: 'individual',
      // biome-ignore lint/suspicious/noThenProperty:
      then: schema =>
        schema
          .required(formatMessage(messages.answersRequired))
          .min(1, formatMessage(messages.answersRequired)),
      otherwise: schema => schema.notRequired(),
    }),
  })

  const form = useForm({
    schema,
    afterSubmit: 'resetValues',
  })

  const resultsValue = form.watch('results')

  const submissions =
    survey?.submissions?.items?.filter(submission => submission.__typename === 'Submission') ?? []

  return (
    <ModalForm
      {...modalProps}
      width={644}
      title={<FormattedMessage {...messages.title} />}
      form={form}
      customSubmitButtonText={<FormattedMessage {...sharedMessages.download} />}
      isSuccessMessageDisplayed={false}
      onSubmit={values => {
        const exportFile = values.format === 'csv' ? exportSurveyToCsv : exportSurveyToXlsx
        const answers = values.results === 'individual' ? values.answers : undefined

        exportFile(motherId, reportSourceId, answers)
        onClose()

        return true
      }}
      onClose={onClose}
    >
      <Select
        label={<FormattedMessage {...messages.results} />}
        name='results'
        options={results.map(result => ({
          label: formatMessage(messages[result]),
          value: result,
        }))}
        control={form.control}
      />
      {resultsValue === 'individual' && (
        <MultiSelect
          label={<FormattedMessage {...messages.answer} />}
          name='answers'
          options={submissions.map(submission => ({
            label: `#${submission.respondentIndex}`,
            value: submission.id,
          }))}
          control={form.control}
        />
      )}
      <Select
        label={<FormattedMessage {...messages.format} />}
        name='format'
        options={formats.map(format => ({
          label: format.toUpperCase(),
          value: format,
        }))}
        control={form.control}
      />
    </ModalForm>
  )
}
