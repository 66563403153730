import type { UntitledIconData } from '../../UntitledIconData'

const ulSearchRefraction: UntitledIconData = {
  prefix: 'ul',
  name: 'search-refraction',
  paths: [
    {
      shape:
        'M21 21L16.65 16.65M11 6C13.7614 6 16 8.23858 16 11M19 11C19 15.4183 15.4183 19 11 19C6.58172 19 3 15.4183 3 11C3 6.58172 6.58172 3 11 3C15.4183 3 19 6.58172 19 11Z',
    },
  ],
}

export default ulSearchRefraction
