import { Flex } from '@faceup/ui-base'
import type { FormEvent, ReactNode } from 'react'
import { ButtonGroup } from './ButtonGroup'
import { FormItem } from './FormItem'

export const submitButtonTextDefiniton = ['save', 'send', 'add'] as const
export type SubmitButtonText = (typeof submitButtonTextDefiniton)[number]

type FormProps = {
  onSubmit?: (e: FormEvent<HTMLFormElement>) => void
  children: ReactNode
}

export const Form = (props: FormProps) => {
  const { children, onSubmit } = props
  return (
    <form
      onSubmit={e => {
        e.preventDefault()
        onSubmit?.(e)
      }}
      className='w-full'
    >
      <Flex vertical gap={16}>
        {children}
      </Flex>
    </form>
  )
}

Form.Item = FormItem
Form.ButtonGroup = ButtonGroup
