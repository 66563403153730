import type { UntitledIconData } from '@faceup/icons'

export const ucHeadlineH2: UntitledIconData = {
  prefix: 'custom',
  name: 'ucHeadlineH2',
  viewBox: '0 0 24 24',
  paths: [
    {
      shape:
        'M3 4V12M3 4H5M3 4H1M3 12V20M3 12L13 12M3 20H5M3 20H1M13 4V12M13 4H15M13 4H11M13 12V20M13 20H15M13 20H11M22 20H17C17 18.2865 17.55 17.7153 18.875 17.1442C20.2 16.573 22 15.8077 22 14.2884C22 13.7515 21.7875 13.226 21.4 12.8148C20.9998 12.4007 20.4561 12.1248 19.8599 12.0333C19.2637 11.9418 18.6512 12.0402 18.125 12.3121C17.6 12.5863 17.2 13.0204 17 13.5344',
      stroke: 'currentColor',
      strokeWidth: 1.25,
      strokeLinecap: 'round',
      strokeLinejoin: 'round',
    },
  ],
}
