import { UntitledIcon } from '@faceup/icons'
import ulEye from '@faceup/icons/src/UntitledIcons/icons/line/ulEye'
import ulEyeOff from '@faceup/icons/src/UntitledIcons/icons/line/ulEyeOff'
import { Input as AntInput, type InputProps as AntInputProps, type InputRef } from 'antd'
import { type ComponentProps, forwardRef } from 'react'

export type InputProps = AntInputProps

type CompoundedComponent = typeof AntInput & {
  Search: typeof AntInput.Search
  TextArea: typeof AntInput.TextArea
  Password: typeof AntInput.Password
  OTP: typeof AntInput.OTP
}

/*
 * Can´t be simply extended as
 * const Input = AntInput
 * Input.Password = InputPassword
 *
 * IDK why, but it freezes Storybook
 */
export const Input = forwardRef<InputRef, InputProps>((props, ref) => (
  <AntInput ref={ref} {...props} />
)) as CompoundedComponent

type InputPasswordProps = ComponentProps<typeof AntInput.Password>

const InputPassword = forwardRef<InputRef, InputPasswordProps>((props, ref) => (
  <AntInput.Password
    ref={ref}
    // Must be wrapped in two spans because
    // antd automatically overrides classes in the outer span
    iconRender={visible => (
      <span>
        <span className='anticon ant-input-password-icon'>
          <UntitledIcon icon={visible ? ulEye : ulEyeOff} />
        </span>
      </span>
    )}
    {...props}
  />
))

Input.Password = InputPassword
Input.OTP = AntInput.OTP
Input.Search = AntInput.Search
Input.TextArea = AntInput.TextArea

Input.OTP.displayName = 'Input.OTP'
Input.Password.displayName = 'Input.Password'
Input.Search.displayName = 'Input.Search'
Input.TextArea.displayName = 'Input.TextArea'
