import type { UntitledIconData } from '../../UntitledIconData'

const ulAlignJustify: UntitledIconData = {
  prefix: 'ul',
  name: 'align-justify',
  paths: [
    {
      shape: 'M21 10H3M21 18H3M21 6H3M21 14H3',
    },
  ],
}

export default ulAlignJustify
