import styled from '@emotion/styled'
import {
  type FieldValues,
  type PasswordInputProps as FormPasswordInputProps,
  useCustomController,
} from '@faceup/form'
import { UntitledIcon } from '@faceup/icons'
import { ulCheckCircle } from '@faceup/icons/ulCheckCircle'
import { ulXCircle } from '@faceup/icons/ulXCircle'
import { FormItem, useLayout, useOutsideAlerter } from '@faceup/ui'
import {
  Flex,
  Icon,
  Input,
  type InputProps,
  List,
  Popover,
  Space,
  Typography,
} from '@faceup/ui-base'
import { type ReactNode, useRef, useState } from 'react'
import { FormattedMessage, defineMessages } from '../TypedIntl'
import usePasswordStats, { type Strength } from '../hooks/usePasswordStats'
import PasswordStrengthMeter from './PasswordStrengthMeter'
import ErrorIcon from './assets/error-icon.svg?react'
import SuccessIcon from './assets/success-icon.svg?react'

const messages = defineMessages({
  popoverTitle: 'Administration.password.popoverTitle',
  hasMinLength: 'Administration.password.hasMinLength',
  hasLowerLetter: 'Administration.password.hasLowerLetter',
  hasUpperLetter: 'Administration.password.hasUpperLetter',
  hasNumber: 'Administration.password.hasNumber',
  hasSpecialCharacter: 'Administration.password.hasSpecialCharacter',
})

const strengthMessages = defineMessages<Strength>({
  none: 'Administration.password.description',
  veryWeak: 'Administration.password.strength.veryWeak',
  weak: 'Administration.password.strength.weak',
  medium: 'Administration.password.strength.medium',
  strong: 'Administration.password.strength.strong',
  veryStrong: 'Administration.password.strength.veryStrong',
})

type PasswordInputProps = InputProps & { popoverPosition?: 'top' | 'left' }

const PasswordInputWithPopover = ({ popoverPosition = 'left', ...props }: PasswordInputProps) => {
  const [openPopover, setOpenPopover] = useState(false)
  const inputRef = useRef(null)
  useOutsideAlerter(inputRef, () => setOpenPopover(false))
  const { isXlUp } = useLayout()
  const password = props?.value?.toString() ?? ''
  const { getPasswordStats } = usePasswordStats()
  const stats = getPasswordStats(password)

  return (
    <>
      <Popover
        open={openPopover}
        placement={!isXlUp || popoverPosition ? 'top' : 'left'}
        autoAdjustOverflow={false}
        destroyTooltipOnHide
        title={
          <TooltipTitle>
            <FormattedMessage {...messages.popoverTitle} />
          </TooltipTitle>
        }
        content={
          <List
            split={false}
            size='small'
            dataSource={[
              {
                description: (
                  <FormattedMessage
                    {...messages.hasMinLength}
                    values={{
                      minLength: stats.minLength,
                    }}
                  />
                ),
                okay: stats.hasMinLength,
              },
              {
                description: <FormattedMessage {...messages.hasLowerLetter} />,
                okay: stats.hasLower,
              },
              {
                description: <FormattedMessage {...messages.hasUpperLetter} />,
                okay: stats.hasUpper,
              },
              {
                description: <FormattedMessage {...messages.hasNumber} />,
                okay: stats.hasNumber,
              },
              {
                description: <FormattedMessage {...messages.hasSpecialCharacter} />,
                okay: stats.hasSymbol,
              },
            ]}
            renderItem={(item, key) => (
              <ListItem key={key} style={{ color: item.okay ? '#0CCE6B' : '#EF4A45' }}>
                <Space>
                  <Icon component={item.okay ? SuccessIcon : ErrorIcon} />
                  {item.description}
                </Space>
              </ListItem>
            )}
          />
        }
      >
        <div ref={inputRef}>
          <Input.Password
            {...props}
            onFocus={event => {
              setOpenPopover(true)
              props?.onFocus?.(event)
            }}
          />
        </div>
      </Popover>
      <PasswordStrengthMeter stats={stats} />
      <PasswordDescription>
        <FormattedMessage
          {...strengthMessages[stats.strength ?? 'none']}
          values={{
            b: content => <b>{content}</b>,
          }}
        />
      </PasswordDescription>
    </>
  )
}

export const PasswordInputWithMessages = <T extends FieldValues>(
  props: FormPasswordInputProps<T>
) => {
  const {
    formItemProps: { errorMessage, ...formItemProps },
    inputProps: { value, ...inputProps },
  } = useCustomController(props)
  const { getPasswordStats } = usePasswordStats()
  const stats = getPasswordStats(value)

  return (
    <FormItem {...formItemProps}>
      <Flex gap={8} vertical>
        <Input.Password value={value} {...inputProps} />
        {value.length > 0 && (
          <MessageList
            messages={[
              {
                description: (
                  <FormattedMessage
                    {...messages.hasMinLength}
                    values={{
                      minLength: stats.minLength,
                    }}
                  />
                ),
                okay: stats.hasMinLength,
                id: 'minLength',
              },
              {
                description: <FormattedMessage {...messages.hasLowerLetter} />,
                okay: stats.hasLower,
                id: 'hasLower',
              },
              {
                description: <FormattedMessage {...messages.hasUpperLetter} />,
                okay: stats.hasUpper,
                id: 'hasUpper',
              },
              {
                description: <FormattedMessage {...messages.hasNumber} />,
                okay: stats.hasNumber,
                id: 'hasNumber',
              },
              {
                description: <FormattedMessage {...messages.hasSpecialCharacter} />,
                okay: stats.hasSymbol,
                id: 'hasSymbol',
              },
            ]}
          />
        )}
      </Flex>
    </FormItem>
  )
}

type Message = {
  okay: boolean
  description: ReactNode
}

type MessageWithId = Message & { id: string }

type MessageListProps = {
  messages: MessageWithId[]
}

const MessageList = ({ messages }: MessageListProps) => {
  return (
    <Flex gap={4} vertical>
      {messages.map(message => (
        <MessageRow key={message.id} okay={message.okay} description={message.description} />
      ))}
    </Flex>
  )
}

const MessageRow = ({ okay, description }: Message) => {
  return (
    <Flex gap={8} align='center'>
      <UntitledIcon color={okay ? '#32C27B' : '#F54070'} icon={okay ? ulCheckCircle : ulXCircle} />
      <Typography.Text type='secondary'>{description}</Typography.Text>
    </Flex>
  )
}

const PasswordDescription = styled.div`
  font-style: italic;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #688699;
`

const TooltipTitle = styled.div`
  font-size: 13px;
  font-weight: 600;
`

const ListItem = styled(List.Item)`
  padding-block: 2px;
  font-weight: 600;
  transition: color 200ms;
  font-size: 12px;
`

export default PasswordInputWithPopover
