import { useMutation } from '@apollo/client'
import {
  Button,
  Form,
  Input,
  Modal,
  type ModalProps,
  notification,
  useMessage,
} from '@faceup/ui-base'
import type { ContactIssue } from '@faceup/utils'
import { useState } from 'react'
import { sharedMessages } from '../../Shared/translations'
import { FormattedMessage, defineMessages, useIntl } from '../../TypedIntl'
import { graphql } from '../../__generated__'
import { useMotherId } from '../../hooks/useMotherId'

const messages = defineMessages({
  title: 'Administration.contactSalesModal.byEmail.title',
  subjectTitle: 'Administration.contactSalesModal.byEmail.subject.title',
  subjectPlaceholder: 'Administration.contactSalesModal.byEmail.subject.placeholder',
  messageTitle: 'Administration.contactSalesModal.byEmail.message.title',
  messagePlaceholder: 'Administration.contactSalesModal.byEmail.message.placeholder',
  contactedSalesMessage: 'Administration.contactedSales.heading',
})

const mutations = {
  ContactSalesModalByEmailMutation: graphql(`
    mutation ContactSalesModalByEmailMutation($input: ContactSalesByEmailInput!) {
      contactSalesByEmail(input: $input) {
        success
      }
    }
  `),
}

type ContactSalesModalByEmailProps = { contactIssue: ContactIssue; note?: string } & Required<
  Pick<ModalProps, 'open' | 'onClose'>
>

const ContactSalesModalByEmail = ({
  contactIssue,
  note,
  ...props
}: ContactSalesModalByEmailProps) => {
  const [subject, setSubject] = useState<{ value: string; error?: boolean }>({ value: '' })
  const [message, setMessage] = useState<{ value: string; error?: boolean }>({ value: '' })
  const { formatMessage } = useIntl()
  const { getMotherId } = useMotherId()
  const antMessage = useMessage()

  const [contactByEmail, { loading }] = useMutation(mutations.ContactSalesModalByEmailMutation, {
    onError: error => {
      console.error(error)
      notification.error({
        message: formatMessage(sharedMessages.apiError),
        description: error.message,
      })
    },
  })

  const submitForm = () => {
    let isEverythingOkay = true
    if (subject.value.length === 0) {
      setSubject(prevState => ({ ...prevState, error: true }))
      isEverythingOkay = false
    }
    if (message.value.length === 0) {
      setMessage(prevState => ({ ...prevState, error: true }))
      isEverythingOkay = false
    }

    if (!isEverythingOkay) {
      return
    }
    void contactByEmail({
      variables: {
        input: {
          subject: subject.value,
          message: message.value,
          issue: contactIssue,
          note,
          motherId: getMotherId(),
        },
      },
      onCompleted: () => {
        void antMessage.success(formatMessage(messages.contactedSalesMessage))
        props.onClose()
      },
    })
  }

  return (
    <Modal {...props} title={<FormattedMessage {...messages.title} />} width={620}>
      <Form noValidate layout='vertical' onFinish={submitForm}>
        <Form.Item
          required
          label={<FormattedMessage {...messages.subjectTitle} />}
          {...(subject.error && {
            validateStatus: 'error',
            help: <FormattedMessage {...sharedMessages.invalidInputError} />,
          })}
        >
          <Input
            placeholder={formatMessage(messages.subjectPlaceholder)}
            value={subject.value}
            onChange={({ target: { value } }) => setSubject({ value })}
          />
        </Form.Item>
        <Form.Item
          required
          label={<FormattedMessage {...messages.messageTitle} />}
          {...(message.error && {
            validateStatus: 'error',
            help: <FormattedMessage {...sharedMessages.invalidInputError} />,
          })}
        >
          <Input.TextArea
            rows={4}
            placeholder={formatMessage(messages.messagePlaceholder)}
            value={message.value}
            onChange={({ target: { value } }) => setMessage({ value })}
          />
        </Form.Item>
        <div className='flex justify-end'>
          <Button loading={loading} type='primary' htmlType='submit'>
            <FormattedMessage {...sharedMessages.send} />
          </Button>
        </div>
      </Form>
    </Modal>
  )
}

export default ContactSalesModalByEmail
