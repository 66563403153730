import { CookieConsent } from '@faceup/ui'
import { isCookieConsentRequired } from '@faceup/utils'
import { useContext, useEffect, useState } from 'react'
import { GeoDataContext } from '../../Contexts/GeoDataProvider'
import { defineMessages, useIntl } from '../../TypedIntl'
import { useGTM } from '../../utils/gtm'

const messages = defineMessages({
  consentModalTitle: 'Administration.cookieConsentBanner.modal.title',
  consentModalDescription: 'Administration.cookieConsentBanner.modal.description',
  consentModalManagePreferences: 'Administration.cookieConsentBanner.modal.managePreferences',
  consentModalAcceptAll: 'Administration.cookieConsentBanner.modal.acceptAll',
  consentModalNecessary: 'Administration.cookieConsentBanner.modal.necessary',
  consentPreferencesTitle: 'Administration.cookieConsentBanner.modal.preferences.title',
  consentPreferencesAcceptAll: 'Administration.cookieConsentBanner.modal.preferences.acceptAll',
  consentPreferencesAcceptNecessary:
    'Administration.cookieConsentBanner.modal.preferences.acceptNecessary',
  consentPreferencesSavePreferencesTitle:
    'Administration.cookieConsentBanner.modal.preferences.savePreferences',
  consentPreferencesCloseIconLabel:
    'Administration.cookieConsentBanner.modal.preferences.closeIconLabel',
  consentPreferencesServiceCounterLabel:
    'Administration.cookieConsentBanner.modal.preferences.serviceCounterLabel',
  consentPreferencesCookieUsageTitle:
    'Administration.cookieConsentBanner.modal.preferences.sections.cookieUsage.title',
  consentPreferencesCookieUsageDescription:
    'Administration.cookieConsentBanner.modal.preferences.sections.cookieUsage.description',
  consentPreferencesNecessaryCookiesTitle:
    'Administration.cookieConsentBanner.modal.preferences.sections.necessaryCookies.title',
  consentPreferencesNecessaryCookiesDescription:
    'Administration.cookieConsentBanner.modal.preferences.sections.necessaryCookies.description',
})

const cookieConsentSections = [
  'cookieUsage',
  'necessary',
  'statistics',
  'preference',
  'marketing',
  'moreInformation',
] as const

type CookieConsentSection = (typeof cookieConsentSections)[number]

const sectionTitles = defineMessages<CookieConsentSection>({
  cookieUsage: 'Administration.cookieConsentBanner.modal.preferences.sections.cookieUsage.title',
  necessary: 'Administration.cookieConsentBanner.modal.preferences.sections.necessaryCookies.title',
  statistics:
    'Administration.cookieConsentBanner.modal.preferences.sections.statisticsCookies.title',
  preference:
    'Administration.cookieConsentBanner.modal.preferences.sections.preferenceCookies.title',
  marketing: 'Administration.cookieConsentBanner.modal.preferences.sections.marketingCookies.title',
  moreInformation:
    'Administration.cookieConsentBanner.modal.preferences.sections.moreInformation.title',
})

const sectionDescriptions = defineMessages<CookieConsentSection>({
  cookieUsage:
    'Administration.cookieConsentBanner.modal.preferences.sections.cookieUsage.description',
  necessary:
    'Administration.cookieConsentBanner.modal.preferences.sections.necessaryCookies.description',
  statistics:
    'Administration.cookieConsentBanner.modal.preferences.sections.statisticsCookies.description',
  preference:
    'Administration.cookieConsentBanner.modal.preferences.sections.preferenceCookies.description',
  marketing:
    'Administration.cookieConsentBanner.modal.preferences.sections.marketingCookies.description',
  moreInformation:
    'Administration.cookieConsentBanner.modal.preferences.sections.moreInformation.description',
})

const cookieCategories: { [key: string]: string[] } = {
  necessary: ['functionality_storage', 'security_storage'],
  statistics: ['analytics_storage'],
  preference: ['personalization_storage'],
  marketing: ['ad_personalization', 'ad_user_data', 'ad_storage'],
}

const CookieConsentBanner = () => {
  const geoData = useContext(GeoDataContext)
  const [consent, setConsent] = useState({
    functionality_storage: 'denied',
    security_storage: 'denied',
    analytics_storage: 'denied',
    personalization_storage: 'denied',
    ad_personalization: 'denied',
    ad_user_data: 'denied',
    ad_storage: 'denied',
  })
  const { formatMessage } = useIntl()
  const { updateConsent } = useGTM()

  const sections = cookieConsentSections.map(section => {
    const isMoreInformationOrCookieUsage =
      section === 'moreInformation' || section === 'cookieUsage'

    return {
      title: formatMessage(sectionTitles[section]),
      description: formatMessage(sectionDescriptions[section]),
      ...(isMoreInformationOrCookieUsage ? {} : { linkedCategory: section }),
    }
  })

  useEffect(() => {
    if (geoData.country && isCookieConsentRequired(geoData.country)) {
      CookieConsent.run({
        hideFromBots: true,
        disablePageInteraction: true,
        cookie: {
          name: 'cc_cookie',
          domain: '.faceup.com',
        },
        guiOptions: {
          consentModal: {
            layout: 'cloud',
            position: 'bottom right',
            equalWeightButtons: true,
            flipButtons: false,
          },
          preferencesModal: {
            layout: 'box',
            equalWeightButtons: true,
            flipButtons: false,
          },
        },

        categories: {
          necessary: {
            enabled: true, // this category is enabled by default
            readOnly: true, // this category cannot be disabled
          },
          statistics: {
            enabled: true,
          },
          preference: {
            enabled: true,
          },
          marketing: {
            enabled: true,
          },
        },

        onConsent: ({ cookie }) => {
          const newConsent = structuredClone(consent)

          cookie.categories.forEach(category => {
            cookieCategories[category]?.forEach(consentType => {
              newConsent[consentType as keyof typeof newConsent] = 'granted'
            })
          })
          setConsent(newConsent)
          updateConsent(['consent', 'update', newConsent])
        },

        language: {
          default: 'en',
          translations: {
            en: {
              consentModal: {
                title: formatMessage(messages.consentModalTitle),
                description: `${formatMessage(messages.consentModalDescription)} ${`<button type="button" data-cc="show-preferencesModal" class="cc-link" aria-haspopup="dialog">${formatMessage(messages.consentModalManagePreferences)}</button>`}`,
                acceptAllBtn: formatMessage(messages.consentModalAcceptAll),
                acceptNecessaryBtn: formatMessage(messages.consentModalNecessary),
              },

              preferencesModal: {
                title: formatMessage(messages.consentPreferencesTitle),
                acceptAllBtn: formatMessage(messages.consentPreferencesAcceptAll),
                acceptNecessaryBtn: formatMessage(messages.consentPreferencesAcceptNecessary),
                savePreferencesBtn: formatMessage(messages.consentPreferencesSavePreferencesTitle),
                closeIconLabel: formatMessage(messages.consentPreferencesCloseIconLabel),
                serviceCounterLabel: formatMessage(messages.consentPreferencesServiceCounterLabel),
                sections,
              },
            },
          },
        },
      })
    }
  }, [consent, sections, updateConsent, formatMessage, geoData.country])

  return null
}

export default CookieConsentBanner
