import type { UntitledIconData } from '../../UntitledIconData'

const ulCode02: UntitledIconData = {
  prefix: 'ul',
  name: 'code-02',
  paths: [
    {
      shape: 'M17 17L22 12L17 7M7 7L2 12L7 17M14 3L10 21',
    },
  ],
}

export default ulCode02
