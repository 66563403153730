import { UntitledIcon } from '@faceup/icons'
import { usAlertCircle } from '@faceup/icons/usAlertCircle'
import { Modal, Space, Typography } from '@faceup/ui-base'
import { VerificationStatus } from '@faceup/utils'
import { useContext, useEffect } from 'react'
import { ModalsContext } from '../Contexts/ModalsContext'
import { FormattedMessage, defineMessages } from '../TypedIntl'
import { type FragmentType, getFragmentData, graphql } from '../__generated__'

const { Title } = Typography

const fragments = {
  AccountVerificationModal_companyRegistration: graphql(`
    fragment AccountVerificationModal_companyRegistration on Company {
      id
      isVerified
    }
  `),

  AccountVerificationModal_partnerInstitution: graphql(`
    fragment AccountVerificationModal_partnerInstitution on PartnerInstitution {
      id
      verificationStatus
    }
  `),
}

const messages = defineMessages({
  title: 'Administration.verifyingAccount.title',
  description: 'Administration.verifyingAccount.description',
})

type Props = {
  isUserPartner: boolean
  mother: FragmentType<typeof fragments.AccountVerificationModal_companyRegistration> | null
  partnerInstitution: FragmentType<
    typeof fragments.AccountVerificationModal_partnerInstitution
  > | null
}

const AccountVerificationModal = ({
  isUserPartner,
  mother: _mother,
  partnerInstitution: _partnerInstitution,
}: Props) => {
  const mother = getFragmentData(fragments.AccountVerificationModal_companyRegistration, _mother)
  const partnerInstitution = getFragmentData(
    fragments.AccountVerificationModal_partnerInstitution,
    _partnerInstitution
  )
  const { isInstitutionVerificationVisible, setIsInstitutionVerificationVisible } =
    useContext(ModalsContext)

  useEffect(() => {
    if (
      isUserPartner
        ? partnerInstitution?.verificationStatus === VerificationStatus.Pending
        : mother?.isVerified === false
    ) {
      setIsInstitutionVerificationVisible(true)
    }
  }, [
    mother?.isVerified,
    partnerInstitution?.verificationStatus,
    setIsInstitutionVerificationVisible,
    isUserPartner,
  ])

  return (
    <Modal open={isInstitutionVerificationVisible} data-test='AccountVerificationModal'>
      <Space
        style={{ width: '100%', marginTop: '1rem', marginBottom: '1rem' }}
        align='center'
        direction='vertical'
        size='large'
      >
        <UntitledIcon icon={usAlertCircle} size='1.5rem' color='#f7990e' />
        <Space align='center' direction='vertical'>
          <Title level={4} style={{ textAlign: 'center' }}>
            <FormattedMessage {...messages.title} />
          </Title>
          <FormattedMessage {...messages.description} />
        </Space>
      </Space>
    </Modal>
  )
}

export default AccountVerificationModal
