import type { UntitledIconData } from '../../UntitledIconData'

const ulChevronLeftDouble: UntitledIconData = {
  prefix: 'ul',
  name: 'chevron-left-double',
  paths: [
    {
      shape: 'M18 17L13 12L18 7M11 17L6 12L11 7',
    },
  ],
}

export default ulChevronLeftDouble
