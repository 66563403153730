import { TextSelect, type TextSelection } from '@faceup/ui'
import { Button, Popover, Typography } from '@faceup/ui-base'
import { type ReactNode, useState } from 'react'
import { FormattedMessage, defineMessages } from '../../TypedIntl'
import { redactCharacter } from '../../utils'
import {
  type RedactTextType,
  type RedactionProviderContextProps,
  type SpecialTextType,
  type SubText,
  specialTextTypeDef,
  useRedaction,
} from './RedactionProvider'

const messages = defineMessages({
  anonymize: 'Report.textToRedact.anonymize',
  deanonymize: 'Report.textToRedact.deanonymize',
})

export const getText = (
  texts: RedactionProviderContextProps,
  type: RedactTextType
): string | undefined => {
  if (specialTextTypeDef.includes(type.type as SpecialTextType)) {
    return (texts[type.type] as SubText)?.[type.id ?? '']
  }

  return texts[type.type] as string | undefined
}

const getSplitText = (text: string, selection: TextSelection) => ({
  textBefore: text.substring(0, selection.start),
  textSelected: text.substring(selection.start, selection.end),
  textAfter: text.substring(selection.end),
})

type TextToRedactProps = {
  children: ReactNode
  type: RedactTextType
  text: string
}

export const TextToRedact = (props: TextToRedactProps) => {
  const { children, type, text: originalText } = props
  const [selectedText, setSelectedText] = useState<TextSelection>()
  const { isRedactionActive, texts, redactText } = useRedaction()
  const text = getText(texts, type) ?? originalText

  if (!isRedactionActive) {
    return <>{children}</>
  }

  if (selectedText) {
    const { textBefore, textSelected, textAfter } = getSplitText(text, selectedText)
    return (
      <div onClick={() => setSelectedText(undefined)}>
        {textBefore}
        <Popover
          content={
            <div>
              <Button
                ghost
                type='primary'
                onClick={() =>
                  redactText(
                    type,
                    {
                      start: selectedText.start,
                      end: selectedText.end,
                      variant: 'redacted',
                    },
                    originalText
                  )
                }
              >
                <FormattedMessage {...messages.anonymize} />
              </Button>
              <Button
                ghost
                type='primary'
                onClick={() =>
                  redactText(
                    type,
                    {
                      start: selectedText.start,
                      end: selectedText.end,
                      variant: 'notRedacted',
                    },
                    originalText
                  )
                }
              >
                <FormattedMessage {...messages.deanonymize} />
              </Button>
            </div>
          }
        >
          <Typography.Text mark className='p-0'>
            {textSelected}
          </Typography.Text>
        </Popover>
        {textAfter}
      </div>
    )
  }

  return (
    <TextSelect
      text={text}
      onTextSelect={(selectedText: TextSelection) => {
        const { textSelected } = getSplitText(text, selectedText)
        const isSomethingInSelectionRedacted = textSelected.includes(redactCharacter)
        if (isSomethingInSelectionRedacted) {
          setSelectedText(selectedText)
        } else {
          redactText(
            type,
            {
              start: selectedText.start,
              end: selectedText.end,
              variant: 'redacted',
            },
            originalText
          )
        }
      }}
    />
  )
}
