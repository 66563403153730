import { UntitledIcon } from '@faceup/icons'
import { ulChevronDown } from '@faceup/icons/ulChevronDown'
import { UnstyledButton } from '@faceup/ui'
import { Dropdown, Typography } from '@faceup/ui-base'
import { SupportedRegion } from '@faceup/utils'
import { FormattedMessage, defineMessages } from '../TypedIntl'

const messages = defineMessages({
  hostingRegion: 'Administration.registration.fillInfo.step3.hostingRegion',
})

const regionMessages = defineMessages<SupportedRegion>({
  [SupportedRegion.EuWest1]: 'Administration.selectRegion.region.euWest1',
  [SupportedRegion.UsWest1]: 'Administration.selectRegion.region.usWest1',
  [SupportedRegion.MeCentral1]: 'Administration.selectRegion.region.meCentral1',
})

type SelectRegionProps = {
  value: SupportedRegion
  onChange: (value: SupportedRegion) => void
}

export const SelectRegion = (props: SelectRegionProps) => {
  const { value, onChange } = props
  return (
    <Typography.Text type='secondary'>
      <FormattedMessage {...messages.hostingRegion} />{' '}
      <Dropdown
        menu={{
          items: Object.values(SupportedRegion).map(region => ({
            key: region,
            label: <FormattedMessage {...regionMessages[region]} />,
            onClick: () => onChange(region),
          })),
        }}
      >
        <UnstyledButton>
          <Typography.Text type='secondary' strong>
            <FormattedMessage {...regionMessages[value]} />
          </Typography.Text>{' '}
          <Typography.Text type='secondary'>
            <UntitledIcon icon={ulChevronDown} />
          </Typography.Text>
        </UnstyledButton>
      </Dropdown>
    </Typography.Text>
  )
}
