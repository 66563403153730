import { Empty as AntEmpty, type EmptyProps as AntEmptyProps } from 'antd'
import defaultEmpty from './assets/empty-default.svg'
import simpleEmpty from './assets/empty-simple.svg'

type EmptyProps = AntEmptyProps

export const Empty = (props: EmptyProps) => {
  return <AntEmpty image={<DefaultEmptyImg />} {...props} />
}

const DefaultEmptyImg = () => <img src={defaultEmpty} alt='Empty' />

const SimpleEmptyImg = () => <img src={simpleEmpty} alt='Empty' />

Empty.PRESENTED_IMAGE_DEFAULT = <DefaultEmptyImg />
Empty.PRESENTED_IMAGE_SIMPLE = <SimpleEmptyImg />
