import { useMutation } from '@apollo/client'
import { useAccessRights } from '@faceup/member'
import { type ModalProps, notification } from '@faceup/ui-base'
import { FrontendPermissionType } from '@faceup/utils'
import type { ResultOf } from '@graphql-typed-document-node/core'
import { sharedMessages } from '../../Shared/translations'
import { FormattedMessage, defineMessages, useIntl } from '../../TypedIntl'
import { type FragmentType, getFragmentData, graphql } from '../../__generated__'
import { useMotherId } from '../../providers'
import { AbstractMemberModal } from './AbstractMemberModal'
import { findPermissionByPermissionType } from './AbstractMemberModal/abstractMemberModalHelpers'

const messages = defineMessages({
  title: 'Administration.settings.createNewUser.title',
})

const fragments = {
  CreateMemberModal_member: graphql(`
    fragment CreateMemberModal_member on Member {
      id
      isAccountOwner(motherId: $motherId)

      companies(motherId: $motherId) {
        id
      }

      keys {
        id
        permissions(motherId: $motherId) {
          type
          enabled
          additionalData {
            categoryIds
          }
        }
      }

      ...AbstractMemberModal_member
    }
  `),
  CreateMemberModal_institution: graphql(`
    fragment CreateMemberModal_institution on Company {
      id
      config {
        id
        reportCategories {
          id
        }
      }
      organizationalStructure {
        id
        organizationalUnitName
      }

      ...AbstractMemberModal_institution
    }
  `),
}

const mutations = {
  CreateMember: graphql(`
    mutation CreateMemberMutation($input: CreateMemberInput!, $motherId: UUID!) {
      createMember(input: $input) {
        createdMember {
          id
          name
          phone
          email

          # need up-to-date count for addMember upsell
          mother(motherId: $motherId) {
            id
            countOfMembers
          }
        }
      }
    }
  `),
}

type CreateMemberMutation = ResultOf<typeof mutations.CreateMember>

type CreateMemberModalProps = {
  onCompleted: (data?: CreateMemberMutation) => void
  member: FragmentType<typeof fragments.CreateMemberModal_member> | null
  institution: FragmentType<typeof fragments.CreateMemberModal_institution>
} & Required<Pick<ModalProps, 'open' | 'onClose'>>

export const CreateMemberModal = ({
  member: _member,
  institution: _institution,
  onCompleted,
  open,
  onClose,
}: CreateMemberModalProps) => {
  const member = getFragmentData(fragments.CreateMemberModal_member, _member)
  const institution = getFragmentData(fragments.CreateMemberModal_institution, _institution)
  const { formatMessage } = useIntl()
  const { getMotherId } = useMotherId()
  const accessRights = useAccessRights()

  const [createMember] = useMutation(mutations.CreateMember, {
    onError: error => {
      console.error(error)
      notification.error({
        message: formatMessage(sharedMessages.apiError),
        description: error.message,
      })
    },
    onCompleted,
  })

  const isKredenc = !member

  return (
    <AbstractMemberModal
      modalVariant='add'
      title={<FormattedMessage {...messages.title} />}
      member={member}
      institution={institution}
      open={open}
      onClose={onClose}
      defaultValues={{
        email: '',
        institutionIds: isKredenc
          ? institution.organizationalStructure.map(institution => institution.id)
          : (member.companies?.map(company => company.id) ?? []),
        permissions: Object.values(FrontendPermissionType).map(type => {
          const enabled = member?.isAccountOwner || accessRights.isVisibleForPermission[type]

          if (type === FrontendPermissionType.ReportAccess) {
            return {
              type,
              enabled,
              additionalData: isKredenc
                ? {
                    categoryIds: null,
                  }
                : findPermissionByPermissionType(member.keys?.permissions ?? [], type)
                    ?.additionalData,
            }
          }
          return {
            type,
            enabled,
          }
        }),
      }}
      onSubmit={async values => {
        const result = await createMember({
          variables: {
            motherId: getMotherId(),
            input: {
              motherId: getMotherId(),
              companyIds: values.institutionIds ?? [],
              email: values.email,
              permissions: values.permissions,
            },
          },
        })

        if (result.errors) {
          return false
        }

        onClose()
        return true
      }}
    />
  )
}
