import { useMutation } from '@apollo/client'
import { LanguageSelect, ModalForm, useForm, yup } from '@faceup/form'
import { UntitledIcon } from '@faceup/icons'
import { ulDotsVertical } from '@faceup/icons/ulDotsVertical'
import { useMotherId } from '@faceup/institution'
import { useNavigate } from '@faceup/router'
import { Button, Dropdown, notification, useMessage, useModal } from '@faceup/ui-base'
import { ChannelStatus, Language } from '@faceup/utils'
import type { ResultOf } from '@graphql-typed-document-node/core'
import { type ComponentProps, useState } from 'react'
import { sharedMessages } from '../../../Shared/translations'
import { FormattedMessage, defineMessages, useIntl } from '../../../TypedIntl'
import { type FragmentType, getFragmentData, graphql } from '../../../__generated__'
import { SurveyExportModal } from './SurveyExportModal'
import { SurveySharedWithEmployeesModal } from './SurveySharedWithEmployeesModal'

type Survey = ResultOf<typeof fragments.SurveyOptionsMenu_reportSource>

const messages = defineMessages({
  viewDetails: 'Administration.surveys.options.viewDetails',
  duplicate: 'Administration.surveys.options.duplicate',
  deactivate: 'Administration.surveys.options.deactivate',
  activate: 'Administration.surveys.options.activate',
  delete: 'Administration.surveys.options.delete',
  confirmDelete: 'Administration.surveys.confirmDelete',
  downloadData: 'Administration.surveys.detail.downloadData',
  changeLanguage: 'Administration.surveys.detail.changeLanguage',
  deleteAnswers: 'Administration.surveys.detail.deleteAnswers',
  copyPrefix: 'Administration.surveys.detail.copyPrefix',
  language: 'Administration.surveys.create.language',
  sharedWith: 'Administration.surveys.detail.sharedWith',
})

const fragments = {
  SurveyOptionsMenu_reportSource: graphql(`
    fragment SurveyOptionsMenu_reportSource on ReportSource {
      id
      createdAt
      name
      defaultLanguage
      createdByMember {
        id
        name
      }
      submissions(page: 0, rowsPerPage: 1000) {
        totalCount
        items {
          ... on Submission {
            id
          }
        }
      }
      config {
        ... on SurveyChannelConfiguration {
          id
          sharedWithEmployees {
            id
            name
            email
          }
        }
      }
      isEnabled
      ...SurveyExportModal_ReportSource
    }
  `),
}

const mutations = {
  DeleteChannel: graphql(`
    mutation DeleteSurveyChannelMutation($input: DeleteChannelInput!) {
      deleteChannel(input: $input) {
        channel {
          id
          isDeleted
        }
      }
    }
  `),
  DuplicateSurvey: graphql(`
    mutation DuplicateSurvey($input: DuplicateSurveyInput!) {
      duplicateSurvey(input: $input) {
        survey {
          id
        }
      }
    }
  `),
  ToggleSurveyActivation: graphql(`
    mutation ToggleSurveyActivation($input: ToggleSurveyActivationInput!) {
      toggleSurveyActivation(input: $input) {
        survey {
          id
          isEnabled
        }
      }
    }
  `),
  ChangeSurveyLanguage: graphql(`
    mutation ChangeSurveyLanguage($input: ChangeSurveyLanguageInput!) {
      changeSurveyLanguage(input: $input) {
        survey {
          id
          defaultLanguage
          config {
            ... on SurveyChannelConfiguration {
              id
              formItems {
                id
                labelTranslations {
                  language
                  translation
                }
                hintTranslations {
                  language
                  translation
                }
                options(includeDeleted: false) {
                  id
                  labelTranslations {
                    language
                    translation
                  }
                  order
                }
                scaleStartLabelTranslations {
                  language
                  translation
                }
                scaleEndLabelTranslations {
                  language
                  translation
                }
              }
            }
          }
        }
      }
    }
  `),
}

type MenuOptions =
  | 'viewDetails'
  | 'duplicate'
  | 'deactivate'
  | 'delete'
  | 'downloadData'
  | 'changeLanguage'
  | 'deleteAnswers'
  | 'sharedWithEmployees'

const ChangeLanguageModal = ({
  open,
  onClose,
  survey,
}: { open: boolean; onClose: () => void; survey: Survey }) => {
  const { formatMessage } = useIntl()
  const { getMotherId } = useMotherId()

  const [changeSurveyLanguage] = useMutation(mutations.ChangeSurveyLanguage, {
    onError: error => {
      console.error(error)
      notification.error({
        message: formatMessage(sharedMessages.apiError),
        description: error.message,
      })
    },
  })

  const schema = yup.object({
    language: yup.string().oneOf(Object.values(Language)).required(),
  })
  const form = useForm({
    schema,
    afterSubmit: 'persistValues',
    defaultValues: {
      language: survey.defaultLanguage,
    },
  })

  return (
    <ModalForm
      title={<FormattedMessage {...messages.changeLanguage} />}
      submitButtonText='save'
      open={open}
      onClose={onClose}
      form={form}
      onSubmit={async values => {
        const result = await changeSurveyLanguage({
          variables: {
            input: {
              language: values.language,
              channelId: survey.id,
              motherId: getMotherId(),
            },
          },
        })
        onClose()
        return !result.errors
      }}
    >
      <LanguageSelect
        control={form.control}
        name='language'
        label={<FormattedMessage {...messages.language} />}
      />
    </ModalForm>
  )
}

export const SurveyOptionsMenu = ({
  survey: _survey,
  optionsToDisplay,
  buttonProps,
}: {
  survey: FragmentType<typeof fragments.SurveyOptionsMenu_reportSource>
  optionsToDisplay: MenuOptions[]
  buttonProps?: Omit<ComponentProps<typeof Button>, 'children' | 'icon'>
}) => {
  const survey = getFragmentData(fragments.SurveyOptionsMenu_reportSource, _survey)
  const [isExportModalOpened, setIsExportModalOpened] = useState(false)
  const [languageModalOpen, setLanguageModalOpen] = useState(false)
  const [isSharedWithModalOpen, setSharedWithModalOpen] = useState(false)
  const { formatMessage } = useIntl()
  const { getMotherId } = useMotherId()

  const message = useMessage()
  const navigate = useNavigate()
  const modal = useModal()

  const [deleteSource] = useMutation(mutations.DeleteChannel, {
    onError: error => {
      console.error(error)
      notification.error({
        message: formatMessage(sharedMessages.apiError),
        description: error.message,
      })
    },
    refetchQueries: ['SurveysTableQuery'],
    onCompleted: () => {
      message.success(formatMessage(sharedMessages.savedMessage))
      navigate(routes => routes.surveys())
    },
  })

  const [duplicateSurvey, { loading: duplicateLoading }] = useMutation(mutations.DuplicateSurvey, {
    onError: error => {
      console.error(error)
      notification.error({
        message: formatMessage(sharedMessages.apiError),
        description: error.message,
      })
    },
    refetchQueries: ['SurveysTableQuery'],
    onCompleted: () => {
      message.success(formatMessage(sharedMessages.savedMessage))
      navigate(routes => routes.surveys())
    },
  })

  const [toggleSurveyActivation] = useMutation(mutations.ToggleSurveyActivation, {
    onError: error => {
      console.error(error)
      notification.error({
        message: formatMessage(sharedMessages.apiError),
        description: error.message,
      })
    },
    onCompleted: () => message.success(formatMessage(sharedMessages.savedMessage)),
    refetchQueries: ['SurveysTableQuery'],
  })

  const submissionsCount = survey.submissions?.totalCount ?? 0

  const sharedWithEmployees =
    survey.config?.__typename === 'SurveyChannelConfiguration'
      ? (survey.config.sharedWithEmployees ?? [])
      : []

  const items = optionsToDisplay.map(key => {
    switch (key) {
      case 'viewDetails':
        return {
          key,
          label: <FormattedMessage {...messages.viewDetails} />,
        }
      case 'duplicate':
        return {
          key,
          label: <FormattedMessage {...messages.duplicate} />,
          disabled: duplicateLoading,
        }
      case 'deactivate':
        return {
          key,
          label: <FormattedMessage {...messages.deactivate} />,
          disabled: !survey.isEnabled,
        }
      case 'delete':
        return {
          key,
          label: <FormattedMessage {...messages.delete} />,
          danger: true,
        }
      case 'downloadData':
        return {
          key,
          label: <FormattedMessage {...messages.downloadData} />,
          disabled: submissionsCount === 0,
        }
      case 'changeLanguage':
        return {
          key,
          label: <FormattedMessage {...messages.changeLanguage} />,
        }
      case 'sharedWithEmployees':
        return sharedWithEmployees.length
          ? {
              key,
              label: <FormattedMessage {...messages.sharedWith} />,
            }
          : null
      case 'deleteAnswers':
        return {
          key,
          label: <FormattedMessage {...messages.deleteAnswers} />,
          disabled: true,
          danger: true,
        }
    }
  })

  return (
    <>
      <Dropdown
        menu={{
          onClick: menuItem => {
            switch (menuItem.key as MenuOptions) {
              case 'delete':
                modal.confirm.danger({
                  title: <FormattedMessage {...sharedMessages.delete} />,
                  content: <FormattedMessage {...messages.confirmDelete} />,
                  onConfirm: async () => {
                    await deleteSource({
                      variables: {
                        input: { motherId: getMotherId(), channelId: survey?.id ?? '' },
                      },
                    })
                  },
                })
                break
              case 'duplicate':
                duplicateSurvey({
                  variables: {
                    input: {
                      motherId: getMotherId(),
                      channelId: survey?.id ?? '',
                      newName: formatMessage(messages.copyPrefix, { name: survey?.name }),
                    },
                  },
                })
                break
              case 'deactivate':
                toggleSurveyActivation({
                  variables: {
                    input: {
                      motherId: getMotherId(),
                      channelId: survey?.id ?? '',
                      status: ChannelStatus.Disabled,
                    },
                  },
                })
                break
              case 'viewDetails':
                navigate(routes => routes.surveyDetail({ id: survey?.id ?? '' }))
                break
              case 'downloadData':
                setIsExportModalOpened(true)
                break
              case 'changeLanguage':
                setLanguageModalOpen(true)
                break
              case 'sharedWithEmployees':
                setSharedWithModalOpen(true)
                break
              case 'deleteAnswers': // fall-through
                break
            }
          },
          items,
        }}
      >
        <Button {...buttonProps} icon={<UntitledIcon icon={ulDotsVertical} />} />
      </Dropdown>
      {survey?.id && (
        <SurveyExportModal
          open={isExportModalOpened}
          reportSourceId={survey.id}
          onClose={() => setIsExportModalOpened(false)}
          survey={survey}
        />
      )}
      {languageModalOpen && (
        <ChangeLanguageModal
          open={languageModalOpen}
          onClose={() => setLanguageModalOpen(false)}
          survey={survey}
        />
      )}
      {isSharedWithModalOpen && (
        <SurveySharedWithEmployeesModal
          selectedEmployees={sharedWithEmployees}
          onClose={() => setSharedWithModalOpen(false)}
        />
      )}
    </>
  )
}
