import { yup } from '@faceup/form'
import { useSearchParams } from '@faceup/router'
import {
  CASE_RATING_RANGE,
  CaseClosingReason,
  CaseCreationOrigin,
  CaseDeadlineFilter,
  CaseJustification,
  CasePriority,
  type CaseRating,
  CaseSourceType,
  CaseStatus,
} from '@faceup/utils'
import moment from 'moment-timezone'
import { usePersistentUserStorage } from './persistentUserStorage'

export enum CreationOriginFilter {
  Original = 'Original',
  Redacted = 'Redacted',
}

const origins = {
  [CreationOriginFilter.Original]: [CaseCreationOrigin.Reporter, CaseCreationOrigin.Member],
  [CreationOriginFilter.Redacted]: [CaseCreationOrigin.Redaction],
}

export const reportsFilterSchema = yup.object().shape({
  dateFrom: yup.date(),
  dateTo: yup.date(),
  categoryIds: yup.array().min(1).of(yup.string().required()),
  assignedMemberIds: yup.array().min(1).of(yup.string().required()),
  selectedCompanyIds: yup.array().min(1).of(yup.string().required()),
  motherIds: yup.array().min(1).of(yup.string().required()),
  closingReasons: yup
    .array()
    .min(1)
    .of(yup.string().oneOf(Object.values(CaseClosingReason)).required()),
  sources: yup
    .array()
    .min(1)
    .of(yup.string().oneOf(Object.values(CaseSourceType)).required()),
  reportSourceIds: yup.array().min(1).of(yup.string().required()),
  labelIds: yup.array().min(1).of(yup.string().required()),
  statuses: yup
    .array()
    .min(1)
    .of(yup.string().oneOf(Object.values(CaseStatus)).required()),
  priorities: yup
    .array()
    .min(1)
    .of(yup.string().oneOf(Object.values(CasePriority)).required()),
  deadline: yup.string().oneOf(Object.values(CaseDeadlineFilter)),
  tag: yup.string(),
  justifications: yup
    .array()
    .min(1)
    .of(yup.string().oneOf(Object.values(CaseJustification)).required()),
  creationOrigin: yup.string().oneOf(Object.values(CreationOriginFilter)),
  ratings: yup
    .array()
    .min(1)
    .of(yup.string().oneOf(CASE_RATING_RANGE.map(String)).required()),
  employeeIds: yup.array().min(1).of(yup.string().required()),
  hiddenFromStatistics: yup
    .array()
    .min(1)
    .of(yup.string().oneOf(['true', 'false']).required())
    .nullable(),
})

type ReturnReportsFilterSchema = {
  dateFrom: string | null
  dateTo: string | null
  categoryIds: string[] | null
  closingReasons: CaseClosingReason[] | null
  companyIds: string[] | null
  memberIds: string[] | null
  labelIds: string[] | null
  statuses: CaseStatus[] | null
  priorities: CasePriority[] | null
  deadline: CaseDeadlineFilter | null
  sources: CaseSourceType[] | null
  tag: string | null
  justifications: CaseJustification[] | null
  reportSourceIds: string[] | null
  creationOrigins: CaseCreationOrigin[] | undefined
  ratings: CaseRating[] | null
  employeeIds: string[] | null
  hiddenFromStatistics: boolean[] | null
}

export type ReportsFilterSchema = {
  dateFrom?: string
  dateTo?: string
  categoryIds?: string[]
  assignedMemberIds?: string[]
  selectedCompanyIds?: string[]
  motherIds?: string[]
  closingReasons?: CaseClosingReason[]
  sources?: CaseSourceType[]
  reportSourceIds?: string[]
  labelIds?: string[]
  statuses?: CaseStatus[]
  priorities?: CasePriority[]
  deadline?: CaseDeadlineFilter
  tag?: string
  justifications?: CaseJustification[]
  creationOrigin?: CreationOriginFilter
  ratings?: `${CaseRating}`[]
  employeeIds?: string[]
  hiddenFromStatistics?: `${boolean}`[]
}

// Hint: Do not forget to add new parameter into gql queries which use this function,
// otherwise it will be sent to BE but not used. GQL doesn't alert you. This advice prevents unnecessary debugging.
export const convertFiltersForGqlVariables = (
  filter: ReportsFilterSchema
): ReturnReportsFilterSchema => ({
  dateFrom: filter?.dateFrom ?? null,
  dateTo: filter?.dateTo ?? null,
  categoryIds: filter?.categoryIds ?? null,
  closingReasons: filter?.closingReasons ?? null,
  companyIds: filter?.selectedCompanyIds ?? null,
  memberIds: filter?.assignedMemberIds ?? null,
  labelIds: filter?.labelIds ?? null,
  statuses: filter?.statuses ?? null,
  priorities: filter.priorities ?? null,
  deadline: filter.deadline ?? null,
  sources: filter.sources ?? null,
  // ID can be searchable with prefixed (or suffixed) # and we do not want to send this character to BE, do not use `replaceAll` as it is not supported in all browsers
  tag: filter.tag?.split('#').join('') ?? null,
  justifications: filter?.justifications ?? null,
  reportSourceIds: filter.reportSourceIds ?? null,
  creationOrigins: filter.creationOrigin ? origins[filter.creationOrigin] : undefined,
  ratings: (filter.ratings as unknown as CaseRating[]) ?? null,
  employeeIds: filter.employeeIds ?? null,
  hiddenFromStatistics: filter.hiddenFromStatistics
    ? filter.hiddenFromStatistics.map(value => value === 'true')
    : null,
})

export type FilterVariant = 'reports' | 'statistics'

export const defaultFilter: Record<FilterVariant, ReportsFilterSchema> = {
  reports: {
    statuses: [CaseStatus.Open],
  },
  statistics: {
    statuses: [CaseStatus.Open, CaseStatus.Closed],
    dateFrom: moment().subtract(1, 'year').startOf('day').toISOString(),
    dateTo: moment().endOf('day').toISOString(),
  },
}

const useFilterParams = (defaultFilter: ReportsFilterSchema) => {
  const [params, setParams] = useSearchParams()

  const getFilter = () => {
    const filter = Object.entries(reportsFilterSchema.fields).reduce((acc, [key, item]) => {
      const getValue = () => {
        if ('type' in item && item.type === 'array') {
          const values = params.getAll(key)
          return values.length > 0 ? values : undefined
        }
        return params.get(key) ?? undefined
      }
      const value = getValue()
      if (value === undefined) {
        return acc
      }
      return { ...acc, [key]: value }
    }, {} as ReportsFilterSchema)
    if (Object.keys(filter).length === 0) {
      setParams(defaultFilter)
      return defaultFilter
    }
    if (reportsFilterSchema.isValidSync(filter)) {
      return filter
    }

    setParams(defaultFilter)
    return defaultFilter
  }

  const filter = getFilter()

  const setFilter = (filter: ReportsFilterSchema) => {
    const params = Object.entries(filter).reduce(
      (acc, [key, value]) => {
        if (value === undefined) {
          return acc
        }
        return { ...acc, [key]: value }
      },
      {} as Record<string, string | string[]>
    )
    setParams(params)
  }

  return { filter, setFilter }
}

export const useReportsFilterParams = () => {
  const { setReportsFilters: setPersistedReportsFilters } = usePersistentUserStorage()
  const { filter, setFilter: setSharedFilter } = useFilterParams(defaultFilter.reports)

  const setFilter = (newFilter: ReportsFilterSchema) => {
    setSharedFilter(newFilter)
    setPersistedReportsFilters(newFilter)
  }

  return { filter, setFilter }
}

export const useStatisticsFilterParams = () => {
  const { setStatisticsFilters: setPersistedStatisticsFilters } = usePersistentUserStorage()
  const { filter, setFilter: setSharedFilter } = useFilterParams(defaultFilter.statistics)

  const setFilter = (newFilter: ReportsFilterSchema) => {
    setSharedFilter(newFilter)
    setPersistedStatisticsFilters(newFilter)
  }

  return { filter, setFilter }
}
