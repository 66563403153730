import { UntitledIcon } from '@faceup/icons'
import { ulArrowLeft } from '@faceup/icons/ulArrowLeft'
import { ulXClose } from '@faceup/icons/ulXClose'
import { Modal as AntModal } from 'antd'
import type { ReactNode } from 'react'
import { Button } from './Button'
import { Typography } from './Typography'

export type ModalProps = {
  children: ReactNode
  open: boolean
  onClose?: () => void
  width?: number | string
  footer?: ReactNode
  'data-test'?: string
  'data-onboarding'?: string
} & ModalTitleProps

type ModalTitleProps = {
  title?: ReactNode
  onClickBackArrow?: () => void
}

export const Modal = ({
  children,
  open,
  onClose,
  width,
  title,
  footer,
  onClickBackArrow,
  'data-test': dataTest,
  'data-onboarding': dataOnboarding,
  ...props
}: ModalProps) => {
  return (
    <AntModal
      title={<ModalTitle title={title} onClickBackArrow={onClickBackArrow} />}
      open={open}
      onCancel={onClose}
      footer={footer ?? null}
      centered
      width={width}
      bodyProps={{ 'data-test': dataTest, 'data-onboarding': dataOnboarding }}
      closeIcon={onClose ? <UntitledIcon icon={ulXClose} color='#547599' size={24} /> : null}
      {...props}
    >
      {children}
    </AntModal>
  )
}

const ModalTitle = (props: ModalTitleProps) => {
  const { title, onClickBackArrow } = props

  return (
    <div className='ant-modal-title-wrapper'>
      {onClickBackArrow && (
        <Button
          size='small'
          type='text'
          onClick={onClickBackArrow}
          icon={<UntitledIcon icon={ulArrowLeft} size={20} />}
        />
      )}
      <Typography.Title level={5} color='text' ellipsis>
        {title}
      </Typography.Title>
    </div>
  )
}
