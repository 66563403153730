import { Select, type SelectProps } from '@faceup/ui-base'
import { Country, convertCountryToCountryName } from '@faceup/utils'
import { useIntl } from '../TypedIntl'

type CountrySelectProps = SelectProps<Country> & {
  // Can´t be omitted due to some TS problem
  showSearch?: never
  'data-test': string
}

// TODO: sync this `CountrySelect` component with another one `CountrySelect`
const CountrySelect = (props: CountrySelectProps) => {
  const { locale } = useIntl()

  return (
    <Select
      {...props}
      showSearch
      data-test={`${props['data-test']}-input`}
      filterOption={(input, option) =>
        (option?.label?.toString() ?? '').toLowerCase().includes(input.toLowerCase())
      }
      dropdownRender={menu => <div data-test={`${props['data-test']}-menu`}>{menu}</div>}
      options={Object.values(Country).map(country => ({
        value: country,
        label: convertCountryToCountryName(country, locale),
      }))}
    />
  )
}

export default CountrySelect
