import { Button, type ButtonProps } from '@faceup/ui-base'
import { useDisclosure } from '@mantine/hooks'
import { forwardRef } from 'react'
import { UpsellIcon } from './UpsellIcon'
import { UpsellModal } from './UpsellModal'
import type { UpsellData } from './upsellData'

type UpsellButtonProps = {
  upsell: UpsellData
  onClick?: () => void
} & ButtonProps

export const UpsellButton = forwardRef<HTMLButtonElement, UpsellButtonProps>(
  ({ upsell, href, ...props }, ref) => {
    const [opened, { open, close }] = useDisclosure(false)
    const { showUpsell } = upsell

    return (
      <>
        <Button
          ref={ref}
          {...props}
          href={showUpsell ? undefined : href}
          onClick={event => {
            if (showUpsell) {
              event.preventDefault()
              open()
            } else {
              props?.onClick?.()
            }
          }}
        >
          {props.children}
          {showUpsell && <UpsellIcon isColorFromParent />}
        </Button>
        <UpsellModal open={opened} onClose={close} upsell={upsell} />
      </>
    )
  }
)
