import { Card, ContentLayout } from '@faceup/ui'

const Typography = () => (
  <ContentLayout header={<ContentLayout.Header title='Typography' />}>
    <div className='flex flex-col gap-[8px]'>
      <Card>
        <Card.Header extra='Extra content' hint='hint'>
          Title
        </Card.Header>
        <Card.Content>Lorem Ipsum</Card.Content>
      </Card>

      <Card>
        <Card.Section>
          <Card.Header extra='Extra content' hint='hint'>
            Title
          </Card.Header>
          <Card.Content>Lorem Ipsum</Card.Content>
        </Card.Section>
        <Card.Section>
          <Card.Header extra='Extra content' hint='hint'>
            Title
          </Card.Header>
          <Card.Content>Lorem Ipsum</Card.Content>
        </Card.Section>
        <Card.Section>
          <Card.Header extra='Extra content' hint='hint'>
            Title
          </Card.Header>
          <Card.Content>Lorem Ipsum</Card.Content>
        </Card.Section>
      </Card>
    </div>
  </ContentLayout>
)

export default Typography
