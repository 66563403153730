import { useMutation } from '@apollo/client'
import { Form, type InferType, Select, TextArea, TextInput, useForm, yup } from '@faceup/form'
import { UntitledIcon } from '@faceup/icons'
import { useMotherId } from '@faceup/institution'
import { getFragmentData } from '@faceup/institution/src/__generated__'
import { DropzoneImage, FormItem, pagesIcons } from '@faceup/ui'
import { Col, Flex, Row, notification } from '@faceup/ui-base'
import {
  ChannelPageType,
  MEDIUM_STRING_MAX_LENGTH,
  SHORT_STRING_MAX_LENGTH,
  STRING_MIN_LENGTH,
  TEXT_MAX_LENGTH,
} from '@faceup/utils'
import { useIntl } from 'react-intl'
import { TextEditor } from '../../../../../Components/Form'
import { sharedMessages } from '../../../../../Shared/translations'
import { FormattedMessage, defineMessages } from '../../../../../TypedIntl'
import { type FragmentType, graphql } from '../../../../../__generated__'
import { PageFormItems } from './PageFormItems'
const messages = defineMessages({
  labelIcon: 'Administration.customization.pages.label.icon',
  labelTitle: 'Administration.customization.pages.label.title',
  labelContent: 'Administration.customization.pages.label.content',
  leaveQuestion: 'Administration.customization.formItems.unsavedChangesMessage',
  title: 'Administration.surveys.detail.title',
  logo: 'Administration.surveys.detail.logo',
  description: 'Administration.surveys.detail.description',
})

const mutations = {
  EditSurveyLogoMutation: graphql(`
    mutation EditSurveyLogoMutation($input: EditSurveyLogoInput!) {
      editSurveyLogo(input: $input) {
        survey {
          id
          logoUrl
        }
      }
    }
  `),
}

const fragments = {
  PageFormAbstract_reportSource: graphql(`
    fragment PageFormAbstract_reportSource on ReportSource {
      id
      logoUrl
    }
  `),
}

const dropzoneSize = {
  width: '100%',
  height: '104px',
}

type ButtonVariant = 'create' | 'update'

const MATCH_HTML_TAG = /<([^<>]+)>/g

const useSchemas = () => {
  const { formatMessage } = useIntl()

  return {
    reportForm: yup.object().shape({
      icon: yup.string().required(),
      title: yup.string().max(SHORT_STRING_MAX_LENGTH).required(),
      content: yup
        .string()
        .max(TEXT_MAX_LENGTH)
        .test(
          'nonEmptyHtml',
          formatMessage(sharedMessages.invalidInputError),
          value => (value?.replace(MATCH_HTML_TAG, '')?.length ?? 0) > 0
        )
        .required(),
    }),
    afterSchema: yup.object().shape({
      title: yup.string().required().max(SHORT_STRING_MAX_LENGTH).nullable().notOneOf([null]),
      content: yup
        .string()
        .nullable()
        .max(MEDIUM_STRING_MAX_LENGTH)
        .min(STRING_MIN_LENGTH)
        .required(),
    }),
    beforeSchema: yup.object().shape({
      title: yup.string().required().max(SHORT_STRING_MAX_LENGTH).nullable().notOneOf([null]),
      content: yup
        .string()
        .nullable()
        .max(MEDIUM_STRING_MAX_LENGTH)
        .min(STRING_MIN_LENGTH)
        .required(),
    }),
  }
}

type Schemas = ReturnType<typeof useSchemas>

export type ReportFormValues = InferType<Schemas['reportForm']>
export type SurveyAfterSendValues = InferType<Schemas['afterSchema']>
export type SurveyBeforeSendValues = InferType<Schemas['beforeSchema']>

type ReportFormOnSubmit = (data: ReportFormValues) => boolean | Promise<boolean>
type AfterSendSubmit = (data: SurveyAfterSendValues) => boolean | Promise<boolean>
type BeforeSendSubmit = (data: SurveyBeforeSendValues) => boolean | Promise<boolean>

type PageFormAbstractProps = {
  buttonVariant: ButtonVariant
  isTitleAutofocused?: boolean
} & (
  | {
      type: 'reportForm'
      values: ReportFormValues
      onSubmit: ReportFormOnSubmit
      channel?: never
    }
  | {
      type: ChannelPageType.FormItems
      values?: never
      onSubmit?: never
      channel: FragmentType<typeof fragments.PageFormAbstract_reportSource>
    }
  | {
      type: ChannelPageType.BeforeSend
      values: SurveyBeforeSendValues
      onSubmit: BeforeSendSubmit
      channel: FragmentType<typeof fragments.PageFormAbstract_reportSource>
    }
  | {
      type: ChannelPageType.AfterSend
      values: SurveyAfterSendValues
      onSubmit: AfterSendSubmit
      channel: FragmentType<typeof fragments.PageFormAbstract_reportSource>
    }
)

const PageFormAbstract = ({
  onSubmit,
  type,
  values,
  buttonVariant,
  isTitleAutofocused,
  channel: _channel,
}: PageFormAbstractProps) => {
  const channel = getFragmentData(fragments.PageFormAbstract_reportSource, _channel)
  switch (type) {
    case 'reportForm':
      return (
        <ReportForm
          values={values}
          isTitleAutofocused={isTitleAutofocused}
          buttonVariant={buttonVariant}
          onSubmit={onSubmit}
        />
      )
    case ChannelPageType.FormItems:
      return <PageFormItems reportSourceId={channel?.id ?? ''} />
    case ChannelPageType.BeforeSend:
      return <SurveyBeforeSend onSubmit={onSubmit} values={values} channel={_channel} />
    case ChannelPageType.AfterSend:
      return <AfterSend onSubmit={onSubmit} values={values} />
  }
}

type ReportFormProps = {
  values: ReportFormValues
  buttonVariant: ButtonVariant
  onSubmit: ReportFormOnSubmit
  isTitleAutofocused?: boolean
}

const ReportForm = ({ values, buttonVariant, onSubmit, isTitleAutofocused }: ReportFormProps) => {
  const schemas = useSchemas()
  const form = useForm({
    schema: schemas.reportForm,
    afterSubmit: buttonVariant === 'create' ? 'resetValues' : 'persistValues',
    values,
  })

  return (
    <Form form={form} onSubmit={onSubmit}>
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Flex justify='space-between' gap={16}>
            <div
              style={{
                width: 80,
              }}
            >
              <Select
                label={<FormattedMessage {...messages.labelIcon} />}
                control={form.control}
                name='icon'
                style={{
                  textAlign: 'center',
                }}
                options={Object.entries(pagesIcons).map(([key, value]) => ({
                  value: key,
                  label: <UntitledIcon icon={value} />,
                }))}
              />
            </div>
            <div
              style={{
                flex: '1 0',
              }}
            >
              <TextInput
                label={<FormattedMessage {...messages.labelTitle} />}
                control={form.control}
                name='title'
                autoFocus={isTitleAutofocused}
              />
            </div>
          </Flex>
        </Col>
        <Col span={24}>
          <TextEditor
            label={<FormattedMessage {...messages.labelContent} />}
            control={form.control}
            name='content'
            maxLength={TEXT_MAX_LENGTH}
          />
        </Col>
      </Row>
    </Form>
  )
}

type SurveyBeforeSendProps = {
  onSubmit: BeforeSendSubmit
  values: SurveyBeforeSendValues
  channel: FragmentType<typeof fragments.PageFormAbstract_reportSource>
}

const SurveyBeforeSend = ({ onSubmit, values, channel: _channel }: SurveyBeforeSendProps) => {
  const channel = getFragmentData(fragments.PageFormAbstract_reportSource, _channel)
  const { getMotherId } = useMotherId()
  const { formatMessage } = useIntl()
  const [editSurveyLogo, { loading }] = useMutation(mutations.EditSurveyLogoMutation, {
    onError: error => {
      console.error(error)
      notification.error({
        message: formatMessage(sharedMessages.apiError),
        description: error.message,
      })
    },
  })

  const schemas = useSchemas()
  const form = useForm({
    schema: schemas.beforeSchema,
    afterSubmit: 'persistValues',
    values,
  })
  return (
    <Form form={form} onSubmit={onSubmit}>
      <Flex gap={32} vertical>
        <FormItem withAsterisk label={<FormattedMessage {...messages.logo} />}>
          <DropzoneImage
            onRemove={() =>
              editSurveyLogo({
                variables: {
                  input: { logo: null, channelId: channel.id, motherId: getMotherId() },
                },
              })
            }
            setLogo={image => {
              editSurveyLogo({
                variables: {
                  input: { logo: image, channelId: channel.id, motherId: getMotherId() },
                },
              })
            }}
            shownLogo={channel.logoUrl}
            loading={loading}
            width={dropzoneSize.width}
            height={dropzoneSize.height}
          />
        </FormItem>
        <TextInput
          label={<FormattedMessage {...messages.title} />}
          control={form.control}
          name='title'
        />
        <TextArea
          label={<FormattedMessage {...messages.description} />}
          control={form.control}
          name='content'
          rows={5}
        />
      </Flex>
    </Form>
  )
}

type AfterSendProps = {
  onSubmit: AfterSendSubmit
  values: SurveyBeforeSendValues
}

const AfterSend = ({ onSubmit, values }: AfterSendProps) => {
  const schemas = useSchemas()
  const form = useForm({
    schema: schemas.afterSchema,
    afterSubmit: 'persistValues',
    values,
  })
  return (
    <Form form={form} onSubmit={onSubmit}>
      <Flex gap={32} vertical>
        <TextInput
          label={<FormattedMessage {...messages.title} />}
          control={form.control}
          name='title'
        />
        <TextArea
          label={<FormattedMessage {...messages.description} />}
          control={form.control}
          name='content'
          rows={5}
        />
      </Flex>
    </Form>
  )
}

export default PageFormAbstract
