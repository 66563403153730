import { UntitledIcon } from '@faceup/icons'
import { ulChevronDown } from '@faceup/icons/ulChevronDown'
import { ulChevronLeft } from '@faceup/icons/ulChevronLeft'
import { ulChevronRight } from '@faceup/icons/ulChevronRight'

import type { PaginationProps } from 'antd'

export const itemRender: PaginationProps['itemRender'] = (_, type, originalElement) => {
  if (type === 'prev') {
    return (
      <button className='ant-pagination-item-link'>
        <span className='anticon anticon-left'>
          <UntitledIcon icon={ulChevronLeft} />
        </span>
      </button>
    )
  }
  if (type === 'next') {
    return (
      <button className='ant-pagination-item-link'>
        <span className='anticon anticon-right'>
          <UntitledIcon icon={ulChevronRight} />
        </span>
      </button>
    )
  }
  return originalElement
}

export const showSizeChanger = {
  suffixIcon: <UntitledIcon icon={ulChevronDown} size={16} />,
}
