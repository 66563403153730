import { Skeleton as AntSkeleton, type SkeletonProps as AntSkeletonProps } from 'antd'
import type { ComponentProps } from 'react'

export type SkeletonProps = AntSkeletonProps

export const Skeleton = ({
  active = true,
  paragraph = { width: '100%' }, // last paragraph line width should be 100%
  ...props
}: SkeletonProps) => {
  return <AntSkeleton {...props} active={active} paragraph={paragraph} />
}

const Button = ({
  active = true,
  size = 'large',
  ...props
}: ComponentProps<typeof AntSkeleton.Button>) => {
  return <AntSkeleton.Button {...props} active={active} size={size} />
}

const Input = ({
  active = true,
  size = 'large',
  ...props
}: ComponentProps<typeof AntSkeleton.Input>) => {
  return <AntSkeleton.Input {...props} active={active} size={size} />
}

const Node = ({ active = true, ...props }: ComponentProps<typeof AntSkeleton.Node>) => {
  return <AntSkeleton.Node {...props} active={active} />
}

const Avatar = ({ active = true, ...props }: ComponentProps<typeof AntSkeleton.Avatar>) => {
  return <AntSkeleton.Avatar {...props} active={active} />
}

const Image = ({ active = true, ...props }: ComponentProps<typeof AntSkeleton.Image>) => {
  return <AntSkeleton.Image {...props} active={active} />
}

Button.displayName = 'Skeleton.Button'
Input.displayName = 'Skeleton.Input'
Node.displayName = 'Skeleton.Node'
Avatar.displayName = 'Skeleton.Avatar'
Image.displayName = 'Skeleton.Image'

Skeleton.Button = Button
Skeleton.Input = Input
Skeleton.Node = Node
Skeleton.Avatar = Avatar
Skeleton.Image = Image
