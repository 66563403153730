import { UntitledIcon, type UntitledIconData } from '@faceup/icons'
import { ulInfoCircle } from '@faceup/icons/ulInfoCircle'
import { ulXClose } from '@faceup/icons/ulXClose'
import { usCheckCircle } from '@faceup/icons/usCheckCircle'
import { usInfoCircle } from '@faceup/icons/usInfoCircle'
import { usXCircle } from '@faceup/icons/usXCircle'
import { App, type ModalFuncProps } from 'antd'
import type { ReactNode } from 'react'

const sharedStyles: ModalFuncProps['styles'] = {
  content: { padding: '20px 24px', borderRadius: '12px' },
  mask: {
    backgroundColor: '#062D46B2',
  },
}

const sharedProps: ModalFuncProps = {
  styles: sharedStyles,
  closable: false,
  centered: true,
  closeIcon: <UntitledIcon icon={ulXClose} size={22} color='#062D46' />,
}

export const alertTypes = ['info', 'success', 'error', 'warning'] as const
export type AlertType = (typeof alertTypes)[number]

export const confirmTypes = ['warning', 'danger'] as const
export type ConfirmType = (typeof confirmTypes)[number]

type ReturnAlertModalFunctionProps = {
  onConfirm?: () => void
  confirmText?: ReactNode
} & Pick<ModalFuncProps, 'title' | 'content'>

type ReturnConfirmModalFunctionProps = {
  onConfirm?: () => void
  onCancel?: () => void
  confirmText?: ReactNode
  cancelText?: ReactNode
} & Pick<ModalFuncProps, 'title' | 'content' | 'afterClose'>

const iconsAlert: Record<AlertType, { icon: UntitledIconData; color: string }> = {
  info: { icon: usInfoCircle, color: '#0085FF' },
  success: { icon: usCheckCircle, color: '#32C27B' },
  error: { icon: usXCircle, color: '#F54070' },
  warning: { icon: usInfoCircle, color: '#FA914B' },
}

const iconsConfirm: Record<ConfirmType, { icon: UntitledIconData; color: string }> = {
  warning: { icon: ulInfoCircle, color: '#FA914B' },
  danger: { icon: ulInfoCircle, color: '#F54070' },
}

export const useModal = () => {
  const app = App.useApp()

  const getIconAlert = (type: AlertType) => {
    return type ? (
      <UntitledIcon icon={iconsAlert[type].icon} color={iconsAlert[type].color} size={21} />
    ) : undefined
  }

  const getIconConfirm = (type: ConfirmType) => {
    return type ? (
      <UntitledIcon icon={iconsConfirm[type].icon} color={iconsConfirm[type].color} size={21} />
    ) : undefined
  }

  const createCustomAlertModal = (
    type: AlertType,
    { confirmText, onConfirm, ...props }: ReturnAlertModalFunctionProps
  ) => {
    return app.modal[type]({
      icon: getIconAlert(type),
      ...sharedProps,
      ...props,
      onOk: onConfirm,
      okText: confirmText,
    })
  }

  type ConfirmType = 'warning' | 'danger'
  const createConfirmCustomModal = (
    type: ConfirmType,
    { onConfirm, onCancel, confirmText, cancelText, ...props }: ReturnConfirmModalFunctionProps
  ) => {
    const mapConfirmToAlert: Record<ConfirmType, AlertType> = {
      warning: 'warning',
      danger: 'error',
    }
    return app.modal[mapConfirmToAlert[type]]({
      type: 'confirm',
      icon: getIconConfirm(type),
      ...sharedProps,
      ...props,
      onOk: onConfirm,
      onCancel,
      okText: confirmText,
      okButtonProps: { danger: type === 'danger' },
      cancelText,
      okCancel: true,
    })
  }

  return {
    alert: {
      info: (props: ReturnAlertModalFunctionProps) => createCustomAlertModal('info', props),
      success: (props: ReturnAlertModalFunctionProps) => createCustomAlertModal('success', props),
      error: (props: ReturnAlertModalFunctionProps) => createCustomAlertModal('error', props),
      warning: (props: ReturnAlertModalFunctionProps) => createCustomAlertModal('warning', props),
    },
    confirm: {
      warning: (props: ReturnConfirmModalFunctionProps) =>
        createConfirmCustomModal('warning', props),
      danger: (props: ReturnConfirmModalFunctionProps) => createConfirmCustomModal('danger', props),
    },
  }
}
