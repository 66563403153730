import type { UntitledIconData } from '@faceup/icons'

export const ucLoader: UntitledIconData = {
  prefix: 'custom',
  name: 'ucLoader',
  viewBox: '0 0 24 24',
  paths: [
    {
      shape:
        'M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12ZM3.5 12C3.5 16.6944 7.30558 20.5 12 20.5C16.6944 20.5 20.5 16.6944 20.5 12C20.5 7.30558 16.6944 3.5 12 3.5C7.30558 3.5 3.5 7.30558 3.5 12Z',
      fill: 'currentColor',
      opacity: 0.1,
    },
    {
      shape:
        'M12 2.75C12 2.33579 12.3363 1.99707 12.7494 2.0281C14.8952 2.1893 16.9395 3.03956 18.5716 4.46246C20.2036 5.88536 21.3246 7.79469 21.7767 9.89857C21.8638 10.3035 21.574 10.6829 21.1637 10.7393C20.7533 10.7958 20.3782 10.5078 20.2859 10.104C19.8866 8.35909 18.9446 6.77776 17.5858 5.59309C16.227 4.40843 14.5321 3.69076 12.7491 3.53305C12.3365 3.49656 12 3.16421 12 2.75Z',
      fill: 'currentColor',
    },
  ],
}
