import { InputPhone, Scale } from '@faceup/ui'
import { DatePicker, Input, MultiSelect, Select } from '@faceup/ui-base'
import { FormItemType, type Language, SHORT_STRING_MAX_LENGTH, getTranslation } from '@faceup/utils'
import type { ResultOf } from '@graphql-typed-document-node/core'
import moment from 'moment-timezone'
import { useRef } from 'react'
import { graphql } from '../../../../__generated__'

const fragments = {
  FormElement_formItem: graphql(`
    fragment FormElement_formItem on FormItem {
      id
      formItemId
      type
      hasHint
      isDeleted
      isRequired
      labelTranslations {
        language
        translation
      }
      hintTranslations {
        language
        translation
      }
      options(includeDeleted: $includeDeletedOptions) {
        id
        isDeleted
        order
        labelTranslations {
          translation
          language
        }
      }
      scaleStartLabelTranslations {
        language
        translation
      }
      scaleEndLabelTranslations {
        language
        translation
      }
    }
  `),
}

type FormElementProps = {
  item: ResultOf<typeof fragments.FormElement_formItem>
  language: Language
  defaultLanguage?: Language
  type: FormItemType
  values: string[]
  onChange: (values: string[]) => void
}

export const FormElement = (props: FormElementProps) => {
  const { item, type, values, onChange, language, defaultLanguage } = props
  const singleValue = values[0] ?? ''
  /**
   * Only for Select: when the current option is deleted we still have to show it, but it's not possible to select it anymore.
   */
  const currentValue = useRef(singleValue)

  switch (type) {
    case FormItemType.SimpleText:
      return (
        <Input
          value={singleValue}
          onChange={e => {
            onChange([e.target.value])
          }}
          maxLength={SHORT_STRING_MAX_LENGTH}
        />
      )
    case FormItemType.Select: {
      const options = item.options
      return (
        <Select
          style={{ width: '100%' }}
          value={singleValue}
          onChange={value => onChange(value ? [value] : [])}
          allowClear
          options={options
            .filter(option => !option.isDeleted || option.id === currentValue.current)
            .map(option => ({
              label: getTranslation(option.labelTranslations, language, defaultLanguage),
              value: option.id,
              disabled: option.isDeleted,
            }))}
        />
      )
    }
    case FormItemType.MultiSelect: {
      const options = item.options
      return (
        <MultiSelect
          style={{ width: '100%' }}
          value={values}
          allowClear
          onChange={values => onChange(values)}
          options={options
            .filter(option => !option.isDeleted)
            .map(option => ({
              label: getTranslation(option.labelTranslations, language, defaultLanguage),
              value: option.id,
              disabled: option.isDeleted,
            }))}
        />
      )
    }
    case FormItemType.MultilineText:
      return (
        <Input.TextArea
          value={singleValue}
          onChange={e => {
            onChange([e.target.value])
          }}
          rows={6}
        />
      )
    case FormItemType.Date:
      return (
        <DatePicker
          value={singleValue ? moment(singleValue) : undefined}
          onChange={date => {
            onChange([date?.toISOString() ?? ''])
          }}
          style={{
            width: '100%',
          }}
        />
      )
    case FormItemType.Email:
      return (
        <Input
          value={singleValue}
          onChange={e => {
            onChange([e.target.value])
          }}
          maxLength={SHORT_STRING_MAX_LENGTH}
        />
      )
    case FormItemType.PhoneNumber:
      return (
        <InputPhone
          value={singleValue}
          onChange={value => {
            onChange([value])
          }}
        />
      )
    case FormItemType.Scale: {
      const translatedOptions = item.options.map(option => {
        const label = getTranslation(option.labelTranslations, language, defaultLanguage)

        return {
          id: option.id,
          label,
          isActive: singleValue === label,
        }
      })

      return (
        <>
          <input style={{ width: 0, height: 0, visibility: 'hidden', position: 'absolute' }} />
          <Scale
            startLabel={
              item.scaleStartLabelTranslations
                ? getTranslation(item.scaleStartLabelTranslations, language, defaultLanguage)
                : undefined
            }
            endLabel={
              item.scaleEndLabelTranslations
                ? getTranslation(item.scaleEndLabelTranslations, language, defaultLanguage)
                : undefined
            }
            onChange={onChange}
            options={translatedOptions}
          />
        </>
      )
    }
    case FormItemType.SenderName:
    case FormItemType.Classroom:
    case FormItemType.MoreInformation:
    case FormItemType.OrganizationalUnit:
    case FormItemType.Category:
      // it's rendered via moreInformationRender in CustomFormItems
      return null
  }
}
