import { twMerge, useTabKeyBlocker } from '@faceup/ui'
import { useDisclosure } from '@mantine/hooks'
import { type ReactNode, useRef } from 'react'
import { UpsellModal } from './UpsellModal'
import { UpsellProvider } from './contexts'
import type { UpsellData } from './upsellData'

type UpsellProps = {
  upsell: UpsellData
  children: ReactNode
  className?: string
}

export const Upsell = (props: UpsellProps) => {
  const { upsell, children, className } = props
  const [opened, { open, close }] = useDisclosure(false)
  const ref = useRef<HTMLDivElement>(null)
  useTabKeyBlocker(upsell.showUpsell ? [ref] : [])

  return (
    <>
      <UpsellProvider upsell={upsell}>
        <div ref={ref} onClick={upsell.showUpsell ? open : undefined}>
          <div className={twMerge(className, upsell.showUpsell && 'pointer-events-none')}>
            {children}
          </div>
        </div>
      </UpsellProvider>
      <UpsellModal open={opened} onClose={close} upsell={upsell} />
    </>
  )
}
