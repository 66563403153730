import { UntitledIcon } from '@faceup/icons'
import ulEye from '@faceup/icons/src/UntitledIcons/icons/line/ulEye'
import { ulAttachment01 } from '@faceup/icons/ulAttachment01'
import { ulDownload01 } from '@faceup/icons/ulDownload01'
import { ulTrash01 } from '@faceup/icons/ulTrash01'
import { Upload as AntUpload, type UploadProps as AntUploadProps } from 'antd'
import type { ComponentProps } from 'react'

export type UploadProps = Omit<AntUploadProps, 'showUploadList'> & {
  showUploadList?: Omit<
    AntUploadProps['showUploadList'],
    'removeIcon' | 'previewIcon' | 'downloadIcon' | 'extra'
  >
}

export const Upload = (props: UploadProps) => {
  return (
    <AntUpload
      {...props}
      showUploadList={{
        ...props.showUploadList,
        ...sharedUploadList,
      }}
    />
  )
}

type AntDraggerProps = ComponentProps<typeof AntUpload.Dragger>
type DraggerProps = {
  showUploadList?: Omit<
    AntDraggerProps['showUploadList'],
    'removeIcon' | 'previewIcon' | 'downloadIcon' | 'extra'
  >
} & Omit<AntDraggerProps, 'showUploadList'>

Upload.Dragger = (props: DraggerProps) => {
  return (
    <AntUpload.Dragger
      {...props}
      showUploadList={{
        ...props.showUploadList,
        ...sharedUploadList,
      }}
    />
  )
}

const sharedUploadList = {
  // span wrapper is needed to inherit icon color
  removeIcon: (
    <span className='anticon'>
      <UntitledIcon icon={ulTrash01} />
    </span>
  ),
  previewIcon: (
    <span className='anticon'>
      <UntitledIcon icon={ulEye} />
    </span>
  ),
  downloadIcon: (
    <span className='anticon'>
      <UntitledIcon icon={ulDownload01} />
    </span>
  ),
  // hack for rtl functionality
  extra: (
    <span
      style={{
        position: 'absolute',
        left: 0,
        right: 0,
        width: '100%',
        pointerEvents: 'none',
        color: '#6F91B7',
      }}
    >
      <UntitledIcon icon={ulAttachment01} />
    </span>
  ),
}
