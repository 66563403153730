import { UntitledIcon } from '@faceup/icons'
import { useThemeColors } from '@faceup/ui'
import { Avatar, Typography } from '@faceup/ui-base'
import type { ReactNode } from 'react'
import CheckIcon from './assets/check-icon.svg'
import { DotsMesh, UpgradeButtons } from './components'
import type { UpsellData } from './upsellData'

type UpsellPageContentProps = {
  upsell: UpsellData
  children: ReactNode
}

export const UpsellPageContent = (props: UpsellPageContentProps) => {
  const { children, upsell } = props
  const { icon, title, description, features, image, showUpsell } = upsell

  const { themeColors } = useThemeColors()

  if (!showUpsell) {
    return <>{children}</>
  }

  return (
    <div className='my-4 mx-8 grid grid-cols-12 gap-12 justify-center items-center flex-1'>
      <div className='col-span-5'>
        <div className='flex flex-col gap-8'>
          <Avatar size={56} className='bg-blue-500'>
            <UntitledIcon icon={icon} />
          </Avatar>
          <div className='flex flex-col gap-4'>
            <Typography.Title level={3}>{title}</Typography.Title>
            <Typography.Text className='text-prussianBlue-800'>{description}</Typography.Text>
            {features.map((feature, i) => (
              // biome-ignore lint/suspicious/noArrayIndexKey:
              <div key={i} className='flex items-center gap-1'>
                <img width={16} height={16} src={CheckIcon} alt='' />
                <Typography.Text className='text-prussianBlue-800'>{feature}</Typography.Text>
              </div>
            ))}
          </div>
          <UpgradeButtons upsell={upsell} />
        </div>
      </div>
      {
        <div className='relative col-span-7 p-12'>
          <div className='absolute right-0 top-0'>
            <DotsMesh rows={4} cols={12} />
          </div>
          {image && (
            <img
              className='relative z-1 object-cover w-full h-auto'
              src={image.toString()}
              alt=''
            />
          )}
          <div className='absolute left-0 bottom-0'>
            <DotsMesh rows={12} cols={12} color={themeColors.success[20]} />
          </div>
        </div>
      }
    </div>
  )
}
