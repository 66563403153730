import { useQuery } from '@apollo/client'
import { UpsellPageContent, useMotherId, useUpsell } from '@faceup/institution'
import { useParams } from '@faceup/router'
import { ContentLayout } from '@faceup/ui'
import { useState } from 'react'
import { graphql } from '../../__generated__'
import InstitutionCustomizationPagesView from '../InstitutionCustomization/ReportForm/InstitutionCustomizationPagesView'
import { PublishSurveyModal, type SurveyModalModes } from './components'
import { SurveyContentHeader } from './components/SurveyContentHeader'

const query = {
  SurveyDetailViewQuery: graphql(`
    query SurveyDetailViewQuery(
      $channelId: ReportSourceGlobalId!
      $motherId: UUID!
    ) {
      survey(reportSourceId: $channelId, motherId: $motherId) {
        id
        ...SurveyContentHeader_reportSource
        ...PublishSurveyModalFragment
      }
    }
  `),
}

const SurveyDetailView = () => {
  const { id } = useParams<{ id: string }>()

  const upsell = useUpsell()
  const { getMotherId } = useMotherId()

  const [shareModal, setShareModal] = useState<SurveyModalModes>()

  const { data } = useQuery(query.SurveyDetailViewQuery, {
    variables: { channelId: id ?? '', motherId: getMotherId() },
  })

  const survey = data?.survey

  if (!survey) {
    return null
  }

  return (
    <UpsellPageContent upsell={upsell.surveys}>
      <ContentLayout
        header={
          <SurveyContentHeader
            survey={survey}
            surveyNavigationType='submissions'
            arrowGoBackTo={routes => routes.surveys()}
          />
        }
      >
        <InstitutionCustomizationPagesView formId={survey.id} />
      </ContentLayout>
      {shareModal && (
        <PublishSurveyModal
          survey={survey}
          mode={shareModal}
          onClose={() => setShareModal(undefined)}
        />
      )}
    </UpsellPageContent>
  )
}

export default SurveyDetailView
