import type { UntitledIconData } from '@faceup/icons'

export const ucSubscript: UntitledIconData = {
  prefix: 'custom',
  name: 'ucSubscript',
  viewBox: '0 0 24 24',
  paths: [
    {
      shape:
        'M3 7L13 17M13 7L3 17M21 21.0001H17C17 19.5001 17.44 19.0001 18.5 18.5001C19.56 18.0001 21 17.3301 21 16.0001C21 15.5301 20.83 15.0701 20.52 14.7101C20.1999 14.3476 19.7649 14.1061 19.2879 14.026C18.811 13.9459 18.321 14.0321 17.9 14.2701C17.48 14.5101 17.16 14.8901 17 15.3401',
      stroke: 'currentColor',
      strokeWidth: 1.25,
      strokeLinecap: 'round',
      strokeLinejoin: 'round',
    },
  ],
}
